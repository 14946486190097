import { PATH_COURSES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ListCoursesModel } from "../data/course.model";

export const GetAllCoursesUsecase: (
  params: string
) => Promise<ListCoursesModel> = async (params: string) => {
  try {
    let response = await client.get(PATH_COURSES + params);
    return {
      list: response.data.data.list,
      meta: response.data.data.meta,
    };
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
