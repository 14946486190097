/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FaExclamationTriangle,
  FaEyeSlash,
  FaLink,
  FaRegCalendarAlt,
  FaRegClipboard,
  FaUserAlt,
} from "react-icons/fa";

import { AccordionComponent } from "@Components/accordion/Accordion.component";
import { ContentHeader } from "@Components/ContentHeader.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { MidasVideoPlayer } from "@Components/video-player/VideoPlayer.component";
import { convertDateFromApiToView } from "@Helpers/formatter";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { copyToClipboard } from "@Utils/Utils";

import { ResourceModel } from "../data/resource.model";
import { DeleteResourceUsecase } from "../domain/DeleteResource.usecase";
import { GetResource } from "../domain/GetResource.usecase";
import { UpdateResourceUsecase } from "../domain/UpdateResource.usecase";

export function DetailResourcePage() {
  const params = useParams();
  const navigate = useNavigate();

  const modalContext = UseModalContext();
  const toasterContext = UseToasterContext();

  const [detail, setDetail] = useState<ResourceModel>();

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await GetResource(params.id ?? "");
        setDetail(response);
      } catch (errors) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to fetch Resource",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      }
    }
    fetchData();
  }, []);

  const handleCopyToClipboard = () => {
    copyToClipboard(detail?.link_url ?? "");
    toasterContext.setToastList([
      ...toasterContext.toastList,
      new ToasterModel(
        "Berhasil",
        "URL media berhasil disalin.",
        ToasterType.SUCCESS
      ),
    ]);
  };

  const showDeleteModal = () => {
    let icon = (
      <div className="text-red-500">
        <FaExclamationTriangle />
      </div>
    );
    let title = "Hapus Media";
    let description = (
      <>
        Apakah anda yakin ingin menghapus{" "}
        <span className="font-bold">{detail?.name}</span> ?
      </>
    );
    let primaryCta = (
      <button
        className="button-red-outline-with-hover"
        onClick={() => handleDelete()}
      >
        Hapus
      </button>
    );
    let secondaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(icon, title, description, primaryCta, secondaryCta)
    );
  };

  const handleDelete = async () => {
    try {
      await DeleteResourceUsecase(detail?.id ?? 0);
      navigate(-1);
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to delete media",
          errors.message,
          ToasterType.SUCCESS
        ),
      ]);
    }
  };

  const updateData = async () => {
    try {
      await UpdateResourceUsecase(
        detail?.id,
        detail?.alt_text,
        detail?.description
      );
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Berhasil",
          "Media detail berhasil di perbarui!",
          ToasterType.SUCCESS
        ),
      ]);
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to update media",
          errors.message,
          ToasterType.SUCCESS
        ),
      ]);
    }
  };

  return (
    <div className="p-4 h-full bg-gray-300">
      {!detail && <MidasShimmering />}

      {detail && (
        <>
          <ContentHeader title={detail.name} />
          <div className="h-6" />

          <div className="place-self-center w-full justify-center">
            {detail.mime_type.includes("image") ? (
              <img src={detail.link_url} className="place-self-center" alt="" />
            ) : detail.mime_type.includes("video") ? (
              <MidasVideoPlayer
                componentKey={"video-" + detail.id}
                videoUrl={detail.link_url}
                options={{
                  widthPercentage: 100,
                  showVideoControls: true,
                  isAutoPlay: false,
                  canDownload: false,
                  videoSource: "file",
                }}
              />
            ) : (
              <div className="w-16 h-16 m-auto">
                <FaEyeSlash />
              </div>
            )}
          </div>
          <div className="w-full mt-4">
            <div>Alternative Text</div>
            <input
              type="text"
              className="input-field-full-width"
              value={detail.alt_text != null ? detail.alt_text : ""}
              onChange={(e) =>
                setDetail({
                  ...detail,
                  alt_text: e.target.value,
                })
              }
            />
          </div>
          <div className="w-full mt-4">
            <div>Description</div>
            <input
              type="text"
              className="input-field-full-width"
              value={detail.description != null ? detail.description : ""}
              onChange={(e) =>
                setDetail({
                  ...detail,
                  description: e.target.value,
                })
              }
            />
          </div>
          <div className="w-full mt-4 border bg-white">
            <AccordionComponent title="Save" isOpen={true}>
              <div className="p-4">
                {/* Uploaded Date */}
                <div className="flex">
                  <div className="w-4">
                    <FaRegCalendarAlt />
                  </div>
                  <div className="ml-2 text-xs m-auto text-ellipsis">
                    Uploaded on:{" "}
                    <span className="font-medium">
                      {convertDateFromApiToView(detail.created_at)}
                    </span>
                  </div>
                </div>

                {/* Uploaded By */}
                <div className="flex mt-2">
                  <div className="w-4">
                    <FaUserAlt />
                  </div>
                  <div className="ml-2 text-xs m-auto text-ellipsis">
                    Uploaded by:{" "}
                    <span className="font-medium">
                      {detail.created_by.fullname}
                    </span>
                  </div>
                </div>

                {/* Resource URL */}
                <div className="flex mt-2">
                  <div className="w-4">
                    <FaLink />
                  </div>
                  <div className="w-full ml-2 text-xs text-ellipsis">
                    File URL:{" "}
                    <span className="font-medium">{detail.link_url}</span>
                  </div>
                  <div className="w-4" onClick={handleCopyToClipboard}>
                    <FaRegClipboard />
                  </div>
                </div>

                {/* Resource Name */}
                <div className="flex mt-2">
                  <div className="w-full h-4 text-xs text-ellipsis">
                    File Path:{" "}
                    <span className="font-medium">{detail.path}</span>
                  </div>
                </div>

                {/* Resource Mime */}
                <div className="flex mt-2">
                  <div className="w-full h-4 text-xs text-ellipsis">
                    File Mime:{" "}
                    <span className="font-medium">{detail.mime_type}</span>
                  </div>
                </div>
              </div>

              <div className="flex border-t-2 justify-between py-2 mt-2 px-4">
                <button
                  className="button-red-outline-with-hover text-xs"
                  onClick={showDeleteModal}
                >
                  Delete Permanently
                </button>
                <button
                  className="button-yellow-outline-with-hover text-xs"
                  onClick={updateData}
                >
                  Save
                </button>
              </div>
            </AccordionComponent>
          </div>
        </>
      )}
    </div>
  );
}
