import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { SaveSettingsUsecase } from "@Pages/setting/domain/SaveSettings.usecase";
import {
  PHILOSOPHY_CRITERIA,
  PHILOSOPHY_TOP_HEADER,
} from "@Pages/setting/utils/const";
import { FC } from "react";
import {
  CarouselFormComponent,
  CarouselInputModel,
} from "../components/CarouselForm.component";
import {
  CriteriaFormComponent,
  CriteriaInputModel,
} from "../components/CriteriaForm.component";
import { SettingsSectionsProps } from "../SettingStaticPages.page";

export const PhilosophySection: FC<SettingsSectionsProps> = ({
  settingsData,
  setNeedRefetch,
}) => {
  const toasterContext = UseToasterContext();

  const getTopHeaderDetail = () => {
    let data = settingsData.find((item) => item.key === PHILOSOPHY_TOP_HEADER);
    if (data) {
      let parsed = JSON.parse(data.value);
      return [parsed];
    }
    return [{ imageUrl: "" }];
  };

  const getCriteriaList = () => {
    let data = settingsData.find((item) => item.key === PHILOSOPHY_CRITERIA);
    if (data) {
      let parsed = JSON.parse(data.value);
      return parsed;
    }
    return [];
  };

  const saveSetting = (
    type: string,
    isUpdating: boolean,
    key: string,
    value: string
  ) => {
    SaveSettingsUsecase(isUpdating, key, value)
      .then((message) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            `${type} updated successfully!`,
            message,
            ToasterType.SUCCESS
          ),
        ]);
        setNeedRefetch(true);
      })
      .catch((errors) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to update settings!",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      });
  };

  const onSaveTopHeaderDetail = (data: CarouselInputModel[]) => {
    if (data.length === 0) {
      return;
    }

    let listStringify = JSON.stringify(data[0]);
    listStringify.replaceAll("null,", "");
    let currentList = settingsData.find(
      (item) => item.key === PHILOSOPHY_TOP_HEADER
    );

    saveSetting(
      "Top Header",
      currentList !== undefined,
      PHILOSOPHY_TOP_HEADER,
      listStringify
    );
  };

  const onSaveCriteriaList = (data: CriteriaInputModel[]) => {
    if (data.length === 0) {
      return;
    }

    let listStringify = JSON.stringify(data);
    listStringify.replaceAll("null,", "");
    let currentList = settingsData.find(
      (item) => item.key === PHILOSOPHY_CRITERIA
    );

    saveSetting(
      "Criteria",
      currentList !== undefined,
      PHILOSOPHY_CRITERIA,
      listStringify
    );
  };

  return (
    <div className="mt-4">
      <CarouselFormComponent
        title="Top Header"
        max={1}
        carouselList={getTopHeaderDetail()}
        onSaveCarousel={onSaveTopHeaderDetail}
        isNeedProduct={false}
        products={[]}
      />
      <div className="w-full border-t mt-4"></div>
      <CriteriaFormComponent
        criteriaList={getCriteriaList()}
        onSaveCriteria={onSaveCriteriaList}
      />
    </div>
  );
};
