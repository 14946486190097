import { AddSettingUsecase } from "./AddSetting.usecase";
import { BulkUpdateSettingsUsecase } from "./BulkUpdateSettings.usecase";

export const SaveSettingsUsecase = async (
  isUpdating: boolean,
  key: string,
  value: string
) => {
  if (isUpdating) {
    return await BulkUpdateSettingsUsecase(
      JSON.stringify([
        {
          key: key,
          value: value,
        },
      ])
    );
  } else {
    return await AddSettingUsecase(key, value);
  }
};
