/* eslint-disable react-hooks/exhaustive-deps */
import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { ShopModel } from "@Pages/get-response/data/shop.model";
import {
  AddShopUsecase,
  DeleteShopUsecase,
} from "@Pages/get-response/domain/Shops.usecase";
import { FC, useRef } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { PageControllerComponent } from "../components/PageController.component";
import { GetResponseSection, SectionProps } from "../GetResponse.page";

export type ShopSectionProps = SectionProps & {
  data: ShopModel[];
};

export const ShopSection: FC<ShopSectionProps> = ({
  data,
  page,
  setPage,
  needRefetch,
}) => {
  const modalContext = UseModalContext();
  const toastContext = UseToasterContext();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onAddNewShop = async () => {
    if (inputRef.current === null) return;

    let response = await AddShopUsecase(inputRef.current.value);
    toastContext.setToastList([
      ...toastContext.toastList,
      new ToasterModel(response, "", ToasterType.SUCCESS),
    ]);

    needRefetch([GetResponseSection.SHOPS]);
  };

  const onDelete = async (id: string) => {
    await DeleteShopUsecase(id);
    modalContext.setModal(null);
    needRefetch([GetResponseSection.SHOPS]);
  };

  const showDeleteModal = (index: number) => {
    let title = data[index].id;
    let description = `Apakah anda yakin ingin menghapus ${data[index].name} ?`;
    let primaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => onDelete(title)}
      >
        Delete
      </button>
    );
    let secondaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Close
      </button>
    );

    modalContext.setModal(
      createModal(null, title, description, primaryCta, secondaryCta)
    );
  };

  return (
    <div className="mt-4">
      <div className="flex w-full">
        <input
          ref={inputRef}
          className="input-field-full-width mr-4"
          type={"text"}
        />
        <YellowHoverButton label="Add" onClick={onAddNewShop} />
      </div>

      <table className={`border-collapse border w-full table-auto mt-4`}>
        <thead className="p-2 shadow-sm bg-gray-100 sticky">
          <tr>
            <th className="border border-spacing-1 text-center font-bold px-2">
              ID
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 text-ellipsis">
              Name
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 hidden md:table-cell">
              Locale
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 hidden md:table-cell">
              Currency
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={`shop-row-${item.id}-${index}`}>
              <td className="border px-2 text-center">{item.id}</td>
              <td className="border px-2 text-left">
                {item.name}
                <div>
                  <button onClick={() => showDeleteModal(index)}>
                    <FaTrashAlt size={20} className="text-red-500" />
                  </button>
                </div>
              </td>
              <td className="border px-2 hidden md:table-cell">
                {item.locale}
              </td>
              <td className="border px-2 hidden md:table-cell">
                {item.currency}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PageControllerComponent
        currentPage={page}
        showPrev={page > 1}
        showNext={data.length >= 100}
        onPrev={setPage}
        onNext={setPage}
      />
    </div>
  );
};
