import { PATH_RESOURCES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { AxiosProgressEvent } from "axios";

export const UploadResourceUsecase: (
  uploadProgressCallback: (progressEvent: AxiosProgressEvent) => void,
  title: string,
  files: FileList,
  alt?: string,
  description?: string
) => Promise<boolean> = async (
  uploadProgressCallback,
  title,
  files,
  alt,
  description
) => {
  try {
    let formData = new FormData();
    formData.append("name", title);
    formData.append("file", files[0]);
    formData.append("file_name", title);
    if (alt) formData.append("alt_text", alt);
    if (description) formData.append("description", description);

    await client.post(PATH_RESOURCES, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: uploadProgressCallback,
    });
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
