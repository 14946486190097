import { ProductModel } from "@Pages/product/data/product.model";
import { FC, useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaPlus,
  FaRegTrashAlt,
} from "react-icons/fa";
import {
  MediaEnum,
  SelectMediaInputComponent,
} from "./forms/SelectMediaInput.component";

export type TeaserInputModel = {
  productId: string;
  imageUrl: string;
  altText: string;
  teaserUrl: string;
};

export type TeaserInputProps = {
  index: number;
  carousel: TeaserInputModel;
  products: ProductModel[];
  onChange: (index: number, carousel: TeaserInputModel) => void;
  onDelete: (index: number) => void;
};

export type TeaserFormProps = {
  title: string;
  max?: number;
  products: ProductModel[];
  carouselList: TeaserInputModel[];
  onSaveCarousel: (carouselList: TeaserInputModel[]) => void;
};

export const TeaserItem: FC<TeaserInputProps> = ({
  index,
  carousel,
  products,
  onChange,
  onDelete,
}) => {
  return (
    <div className="my-2 rounded-xl bg-slate-300 border shadow p-4">
      <div className="mt-4 flex justify-end">
        <button
          className="button-red-outline-with-hover"
          onClick={() => onDelete(index)}
        >
          <FaRegTrashAlt />
        </button>
      </div>
      <div className="flex mt-4">
        <label className="font-medium my-auto w-32">Select Product</label>
        <select
          className={"input-field-full-width"}
          value={carousel.productId}
          onChange={(e) => {
            let newCarousel = { ...carousel };
            newCarousel.productId = e.target.value;
            onChange(index, newCarousel);
          }}
        >
          <option value="">Pilih Produk</option>
          {products.map((item) => (
            <option
              key={item.id}
              value={item.id}
            >{`${item.title} - ${item.duration_length} ${item.duration_type}`}</option>
          ))}
        </select>
      </div>
      <SelectMediaInputComponent
        media={MediaEnum.PDF}
        mediaLabel="Teaser"
        value={carousel.teaserUrl}
        onChange={(url) => {
          let newCarousel = { ...carousel };
          newCarousel.teaserUrl = url;
          onChange(index, newCarousel);
        }}
      />
      <SelectMediaInputComponent
        media={MediaEnum.IMAGE}
        mediaLabel="Image"
        value={carousel.imageUrl}
        onChange={(url) => {
          let newCarousel = { ...carousel };
          newCarousel.imageUrl = url;
          onChange(index, newCarousel);
        }}
      />
      <div className="flex mt-4">
        <label className="font-medium my-auto w-32">Alternative Text</label>
        <input
          className={"input-field-full-width"}
          type="text"
          value={carousel.altText}
          onChange={(e) => {
            let newCarousel = { ...carousel };
            newCarousel.altText = e.target.value;
            onChange(index, newCarousel);
          }}
        />
      </div>
    </div>
  );
};

export const TeaserFormComponent: FC<TeaserFormProps> = ({
  title,
  max,
  products,
  carouselList,
  onSaveCarousel,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [mCarouselList, setMCarouselList] =
    useState<TeaserInputModel[]>(carouselList);

  const onAddNew = () => {
    let newCarouselList = [...mCarouselList];
    newCarouselList.splice(0, 0, {
      productId: "",
      imageUrl: "",
      altText: "",
      teaserUrl: "",
    });

    setMCarouselList(newCarouselList);
  };

  const onDelete = (index: number) => {
    let newCarouselList = [...mCarouselList];
    newCarouselList.splice(index, 1);
    setMCarouselList(newCarouselList);
  };

  const onChange = (index: number, carousel: TeaserInputModel) => {
    let newCarouselList = [...mCarouselList];
    newCarouselList[index] = carousel;
    setMCarouselList(newCarouselList);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div
          className="font-bold text-3xl w-full"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {title}
        </div>

        <div className="flex">
          {!isCollapsed &&
            (max === undefined || (max && mCarouselList.length < max)) && (
              <button
                className="button-yellow-outline-with-hover mr-2"
                onClick={onAddNew}
              >
                <FaPlus />
              </button>
            )}
          {!isCollapsed && (
            <button
              className="button-yellow-outline-with-hover"
              onClick={() => onSaveCarousel(mCarouselList)}
            >
              Save
            </button>
          )}
          <button
            className="ml-4 mx-auto"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </div>
      </div>

      {!isCollapsed && mCarouselList.length === 0 && (
        <div className="rounded bg-gray-400 text-center mt-4 p-4">
          {title} Empty
        </div>
      )}

      {!isCollapsed &&
        mCarouselList.map((item, index) => {
          return (
            <TeaserItem
              key={`teaser-${title}-${index}`}
              index={index}
              carousel={item}
              onChange={onChange}
              onDelete={onDelete}
              products={products}
            />
          );
        })}
    </div>
  );
};
