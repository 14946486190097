import { PATH_ANALYSIS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const AddAnalysisUsecase: (
  formData: FormData
) => Promise<boolean> = async (formData) => {
  try {
    await client.post(PATH_ANALYSIS, formData);
    return true;
  } catch (e) {
    throw new Error(e.response.message);
  }
};
