import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { UploadCsvUsecase } from "@Pages/user/domain/UploadCsv.usecase";
import { useForm } from "react-hook-form";

export type UploadCsvFormInput = {
  file: FileList | null;
};

export const UploadCsvComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UploadCsvFormInput>();

  const toasterContext = UseToasterContext();
  const modalContext = UseModalContext();

  const onSubmit = async (data: UploadCsvFormInput) => {
    if (data.file) {
      try {
        await UploadCsvUsecase(data.file);
        removeModal();
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "CSV uploaded, processing...",
            "",
            ToasterType.SUCCESS
          ),
        ]);
      } catch (e) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to upload CSV.",
            e.message,
            ToasterType.DANGER
          ),
        ]);
      }
    }
  };

  const removeModal = () => {
    modalContext.setModal(null);
  };

  return (
    <form
      className="grid grid-cols-1 gap-4 w-full pt-4 pb-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="w-full">
        <input
          className={`input-field-full-width ${
            errors.file && "border-red-500"
          }`}
          type="file"
          {...register("file", {
            required: "File is required",
          })}
        />
        {errors.file && (
          <p className="text-red-500 text-xs">{errors.file?.message}</p>
        )}
      </div>

      <div>
        <div className="text-xl font-bold">PENTING !!!</div>
        <ol className="text-lg font-normal list-decimal">
          <li>Pemisah setiap kolom menggunakan ;</li>
          <li>
            Format CSV : <br />
            <span className="font-bold">
              {"{Tanggal Join}"};{"{Tanggal Expired}"};{"{Nama}"};{"{HP/WA}"};
              {"{Email}"};{"{Telegram}"};{"{Type}"}
            </span>
            <br />
            Detail :
            <ul className="list-disc ml-4">
              <li>Tanggal Join : Tanggal user join</li>
              <li>Tanggal Expired : Tanggal expired dari role user</li>
              <li>Nama : Nama user</li>
              <li>Hp/Wa : Nomor hp user</li>
              <li>
                Email : Email user,{" "}
                <span className="font-bold">
                  TIDAK BOLEH ADA LEBIH DARI SATU (dengan / atau |)
                </span>
              </li>
              <li>Telegram : Id Telegram user</li>
              <li>
                Type : Tipe role user, pilihan :
                <br />
                MEMBERSHIP_AND_PORTOFOLIO,
                <br />
                MEMBERSHIP, atau
                <br />
                PORTOFOLIO
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <button className="button-yellow-outline-with-hover w-full" type="submit">
        Submit
      </button>
      <button
        className="button-red-outline-with-hover w-full"
        type="button"
        onClick={removeModal}
      >
        Cancel
      </button>
    </form>
  );
};
