/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { MutableRefObject } from "react";
import { useEffect, useRef, useState } from "react";

import { REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE } from "@Helpers/regex";
import { buildQueryParamsFromUrl, buildUrlSearchParams } from "@Helpers/utils";
import { ListOrderModel } from "../data/order.model";
import { ContentHeader } from "@Components/ContentHeader.component";
import { BsPencilSquare } from "react-icons/bs";
import { SearchInputComponent } from "@Components/inputs/SearchInputs.component";
import { BottomListComponent } from "@Components/BottomList.component";
import { GetAllOrdersUsecase } from "../domain/GetAllOrders.usecase";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { convertDateFromApiToView } from "@Helpers/formatter";

export const OrderListPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const toasterContext = UseToasterContext();

  const searchRef = useRef() as MutableRefObject<HTMLInputElement>;

  const [needRefetch, setNeedRefetch] = useState(true);
  const [apiData, setApiData] = useState<ListOrderModel>();

  useEffect(() => {
    if (needRefetch) {
      async function fetchData() {
        let queryParams = buildQueryParamsFromUrl(searchParams.entries());
        if (queryParams !== "") {
          queryParams = "?" + queryParams;
        }

        try {
          let response = await GetAllOrdersUsecase(queryParams);
          setApiData(response);
        } catch (errors) {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Failed to fetch orders",
              errors.message,
              ToasterType.DANGER
            ),
          ]);
        }

        setNeedRefetch(false);
      }

      fetchData();
    }
  }, [needRefetch]);

  const handleSearch = (e: any) => {
    if (e.key === "Enter") {
      let clearedString = e.target.value
        .replace(REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE, "")
        .replaceAll(" ", "+");

      setNeedRefetch(true);
      navigate({
        search:
          "?" +
          buildUrlSearchParams(searchParams.entries(), "search", clearedString),
      });
    }
  };

  const canGoPrev = () =>
    (apiData && apiData.meta && apiData.meta.page > 1) ?? false;

  const onPrevPage = () => {
    if (apiData && apiData.meta && canGoPrev()) {
      let prevPage = apiData.meta.page - 1;
      if (prevPage >= 1) {
        setNeedRefetch(true);
        navigate({
          search:
            "?" +
            buildUrlSearchParams(
              searchParams.entries(),
              "page",
              prevPage.toString()
            ),
        });
      }
    }
  };

  const canGoNext = () =>
    (apiData && apiData.meta && apiData.meta.end < apiData.meta.total) ?? false;

  const onNextPage = () => {
    if (apiData && apiData.meta && canGoNext()) {
      let nextPage = apiData.meta.page + 1;
      setNeedRefetch(true);
      navigate({
        search:
          "?" +
          buildUrlSearchParams(
            searchParams.entries(),
            "page",
            nextPage.toString()
          ),
      });
    }
  };

  const getQueryStatus = () => {
    let status = searchParams.get("status");
    if (status === null) {
      status = "all";
    }
    return status;
  };

  const onFilterProductStatus = (selectedStatus: string) => {
    setNeedRefetch(true);
    navigate({
      search:
        "?" +
        buildUrlSearchParams(searchParams.entries(), "status", selectedStatus),
    });
  };

  const onChangeLimit = (newValue: string) => {
    setNeedRefetch(true);
    navigate({
      search:
        "?" + buildUrlSearchParams(searchParams.entries(), "limit", newValue),
    });
  };

  return (
    <div className="m-4">
      {!apiData && <MidasShimmering />}

      {apiData && (
        <>
          <ContentHeader title={"Order List"} />
          <div className="h-6"></div>

          {/* top config */}
          <div className="border p-3 align-right flex flex-col justify-between shadow-sm md:flex-row">
            <div>
              {/* status filter */}
              <select
                className="mr-1 border rounded pl-1 pr-1"
                value={getQueryStatus()}
                onChange={(event) => onFilterProductStatus(event.target.value)}
              >
                <option value="all">All</option>
                <option value="in_progress">In Progress</option>
                <option value="waiting_for_payment">Waiting for Payment</option>
                <option value="paid">Paid</option>
                <option value="complete">Complete</option>
                <option value="failed">Failed</option>
                <option value="refunded">Refunded</option>
              </select>
            </div>
            <div className="border rounded mt-2 md:mt-0 flex">
              <SearchInputComponent
                searchRef={searchRef}
                onKeyDown={handleSearch}
              />
            </div>
          </div>

          {/* list items */}
          <div className="mt-4">
            {apiData.list.length === 0 && (
              <div className="text-center bg-gray-300 p-4">
                No Order Available!
              </div>
            )}

            {apiData.list.length > 0 && (
              <table className="table-auto border w-full">
                <thead className="p-2 shadow-sm bg-gray-100 sticky">
                  <tr>
                    <th className="border-l border-t border-b border-black"></th>
                    <th className="border-t border-b border-black">Name</th>
                    <th className="border-t border-b border-black">
                      Total Price
                    </th>
                    <th className="border-t border-b  border-black hidden md:table-cell">
                      Last Update
                    </th>
                    <th className="border-t border-b border-r border-black hidden md:table-cell">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {apiData.list.map((item, index) => (
                    <tr key={`order-${item.id}`}>
                      <td className="border-l text-center border-t border-b border-black p-2">
                        {index + 1}
                      </td>
                      <td className="p-2 border-t border-b border-black">
                        <div className="font-bold text-ellipsis">
                          Order #{item.id}
                        </div>
                        <div className="font-bold text-ellipsis">
                          {item.created_by?.fullname ?? ""}
                        </div>
                        <div className="text-ellipsis">
                          {item.created_by ? `(${item.created_by.email})` : ""}
                        </div>
                        <div className="flex mt-3">
                          <Link to={"/orders/detail/" + item.id}>
                            <button
                              className="text-blue-400 mr-2 w-6 h-6"
                              title="Edit"
                            >
                              <BsPencilSquare />
                            </button>
                          </Link>
                        </div>
                      </td>
                      <td className="text-center text-ellipsis p-2 border-t border-b border-black">
                        {item.total_price_str}
                      </td>
                      <td className="text-center hidden md:table-cell p-2 border-t border-b border-black">
                        {convertDateFromApiToView(item.updated_at ?? "")}
                      </td>
                      <td className="border-r text-center hidden md:table-cell p-2 border-t border-b border-black">
                        {item.deleted_at != null ? (
                          <div className="text-red-500">In Trash</div>
                        ) : item.status === "in_progress" ? (
                          <div className="text-yellow-500">In Progress</div>
                        ) : item.status === "waiting_for_payment" ? (
                          <div className="text-yellow-500">
                            Waiting for Payment
                          </div>
                        ) : item.status === "paid" ? (
                          <div className="text-green-500">Paid</div>
                        ) : item.status === "complete" ? (
                          <div className="text-green-500">Complete</div>
                        ) : item.status === "failed" ? (
                          <div className="text-red-500">Failed</div>
                        ) : item.status === "expired" ? (
                          <div className="text-red-500">Expired</div>
                        ) : item.status === "refunded" ? (
                          <div className="text-orange-500">Refunded</div>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {apiData.meta && (
              <BottomListComponent
                shouldShow={apiData.meta !== undefined}
                onLimitChange={onChangeLimit}
                canGoPrev={canGoPrev()}
                onGoToPrev={onPrevPage}
                canGoNext={canGoNext()}
                onGoToNext={onNextPage}
                meta={apiData.meta}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
