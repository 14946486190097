import { FC } from "react";

export type AffiliateWithdrawCompleteProps = {
  onCancel: () => void;
  onComplete: () => void;
};

export const AffiliateWithdrawCompleteRequestView: FC<
  AffiliateWithdrawCompleteProps
> = ({ onCancel, onComplete }) => {
  return (
    <div>
      <div className="w-full text-center">
        Pastikan telah melakukan pencairan dana Affiliate sebelum konfirmasi.
      </div>

      <div className="my-4 w-full flex justify-center">
        <button
          type="button"
          className="button-outline-with-hover mx-2"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className="button-yellow-outline-with-hover mx-2"
          onClick={onComplete}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};
