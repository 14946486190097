import { useRef } from "react";
import { UsePageEditorContext } from "../../context/page-editor.context";

export const EditorPreviewPage = () => {
  const { pageConfigs, setPageConfigs } = UsePageEditorContext();

  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);

  const handleDragDrop = () => {
    let newComponents = [...pageConfigs.pageComponents];
    let isEditing = false;
    let editIndex = -1;

    if (
      dragItem !== null &&
      dragItem.current !== null &&
      dragOverItem !== null &&
      dragOverItem.current !== null &&
      dragItem.current !== dragOverItem.current
    ) {
      const draggedItem = newComponents.splice(dragItem.current, 1)[0];
      newComponents.splice(dragOverItem.current, 0, draggedItem);

      dragItem.current = null;
      dragOverItem.current = null;
    }

    setPageConfigs({
      ...pageConfigs,
      pageComponents: newComponents,
      isEditing: isEditing,
      editIndex: editIndex,
    });
  };

  const onClick = (index: number) => {
    setPageConfigs({
      ...pageConfigs,
      isEditing: true,
      editIndex: index,
    });
  };

  return (
    <div>
      {pageConfigs.pageComponents.map((item, index) =>
        item !== null ? (
          <div
            key={"page-item-" + index}
            draggable={true}
            onDragStart={() => (dragItem.current = index)}
            onDragEnter={() => (dragOverItem.current = index)}
            onDragEnd={handleDragDrop}
            onDragOver={(e) => e.preventDefault()}
            onClick={() => onClick(index)}
            className={
              pageConfigs.isEditing && pageConfigs.editIndex === index
                ? "border-4 border-blue-500 shadow-lg pt-1 pb-1 mb-1"
                : ""
            }
          >
            {item.render()}
          </div>
        ) : (
          <></>
        )
      )}
    </div>
  );
};
