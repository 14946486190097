import axios, { InternalAxiosRequestConfig } from "axios";
import { API_BASE_URL, API_PATH } from "./ApiConst";
import { UserSession } from "./UserSession";

export const client = axios.create({
  baseURL: API_BASE_URL + API_PATH,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use((config) => {
  let headers = { ...config.headers };

  if (UserSession.checkIsLoggedIn()) {
    headers["Authorization"] = "Bearer " + UserSession.getToken();
    headers["X-User-ID"] = UserSession.getUserSession().userId;
  }

  return {
    ...config,
    headers: headers,
  } as InternalAxiosRequestConfig<any>;
});
