/* eslint-disable react-hooks/exhaustive-deps */
import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE } from "@Helpers/regex";
import { ResourceModel } from "@Pages/resources/data/resource.model";
import { GetAllResourcesUsecase } from "@Pages/resources/domain/GetAllResources.usecase";
import { UploadResourceUsecase } from "@Pages/resources/domain/UploadResource.usecase";
import {
  AddResourceFormInputs,
  AddResourceUploadState,
} from "@Pages/resources/presentation/AddResource.page";
import { AxiosProgressEvent } from "axios";
import { FC } from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaFilePdf, FaVideo } from "react-icons/fa";
import { UseToasterContext } from "../contexts/toaster.context";

export type MediaChooserProps = {
  type: string;
  onMediaSelected: (value: ResourceModel) => void;
};

export const MediaChooser: FC<MediaChooserProps> = ({
  type,
  onMediaSelected,
}) => {
  const [apiData, setApiData] = useState<ResourceModel[]>();
  const [inUploadMode, setInUploadMode] = useState(false);
  const [needRefetch, setNeedRefetch] = useState(true);

  const toasterContext = UseToasterContext();

  const [uploadState, setUploadState] = useState<AddResourceUploadState>({
    isUploading: false,
    uploadProgress: 0,
    isDoneUpload: false,
    isFailedUpload: false,
    failedMessage: "",
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AddResourceFormInputs>();

  useEffect(() => {
    if (needRefetch) {
      GetAllResourcesUsecase("?filter=" + type + "&limit=1000")
        .then((response) => setApiData(response.list))
        .catch(() => {});

      setNeedRefetch(false);
    }
  }, [needRefetch]);

  useEffect(() => {
    if (uploadState.isDoneUpload) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("Success to upload media.", "", ToasterType.SUCCESS),
      ]);
      setNeedRefetch(true);
    }
  }, [uploadState]);

  const uploadProgressCallback = (e: AxiosProgressEvent) => {
    let progress = Math.round((100 * e.loaded) / (e.total ?? 1));

    setUploadState({
      ...uploadState,
      uploadProgress: progress,
    });
  };

  const onUpload = async (data: AddResourceFormInputs) => {
    if (data.file !== null) {
      setUploadState({
        ...uploadState,
        isUploading: true,
      });

      try {
        await UploadResourceUsecase(
          uploadProgressCallback,
          data.title,
          data.file,
          data.alt,
          data.description
        );

        setUploadState({
          ...uploadState,
          isUploading: false,
          isDoneUpload: true,
          isFailedUpload: false,
          failedMessage: "",
        });
      } catch (e) {
        setUploadState({
          ...uploadState,
          isUploading: false,
          isDoneUpload: false,
          isFailedUpload: true,
          failedMessage: e.message,
        });
      }
    } else {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("File not found", "", ToasterType.DANGER),
      ]);
    }
  };

  const buildUploadForm = () => {
    return (
      <div className="mt-4 w-full border border-black rounded-lg p-4 shadow-lg">
        <form
          className="grid grid-cols-1 md:grid-cols-2 gap-4"
          onSubmit={handleSubmit(onUpload)}
        >
          {/* Title */}
          <div>
            <div className="font-medium">Title</div>
            <div className="w-full">
              <input
                className={`input-field-full-width ${
                  errors.title && "border-red-500"
                }`}
                type="text"
                {...register("title", {
                  required: "Title is required",
                  pattern: {
                    value: REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE,
                    message:
                      "Invalid title format, only alphanumeric and dash allowed",
                  },
                })}
              />
              {errors.title && (
                <p className="text-red-500 text-xs">{errors.title?.message}</p>
              )}
            </div>
          </div>

          {/* File field */}
          <div>
            <div className="font-medium">File</div>
            <div className="w-full">
              <div className="flex">
                <input
                  className={`input-field-full-width ml-1 ${
                    errors.file && "border-red-500"
                  }`}
                  type="file"
                  {...register("file", {
                    required: "File is required",
                  })}
                />
                <div className="m-auto ml-3 text-red-500">
                  <button onClick={() => setValue("file", null)}>
                    <AiOutlineCloseCircle />
                  </button>
                </div>
              </div>
              {errors.file && (
                <p className="text-red-500 text-xs">{errors.file?.message}</p>
              )}
            </div>
          </div>

          {/* Alt Text */}
          <div>
            <div className="font-medium">Alternative Text</div>
            <div className="w-full">
              <input
                className={`input-field-full-width ${
                  errors.alt && "border-red-500"
                }`}
                type="text"
                {...register("alt")}
              />
              {errors.alt && (
                <p className="text-red-500 text-xs">{errors.alt?.message}</p>
              )}
            </div>
          </div>

          {/* Description */}
          <div>
            <div className="font-medium">Description</div>
            <div className="w-full">
              <input
                className={`input-field-full-width ${
                  errors.alt && "border-red-500"
                }`}
                type="text"
                {...register("description")}
              />
              {errors.description && (
                <p className="text-red-500 text-xs">
                  {errors.description?.message}
                </p>
              )}
            </div>
          </div>

          <button
            className="button-yellow-outline-with-hover w-full mt-4 col-span-2"
            type="submit"
          >
            Upload
          </button>
        </form>
      </div>
    );
  };

  const buildUploadProgressBar = () => {
    return (
      <div className="w-full mt-4">
        {uploadState.isUploading && (
          <div className="border rounded-lg">
            <div
              className={`text-center text-white rounded-lg 
       ${
         uploadState.isUploading
           ? "bg-yellow-400"
           : uploadState.isDoneUpload
           ? "bg-green-500"
           : "bg-red-500"
       }`}
              style={{ width: uploadState.uploadProgress + "%" }}
            >
              {uploadState.uploadProgress + "%"}
            </div>
          </div>
        )}
        {uploadState.failedMessage !== "" && (
          <div className="text-red-500 text-center text-xs">
            {uploadState.failedMessage}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mt-4 overflow-auto">
      {!apiData && <MidasShimmering />}
      {apiData && (
        <div className="flex justify-end w-full">
          {inUploadMode && (
            <button
              className="button-red-outline-with-hover"
              onClick={() => {
                setInUploadMode(false);
              }}
            >
              Cancel
            </button>
          )}
          {!inUploadMode && (
            <YellowHoverButton
              label="Add"
              onClick={() => {
                setInUploadMode(true);
              }}
            />
          )}
        </div>
      )}
      {inUploadMode && buildUploadForm()}
      {uploadState.isUploading && buildUploadProgressBar()}

      {apiData && apiData.length === 0 && (
        <div className="text-center bg-gray-300 p-4 mt-4">
          No Media Available!
        </div>
      )}

      {apiData && apiData.length >= 0 && (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 mt-4">
          {apiData.map((item) => (
            <button
              key={item.link_url}
              className="m-auto"
              type="button"
              onClick={() => onMediaSelected(item)}
            >
              {item.mime_type.includes("video") ? (
                <div
                  key={"media-" + item.id}
                  className="place-self-center p-5 bg-gray-400 rounded-xl relative"
                >
                  <FaVideo size={96} />
                  <div className="bg-black bg-opacity-50 absolute bottom-0 left-0 right-0 w-full text-white rounded-b-xl">
                    {item.name}
                  </div>
                </div>
              ) : item.mime_type.includes("image") ? (
                <div
                  key={"media-" + item.id}
                  className="place-self-center bg-gray-400 rounded-xl relative"
                >
                  <img src={item.link_url} className="object-cover" alt="" />
                  <div className="bg-black bg-opacity-50 absolute bottom-0 left-0 right-0 w-full text-white rounded-b-xl">
                    {item.name}
                  </div>
                </div>
              ) : item.mime_type.includes("pdf") ? (
                <div
                  key={"media-" + item.id}
                  className="place-self-center p-5 bg-gray-400 rounded-xl relative"
                >
                  <FaFilePdf size={96} />
                  <div className="bg-black bg-opacity-50 absolute bottom-0 left-0 right-0 w-full text-white rounded-b-xl">
                    {item.name}
                  </div>
                </div>
              ) : (
                <div
                  key={"media-" + item.id}
                  className="place-self-center bg-gray-400 rounded-xl relative w-24 text-center"
                >
                  {item.name}
                </div>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
