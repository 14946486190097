import { PATH_GR_ACCOUNT } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { AccountInformationModel } from "../data/account.model";

export const GetAccountInformationUsecase: () => Promise<AccountInformationModel> =
  async () => {
    try {
      let response = await client.get(PATH_GR_ACCOUNT);

      let data = response.data.data;

      return {
        id: data.account.accountId,
        firstName: data.account.firstName,
        lastName: data.account.lastName,
        email: data.account.email,
        phone: data.account.phone,
        companyName: data.account.companyName,
        state: data.account.state,
        city: data.account.city,
        street: data.account.street,
        zipCode: data.account.zipCode,
        countryCodeId: data.account.countryCode.countryCodeId,
        countryCode: data.account.countryCode.countryCode,
        numberOfEmployees: data.account.numberOfEmployees,
        href: data.account.href,
        badgeStatus: data.badge.status,
      };
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  };
