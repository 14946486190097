import { PATH_COURSES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { CourseModel } from "../data/course.model";

export const GetCourse: (id: string) => Promise<CourseModel> = async (id) => {
  try {
    let response = await client.get(PATH_COURSES + "/" + id);
    return response.data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
