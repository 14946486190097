/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FaExclamationTriangle,
  FaRegCalendarAlt,
  FaUserAlt,
} from "react-icons/fa";

import { AccordionComponent } from "@Components/accordion/Accordion.component";
import { ContentHeader } from "@Components/ContentHeader.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { convertDateFromApiToView } from "@Helpers/formatter";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { RoleModel } from "../data/role.model";
import { DeleteRoleUsecase } from "../domain/DeleteRole.usecase";
import { UpdateRoleUsecase } from "../domain/UpdateRole.usecase";
import { REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE } from "@Helpers/regex";
import { AvailableRoles } from "../data/role_const";
import { GetRoleUsecase } from "../domain/GetRole.usecase";

export function DetailRolePage() {
  const params = useParams();
  const navigate = useNavigate();

  const modalContext = UseModalContext();
  const toasterContext = UseToasterContext();

  const [detail, setDetail] = useState<RoleModel>();
  const [rolesCount, setRolesCount] = useState({});
  const [availableRolesMapped, setAvailableRolesMapped] =
    useState<Map<string, string[]>>();

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await GetRoleUsecase(params.id ?? "");
        setDetail(response);
      } catch (errors) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to fetch Role",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    let availableRolesCount = { ...rolesCount };
    let roleMapped = new Map();

    Object.entries(AvailableRoles).forEach((role) => {
      let roleCount = 0;
      let pageRoles = {};

      let parentRoles: string[] = [];

      Object.entries(role[1]).forEach((pageRole) => {
        let pageRoleCount = 0;

        let childRoles: string[] = [];

        pageRole[1].forEach((item) => {
          ++pageRoleCount;
          ++roleCount;

          let accesses = item.access.split(";");

          roleMapped.set(
            `${role[0].toLowerCase()}::${pageRole[0].toLowerCase()}::${item.label.toLowerCase()}`,
            accesses
          );

          accesses.forEach((mAccess) => {
            if (childRoles.indexOf(mAccess) === -1) childRoles.push(mAccess);
          });
        });

        roleMapped.set(
          `${role[0].toLowerCase()}::${pageRole[0].toLowerCase()}`,
          childRoles
        );
        parentRoles = [...parentRoles, ...childRoles];

        pageRoles = {
          ...pageRoles,
          [pageRole[0]]: pageRoleCount,
        };
      });

      roleMapped.set(role[0].toLowerCase(), parentRoles);

      availableRolesCount = {
        ...availableRolesCount,
        [role[0]]: {
          pageRoles: pageRoles,
          count: roleCount,
        },
      };
    });

    setAvailableRolesMapped(roleMapped);
    setRolesCount(availableRolesCount);
  }, []);

  const showDeleteModal = () => {
    let icon = (
      <div className="text-red-500">
        <FaExclamationTriangle />
      </div>
    );
    let title = "Hapus Role";
    let description = (
      <>
        Apakah anda yakin ingin menghapus{" "}
        <span className="font-bold">{detail?.title}</span> ?
      </>
    );
    let primaryCta = (
      <button
        className="button-red-outline-with-hover"
        onClick={() => handleDelete()}
      >
        Hapus
      </button>
    );
    let secondaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(icon, title, description, primaryCta, secondaryCta)
    );
  };

  const handleDelete = async () => {
    try {
      await DeleteRoleUsecase(detail?.id ?? 0);
      navigate(-1);
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to delete role",
          errors.message,
          ToasterType.SUCCESS
        ),
      ]);
    }
  };

  const updateData = async () => {
    try {
      let newAccess: string[] = [];

      detail?.access.forEach((item) => {
        if (item !== "") {
          let splitted = item.split(";");
          if (splitted.length > 0) {
            newAccess.push(...splitted);
          }
        }
      });

      let setAccess = new Set(newAccess);

      await UpdateRoleUsecase(
        (detail?.id ?? "").toString(),
        detail?.title,
        detail?.role,
        Array.from(setAccess)
      );
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Berhasil",
          "Role berhasil di perbarui!",
          ToasterType.SUCCESS
        ),
      ]);
      popWithDelay();
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Gagal memperbarui rekaman",
          errors.message,
          ToasterType.SUCCESS
        ),
      ]);
    }
  };

  const popWithDelay = () => {
    setTimeout(function () {
      navigate(-1);
    }, 1000);
  };

  const onSelectAll = (role: string, pageRole: string, isSelected: boolean) => {
    let allSelectedRoles: string[] = [];
    let actionToBeDeleted: string[] = [];

    function addRole(roles: string[], newRole: string) {
      roles.push(newRole);
    }

    function processAccess(actionList: any[], actionToBeDeleted: string[]) {
      actionList.forEach((action) => {
        let accessName = action.access;
        if (isSelected) {
          addRole(allSelectedRoles, accessName);
        } else {
          actionToBeDeleted.push(accessName);
        }
      });
    }

    Object.entries(AvailableRoles).forEach((roles) => {
      if (roles[0] === role) {
        Object.entries(roles[1]).forEach((pageRoleItem) => {
          if (
            (pageRole !== "" && pageRoleItem[0] === pageRole) ||
            pageRole === ""
          ) {
            processAccess(pageRoleItem[1], actionToBeDeleted);
          }
        });
      }
    });

    if (detail) {
      let newDetail = { ...detail };
      if (isSelected) {
        newDetail.access = [
          ...new Set([...newDetail.access, ...allSelectedRoles]),
        ];
      } else if (!isSelected && detail) {
        newDetail.access = newDetail.access?.filter((item) => {
          let result = true;
          actionToBeDeleted.forEach((deleteItem) => {
            if (item === deleteItem) {
              result = false;
              return;
            }
          });
          return result;
        });
      }
      setDetail(newDetail);
    }
  };

  const isChecked = (role: string, pageRole: string) => {
    try {
      let key = "";
      if (role !== "" && pageRole !== "") {
        key = `${role.toLowerCase()}::${pageRole.toLowerCase()}`;
      } else if (role !== "") {
        key = `${role.toLowerCase()}`;
      }

      if (key !== "" && availableRolesMapped) {
        let access = availableRolesMapped.get(key);
        if (!access || access?.length === 0) return false;
        if (!detail?.access) return false;

        let selectedAccess: string[] = [];
        detail.access.forEach((item) => {
          let split = item.split(";");
          split.forEach((splittedItem) => {
            if (selectedAccess.indexOf(splittedItem) === -1)
              selectedAccess.push(splittedItem);
          });
        });

        let selected = true;
        access.forEach((item) => {
          if (!selectedAccess.includes(item)) selected = false;
        });

        return selected;
      }

      return false;
    } catch (e) {}

    return false;
  };

  const isItemChecked = (key: string) => {
    if (key === "") return false;
    if (!detail || detail.access.length === 0) return false;

    let roleData = availableRolesMapped?.get(key);
    if (!roleData || roleData.length === 0) return false;

    let selectedAccess: string[] = [];
    detail.access.forEach((item) => {
      let split = item.split(";");
      split.forEach((splittedItem) => {
        if (selectedAccess.indexOf(splittedItem) === -1)
          selectedAccess.push(splittedItem);
      });
    });

    let isChecked = true;

    roleData.forEach((item) => {
      if (!selectedAccess.includes(item)) isChecked = false;
    });

    return isChecked;
  };

  return (
    <div className="p-4 h-full bg-gray-300 overflow-x-scroll">
      {!detail && <MidasShimmering />}
      {detail && (
        <>
          <ContentHeader title={"Role Detail"} />
          <div className="h-6" />

          {/* Role title */}
          <div className="flex mt-4">
            <label className="font-medium my-auto w-28" htmlFor="title">
              Title
            </label>
            <div className="w-full">
              <input
                id="title"
                className={`input-field-full-width`}
                type="text"
                value={detail?.title ?? ""}
                onChange={(e) => {
                  if (detail) {
                    let newDetail = { ...detail };
                    newDetail.title = e.target.value;
                    newDetail.role = e.target.value
                      .toLowerCase()
                      .replaceAll(" ", "_");

                    setDetail(newDetail);
                  }
                }}
              />
            </div>
          </div>

          {/* Role field */}
          <div className="flex mt-4">
            <label className="font-medium w-28 mt-1" htmlFor="role">
              Role
            </label>
            <div className="w-full">
              <input
                id="role"
                className="input-field-full-width"
                type={"text"}
                value={detail?.role ?? ""}
                onChange={(e) => {
                  if (
                    detail &&
                    REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE.test(e.target.value)
                  ) {
                    let newDetail = { ...detail };
                    newDetail.role = e.target.value;

                    setDetail(newDetail);
                  }
                }}
              />
            </div>
          </div>

          {/* Access field */}
          <div className="w-full mt-4 border bg-white">
            <AccordionComponent title="Access" isOpen={true}>
              <div className="w-full p-4">
                {Object.entries(AvailableRoles).map((role, index) => {
                  return (
                    <div
                      key={`access-${role[0]}`}
                      className={index === 0 ? "my-0" : "my-4"}
                    >
                      <div className="flex justify-between">
                        <div className="text-xl font-bold my-auto">
                          {role[0]}
                        </div>
                        <div className="flex mr-0 md:mr-4 my-auto">
                          <input
                            className="mr-2 my-auto"
                            type="checkbox"
                            checked={isChecked(role[0], "")}
                            onChange={(e) => {
                              onSelectAll(role[0], "", e.target.checked);
                            }}
                          />
                          <div className="my-auto">Select all</div>
                        </div>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4">
                        {Object.entries(role[1]).map(
                          (pageRole, pageRoleIndex) => {
                            return (
                              <div
                                key={`page-role-${role[0]}-${pageRole[0]}-${pageRoleIndex}`}
                                className={`p-2 border`}
                              >
                                <div className="flex justify-between">
                                  <div className="font-bold my-auto">
                                    {pageRole[0]}
                                  </div>
                                  <div className="flex mr-0 md:mr-4 my-auto">
                                    <input
                                      className="mr-2 my-auto"
                                      type="checkbox"
                                      checked={isChecked(role[0], pageRole[0])}
                                      onChange={(e) => {
                                        onSelectAll(
                                          role[0],
                                          pageRole[0],
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <div className="my-auto">Select all</div>
                                  </div>
                                </div>
                                {pageRole[1].map((action, index) => {
                                  return (
                                    <div
                                      key={`role-action-${role[0]}-${pageRole[0]}-${action}-${index}`}
                                      className="flex mt-1"
                                    >
                                      <input
                                        className="mr-2"
                                        type="checkbox"
                                        value={action.access}
                                        checked={isItemChecked(
                                          `${role[0].toLowerCase()}::${pageRole[0].toLowerCase()}::${action.label.toLowerCase()}`
                                        )}
                                        onChange={(e) => {
                                          let newDetail = { ...detail };
                                          let actionName = action.access;
                                          if (e.target.checked) {
                                            newDetail.access.push(actionName);
                                          } else {
                                            newDetail.access =
                                              newDetail.access.filter(
                                                (item) => item !== actionName
                                              );
                                          }

                                          setDetail(newDetail);
                                        }}
                                      />
                                      {action.label}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </AccordionComponent>
          </div>

          <div className="w-full mt-4 border bg-white">
            <AccordionComponent title="Save" isOpen={true}>
              <div className="p-4">
                {/* Uploaded Date */}
                <div className="flex">
                  <div className="w-4">
                    <FaRegCalendarAlt />
                  </div>
                  <div className="ml-2 text-xs m-auto text-ellipsis">
                    Created on:{" "}
                    <span className="font-medium">
                      {convertDateFromApiToView(detail.created_at)}
                    </span>
                  </div>
                </div>

                {/* Uploaded By */}
                <div className="flex mt-2">
                  <div className="w-4">
                    <FaUserAlt />
                  </div>
                  <div className="ml-2 text-xs m-auto text-ellipsis">
                    Created by:{" "}
                    <span className="font-medium">
                      {detail.created_by.fullname}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex border-t-2 justify-between py-2 mt-2 px-4">
                <button
                  className="button-red-outline-with-hover text-xs"
                  onClick={showDeleteModal}
                >
                  Delete Permanently
                </button>
                <div>
                  <button
                    className="button-yellow-outline-with-hover text-xs"
                    onClick={() => updateData()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </AccordionComponent>
          </div>
        </>
      )}
    </div>
  );
}
