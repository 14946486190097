import { UsePageEditorContext } from "../../context/page-editor.context";

import { DividerViewConfig } from "./configs/DividerConfig.component";
import { SpacerViewConfig } from "./configs/SpacerConfig.component";
import { ImageViewConfig } from "./configs/ImageConfig.component";
import { VideoViewConfig } from "./configs/VideoConfig.component";
import { BaseComponentModel } from "@Pages/page/context/editor-sidebar.context";
import { TextModel } from "../models/text.model";
import { DividerModel } from "../models/divider.model";
import { SpacerModel } from "../models/spacer.model";
import { ImageModel } from "../models/image.model";
import { VideoModel } from "../models/video.model";
import { HeaderModel } from "../models/header.model";
import { BulletModel } from "../models/bullet.model";
import { PostDetailModel } from "../models/post-detail.model";
import { DisclaimerModel } from "../models/disclaimer.model";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaCheck, FaRegTrashAlt } from "react-icons/fa";
import { TextViewConfig } from "./configs/TextConfig.component";

function getModelToConfigMapper(model: BaseComponentModel) {
  if (
    (model instanceof TextModel && model.componentKey.startsWith("text-")) || 
    (model instanceof HeaderModel && model.componentKey.startsWith("header-")) ||
    (model instanceof BulletModel && model.componentKey.startsWith("bullet-")) ||
    (model instanceof PostDetailModel && model.componentKey.startsWith("post-detail-")) ||
    (model instanceof DisclaimerModel && model.componentKey.startsWith("disclaimer-"))
  ) {
    return <TextViewConfig />;
  } else if (
    model instanceof DividerModel &&
    model.componentKey.startsWith("divider-")
  ) {
    return <DividerViewConfig />;
  } else if (
    model instanceof SpacerModel &&
    model.componentKey.startsWith("spacer-")
  ) {
    return <SpacerViewConfig />;
  } else if (
    model instanceof ImageModel &&
    model.componentKey.startsWith("image-")
  ) {
    return <ImageViewConfig />;
  } else if (
    model instanceof VideoModel &&
    model.componentKey.startsWith("video-")
  ) {
    return <VideoViewConfig />;
  }
}

export const EditorConfigComponent = () => {
  const { pageConfigs, setPageConfigs } = UsePageEditorContext();

  let editingModel = pageConfigs.pageComponents[pageConfigs.editIndex];
  let child = getModelToConfigMapper(editingModel);

  const onRemove = () => {
    let newComponents = [...pageConfigs.pageComponents];
    newComponents.splice(pageConfigs.editIndex, 1);

    let newConfig = {
      ...pageConfigs,
      pageComponents: newComponents,
      oldModel: null,
      isEditing: false,
      editIndex: -1,
    };

    setPageConfigs(newConfig);
  };

  const onCancelEdit = () => {
    let newComponents = [...pageConfigs.pageComponents];
    let oldModel = pageConfigs.oldModel;

    if (oldModel) {
      newComponents[pageConfigs.editIndex] =
        pageConfigs.oldModel as BaseComponentModel;
      oldModel = null;
    }

    let newConfig = {
      ...pageConfigs,
      pageComponents: newComponents,
      oldModel: oldModel,
      isEditing: false,
      editIndex: -1,
    };

    setPageConfigs(newConfig);
  };

  const onSaveEdit = () => {
    let newConfig = {
      ...pageConfigs,
      oldModel: null,
      isEditing: false,
      editIndex: -1,
    };

    setPageConfigs(newConfig);
  };

  return (
    <div className="flex flex-col justify-between h-screen">
      <div className="w-full bg-sidebar h-12 flex justify-between px-2">
        <div className="my-auto">
          <button
            title="Cancel"
            className="p-1 bg-white border border-gray-400 rounded w-8 h-8 text-gray-400 hover:text-white hover:bg-gray-400"
            onClick={onCancelEdit}
          >
            <AiOutlineCloseCircle size={20} />
          </button>
        </div>
        <div className="my-auto">
          <button
            className="p-1 bg-white border border-red-400 rounded w-8 h-8 text-red-400 hover:text-white hover:bg-red-400"
            type="button"
            title="Delete"
            onClick={onRemove}
          >
            <FaRegTrashAlt size={20} />
          </button>
          <button
            title="Save"
            className="p-1 bg-white border border-yellow-400 rounded w-8 h-8 text-yellow-400 hover:text-white hover:bg-yellow-400 ml-2"
            onClick={onSaveEdit}
          >
            <FaCheck size={20} />
          </button>
        </div>
      </div>

      <div className="h-full py-4 px-2">{child}</div>
    </div>
  );
};
