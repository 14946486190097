/* eslint-disable react-hooks/exhaustive-deps */
import {
  PageConfigModel,
  UsePageEditorContext,
} from "@Pages/page/context/page-editor.context";
import { useEffect } from "react";
import { useState } from "react";
import { SpacerModel } from "../../models/spacer.model";
import { NumberField } from "../forms/NumberField.component";
import { Slider } from "../forms/Slider.component";

export const SpacerViewConfig = () => {
  const { pageConfigs, setPageConfigs } = UsePageEditorContext();
  const [usePercentage, setUsePercentage] = useState(false);

  const editingModel: SpacerModel = pageConfigs.pageComponents[
    pageConfigs.editIndex
  ] as SpacerModel;

  useEffect(() => {
    if (editingModel.width === 0) {
      setUsePercentage(true);
    }
  }, []);

  const updateOldModel = (newConfig: PageConfigModel) => {
    if (
      pageConfigs.oldModel == null ||
      typeof pageConfigs.oldModel == "undefined"
    ) {
      newConfig["oldModel"] = new SpacerModel(
        editingModel.componentKey,
        editingModel.height,
        editingModel.width,
        editingModel.widthPercentage
      );
    }
  };

  const onChangeHeight = (newValue: number) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as SpacerModel).height = newValue;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeWidth = (newValue: number) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as SpacerModel).width = newValue;
    (pageComponents[pageConfigs.editIndex] as SpacerModel).widthPercentage = 0;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeWidthPercentage = (newValue: number) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as SpacerModel).widthPercentage =
      newValue;
    (pageComponents[pageConfigs.editIndex] as SpacerModel).width = 0;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  return (
    <div className="flex flex-col gap-1">
      <NumberField
        fieldId={"spacerHeight"}
        label={"Height"}
        min={1}
        value={editingModel.height}
        onChange={onChangeHeight}
      />
      {usePercentage ? (
        <>
          <div className="font-semibold">Width Percentage</div>
          <Slider
            min={1}
            max={100}
            sliderValue={editingModel.widthPercentage}
            postfix={"%"}
            onChange={onChangeWidthPercentage}
          />
        </>
      ) : (
        <NumberField
          fieldId={"spacerWidth"}
          label={"Width"}
          min={1}
          value={editingModel.width}
          onChange={onChangeWidth}
        />
      )}
      <div className="flex">
        <div className="w-full">Use Percentage?</div>
        <input
          type="checkbox"
          checked={usePercentage}
          onChange={(e) => setUsePercentage(e.target.checked)}
        />
      </div>
    </div>
  );
};
