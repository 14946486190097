/* eslint-disable react-hooks/exhaustive-deps */
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { CampaignModel } from "@Pages/get-response/data/campaign.model";
import { ContactModel } from "@Pages/get-response/data/contact.model";
import { CampaignContactsUsecase } from "@Pages/get-response/domain/CampaignList.usecase";
import { FC, useEffect, useState } from "react";
import { FaCheckCircle, FaEye, FaTimesCircle } from "react-icons/fa";
import { PageControllerComponent } from "../components/PageController.component";
import { SectionProps } from "../GetResponse.page";

export type CampaignItemProps = {
  data: CampaignModel;
};

export type CampaignSectionProps = SectionProps & {
  data: CampaignModel[];
};

export const CampaignItem: FC<CampaignItemProps> = ({ data }) => {
  const [page, setPage] = useState(1);
  const [contacts, setContacts] = useState<ContactModel[]>([]);

  useEffect(() => {
    async function fetchData() {
      let response = await CampaignContactsUsecase(data.id, page);
      setContacts(response);
    }
    fetchData();
  }, [page]);

  return (
    <div>
      <div className="p-4 grid grid-cols-1 md:grid-cols-3 border border-black rounded text-center">
        <div>
          <div className="">Landing Pages</div>
          <div className="font-bold">{data.statistics.totalLandingPages}</div>
        </div>
        <div>
          <div className="">Newsletters</div>
          <div className="font-bold">{data.statistics.totalNewsletters}</div>
        </div>
        <div>
          <div className="">Subscribers</div>
          <div className="font-bold">{data.statistics.totalSubscribers}</div>
        </div>
        <div>
          <div className="">Triggers</div>
          <div className="font-bold">{data.statistics.totalTriggers}</div>
        </div>
        <div>
          <div className="">Webforms</div>
          <div className="font-bold">{data.statistics.totalWebforms}</div>
        </div>
      </div>

      <div className="p-4 grid grid-cols-2 gap-4">
        <div>Name</div>
        <div>{data.name}</div>

        <div>Description</div>
        <div>{data.description}</div>

        <div>Language</div>
        <div>{data.language}</div>

        <div>Created On</div>
        <div>{data.createdOn}</div>

        <div>Is Default</div>
        <div>{data.isDefault}</div>
      </div>

      <table className={`border-collapse border w-full table-auto mt-4`}>
        <thead className="p-2 shadow-sm bg-gray-100 sticky">
          <tr>
            <th className="border border-spacing-1 text-center font-bold px-2">
              ID
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 text-ellipsis">
              Name
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2">
              Email
            </th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((item, index) => (
            <tr key={`campaign-contact-row-${item.id}-${index}`}>
              <td className="border px-2 text-center">{item.id}</td>
              <td className="border px-2 text-left">{item.name}</td>
              <td className="border px-2">{item.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-end m-4">
        {page > 1 && (
          <button
            className="button-yellow-outline-with-hover p-2"
            onClick={() => setPage(page - 1)}
          >
            &lt;
          </button>
        )}
        <div className="my-auto mx-4">Halaman {page}</div>
        {contacts.length >= 100 && (
          <button
            className="button-yellow-outline-with-hover p-2"
            onClick={() => setPage(page + 1)}
          >
            &gt;
          </button>
        )}
      </div>
    </div>
  );
};

export const CampaignSection: FC<CampaignSectionProps> = ({
  data,
  page,
  setPage,
}) => {
  const modalContext = UseModalContext();

  const showDetail = (index: number) => {
    let title = data[index].id;
    let description = <CampaignItem data={data[index]} />;
    let primaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Close
      </button>
    );

    modalContext.setModal(
      createModal(null, title, description, primaryCta, null)
    );
  };

  return (
    <div className="mt-4">
      <table className={`border-collapse border w-full table-auto`}>
        <thead className="p-2 shadow-sm bg-gray-100 sticky">
          <tr>
            <th className="border border-spacing-1 text-center font-bold px-2">
              ID
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 text-ellipsis">
              Name
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2">
              Is Default
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={`campaign-row-${item.id}-${index}`}>
              <td className="border px-2 text-center">{item.id}</td>
              <td className="border px-2 text-left">
                {item.name}
                <div>
                  <button onClick={() => showDetail(index)}>
                    <FaEye size={20} className="text-blue-400" />
                  </button>
                </div>
              </td>
              <td className="border px-2">
                {item.isDefault === "true" ? (
                  <FaCheckCircle size={24} color="green" className="m-auto" />
                ) : (
                  <FaTimesCircle size={24} color="red" className="m-auto" />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PageControllerComponent
        currentPage={page}
        showPrev={page > 1}
        showNext={data.length >= 100}
        onPrev={setPage}
        onNext={setPage}
      />
    </div>
  );
};
