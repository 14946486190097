import { FC } from "react";
import ReactPlayer from "react-player/lazy";

export type MidasVideoPlayerOptionsProps = {
  widthPercentage: number;
  showVideoControls: boolean;
  isAutoPlay: boolean;
  canDownload: boolean;
  videoSource: string;
};

export type MidasVideoPlayerProps = {
  componentKey: string;
  videoUrl: string;
  options: MidasVideoPlayerOptionsProps;
};

export const MidasVideoPlayer: FC<MidasVideoPlayerProps> = ({
  componentKey,
  videoUrl,
  options,
}) => {
  let controlList = "";

  if (options.canDownload === false) {
    controlList = "nodownload";
  }

  let height = "auto";
  if (options.videoSource === "youtube") {
    height = `${(9 / 16) * options.widthPercentage}%`;
  }

  return (
    <ReactPlayer
      key={componentKey}
      className={`block mx-auto ${
        options.videoSource === "youtube" && "aspect-video"
      }`}
      url={videoUrl}
      controls={options.showVideoControls}
      light={false}
      width={`${options.widthPercentage}%`}
      height={height}
      config={{
        file: {
          attributes: {
            autoPlay: options.isAutoPlay,
            controlsList: controlList,
          },
        },
      }}
    />
  );
};
