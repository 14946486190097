import {
  PageConfigModel,
  UsePageEditorContext,
} from "@Pages/page/context/page-editor.context";
import { FC } from "react";
import {
  FaAlignCenter,
  FaAlignJustify,
  FaAlignLeft,
  FaAlignRight,
} from "react-icons/fa";
import { TextModel } from "../../models/text.model";
import { NumberField } from "../forms/NumberField.component";
import { Slider } from "../forms/Slider.component";
import { MidasTextEditor } from "../forms/wysiwyg.component";
import { TextAlignType } from "../widgets/Text.component";

export type TextWidthControlProps = {
  widthValue: number;
  onChangeWidth: (newValue: number) => void;
};

export const TextWidthControl: FC<TextWidthControlProps> = ({
  widthValue,
  onChangeWidth,
}) => {
  return (
    <div className="flex flex-col w-full">
      <div>Width</div>
      <Slider
        min={1}
        max={100}
        sliderValue={widthValue}
        postfix="%"
        onChange={onChangeWidth}
      />
    </div>
  );
};

export type TextAlignControlProps = {
  currentAlign: TextAlignType;
  onChangeAlign: (newValue: TextAlignType) => void;
};

export const TextAlignControl: FC<TextAlignControlProps> = ({
  currentAlign,
  onChangeAlign,
}) => {
  const onClick = (value: TextAlignType) => {
    if (value !== currentAlign) {
      onChangeAlign(value);
    }
  };

  let defaultButtonClass = "p-1";

  return (
    <div className="flex justify-between">
      <div></div>
      <div className="bg-white border rounded flex divide-x h-8">
        <button
          onClick={() => onClick("left")}
          className={
            currentAlign === "left"
              ? "bg-gray-500 " + defaultButtonClass
              : "bg-white " + defaultButtonClass
          }
        >
          <FaAlignLeft />
        </button>
        <button
          onClick={() => onClick("center")}
          className={
            currentAlign === "center"
              ? "bg-gray-500 " + defaultButtonClass
              : "bg-white " + defaultButtonClass
          }
        >
          <FaAlignCenter />
        </button>
        <button
          onClick={() => onClick("right")}
          className={
            currentAlign === "right"
              ? "bg-gray-500 " + defaultButtonClass
              : "bg-white " + defaultButtonClass
          }
        >
          <FaAlignRight />
        </button>
        <button
          onClick={() => onClick("justify")}
          className={
            currentAlign === "justify"
              ? "bg-gray-500 " + defaultButtonClass
              : "bg-white " + defaultButtonClass
          }
        >
          <FaAlignJustify />
        </button>
      </div>
    </div>
  );
};

export type TextColorControlProps = {
  label: string;
  colorValue: string;
  onChangeColor: (newValue: string) => void;
  canBeTransparent?: boolean;
};

export const TextColorControl: FC<TextColorControlProps> = ({
  label,
  colorValue,
  onChangeColor,
  canBeTransparent,
}) => {
  let shouldShowTransparent = canBeTransparent === undefined;
  shouldShowTransparent = shouldShowTransparent || canBeTransparent === true;

  return (
    <>
      <div className="flex">
        <div className="w-full">{label}</div>
        <div className="">
          <input
            type="color"
            value={colorValue}
            disabled={colorValue === "transparent"}
            onChange={(e) => onChangeColor(e.target.value)}
          />
        </div>
      </div>
      {shouldShowTransparent && (
        <div className="flex">
          <div className="w-full">{label} Transparent</div>
          <div className="text-right">
            <input
              type="checkbox"
              checked={colorValue === "transparent"}
              onChange={(e) => {
                if (e.target.checked) {
                  onChangeColor("transparent");
                } else {
                  onChangeColor("#000000");
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export const TextViewConfig = () => {
  const { pageConfigs, setPageConfigs } = UsePageEditorContext();

  const editingModel: TextModel = pageConfigs.pageComponents[
    pageConfigs.editIndex
  ] as TextModel;

  const updateOldModel = (newConfig: PageConfigModel) => {
    if (
      pageConfigs.oldModel === null ||
      typeof pageConfigs.oldModel === "undefined"
    ) {
      newConfig["oldModel"] = new TextModel(
        editingModel.componentKey,
        editingModel.text,
        editingModel.width,
        editingModel.backgroundColorHex,
        editingModel.textColorHex,
        editingModel.textSize,
        editingModel.textAlign
      );
    }
  };

  const onChangeWidth = (newValue: number) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as TextModel).width = newValue;

    newConfig.pageComponents = pageComponents;
    setPageConfigs(newConfig);
  };

  const onChangeTextSize = (newValue: number) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as TextModel).textSize = newValue;

    newConfig.pageComponents = pageComponents;
    setPageConfigs(newConfig);
  };

  const onChangeTextAlign = (newValue: TextAlignType) => {
    let newConfig = { ...pageConfigs };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as TextModel).textAlign = newValue;

    newConfig.pageComponents = pageComponents;
    setPageConfigs(newConfig);
  };

  const onChangeBackgroundColor = (newValue: string) => {
    let newConfig = { ...pageConfigs };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as TextModel).backgroundColorHex =
      newValue;

    newConfig.pageComponents = pageComponents;
    setPageConfigs(newConfig);
  };

  const onChangeTextColor = (newValue: string) => {
    let newConfig = { ...pageConfigs };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as TextModel).textColorHex =
      newValue;

    newConfig.pageComponents = pageComponents;
    setPageConfigs(newConfig);
  };

  return (
    <div className="flex flex-col gap-2">
      <MidasTextEditor />
      <NumberField
        fieldId={"tvTextSize"}
        label="Size"
        min={1}
        value={editingModel.textSize}
        onChange={onChangeTextSize}
      />
      <TextWidthControl
        widthValue={editingModel.width}
        onChangeWidth={onChangeWidth}
      />
      <TextAlignControl
        currentAlign={editingModel.textAlign}
        onChangeAlign={onChangeTextAlign}
      />
      <TextColorControl
        label={"Background Color"}
        colorValue={editingModel.backgroundColorHex}
        onChangeColor={onChangeBackgroundColor}
      />
      <TextColorControl
        label={"Text Color"}
        colorValue={editingModel.textColorHex}
        onChangeColor={onChangeTextColor}
        canBeTransparent={false}
      />
    </div>
  );
};
