/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FaExclamationTriangle,
  FaRegCalendarAlt,
  FaUserAlt,
} from "react-icons/fa";

import { AccordionComponent } from "@Components/accordion/Accordion.component";
import { ContentHeader } from "@Components/ContentHeader.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { convertDateFromApiToView } from "@Helpers/formatter";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { RecordModel } from "../data/record.model";
import { GetRecordUsecase } from "../domain/GetRecord.usecase";
import { DeleteRecordUsecase } from "../domain/DeleteRecord.usecase";
import { UpdateRecordUsecase } from "../domain/UpdateRecord.usecase";
import { MediaChooser } from "@Pages/common/presentation/MediaChooser.page";
import { ResourceModel } from "@Pages/resources/data/resource.model";

export function DetailRecordPage() {
  const params = useParams();
  const navigate = useNavigate();

  const modalContext = UseModalContext();
  const toasterContext = UseToasterContext();

  const [detail, setDetail] = useState<RecordModel>();

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await GetRecordUsecase(params.id ?? "");
        setDetail(response);
      } catch (e) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to fetch Record",
            e.message,
            ToasterType.DANGER
          ),
        ]);
      }
    }
    fetchData();
  }, []);

  const showDeleteModal = () => {
    let icon = (
      <div className="text-red-500">
        <FaExclamationTriangle />
      </div>
    );
    let title = "Hapus Rekaman";
    let description = (
      <>
        Apakah anda yakin ingin menghapus{" "}
        <span className="font-bold">{detail?.title}</span> ?
      </>
    );
    let primaryCta = (
      <button
        className="button-red-outline-with-hover"
        onClick={() => handleDelete()}
      >
        Hapus
      </button>
    );
    let secondaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(icon, title, description, primaryCta, secondaryCta)
    );
  };

  const handleDelete = async () => {
    try {
      await DeleteRecordUsecase(detail?.id ?? 0);
      navigate(-1);
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to delete record",
          errors.message,
          ToasterType.SUCCESS
        ),
      ]);
    }
  };

  const updateData = async (status: string) => {
    try {
      let formData = new FormData();
      formData.append("title", detail?.title ?? "");
      formData.append("description", detail?.description ?? "");
      formData.append("zoom_link", detail?.zoom_link ?? "");
      formData.append("cover_image_path", detail?.cover_image_path ?? "");
      formData.append("status", status);

      await UpdateRecordUsecase((detail?.id ?? "").toString(), formData);

      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Berhasil",
          "Rekaman berhasil di perbarui!",
          ToasterType.SUCCESS
        ),
      ]);
      popWithDelay();
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Gagal memperbarui rekaman",
          errors.message,
          ToasterType.SUCCESS
        ),
      ]);
    }
  };

  const popWithDelay = () => {
    setTimeout(function () {
      navigate(-1);
    }, 1000);
  };

  const onMediaSelected = (data: ResourceModel) => {
    if (detail) {
      setDetail({
        ...detail,
        cover_image_url: detail.cover_image_url.replace(
          detail.cover_image_path,
          data.path
        ),
        cover_image_path: data.path,
      });
    }
    modalContext.setModal(null);
  };

  const onSelectMediaClicked = () => {
    let title = "Select Media";
    let description = (
      <MediaChooser type={"image"} onMediaSelected={onMediaSelected} />
    );
    let cancelButton = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(null, title, description, cancelButton, null)
    );
  };

  return (
    <div className="p-4 h-full bg-gray-300">
      {!detail && <MidasShimmering />}
      {detail && (
        <>
          <ContentHeader title={detail.title} />
          <div className="h-6" />

          <div className="w-full mt-4">
            <div>Cover Image</div>
            <div className="flex gap-4">
              {detail?.cover_image_url !== "" ? (
                <img
                  src={detail.cover_image_url}
                  className="w-24 h-24 rounded"
                  alt=""
                />
              ) : (
                <div className="w-24 h-24 rounded bg-gray-500"></div>
              )}

              <div className="input-field-full-width flex p-0 h-fit my-auto">
                <input
                  type="text"
                  className="w-full outline-none p-1"
                  value={
                    detail.cover_image_path != null
                      ? detail.cover_image_path
                      : ""
                  }
                  onChange={(e) =>
                    setDetail({
                      ...detail,
                      cover_image_path: e.target.value,
                    })
                  }
                  disabled
                  readOnly
                />
                <button
                  className="rounded-r bg-gray-500 shadow text-white w-32"
                  onClick={onSelectMediaClicked}
                >
                  Select Image
                </button>
              </div>
            </div>
          </div>

          <div className="w-full mt-4">
            <div>Description</div>
            <input
              type="text"
              className="input-field-full-width"
              value={detail.description != null ? detail.description : ""}
              onChange={(e) =>
                setDetail({
                  ...detail,
                  description: e.target.value,
                })
              }
            />
          </div>
          <div className="w-full mt-4 flex gap-3">
            <div>Zoom Record URL</div>
            <input
              type="text"
              className="input-field-full-width"
              value={detail.zoom_link != null ? detail.zoom_link : ""}
              onChange={(e) =>
                setDetail({
                  ...detail,
                  zoom_link: e.target.value,
                })
              }
            />
          </div>

          <div className="w-full mt-4 border bg-white">
            <AccordionComponent title="Save" isOpen={true}>
              <div className="p-4">
                {/* Uploaded Date */}
                <div className="flex">
                  <div className="w-4">
                    <FaRegCalendarAlt />
                  </div>
                  <div className="ml-2 text-xs m-auto text-ellipsis">
                    Created on:{" "}
                    <span className="font-medium">
                      {convertDateFromApiToView(detail.created_at)}
                    </span>
                  </div>
                </div>

                {/* Uploaded By */}
                <div className="flex mt-2">
                  <div className="w-4">
                    <FaUserAlt />
                  </div>
                  <div className="ml-2 text-xs m-auto text-ellipsis">
                    Created by:{" "}
                    <span className="font-medium">
                      {detail.created_by.fullname}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex border-t-2 justify-between py-2 mt-2 px-4">
                <button
                  className="button-red-outline-with-hover text-xs"
                  onClick={showDeleteModal}
                >
                  Delete Permanently
                </button>
                <div>
                  <button
                    className="button-outline-with-hover text-xs mr-4 h-full"
                    onClick={() => updateData("draft")}
                  >
                    Draft
                  </button>
                  <button
                    className="button-yellow-outline-with-hover text-xs"
                    onClick={() => updateData("published")}
                  >
                    Publish
                  </button>
                </div>
              </div>
            </AccordionComponent>
          </div>
        </>
      )}
    </div>
  );
}
