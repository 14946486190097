import { PATH_VOUCHERS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const AddVoucherUsecase: (
  formData: FormData
) => Promise<string> = async (formData) => {
  try {
    let response = await client.post(PATH_VOUCHERS, formData);
    return response.data.message;
  } catch (e) {
    throw new Error(e.response.data.errors[0]);
  }
};
