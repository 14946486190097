import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { SaveSettingsUsecase } from "@Pages/setting/domain/SaveSettings.usecase";
import {
  HOMEPAGE_PORTFOLIO,
  HOMEPAGE_SHOWCASE,
} from "@Pages/setting/utils/const";
import { FC } from "react";
import {
  PortfolioFormComponent,
  PortfolioInputModel,
} from "../components/PortfolioForm.component";
import {
  ShowcaseFormComponent,
  ShowcaseInputModel,
} from "../components/ShowcaseForm.component";
import { SettingsSectionsProps } from "../SettingStaticPages.page";

export const HomepageSection: FC<SettingsSectionsProps> = ({
  settingsData,
  setNeedRefetch,
}) => {
  const toasterContext = UseToasterContext();

  const getPortfolioList = () => {
    let portfolioList = settingsData.find(
      (item) => item.key === HOMEPAGE_PORTFOLIO
    );
    if (portfolioList) {
      let parsed = JSON.parse(portfolioList.value);
      return parsed;
    }
    return { imageUrl: "" };
  };

  const getShowcaseList = () => {
    let showcasesList = settingsData.find(
      (item) => item.key === HOMEPAGE_SHOWCASE
    );
    if (showcasesList) {
      let parsed = JSON.parse(showcasesList.value);
      return parsed;
    }
    return [];
  };

  const saveSetting = (
    type: string,
    isUpdating: boolean,
    key: string,
    value: string
  ) => {
    SaveSettingsUsecase(isUpdating, key, value)
      .then((message) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            `${type} updated successfully!`,
            message,
            ToasterType.SUCCESS
          ),
        ]);
        setNeedRefetch(true);
      })
      .catch((errors) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to update settings!",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      });
  };

  const onSavePortfolioList = (portfolioList: PortfolioInputModel) => {
    let portfolioStringify = JSON.stringify(portfolioList);
    let portfolioString = settingsData.find(
      (item) => item.key === HOMEPAGE_PORTFOLIO
    );

    saveSetting(
      "Portfolio",
      portfolioString !== undefined,
      HOMEPAGE_PORTFOLIO,
      portfolioStringify
    );
  };

  const onSaveShowcaseList = (showcaseList: ShowcaseInputModel[]) => {
    let showcasesStringify = JSON.stringify(showcaseList);
    let showcasesString = settingsData.find(
      (item) => item.key === HOMEPAGE_SHOWCASE
    );

    saveSetting(
      "Showcase",
      showcasesString !== undefined,
      HOMEPAGE_SHOWCASE,
      showcasesStringify
    );
  };

  return (
    <div className="mt-4">
      <PortfolioFormComponent
        portfolioList={getPortfolioList()}
        onSavePortfolio={onSavePortfolioList}
      />
      <div className="w-full border-t mt-4"></div>
      <ShowcaseFormComponent
        showcaseList={getShowcaseList()}
        onSaveShowcase={onSaveShowcaseList}
        title="Showcase List"
      />
    </div>
  );
};
