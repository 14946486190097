import { PATH_RESOURCES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ResourceModel } from "../data/resource.model";

export const GetResource: (id: string) => Promise<ResourceModel> = async (
  id
) => {
  try {
    let response = await client.get(PATH_RESOURCES + "/" + id);
    return response.data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
