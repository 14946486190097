import {
  PATH_COURSES,
  PATH_COURSES_ITEM,
  PATH_COURSES_SECTION,
} from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import {
  CourseItemModel,
  CourseModel,
  CourseSectionModel,
} from "../data/course.model";

export const AddCourseUsecase: (
  title: string,
  description: string,
  coverImagePath: string,
  slug: string,
  level: string,
  status: string
) => Promise<CourseModel> = async (
  title,
  description,
  coverImagePath,
  slug,
  level,
  status
) => {
  try {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("cover_image_path", coverImagePath);
    formData.append("slug", slug);
    formData.append("level", level);
    formData.append("status", status);

    let response = await client.post(PATH_COURSES, formData);
    return response.data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const AddCourseSectionUsecase: (
  courseId: number,
  order: number,
  title: string
) => Promise<CourseSectionModel> = async (courseId, order, title) => {
  try {
    let formData = new FormData();
    formData.append("orders", order.toString());
    formData.append("title", title);

    let response = await client.post(
      PATH_COURSES + "/" + courseId + "/" + PATH_COURSES_SECTION,
      formData
    );
    return response.data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const AddCourseItemUsecase: (
  sectionId: number,
  order: number,
  title: string,
  description: string,
  coverImagePath: string,
  videoUrl: string,
  durationInSecond: string
) => Promise<CourseItemModel> = async (
  sectionId,
  order,
  title,
  description,
  coverImagePath,
  videoUrl,
  durationInSecond
) => {
  try {
    let formData = new FormData();
    formData.append("orders", order.toString());
    formData.append("title", title);
    formData.append("description", description);
    formData.append("cover_image_path", coverImagePath);
    formData.append("video_url", videoUrl);
    formData.append("duration_in_seconds", durationInSecond);

    let response = await client.post(
      PATH_COURSES + "/" + sectionId + "/" + PATH_COURSES_ITEM,
      formData
    );
    return response.data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
