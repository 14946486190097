import { PATH_LOAD_BATCH_SETTINGS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export type PageSettingListModel = {
  [key: string]: string;
};

export const LoadSettingUsecase: (
  optionKeys: string
) => Promise<PageSettingListModel> = async (optionKeys) => {
  try {
    let response = await client.get(
      PATH_LOAD_BATCH_SETTINGS + "/" + optionKeys
    );
    let data = response.data.data;

    let result: PageSettingListModel = {};

    data.forEach((item: any) => {
      result[item.key] = item.value;
    });

    return result;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};