import { AffiliateWithdrawModel } from "@Pages/affiliate/data/AffiliateWithdraw.model";
import { FC } from "react";
import { AffiliateWithdrawDetailItemComponent } from "./AffiliateWithdrawDetail.component";
import { formatToCurrency } from "@Helpers/formatter";

export type AffilaiteWithdrawApproveProps = {
  withdrawRequest: AffiliateWithdrawModel;
  onCancel: () => void;
  onComplete: () => void;
};

export const AffiliateWithdrawApproveRequestView: FC<
  AffilaiteWithdrawApproveProps
> = ({ withdrawRequest, onCancel, onComplete }) => {
  return (
    <div>
      <div className="w-full text-center">
        Silahkan lakukan pencairan dana Affiliate dengan detail sebagai berikut
        :{" "}
      </div>

      <div className="mt-4 border rounded p-4">
        <AffiliateWithdrawDetailItemComponent
          label="Bank Name"
          value={withdrawRequest.bank.bankName}
          className=""
        />

        <AffiliateWithdrawDetailItemComponent
          label="Bank Holder Name"
          value={withdrawRequest.bank.bankHolderName}
          className=""
        />

        <AffiliateWithdrawDetailItemComponent
          label="Bank Account Name"
          value={withdrawRequest.bank.bankAccountNumber}
          className=""
        />

        <AffiliateWithdrawDetailItemComponent
          label="Jumlah"
          value={formatToCurrency(withdrawRequest.amount)}
          className=""
        />
      </div>

      <div className="my-4 w-full flex justify-center">
        <button
          type="button"
          className="button-outline-with-hover mx-2"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className="button-yellow-outline-with-hover mx-2"
          onClick={onComplete}
        >
          Complete
        </button>
      </div>
    </div>
  );
};
