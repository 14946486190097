import { PATH_AFFILIATES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { AffiliateUserModel } from "../data/AffiliateUser.model";

export const GetAffiliateUsersUsecase: () => Promise<
  AffiliateUserModel[]
> = async () => {
  try {
    let response = await client.get(PATH_AFFILIATES);
    return response.data.data.map((item: any) => ({
      affiliateName: item.affiliate_name,
      createdAt: item.created_at,
      currentBalance: item.current_balance,
      currentBalanceStr: item.current_balance_str,
      notes: item.notes,
      onHoldBalance: item.on_hold_balance,
      status: item.status,
      totalEarnings: item.total_earnings,
      totalEarningsStr: item.total_earnings_str,
      updatedAt: item.updated_at,
      createdBy: item.created_by,
      user: item.user,
    }));
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
