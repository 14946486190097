import { PATH_AFFILIATES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const UpdateAffiliateWithdrawRequestStatusUseCase: (
  withdrawId: number,
  status: string,
  notes?: string
) => Promise<boolean> = async (withdrawId, status, notes) => {
  try {
    let formData = new FormData();
    formData.append("status", status);
    if (notes) {
      formData.append("notes", notes);
    }

    await client.put(
      PATH_AFFILIATES + "/withdrawRequest/" + withdrawId,
      formData
    );
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
