import { FC } from "react";
import { useState } from "react";

export type SliderProps = {
  sliderValue: number;
  min: number;
  max: number;
  onChange: (newValue: number) => void;
  prefix?: string;
  postfix?: string;
};

export const Slider: FC<SliderProps> = ({
  sliderValue,
  min,
  max,
  onChange,
  prefix,
  postfix,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const mOnChange = (event: any) => {
    let formattedValue = event.target.value
      .replace(prefix, "")
      .replace(postfix, "");
    try {
      let intValue = parseInt(formattedValue);
      if (intValue >= min && intValue <= max) {
        onChange(intValue);
        setErrorMessage("");
      } else if (intValue < min) {
        setErrorMessage(`Min ${prefix}${min}${postfix}`);
      } else if (intValue > max) {
        setErrorMessage(`Max ${prefix}${max}${postfix}`);
      }
    } catch (e) {
      setErrorMessage(`Only number between ${min} to ${max} allowed`);
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className="w-full">
          <input
            type={"range"}
            min={min}
            max={max}
            value={sliderValue}
            onChange={mOnChange}
            className="align-middle input-field-full-width"
          />
        </div>
        <div className="w-full ml-0 md:w-5/12 lg:w-4/12 md:ml-2">
          <input
            type="text"
            className="input-field-full-width"
            value={prefix ?? "" + sliderValue + postfix ?? ""}
            onChange={mOnChange}
          />
        </div>
      </div>
      <div className="text-red-500 text-xs">{errorMessage}</div>
    </>
  );
};
