import { PATH_GR_CAMPAIGNS, PATH_GR_CAMPAIGNS_CONTACTS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { CampaignModel } from "../data/campaign.model";
import { ContactModel } from "../data/contact.model";

export const CampaignListUsecase: (
  page: number
) => Promise<CampaignModel[]> = async (page) => {
  try {
    let response = await client.get(PATH_GR_CAMPAIGNS + `?page=${page}`);
    let data = response.data.data;

    return data.map((item: any) => {
      return {
        id: item.campaignId,
        createdOn: item.createdOn,
        name: item.name,
        language: item.languageCode,
        isDefault: item.isDefault,
        description: item.description,
        statistics: {
          totalLandingPages: item.statistics.totalLandingPages,
          totalNewsletters: item.statistics.totalNewsletters,
          totalSubscribers: item.statistics.totalSubscribers,
          totalTriggers: item.statistics.totalTriggers,
          totalWebforms: item.statistics.totalWebforms,
        },
      };
    });
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const CampaignContactsUsecase: (
  id: string,
  page: number
) => Promise<ContactModel[]> = async (id, page) => {
  try {
    let response = await client.get(
      PATH_GR_CAMPAIGNS_CONTACTS.replace("{id}", id) + `?page=${page}`
    );
    let data = response.data.data;

    return data.map((item: any) => {
      return {
        id: item.contactId,
        cratedOn: item.createdOn,
        changedOn: item.changedOn,
        email: item.email,
        name: item.name,
      };
    });
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
