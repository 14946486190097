import { PATH_AFFILIATES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const GetAffiliateRequestCount: () => Promise<number> = async () => {
  try {
    let response = await client.get(PATH_AFFILIATES + "/requestWithdrawCount");
    return response.data.data.count;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
