import { FC } from "react";
import { useForm } from "react-hook-form";

export type AffilaiteWithdrawRejectProps = {
  onReject: (notes: string) => void;
  onCancel: () => void;
};

export const AffiliateWithdrawRejectRequestView: FC<
  AffilaiteWithdrawRejectProps
> = ({ onReject, onCancel }) => {
  type FormInput = {
    notes: string;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>();

  const onSubmit = (data: FormInput) => {
    onReject(data.notes);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="font-medium">Catatan Penolakan</div>
      <div className="w-full">
        <input
          className={`input-field-full-width ${
            errors.notes && "border-red-500"
          }`}
          type="text"
          {...register("notes", {
            required: "Notes is required",
          })}
        />
        {errors.notes && (
          <p className="text-red-500 text-xs">{errors.notes?.message}</p>
        )}
      </div>

      <div className="my-4 w-full flex justify-center">
        <button
          type="button"
          className="button-outline-with-hover mx-2"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button type="submit" className="button-red-outline-with-hover mx-2">
          Submit
        </button>
      </div>
    </form>
  );
};
