import {
  PageConfigModel,
  UsePageEditorContext,
} from "@Pages/page/context/page-editor.context";

import { FC } from "react";
import { AiOutlineDash, AiOutlineSmallDash } from "react-icons/ai";
import { CgBorderStyleSolid } from "react-icons/cg";

import { DividerModel } from "../../models/divider.model";
import { NumberField } from "../forms/NumberField.component";
import { TextColorControl } from "./TextConfig.component";

export type DividerTypeControlProps = {
  currentType: string;
  onChangeType: (newValue: string) => void;
};

export const DividerTypeControl: FC<DividerTypeControlProps> = ({
  currentType,
  onChangeType,
}) => {
  const onClick = (value: string) => {
    if (value !== currentType) {
      onChangeType(value);
    }
  };

  let defaultClass = "w-full p-2";

  return (
    <div className="flex justify-evenly">
      <div className="w-full">Divider Type</div>
      <div className="w-full text-right">
        <div className="border rounded bg-white flex h-8">
          <button
            onClick={() => onClick("solid")}
            className={
              currentType === "solid"
                ? "bg-gray-500 " + defaultClass
                : "bg-white " + defaultClass
            }
          >
            <CgBorderStyleSolid />
          </button>
          <button
            onClick={() => onClick("dashed")}
            className={
              currentType === "dashed"
                ? "bg-gray-500 " + defaultClass
                : "bg-white " + defaultClass
            }
          >
            <AiOutlineDash />
          </button>
          <button
            onClick={() => onClick("dotted")}
            className={
              currentType === "dotted"
                ? "bg-gray-500 " + defaultClass
                : "bg-white " + defaultClass
            }
          >
            <AiOutlineSmallDash />
          </button>
        </div>
      </div>
    </div>
  );
};

export const DividerViewConfig = () => {
  const { pageConfigs, setPageConfigs } = UsePageEditorContext();

  const editingModel: DividerModel = pageConfigs.pageComponents[
    pageConfigs.editIndex
  ] as DividerModel;

  const updateOldModel = (newConfig: PageConfigModel) => {
    if (
      pageConfigs.oldModel == null ||
      typeof pageConfigs.oldModel == "undefined"
    ) {
      newConfig["oldModel"] = new DividerModel(
        editingModel.componentKey,
        editingModel.thickness,
        editingModel.indent,
        editingModel.endIndent,
        editingModel.colorHex,
        editingModel.topSpace,
        editingModel.bottomSpace,
        editingModel.type
      );
    }
  };

  const onChangeThickness = (newValue: number) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as DividerModel).thickness =
      newValue;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeIndent = (newValue: number) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as DividerModel).indent = newValue;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeEndIndent = (newValue: number) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as DividerModel).endIndent =
      newValue;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeTopSpace = (newValue: number) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as DividerModel).topSpace = newValue;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeBottomSpace = (newValue: number) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as DividerModel).bottomSpace =
      newValue;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeType = (newValue: string) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as DividerModel).type = newValue;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeColor = (newValue: string) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as DividerModel).colorHex = newValue;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  return (
    <div className="flex flex-col gap-1">
      <NumberField
        fieldId={"dividerThickness"}
        label={"Thickness"}
        min={1}
        value={editingModel.thickness}
        onChange={onChangeThickness}
      />
      <NumberField
        fieldId={"dividerIndent"}
        label={"Indent"}
        min={0}
        value={editingModel.indent}
        onChange={onChangeIndent}
      />
      <NumberField
        fieldId={"dividerEndIndent"}
        label={"End Indent"}
        min={0}
        value={editingModel.endIndent}
        onChange={onChangeEndIndent}
      />
      <NumberField
        fieldId={"dividerTopSpace"}
        label={"Top Space"}
        min={0}
        value={editingModel.topSpace}
        onChange={onChangeTopSpace}
      />
      <NumberField
        fieldId={"dividerBottomSpace"}
        label={"Bottom Space"}
        min={0}
        value={editingModel.bottomSpace}
        onChange={onChangeBottomSpace}
      />
      <DividerTypeControl
        currentType={editingModel.type}
        onChangeType={onChangeType}
      />
      <TextColorControl
        label={"Color"}
        colorValue={editingModel.colorHex}
        onChangeColor={onChangeColor}
        canBeTransparent={false}
      />
    </div>
  );
};
