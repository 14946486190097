import { PATH_ORDERS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { OrderModel } from "../data/order.model";

export const GetOrderUsecase: (id: string) => Promise<OrderModel> = async (
  id
) => {
  try {
    let response = await client.get(PATH_ORDERS + "/" + id);
    return response.data.data;
  } catch (e) {
    throw new Error(e.response.data);
  }
};
