import { FC, useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export type AdditionalInfoItem = {
  label: string;
  option: string;
};

export type AdditionalInfoFormProps = {
  additionalData: AdditionalInfoItem[];
  onSaveAdditionalInfo: (additionalInfo: AdditionalInfoItem[]) => void;
};

export const AdditionalInfoFormComponent: FC<AdditionalInfoFormProps> = ({
  additionalData,
  onSaveAdditionalInfo,
}) => {
  const [data, setData] = useState<AdditionalInfoItem[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    setData(additionalData);
  }, [additionalData]);

  const onAddNewAdditionalInfo = () => {
    let newData = [...data];
    newData.push({
      label: "",
      option: "",
    });
    setData(newData);
  };

  const onRemoveAdditionalInfo = (indexToRemove: number) => {
    let newData = data.filter((item, index) => index !== indexToRemove);
    setData(newData);
  };

  const onChangeQuestion = (index: number, label: string) => {
    let newData = [...data];
    newData[index].label = label;
    setData(newData);
  };

  const onChangeOptions = (index: number, options: string) => {
    let newData = [...data];
    newData[index].option = options;
    setData(newData);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div
          className="w-full text-lg font-bold my-auto"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          Additional Info List
        </div>
        <div className="flex">
          {!isCollapsed && (
            <button
              type={"button"}
              className="button-yellow-outline-with-hover mr-2"
              onClick={onAddNewAdditionalInfo}
            >
              +
            </button>
          )}
          {!isCollapsed && (
            <button
              type={"button"}
              className="button-yellow-outline-with-hover"
              onClick={() => onSaveAdditionalInfo(data)}
            >
              Save
            </button>
          )}
          <button
            className="ml-4 mx-auto"
            type={"button"}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
          </button>{" "}
        </div>
      </div>
      {!isCollapsed && (
        <div className="mt-2">
          <table className="table-auto border w-full">
            <thead className="p-2 shadow-sm bg-gray-100 sticky">
              <tr>
                <th>No</th>
                <th>Question</th>
                <th>Options</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item: any, index: number) => {
                  return (
                    <tr key={"additional-info-" + index}>
                      <td className="text-center p-2">{index + 1}</td>
                      <td className="text-center">
                        <input
                          className="text-center w-full"
                          type="text"
                          value={item.label}
                          placeholder={"Question"}
                          onChange={(e) =>
                            onChangeQuestion(index, e.target.value)
                          }
                        />
                      </td>
                      <td className="text-center">
                        <input
                          className="text-center w-full"
                          type="text"
                          value={item.options}
                          placeholder={
                            'Options (if select). eg : ["Instagram", "Email"]'
                          }
                          onChange={(e) =>
                            onChangeOptions(index, e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <button
                          className="button-red-outline-with-hover"
                          onClick={() => onRemoveAdditionalInfo(index)}
                        >
                          -
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className="text-center bg-gray-300 p-4">
                      No Additional Info Available!
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
