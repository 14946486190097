import { PATH_PRODUCTS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const DeleteProductUsecase: (id?: number) => Promise<boolean> = async (
  id
) => {
  try {
    if (!id) throw new Error("Id cannot be empty");
    await client.delete(PATH_PRODUCTS + "/" + id);
    return true;
  } catch (e) {
    throw new Error(e.response.data);
  }
};
