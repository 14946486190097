/* eslint-disable react-hooks/exhaustive-deps */
import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { AutoResponderModel } from "@Pages/get-response/data/autoresponder.model";
import { CampaignModel } from "@Pages/get-response/data/campaign.model";
import { ProductGetResponseModel } from "@Pages/get-response/data/product.model";
import {
  AddProductRelation,
  DeleteProductRelation,
} from "@Pages/get-response/domain/Product.usecase";
import { ProductModel } from "@Pages/product/data/product.model";
import { GetAllProductUsecase } from "@Pages/product/domain/GetAllProducts.usecase";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaExclamationTriangle, FaRegTrashAlt } from "react-icons/fa";
import { GetResponseSection, SectionProps } from "../GetResponse.page";

export type ProductSectionProps = SectionProps & {
  data: ProductGetResponseModel[];
  contactList: CampaignModel[];
  autoresponderList: AutoResponderModel[];
};

export type ProductGetResponseInputs = {
  productId: string;
  contactListId: string;
  autoresponderId: string;
};

export const ProductSection: FC<ProductSectionProps> = ({
  data,
  contactList,
  autoresponderList,
  page,
  setPage,
  needRefetch,
}) => {
  const toasterContext = UseToasterContext();
  const modalContext = UseModalContext();

  const [productList, setProductList] = useState<ProductModel[]>([]);
  const [inAddMode, setInAddMode] = useState(false);

  const {
    register,
    trigger,
    watch,
    formState: { errors },
  } = useForm<ProductGetResponseInputs>();

  useEffect(() => {
    async function fetchData() {
      let response = await GetAllProductUsecase("?limit=1000");
      setProductList(response.list);
    }
    fetchData();
  }, []);

  const onAdd = async () => {
    try {
      let valid = await trigger();
      if (!valid) return;

      let productId = watch("productId");

      let contactListId = watch("contactListId");
      let contactData =
        contactListId && contactListId !== ""
          ? contactList.filter((item) => item.id === contactListId)
          : [];
      let contactListName = contactData.length > 0 ? contactData[0].name : "";

      let autoresponderId = watch("autoresponderId");
      let autoresponderData =
        autoresponderId && autoresponderId !== ""
          ? autoresponderList.filter((item) => item.day === autoresponderId)
          : [];
      let autoresponderName =
        autoresponderData.length > 0 ? autoresponderData[0].name : "";

      await AddProductRelation(
        productId,
        contactListId,
        contactListName,
        autoresponderId,
        autoresponderName
      );

      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("Successfully saved!", "", ToasterType.SUCCESS),
      ]);
      setInAddMode(false);
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("Failed to save!", "", ToasterType.DANGER),
      ]);
    } finally {
      needRefetch([GetResponseSection.PRODUCTS]);
    }
  };

  const onDelete = async (index: number) => {
    try {
      await DeleteProductRelation(data[index].id);

      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Success delete configuration",
          "",
          ToasterType.SUCCESS
        ),
      ]);
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to delete configuration",
          "",
          ToasterType.DANGER
        ),
      ]);
    } finally {
      modalContext.setModal(null);
      needRefetch([GetResponseSection.PRODUCTS]);
    }
  };

  const onShowDeleteModal = (index: number) => {
    let icon = (
      <div className="text-red-500">
        <FaExclamationTriangle />
      </div>
    );
    let title = "Hapus Produk";
    let description = <>Apakah anda yakin ingin menghapus konfigurasi?</>;
    let primaryCta = (
      <button
        className="button-red-outline-with-hover"
        onClick={() => onDelete(index)}
      >
        Hapus
      </button>
    );
    let secondaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(icon, title, description, primaryCta, secondaryCta)
    );
  };

  return (
    <div className=" mt-4">
      <div>
        <div className="flex justify-end">
          {inAddMode && (
            <button
              className="button-red-outline-with-hover"
              onClick={() => {
                setInAddMode(false);
              }}
            >
              Close
            </button>
          )}
          {!inAddMode && (
            <YellowHoverButton
              label={inAddMode ? "Cancel" : "Add"}
              onClick={() => {
                setInAddMode(true);
              }}
            />
          )}
        </div>
        {inAddMode && (
          <div className="mt-4 border border-black p-4 rounded-lg shadow-lg grid grid-cols-2 gap-4">
            <label className="font-medium my-auto">Product</label>
            <div className="w-full my-auto">
              <select
                className={`input-field-full-width border border-black ${
                  errors.productId && "border-red-500"
                }`}
                {...register("productId", {
                  required: "Product is required",
                })}
              >
                <option value="">Pilih Produk</option>
                {productList.map((item, index) => {
                  return (
                    <option
                      key={`product-item-${item.id}-${index}`}
                      value={item.id}
                    >
                      {item.title}
                    </option>
                  );
                })}
              </select>
              {errors.productId && (
                <p className="text-red-500 text-xs">
                  {errors.productId?.message}
                </p>
              )}
            </div>

            <label className="font-medium my-auto">Contact List</label>
            <div className="w-full my-auto">
              <select
                className={`input-field-full-width border border-black ${
                  errors.contactListId && "border-red-500"
                }`}
                {...register("contactListId", {
                  required: "Contact list is required",
                })}
              >
                <option value="">Pilih Contact List</option>
                {contactList.map((item, index) => {
                  return (
                    <option
                      key={`contact-list-item-${item.id}-${index}`}
                      value={item.id}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {errors.contactListId && (
                <p className="text-red-500 text-xs">
                  {errors.contactListId?.message}
                </p>
              )}
            </div>

            <label className="font-medium my-auto">
              AutoResponder (optional)
            </label>
            <div className="w-full my-auto">
              <select
                className={`input-field-full-width border border-black ${
                  errors.autoresponderId && "border-red-500"
                }`}
                {...register("autoresponderId")}
              >
                <option value="">Pilih Auto Responder</option>
                {watch("contactListId") &&
                  watch("contactListId") !== "" &&
                  autoresponderList
                    .filter(
                      (item) => item.campaignId === watch("contactListId")
                    )
                    .map((item, index) => {
                      return (
                        <option
                          key={`autoresponder-list-item-${item.id}-${index}`}
                          value={item.day}
                        >
                          Day {item.day} : {item.name}
                        </option>
                      );
                    })}
              </select>
              {errors.autoresponderId && (
                <p className="text-red-500 text-xs">
                  {errors.autoresponderId?.message}
                </p>
              )}
            </div>

            <YellowHoverButton
              customClass="mt-4 col-span-2"
              label="Add"
              onClick={onAdd}
            />
          </div>
        )}
      </div>
      <table className={`border-collapse border w-full table-fixed mt-4`}>
        <thead className="p-2 shadow-sm bg-gray-100 sticky">
          <tr>
            <th className="border border-spacing-1 text-center font-bold px-2">
              ID
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 text-ellipsis">
              Title
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 text-ellipsis hidden md:table-cell">
              Contact list
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 text-ellipsis hidden md:table-cell">
              Autoresponder
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={`product-row-${item.id}-${index}`}>
              <td className="border px-2 text-center">{item.id}</td>
              <td className="border px-2 text-left">
                {item.product.title}
                <div>
                  {/* <button onClick={() => showDetail(index)}>
                    <FaEye size={20} className="text-blue-400" />
                  </button> */}

                  <button
                    className="text-red-400 mr-2 w-6 h-6"
                    title="Delete Permanently"
                    onClick={() => onShowDeleteModal(index)}
                  >
                    <FaRegTrashAlt />
                  </button>
                </div>
              </td>
              <td className="border px-2 hidden md:table-cell text-center">
                {item.contactName}
              </td>
              <td className="border px-2 hidden md:table-cell">
                {item.autoresponderName}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
