import { FC } from "react";
import { ReactNode } from "react";

export type ModalProps = {
  title?: string;
  description: ReactNode;
  icon?: ReactNode;
  primaryCta: ReactNode;
  secondaryCta: ReactNode;
};

export const ModalComponent: FC<ModalProps> = ({
  title,
  description,
  icon,
  primaryCta,
  secondaryCta,
}) => {
  return (
    <div className="modal-container">
      <div className="modal-main py-4">
        {icon && <div className="mt-2 w-9 sm:w-12 mr-4">{icon}</div>}
        <div>
          {title ? <div className="modal-title">{title}</div> : <></>}
          <div className="modal-description">{description}</div>
          {(primaryCta || secondaryCta) && (
            <div className="modal-action-container">
              {primaryCta}
              <div className="w-2" />
              {secondaryCta}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
