import { UserSession } from "../utils/UserSession";
import { useNavigate } from "react-router-dom";

import { default as MidasLogo } from "../assets/midas.png";

import { FaRegUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";

export const Header = () => {
  const navigate = useNavigate();

  const onLogout = () => {
    UserSession.logout();
    navigate("/login");
  };

  return (
    <nav className="flex bg-black p-2 justify-between w-full">
      <div className="lg:ml-20 xl:ml-28">
        <img src={MidasLogo} alt="" />
      </div>
      <div className="flex justify-end lg:mr-20 xl:mr-28 text-white my-auto font-sans gap-2">
        <div className="flex" title="Profile">
          <div className="my-auto">
            <FaRegUserCircle size={24} />
          </div>
          <div className="hidden ml-2 sm:block">{UserSession.getName()}</div>
        </div>
        <div className="my-auto" title="Logout" onClick={onLogout}>
          <MdLogout size={24} />
        </div>
      </div>
    </nav>
  );
};
