import {
  PATH_COURSES,
  PATH_COURSES_ITEM,
  PATH_COURSES_SECTION,
} from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const UpdateCourseUsecase: (
  id: string,
  status: string,
  title?: string,
  description?: string,
  slug?: string,
  coverImagePath?: string,
  level?: string
) => Promise<boolean> = async (
  id,
  status,
  title,
  description,
  slug,
  coverImagePath,
  level
) => {
  try {
    let formData = new FormData();
    formData.append("status", status);
    if (title) formData.append("title", title);
    if (description) formData.append("description", description);
    if (slug) formData.append("slug", slug);
    if (coverImagePath) formData.append("cover_image_path", coverImagePath);
    if (level) formData.append("level", level);

    await client.put(PATH_COURSES + "/" + id, formData);
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const UpdateCourseSectionUsecase: (
  id: string,
  order: number,
  title: string
) => Promise<boolean> = async (id, order, title) => {
  try {
    let formData = new FormData();
    formData.append("orders", order.toString());
    formData.append("title", title);

    await client.put(
      PATH_COURSES + "/" + PATH_COURSES_SECTION + "/" + id,
      formData
    );
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const UpdateCourseItemUsecase: (
  id: string,
  order: number,
  title: string,
  description: string,
  coverImagePath: string,
  videoUrl: string,
  durationInSecond: string
) => Promise<boolean> = async (
  id,
  order,
  title,
  description,
  coverImagePath,
  videoUrl,
  durationInSecond
) => {
  try {
    let formData = new FormData();
    formData.append("orders", order.toString());
    formData.append("title", title);
    formData.append("description", description);
    formData.append("cover_image_path", coverImagePath);
    formData.append("video_url", videoUrl);
    formData.append("duration_in_seconds", durationInSecond);

    await client.put(
      PATH_COURSES + "/" + PATH_COURSES_ITEM + "/" + id,
      formData
    );
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
