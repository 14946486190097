import { PATH_ROLES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const AddRoleUsecase: (
  title: string,
  role: string,
  access: string[]
) => Promise<string> = async (title, role, access) => {
  try {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("role", role);
    formData.append("access", `${access.join(";")};`);

    let response = await client.post(PATH_ROLES, formData);
    return response.data.message;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
