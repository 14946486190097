import { PATH_PAGES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { PageModel } from "../data/page.model";

export const GetPageUsecase: (id: string) => Promise<PageModel> = async (
  id
) => {
  try {
    let response = await client.get(PATH_PAGES + "/" + id);
    let data : PageModel = response.data.data;

    data.is_show_header = response.data.data.is_show_header !== "0";
    data.is_show_footer = response.data.data.is_show_footer !== "0";

    return data;
  } catch (e) {
    throw new Error(e.response.message);
  }
};
