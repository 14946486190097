import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { SaveSettingsUsecase } from "@Pages/setting/domain/SaveSettings.usecase";
import { GENERAL_TESTIMONI } from "@Pages/setting/utils/const";
import { FC } from "react";
import {
  TestimoniFormComponent,
  TestimoniInputModel,
} from "../components/TestimoniForm.component";
import { SettingsSectionsProps } from "../SettingStaticPages.page";

export const TestimoniSection: FC<SettingsSectionsProps> = ({
  settingsData,
  setNeedRefetch,
}) => {
  const toasterContext = UseToasterContext();

  const getTestimoni = () => {
    let testimoni = settingsData.find((item) => item.key === GENERAL_TESTIMONI);
    if (testimoni) {
      let parsed = JSON.parse(testimoni.value);
      return parsed;
    }
    return [];
  };

  const onSaveTestimoni = (testimoniList: TestimoniInputModel[]) => {
    let testimoniStringify = JSON.stringify(testimoniList);
    let testimoniString = settingsData.find(
      (item) => item.key === GENERAL_TESTIMONI
    );

    SaveSettingsUsecase(
      testimoniString !== undefined,
      GENERAL_TESTIMONI,
      testimoniStringify
    )
      .then((message) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Testimoni updated successfully!",
            message,
            ToasterType.SUCCESS
          ),
        ]);
        setNeedRefetch(true);
      })
      .catch((errors) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to update settings!",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      });
  };

  return (
    <div className="mt-4">
      <TestimoniFormComponent
        testimoniList={getTestimoni()}
        onSaveTestimoni={onSaveTestimoni}
      />
    </div>
  );
};
