/* eslint-disable react-hooks/exhaustive-deps */
import { ContentHeader } from "@Components/ContentHeader.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE } from "@Helpers/regex";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { AddPageUsecase } from "@Pages/page/domain/AddPage.usecase";
import { GetPageUsecase } from "@Pages/page/domain/GetPage.usecase";
import { UpdatePageUsecase } from "@Pages/page/domain/UpdatePage.usecase";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export type AddLandingPageFormInputs = {
  title: string;
  slug: string;
  url: string;
};

export const AddLandingPagePage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const toasterContext = UseToasterContext();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AddLandingPageFormInputs>();

  useEffect(() => {
    if (params.id) {
      async function fetchData() {
        try {
          let response = await GetPageUsecase(params.id ?? "");

          if (!response.type.includes("Landing Page")) {
            toasterContext.setToastList([
              ...toasterContext.toastList,
              new ToasterModel(
                "Failed to fetch page",
                "Target page is not Landing Page",
                ToasterType.DANGER
              ),
            ]);
            popWithDelay();
            return;
          }

          setValue("title", response.title);
          setValue("slug", response.slug);

          let contentJson: any[] = JSON.parse(response.content_json);
          contentJson = contentJson.filter((item) => item.type === "iframe");

          if (contentJson.length > 0) {
            setValue("url", contentJson[0].url);
          }
        } catch (e) {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Failed to fetch page",
              e.message,
              ToasterType.DANGER
            ),
          ]);
          popWithDelay();
        }
      }

      fetchData();
    }
  }, [params]);

  const onSubmit = async (data: AddLandingPageFormInputs) => {
    try {
      if (params.id) {
        updateLandingPage(params.id, data);
      } else {
        addLandingPage(data);
      }
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to add Landing Page",
          e.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const popWithDelay = () => {
    setTimeout(function () {
      navigate(-1);
    }, 1000);
  };

  const addLandingPage = async (data: AddLandingPageFormInputs) => {
    let formData = new FormData();

    formData.append("title", data.title);
    formData.append("slug", data.slug);
    formData.append("type", "Landing Page");
    formData.append("content_html", '""');
    formData.append("is_show_header", false.toString());
    formData.append("is_show_footer", false.toString());
    formData.append("visibility", "all");
    formData.append(
      "content_json",
      JSON.stringify([
        {
          type: "iframe",
          url: data.url,
          pageId: "custom-landing-page",
        },
      ])
    );
    formData.append("status", "published");

    await AddPageUsecase(formData);
    toasterContext.setToastList([
      ...toasterContext.toastList,
      new ToasterModel("Landing Page published", "", ToasterType.SUCCESS),
    ]);

    popWithDelay();
  };

  const updateLandingPage = async (
    id: string,
    data: AddLandingPageFormInputs
  ) => {
    let formData = new FormData();
    formData.append("title", data.title);
    formData.append("slug", data.slug);
    formData.append(
      "content_json",
      JSON.stringify([
        {
          type: "iframe",
          url: data.url,
          pageId: "custom-landing-page",
        },
      ])
    );

    await UpdatePageUsecase(id, formData);
    toasterContext.setToastList([
      ...toasterContext.toastList,
      new ToasterModel("Landing Page updated", "", ToasterType.SUCCESS),
    ]);

    popWithDelay();
  };

  return (
    <div className="bg-white m-4">
      <ContentHeader
        title="Add New Landing Page"
        rightButton={
          <button
            className="button-red-outline-with-hover"
            type="button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
        }
      />

      <form className="w-full mt-6" onSubmit={handleSubmit(onSubmit)}>
        {/* Title */}
        <div className="font-medium">
          Title<span className="text-red-500">*</span>
        </div>
        <div className="w-full">
          <input
            className={`input-field-full-width ${
              errors.title && "border-red-500"
            }`}
            type="text"
            {...register("title", {
              required: "Title is required",
              onChange: (e) => {
                let slug = e.target.value.replaceAll(" ", "-").toLowerCase();
                setValue("slug", slug);
              },
            })}
          />
          {errors.title && (
            <p className="text-red-500 text-xs">{errors.title?.message}</p>
          )}
        </div>

        {/* Slug */}
        <div className="font-medium mt-4">
          Slug<span className="text-red-500">*</span>
        </div>
        <div className="w-full">
          <input
            className={`input-field-full-width ${
              errors.slug && "border-red-500"
            }`}
            type="text"
            {...register("slug", {
              required: "Slug is required",
              pattern: {
                value: REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE,
                message:
                  "Invalid slug format, only alphanumeric and dash allowed",
              },
            })}
          />
          {errors.slug && (
            <p className="text-red-500 text-xs">{errors.slug?.message}</p>
          )}
        </div>

        {/* Url */}
        <div className="font-medium mt-4">
          URL<span className="text-red-500">*</span>
        </div>
        <div className="w-full">
          <input
            className={`input-field-full-width ${
              errors.url && "border-red-500"
            }`}
            type="url"
            {...register("url", {
              required: "Url is required",
            })}
          />
          {errors.url && (
            <p className="text-red-500 text-xs">{errors.url?.message}</p>
          )}
        </div>

        <button
          className="font-medium mt-4 button-yellow-outline-with-hover w-full"
          type="submit"
        >
          Publish
        </button>
      </form>
    </div>
  );
};
