import { PATH_PAGES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const UpdatePageUsecase: (
  id: string,
  formData: FormData
) => Promise<boolean> = async (id, formData) => {
  try {
    await client.put(PATH_PAGES + "/" + id, formData);
    return true;
  } catch (e) {
    throw new Error(e.response.message);
  }
};
