import { PropsWithChildren, useState } from "react";
import { FC } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export type AccordionProps = PropsWithChildren & {
  title: string;
  isOpen: boolean;
  onToggle?: (openStatus: boolean) => void;
};

export const AccordionComponent: FC<AccordionProps> = ({
  title,
  isOpen,
  onToggle,
  children,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen);

  const toggleAccordion = () => {
    if (onToggle) {
      onToggle(!isAccordionOpen);
    }
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <>
      <div
        className="shadow py-1 px-4 flex justify-between"
        onClick={toggleAccordion}
      >
        <div className="font-bold">{title}</div>
        <div className="w-4 my-auto">
          {isAccordionOpen ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>

      {isAccordionOpen && children}
    </>
  );
};
