import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { SaveSettingsUsecase } from "@Pages/setting/domain/SaveSettings.usecase";
import {
  MEMBERSHIP_CAROUSEL,
  MEMBERSHIP_FAQ,
  MEMBERSHIP_FINDING_STOCKS,
  MEMBERSHIP_HEADER_VIDEO,
  MEMBERSHIP_INTRODUCTION_VIDEO,
  MEMBERSHIP_SHOWCASE,
} from "@Pages/setting/utils/const";
import { FC } from "react";
import {
  CarouselFormComponent,
  CarouselInputModel,
} from "../components/CarouselForm.component";
import {
  FaqFormComponent,
  FaqInputModel,
} from "../components/FaqForm.component";
import {
  MembershipTopHeaderFormComponent,
  MembershipTopHeaderInputModel,
} from "../components/MembershipTopHeaderForm.component";
import {
  StockFindingFormComponent,
  StockFindingInputModel,
} from "../components/StockFindingForm.component";
import {
  VideoIntroductionFormComponent,
  VideoIntroductionInputModel,
} from "../components/VideoIntroductionForm.component";
import { SettingsSectionsProps } from "../SettingStaticPages.page";
import { ShowcaseFormComponent } from "../components/ShowcaseForm.component";

export type ShowcaseInputModel = {
  imageUrl: string;
  altText: string;
};

export const MembershipSection: FC<SettingsSectionsProps> = ({
  settingsData,
  setNeedRefetch,
}) => {
  const toasterContext = UseToasterContext();

  const getStockFindingList = () => {
    let list = settingsData.find(
      (item) => item.key === MEMBERSHIP_FINDING_STOCKS
    );
    if (list) {
      let parsed = JSON.parse(list.value);
      return parsed;
    }
    return [];
  };

  const getTopHeaderDetail: () => VideoIntroductionInputModel = () => {
    let list = settingsData.find(
      (item) => item.key === MEMBERSHIP_HEADER_VIDEO
    );
    if (list) {
      let parsed = JSON.parse(list.value);
      return parsed;
    }
    return { videoUrl: "" };
  };

  const getVideoIntroductionDetail: () => VideoIntroductionInputModel = () => {
    let list = settingsData.find(
      (item) => item.key === MEMBERSHIP_INTRODUCTION_VIDEO
    );
    if (list) {
      let parsed = JSON.parse(list.value);
      return parsed;
    }
    return { videoUrl: "" };
  };

  const getFAQList: () => FaqInputModel[] = () => {
    let list = settingsData.find((item) => item.key === MEMBERSHIP_FAQ);
    if (list) {
      let parsed = JSON.parse(list.value);
      return parsed;
    }
    return [];
  };

  const getCarouselList: () => CarouselInputModel[] = () => {
    let list = settingsData.find((item) => item.key === MEMBERSHIP_CAROUSEL);
    if (list) {
      let parsed = JSON.parse(list.value);
      return parsed;
    }
    return [];
  };

  const saveSetting = (
    type: string,
    isUpdating: boolean,
    key: string,
    value: string
  ) => {
    SaveSettingsUsecase(isUpdating, key, value)
      .then((message) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            `${type} updated successfully!`,
            message,
            ToasterType.SUCCESS
          ),
        ]);
        setNeedRefetch(true);
      })
      .catch((errors) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to update settings!",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      });
  };

  const onSaveStockFinding = (findingList: StockFindingInputModel[]) => {
    let listStringify = JSON.stringify(findingList);
    let currentList = settingsData.find(
      (item) => item.key === MEMBERSHIP_FINDING_STOCKS
    );

    saveSetting(
      "Stock Finding",
      currentList !== undefined,
      MEMBERSHIP_FINDING_STOCKS,
      listStringify
    );
  };

  const onSaveTopHeader = (data: MembershipTopHeaderInputModel) => {
    let listStringify = JSON.stringify(data);
    let currentList = settingsData.find(
      (item) => item.key === MEMBERSHIP_HEADER_VIDEO
    );

    saveSetting(
      "Membership Header",
      currentList !== undefined,
      MEMBERSHIP_HEADER_VIDEO,
      listStringify
    );
  };

  const onSaveIntroduction = (data: VideoIntroductionInputModel) => {
    let listStringify = JSON.stringify(data);
    let currentList = settingsData.find(
      (item) => item.key === MEMBERSHIP_INTRODUCTION_VIDEO
    );

    saveSetting(
      "Video Introduction",
      currentList !== undefined,
      MEMBERSHIP_INTRODUCTION_VIDEO,
      listStringify
    );
  };

  const onSaveFAQ = (data: FaqInputModel[]) => {
    let listStringify = JSON.stringify(data);
    let currentList = settingsData.find((item) => item.key === MEMBERSHIP_FAQ);

    saveSetting(
      "FAQ",
      currentList !== undefined,
      MEMBERSHIP_FAQ,
      listStringify
    );
  };

  const onSaveCarousel = (data: CarouselInputModel[]) => {
    let listStringify = JSON.stringify(data);
    let currentList = settingsData.find(
      (item) => item.key === MEMBERSHIP_CAROUSEL
    );

    saveSetting(
      "Carousel",
      currentList !== undefined,
      MEMBERSHIP_CAROUSEL,
      listStringify
    );
  };

  const getShowcaseList = () => {
    let showcasesList = settingsData.find(
      (item) => item.key === MEMBERSHIP_SHOWCASE
    );
    if (showcasesList) {
      let parsed = JSON.parse(showcasesList.value);
      return parsed;
    }
    return [];
  };

  const onSaveShowcaseList = (showcaseList: ShowcaseInputModel[]) => {
    let showcasesStringify = JSON.stringify(showcaseList);
    let showcasesString = settingsData.find(
      (item) => item.key === MEMBERSHIP_SHOWCASE
    );

    saveSetting(
      "Showcase",
      showcasesString !== undefined,
      MEMBERSHIP_SHOWCASE,
      showcasesStringify
    );
  };

  return (
    <div className="mt-4">
      <MembershipTopHeaderFormComponent
        data={getTopHeaderDetail()}
        onSave={onSaveTopHeader}
      />
      <div className="w-full border-t mt-4"></div>
      <VideoIntroductionFormComponent
        data={getVideoIntroductionDetail()}
        onSave={onSaveIntroduction}
      />
      <div className="w-full border-t mt-4"></div>
      <ShowcaseFormComponent
        showcaseList={getShowcaseList()}
        onSaveShowcase={onSaveShowcaseList}
        title="Showcase Weekly Meeting"
      />
      <div className="w-full border-t mt-4"></div>
      <StockFindingFormComponent
        stockFindingList={getStockFindingList()}
        onSaveStockFinding={onSaveStockFinding}
      />
      <div className="w-full border-t mt-4"></div>
      <CarouselFormComponent
        title="Last Transactions"
        carouselList={getCarouselList()}
        onSaveCarousel={onSaveCarousel}
        isNeedProduct={false}
        products={[]}
      />
      <div className="w-full border-t mt-4"></div>
      <FaqFormComponent faqList={getFAQList()} onSaveFaq={onSaveFAQ} />
    </div>
  );
};
