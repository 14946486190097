import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { SaveSettingsUsecase } from "@Pages/setting/domain/SaveSettings.usecase";
import { STOCK_KEY_TEASER } from "@Pages/setting/utils/const";
import { FC } from "react";
import { CarouselInputModel } from "../components/CarouselForm.component";
import { TeaserFormComponent } from "../components/TeaserForm.component";
import { SettingSectionWithProductProps } from "../SettingStaticPages.page";

export const StockKeyInsightSection: FC<SettingSectionWithProductProps> = ({
  settingsData,
  setNeedRefetch,
  productList,
}) => {
  const toasterContext = UseToasterContext();

  const getTeaserList = () => {
    let teaserList = settingsData.find((item) => item.key === STOCK_KEY_TEASER);
    if (teaserList) {
      let parsed = JSON.parse(teaserList.value);
      return parsed;
    }
    return [];
  };

  const saveSetting = (
    type: string,
    isUpdating: boolean,
    key: string,
    value: string
  ) => {
    SaveSettingsUsecase(isUpdating, key, value)
      .then((message) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            `${type} updated successfully!`,
            message,
            ToasterType.SUCCESS
          ),
        ]);
        setNeedRefetch(true);
      })
      .catch((errors) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to update settings!",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      });
  };

  const onSaveTeaserList = (data: CarouselInputModel[]) => {
    let listStringify = JSON.stringify(data);
    let currentList = settingsData.find(
      (item) => item.key === STOCK_KEY_TEASER
    );

    saveSetting(
      "Teaser",
      currentList !== undefined,
      STOCK_KEY_TEASER,
      listStringify
    );
  };

  return (
    <div className="mt-4">
      <TeaserFormComponent
        title="Teaser"
        carouselList={getTeaserList()}
        onSaveCarousel={onSaveTeaserList}
        products={productList}
      />
    </div>
  );
};
