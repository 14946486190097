import { PATH_RECORDS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { RecordModel } from "../data/record.model";

export const GetRecordUsecase: (id: string) => Promise<RecordModel> = async (id) => {
  try {
    let response = await client.get(PATH_RECORDS + "/" + id);
    return response.data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
