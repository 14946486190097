import { convertDateFromApiToView, formatToCurrency } from "@Helpers/formatter";
import { AffiliateWithdrawModel } from "@Pages/affiliate/data/AffiliateWithdraw.model";
import { FC } from "react";
import { FaEye } from "react-icons/fa";
import { AffiliateWithdrawDetailComponent } from "./AffiliateWithdrawDetail.component";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { createModal } from "@Helpers/view";

export type AffiliateWithdrawProps = {
  withdrawRequests: AffiliateWithdrawModel[] | undefined;
  statusFilter: string;
  onFilterStatus: (status: string) => void;
  onStatusChanged: (index: number, newStatus: string) => void;
};

export const AffiliateWithdrawComponent: FC<AffiliateWithdrawProps> = ({
  withdrawRequests,
  statusFilter,
  onFilterStatus,
  onStatusChanged,
}) => {
  const modalContext = UseModalContext();

  const showWithdrawDetails = (
    withdrawRequest: AffiliateWithdrawModel,
    index: number
  ) => {
    modalContext.setModal(
      createModal(
        null,
        `Withdraw Id #${withdrawRequest.id}`,
        <AffiliateWithdrawDetailComponent
          withdrawRequest={withdrawRequest}
          onStatusChanged={(newStatus: string) => {
            onStatusChanged(index, newStatus);
          }}
        />,
        null,
        null
      )
    );
  };

  return (
    <div>
      {/* top config */}
      <div className="border p-3 align-right flex flex-col justify-between shadow-sm md:flex-row">
        <div>
          {/* status filter */}
          <select
            className="mr-1 border rounded pl-1 pr-1"
            value={statusFilter}
            onChange={(event) => {
              onFilterStatus(event.target.value);
            }}
          >
            <option value="">All</option>
            <option value="requested">Waiting for Confirmation</option>
            <option value="approved">Approved</option>
            <option value="completed">Complete</option>
            <option value="failed">Failed</option>
          </select>
        </div>
      </div>

      {/* content */}
      <div className="mt-4">
        {/* Empty data */}
        {(!withdrawRequests || withdrawRequests?.length === 0) && (
          <div className="text-center bg-gray-300 p-4">
            No Withdraw Request Available!
          </div>
        )}

        {withdrawRequests && withdrawRequests.length > 0 && (
          <table className="table-auto border w-full">
            <thead className="p-2 shadow-sn bg-gray-100 sticky">
              <tr>
                <th className="border-l border-t border-b border-black"></th>
                <th className="border-t border-b border-black">Name</th>
                <th className="border-t border-b border-black">Amount</th>
                <th className="border-t border-b  border-black hidden md:table-cell">
                  Last Update
                </th>
                <th className="border-t border-b border-r border-black hidden md:table-cell">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {withdrawRequests.map((item, index) => (
                <tr key={`request-${index}-${item.id}`}>
                  <td className="border-l text-center border-t border-b border-black p-2">
                    {index + 1}
                  </td>
                  <td className="p-2 border-t border-b border-black">
                    <div className="font-bold text-ellipsis">
                      Withdraw #{item.id}
                    </div>
                    <div className="font-bold text-ellipsis">
                      {item.affiliateName ?? ""}
                    </div>
                    <div className="flex mt-3">
                      <button
                        className="text-blue-400 mr-2 w-6 h-6"
                        title="View Details"
                        onClick={() => {
                          showWithdrawDetails(item, index);
                        }}
                      >
                        <FaEye />
                      </button>
                    </div>
                  </td>
                  <td className="text-center text-ellipsis p-2 border-t border-b border-black">
                    {formatToCurrency(item.amount)}
                  </td>
                  <td className="text-center hidden md:table-cell p-2 border-t border-b border-black">
                    {convertDateFromApiToView(item.updatedAt ?? "")}
                  </td>
                  <td className="border-r text-center hidden md:table-cell p-2 border-t border-b border-black">
                    {item.status === "requested" ? (
                      <div className="text-yellow-500">
                        Waiting for Confirmation
                      </div>
                    ) : item.status === "approved" ? (
                      <div className="text-green-300">Approved</div>
                    ) : item.status === "completed" ? (
                      <div className="text-green-500">Complete</div>
                    ) : item.status === "rejected" ? (
                      <div className="text-red-500">Rejected</div>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
