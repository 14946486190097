import {
  PATH_COURSES,
  PATH_COURSES_ITEM,
  PATH_COURSES_SECTION,
} from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const DeleteCourseUsecase: (id: number) => Promise<boolean> = async (
  id
) => {
  try {
    await client.delete(PATH_COURSES + "/" + id);
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const DeleteCourseSectionUsecase: (
  id: number
) => Promise<boolean> = async (id) => {
  try {
    await client.delete(PATH_COURSES + "/" + PATH_COURSES_SECTION + "/" + id);
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const DeleteCourseItemUsecase: (id: number) => Promise<boolean> = async (
  id
) => {
  try {
    await client.delete(PATH_COURSES + "/" + PATH_COURSES_ITEM + "/" + id);
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
