import { PATH_JARVIS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { JarvisDetailModel, JarvisListModel } from "../data/jarvis.model";

export const GetJarvisFolderListUseCase: () => Promise<
  JarvisDetailModel[]
> = async () => {
  try {
    let response = await client.get(PATH_JARVIS + "/getFolderList");
    let data = response.data.data as JarvisListModel;

    let formattedData = [];

    for (const key in data) {
      formattedData.push({
        ...data[key],
        status: false,
      });
    }

    return formattedData;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
