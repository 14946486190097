import { FC } from "react";

export type PageControllerComponentProps = {
  currentPage: number;
  showPrev: boolean;
  onPrev: (page: number) => void;
  showNext: boolean;
  onNext: (page: number) => void;
};

export const PageControllerComponent: FC<PageControllerComponentProps> = ({
  currentPage,
  showPrev,
  onPrev,
  showNext,
  onNext,
}) => {
  return (
    <div className="flex justify-between md:justify-end gap-4 w-full border-t pt-4">
      {showPrev && (
        <button
          className="button-yellow-outline-with-hover my-auto"
          onClick={() => onPrev(currentPage - 1)}
        >
          &lt;
        </button>
      )}
      <div className="my-auto">Halaman {currentPage}</div>
      {showNext && (
        <button
          className="button-yellow-outline-with-hover my-auto"
          onClick={() => onNext(currentPage + 1)}
        >
          &gt;
        </button>
      )}
    </div>
  );
};
