import { FC } from "react";
import { ReactNode } from "react";

export type ContentHeaderProps = {
  title: string;
  rightButton?: ReactNode;
};

export const ContentHeader: FC<ContentHeaderProps> = ({
  title,
  rightButton,
}) => {
  return (
    <div className="flex justify-between">
      <div className="font-bold text-xl md:text-3xl">{title}</div>
      {rightButton}
    </div>
  );
};
