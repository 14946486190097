/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FaArrowCircleDown,
  FaArrowCircleUp,
  FaExclamationTriangle,
  FaRegCalendarAlt,
  FaTrashAlt,
  FaUserAlt,
} from "react-icons/fa";

import { AccordionComponent } from "@Components/accordion/Accordion.component";
import { ContentHeader } from "@Components/ContentHeader.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { convertDateFromApiToView } from "@Helpers/formatter";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { MediaChooser } from "@Pages/common/presentation/MediaChooser.page";
import { ResourceModel } from "@Pages/resources/data/resource.model";
import { CourseModel } from "../data/course.model";
import { GetCourse } from "../domain/get_course.usecase";
import {
  DeleteCourseItemUsecase,
  DeleteCourseSectionUsecase,
  DeleteCourseUsecase,
} from "../domain/DeleteCourse.usecase";
import {
  UpdateCourseItemUsecase,
  UpdateCourseSectionUsecase,
  UpdateCourseUsecase,
} from "../domain/UpdateCourse.usecase";
import {
  AddCourseItemUsecase,
  AddCourseSectionUsecase,
} from "../domain/AddCourse.usecase";

export function DetailCoursePage() {
  const params = useParams();
  const navigate = useNavigate();

  const modalContext = UseModalContext();
  const toasterContext = UseToasterContext();

  const [liveData, setLiveData] = useState<CourseModel>();
  const [detail, setDetail] = useState<CourseModel>();
  const [needRefresh, setNeedRefresh] = useState(true);

  useEffect(() => {
    if (needRefresh) {
      async function fetchData() {
        try {
          let response = await GetCourse(params.slug ?? "");

          setLiveData(response);
          setDetail(response);
          setNeedRefresh(false);
        } catch (e) {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Failed to fetch Course",
              e.message,
              ToasterType.DANGER
            ),
          ]);
        }
      }
      fetchData();
    }
  }, [needRefresh]);

  const showDeleteModal = () => {
    let icon = (
      <div className="text-red-500">
        <FaExclamationTriangle />
      </div>
    );
    let title = "Hapus Course";
    let description = (
      <>
        Apakah anda yakin ingin menghapus{" "}
        <span className="font-bold">{detail?.title}</span> ?
      </>
    );
    let primaryCta = (
      <button
        className="button-red-outline-with-hover"
        onClick={() => handleDelete()}
      >
        Hapus
      </button>
    );
    let secondaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(icon, title, description, primaryCta, secondaryCta)
    );
  };

  const handleDelete = async () => {
    try {
      await DeleteCourseUsecase(detail?.id ?? 0);
      navigate(-1);
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to delete course",
          errors.message,
          ToasterType.SUCCESS
        ),
      ]);
    }
  };

  const updateCourse = async (sectionIndex: number, sectionId: number) => {
    let result = await Promise.all(
      detail?.sections[sectionIndex].items.map(async (course, index) => {
        try {
          if (course.id === -1) {
            await AddCourseItemUsecase(
              sectionId,
              index + 1,
              course.title,
              course.description,
              course.cover_image_path,
              course.video_url,
              course.duration_in_seconds
            );

            toasterContext.setToastList([
              ...toasterContext.toastList,
              new ToasterModel(
                "Berhasil",
                "Course berhasil di perbarui!",
                ToasterType.SUCCESS
              ),
            ]);
          } else {
            await UpdateCourseItemUsecase(
              course.id.toString(),
              index + 1,
              course.title,
              course.description,
              course.cover_image_path,
              course.video_url,
              course.duration_in_seconds
            );

            toasterContext.setToastList([
              ...toasterContext.toastList,
              new ToasterModel(
                "Berhasil",
                "Course berhasil di perbarui!",
                ToasterType.SUCCESS
              ),
            ]);
          }

          return true;
        } catch (e) {
          return false;
        }
      }) ?? []
    );

    let shouldPop = true;

    result.forEach((item) => {
      if (item === false) {
        shouldPop = false;
      }
    });

    return shouldPop;
  };

  const updateSection = async () => {
    let result = await Promise.all(
      detail?.sections?.map(async (section, index) => {
        try {
          if (section.id === -1) {
            let response = await AddCourseSectionUsecase(
              detail?.id,
              index + 1,
              section.title
            );
            updateCourse(index, response.id);
            return true;
          } else {
            await UpdateCourseSectionUsecase(
              detail?.id.toString(),
              index + 1,
              section.title
            );

            let result = await updateCourse(index, section.id);
            if (result === false) return false;
            return true;
          }
        } catch (e) {
          return false;
        }
      }) ?? []
    );

    let shouldPop = true;

    result.forEach((item) => {
      if (item === false) {
        shouldPop = false;
      }
    });

    return shouldPop;
  };

  const updateData = async (status: string) => {
    try {
      await UpdateCourseUsecase(
        (detail?.id ?? "").toString(),
        status,
        detail?.title !== liveData?.title ? detail?.title : undefined,
        detail?.description !== liveData?.description
          ? detail?.description
          : undefined,
        detail?.slug !== liveData?.slug ? detail?.slug : undefined,
        detail?.cover_image_path !== liveData?.cover_image_path
          ? detail?.cover_image_path
          : undefined,
        detail?.level !== liveData?.level ? detail?.level : undefined
      );
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Gagal memperbarui course",
          e.message,
          ToasterType.SUCCESS
        ),
      ]);
    }

    let sectionUpdated = await updateSection();
    if (sectionUpdated) {
      popWithDelay();
    }
  };

  const popWithDelay = () => {
    setTimeout(function () {
      navigate(-1);
    }, 1000);
  };

  const onMediaSelected = (data: ResourceModel) => {
    if (detail) {
      setDetail({
        ...detail,
        cover_image_url: detail.cover_image_url.replace(
          detail.cover_image_path,
          data.path
        ),
        cover_image_path: data.path,
      });
    }
    modalContext.setModal(null);
  };

  const onSelectMediaClicked = () => {
    let title = "Select Media";
    let description = (
      <MediaChooser type={"image"} onMediaSelected={onMediaSelected} />
    );
    let cancelButton = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(null, title, description, cancelButton, null)
    );
  };

  const onSelectMediaForCourseClicked = (
    sectionIndex: number,
    courseIndex: number
  ) => {
    let title = "Select Media";
    let description = (
      <MediaChooser
        type={"image"}
        onMediaSelected={(data) => {
          if (detail) {
            let newDetail = { ...detail };
            newDetail.sections[sectionIndex].items[
              courseIndex
            ].cover_image_path = data.path;
            setDetail(newDetail);
          }
          modalContext.setModal(null);
        }}
      />
    );
    let cancelButton = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(null, title, description, cancelButton, null)
    );
  };

  const onAddNewSection = () => {
    if (detail) {
      let newDetail = { ...detail };
      newDetail.sections?.push({
        id: -1,
        title: "",
        items: [],
        total_not_watch_percent: 0,
        total_watch_complete_percent: 0,
        total_watch_less_than_half_percent: 0,
        total_watch_more_than_half_percent: 0,
        total_watch_percent: 0
      });
      setDetail(newDetail);
    }
  };

  const onMoveSectionUp = (index: number) => {
    if (detail && index > 0) {
      let mSectionList = [...detail?.sections];
      let temp = { ...mSectionList[index] };
      mSectionList[index] = { ...mSectionList[index - 1] };
      mSectionList[index - 1] = temp;

      setDetail({ ...detail, sections: mSectionList });
    }
  };

  const onMoveSectionDown = (index: number) => {
    if (detail && index < detail.sections.length - 1) {
      let mSectionList = [...detail.sections];
      let temp = { ...mSectionList[index] };
      mSectionList[index] = { ...mSectionList[index + 1] };
      mSectionList[index + 1] = temp;

      setDetail({ ...detail, sections: mSectionList });
    }
  };

  const onAddNewCourse = (sectionIndex: number) => {
    if (detail) {
      let newDetail = { ...detail };
      newDetail.sections[sectionIndex].items.push({
        id: -1,
        title: "",
        cover_image_path: "",
        cover_image_url: "",
        created_at: "",
        description: "",
        duration_in_seconds: "",
        video_url: "",
        total_not_watch: 0,
        total_watch: 0,
        total_watch_complete: 0,
        total_watch_less_than_half: 0,
        total_watch_more_than_half: 0,
        total_not_watch_percent: 0,
        total_watch_percent: 0,
        total_watch_complete_percent: 0,
        total_watch_less_than_half_percent: 0,
        total_watch_more_than_half_percent: 0
      });
      setDetail(newDetail);
    }
  };

  const onMoveCourseUp = (sectionIndex: number, courseIndex: number) => {
    if (detail) {
      let sections = [...detail.sections];

      if (courseIndex > 0) {
        let coursesList = [...sections[sectionIndex].items];
        let temp = { ...coursesList[courseIndex] };
        coursesList[courseIndex] = { ...coursesList[courseIndex - 1] };
        coursesList[courseIndex - 1] = temp;

        sections[sectionIndex].items = coursesList;

        setDetail({ ...detail, sections: sections });
      }
    }
  };

  const onMoveCourseDown = (sectionIndex: number, courseIndex: number) => {
    if (detail) {
      let sections = [...detail.sections];

      if (courseIndex < sections[sectionIndex].items.length - 1) {
        let coursesList = [...sections[sectionIndex].items];
        let temp = { ...coursesList[courseIndex] };
        coursesList[courseIndex] = { ...coursesList[courseIndex + 1] };
        coursesList[courseIndex + 1] = temp;

        sections[sectionIndex].items = coursesList;

        setDetail({ ...detail, sections: sections });
      }
    }
  };

  return (
    <div className="p-4 h-full bg-gray-300">
      {!detail && <MidasShimmering />}

      {detail && (
        <>
          <ContentHeader title={detail.title} />
          <div className="h-6" />

          <div className="w-full mt-4">
            <div className="my-auto">Cover Image</div>
            <div className="flex gap-4">
              {detail.cover_image_url !== "" ? (
                <img
                  src={detail.cover_image_url}
                  className="w-24 h-24 rounded"
                  alt=""
                />
              ) : (
                <div className="w-24 h-24 rounded bg-gray-500"></div>
              )}

              <div className="input-field-full-width flex p-0 h-fit my-auto">
                <input
                  type="text"
                  className="w-full outline-none p-1"
                  value={
                    detail.cover_image_path != null
                      ? detail.cover_image_path
                      : ""
                  }
                  disabled
                  readOnly
                />
                <button
                  className="rounded-r bg-gray-500 shadow text-white w-32"
                  onClick={onSelectMediaClicked}
                >
                  Select Image
                </button>
              </div>
            </div>
          </div>

          <div className="w-full mt-4">
            <div className="my-auto">Description</div>
            <input
              type="text"
              className="input-field-full-width"
              value={detail.description != null ? detail.description : ""}
              onChange={(e) =>
                setDetail({
                  ...detail,
                  description: e.target.value,
                })
              }
            />
          </div>
          <div className="w-full mt-4 flex gap-3">
            <div className="my-auto">Slug</div>
            <input
              type="text"
              className="input-field-full-width"
              value={detail.slug != null ? detail.slug : ""}
              onChange={(e) =>
                setDetail({
                  ...detail,
                  slug: e.target.value,
                })
              }
            />
          </div>
          <div className="w-full mt-4 flex gap-3">
            <div className="my-auto">Level</div>
            <select
              className="input-field-full-width"
              value={detail.level != null ? detail.level : ""}
              onChange={(e) =>
                setDetail({
                  ...detail,
                  level: e.target.value,
                })
              }
            >
              <option value="">Pilih Level</option>
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advance">Advance</option>
            </select>
          </div>

          {/* Section List */}
          <div className="mt-6">
            <div className="flex justify-between">
              <div className="font-semibold my-auto">Sections</div>
              <button
                className="button-yellow-outline-with-hover"
                onClick={onAddNewSection}
                type={"button"}
              >
                Add Section
              </button>
            </div>
            {detail.sections.map((section, sectionIndex) => {
              return (
                <div
                  key={`section-${sectionIndex}`}
                  className="w-full mt-4 border bg-white"
                >
                  <AccordionComponent title={section.title} isOpen={true}>
                    <div className="p-4">
                      {/* Section Title field */}
                      <div className="flex mt-4">
                        <label className="font-medium w-28 mt-1">
                          Title<span className="text-red-500">*</span>
                        </label>
                        <div className="w-full">
                          <input
                            className="input-field-full-width"
                            value={section.title}
                            onChange={(e) => {
                              let newDetail = { ...detail };
                              newDetail.sections[sectionIndex].title =
                                e.target.value;
                              setDetail(newDetail);
                            }}
                          />
                        </div>

                        {sectionIndex > 0 && (
                          <button
                            className="ml-4"
                            onClick={() => onMoveSectionUp(sectionIndex)}
                            type={"button"}
                          >
                            <FaArrowCircleUp size={24} />
                          </button>
                        )}
                        {sectionIndex < detail?.sections.length - 1 && (
                          <button
                            className="ml-4"
                            onClick={() => onMoveSectionDown(sectionIndex)}
                            type={"button"}
                          >
                            <FaArrowCircleDown size={24} />
                          </button>
                        )}
                        <button
                          type={"button"}
                          className="text-red-500 ml-4"
                          onClick={() => {
                            if (section.id !== -1) {
                              DeleteCourseSectionUsecase(section.id).finally(
                                () => {
                                  setNeedRefresh(true);
                                }
                              );
                            } else {
                              let newDetail = { ...detail };
                              newDetail.sections.splice(sectionIndex, 1);
                              setDetail(newDetail);
                            }
                          }}
                        >
                          <FaTrashAlt size={24} />
                        </button>
                      </div>

                      <div className="flex justify-between mt-4">
                        <div className="font-semibold">Courses</div>
                        <button
                          type={"button"}
                          className="button-yellow-outline-with-hover"
                          onClick={() => onAddNewCourse(sectionIndex)}
                        >
                          Add Course
                        </button>
                      </div>

                      {/* Course Field */}
                      {section.items.map((course, courseIndex) => {
                        return (
                          <div
                            key={`course-${sectionIndex}-${courseIndex}`}
                            className="my-2"
                          >
                            <AccordionComponent
                              title={course.title}
                              isOpen={true}
                            >
                              <div className="bg-gray-200 p-4">
                                {/* Course Title field */}
                                <div className="flex mt-4">
                                  <label className="font-medium w-28 mt-1">
                                    Title<span className="text-red-500">*</span>
                                  </label>
                                  <div className="w-full">
                                    <input
                                      className="input-field-full-width"
                                      value={course.title}
                                      onChange={(e) => {
                                        let newDetail = { ...detail };
                                        newDetail.sections[sectionIndex].items[
                                          courseIndex
                                        ].title = e.target.value;
                                        setDetail(newDetail);
                                      }}
                                    />
                                  </div>

                                  {courseIndex > 0 && (
                                    <button
                                      className="ml-4"
                                      onClick={() =>
                                        onMoveCourseUp(
                                          sectionIndex,
                                          courseIndex
                                        )
                                      }
                                      type={"button"}
                                    >
                                      <FaArrowCircleUp size={24} />
                                    </button>
                                  )}
                                  {courseIndex < section.items.length - 1 && (
                                    <button
                                      className="ml-4"
                                      onClick={() =>
                                        onMoveCourseDown(
                                          sectionIndex,
                                          courseIndex
                                        )
                                      }
                                      type={"button"}
                                    >
                                      <FaArrowCircleDown size={24} />
                                    </button>
                                  )}
                                  <button
                                    type={"button"}
                                    className="text-red-500 ml-4"
                                    onClick={() => {
                                      if (course.id !== -1) {
                                        DeleteCourseItemUsecase(
                                          course.id
                                        ).finally(() => {
                                          setNeedRefresh(true);
                                        });
                                      } else {
                                        let newDetail = { ...detail };
                                        newDetail.sections[
                                          sectionIndex
                                        ].items.splice(courseIndex, 1);
                                        setDetail(newDetail);
                                      }
                                    }}
                                  >
                                    <FaTrashAlt size={24} />
                                  </button>
                                </div>

                                {/* Cover Image */}
                                <div className="w-full mt-4 flex">
                                  <div className="w-28 my-auto">
                                    Cover Image
                                  </div>
                                  <div className="input-field-full-width flex p-0">
                                    <input
                                      type="text"
                                      className="w-full outline-none p-1"
                                      value={course.cover_image_path}
                                      disabled
                                      readOnly
                                    />
                                    <button
                                      className="rounded-r bg-gray-500 shadow text-white w-32"
                                      onClick={() =>
                                        onSelectMediaForCourseClicked(
                                          sectionIndex,
                                          courseIndex
                                        )
                                      }
                                      type="button"
                                    >
                                      Select Image
                                    </button>
                                  </div>
                                </div>

                                {/* Video URL field */}
                                <div className="flex mt-4">
                                  <label className="font-medium w-28 mt-1 my-auto">
                                    Video URL
                                  </label>
                                  <div className="w-full">
                                    <input
                                      className="input-field-full-width"
                                      type="url"
                                      value={course.video_url}
                                      onChange={(e) => {
                                        let newDetail = { ...detail };
                                        newDetail.sections[sectionIndex].items[
                                          courseIndex
                                        ].video_url = e.target.value;
                                        setDetail(newDetail);
                                      }}
                                    />
                                  </div>
                                </div>

                                {/* Duration in Seconds field */}
                                <div className="flex mt-4">
                                  <label className="font-medium w-28 mt-1 my-auto">
                                    Duration (seconds)
                                  </label>
                                  <div className="w-full">
                                    <input
                                      className="input-field-full-width"
                                      type={"number"}
                                      value={course.duration_in_seconds}
                                      onChange={(e) => {
                                        let newDetail = { ...detail };
                                        newDetail.sections[sectionIndex].items[
                                          courseIndex
                                        ].duration_in_seconds = e.target.value;
                                        setDetail(newDetail);
                                      }}
                                    />
                                  </div>
                                </div>

                                {/* Description field */}
                                <div className="flex mt-4">
                                  <label className="font-medium w-28 mt-1">
                                    Description
                                  </label>
                                  <div className="w-full">
                                    <input
                                      className="input-field-full-width"
                                      type="text"
                                      value={course.description}
                                      onChange={(e) => {
                                        let newDetail = { ...detail };
                                        newDetail.sections[sectionIndex].items[
                                          courseIndex
                                        ].description = e.target.value;
                                        setDetail(newDetail);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </AccordionComponent>
                          </div>
                        );
                      })}
                    </div>
                  </AccordionComponent>
                </div>
              );
            })}
          </div>

          <div className="w-full mt-4 border bg-white">
            <AccordionComponent title="Save" isOpen={true}>
              <div className="p-4">
                {/* Uploaded Date */}
                <div className="flex">
                  <div className="w-4">
                    <FaRegCalendarAlt />
                  </div>
                  <div className="ml-2 text-xs m-auto text-ellipsis">
                    Created on:{" "}
                    <span className="font-medium">
                      {convertDateFromApiToView(detail.created_at)}
                    </span>
                  </div>
                </div>

                {/* Uploaded By */}
                <div className="flex mt-2">
                  <div className="w-4">
                    <FaUserAlt />
                  </div>
                  <div className="ml-2 text-xs m-auto text-ellipsis">
                    Created by:{" "}
                    <span className="font-medium">
                      {detail.created_by.fullname}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex border-t-2 justify-between py-2 mt-2 px-4">
                <button
                  className="button-red-outline-with-hover text-xs"
                  onClick={showDeleteModal}
                >
                  Delete Permanently
                </button>
                <div>
                  <button
                    className="button-outline-with-hover text-xs mr-4 h-full"
                    onClick={() => updateData("draft")}
                  >
                    Draft
                  </button>
                  <button
                    className="button-yellow-outline-with-hover text-xs"
                    onClick={() => updateData("published")}
                  >
                    Publish
                  </button>
                </div>
              </div>
            </AccordionComponent>
          </div>
        </>
      )}
    </div>
  );
}
