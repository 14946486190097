import { PATH_RECORDS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ListRecordModel } from "../data/record.model";

export const GetAllRecordsUsecase: (
  params: string
) => Promise<ListRecordModel> = async (params) => {
  try {
    let response = await client.get(PATH_RECORDS + params);

    return {
      list: response.data.data.list,
      meta: response.data.data.meta,
    };
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
