import { FC } from "react";

export type TabProps = {
  tabItems: string[];
  onClickTab: (index: number) => void;
  selectedIndex: number;
  isDisabled?: boolean;
};

export const Tabs: FC<TabProps> = ({
  tabItems,
  onClickTab,
  selectedIndex,
  isDisabled,
}) => {
  return (
    <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 overflow-x-auto">
      {tabItems.map(function (item, index) {
        return (
          <li
            className="mr-2"
            key={item}
            onClick={(e) => {
              onClickTab(index);
            }}
          >
            <span
              className={`inline-block p-4 rounded-t-lg ${
                index === selectedIndex
                  ? "text-blue-600 bg-gray-100 active dark:bg-gray-800 dark:text-blue-500"
                  : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
              } ${
                isDisabled &&
                "inline-block p-4 text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500"
              }`}
            >
              {item}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

Tabs.defaultProps = {
  selectedIndex: 0,
  isDisabled: false,
};
