import { ReactNode } from "react";
import { BaseComponentModel } from "../../context/editor-sidebar.context";
import {
  PageConfigModel,
  UsePageEditorContext,
} from "../../context/page-editor.context";

import { FaCode, FaFont, FaImage, FaVideo,FaHeading, FaList, FaInfoCircle, FaExclamation } from "react-icons/fa";
import { CgSpaceBetweenV } from "react-icons/cg";
import { AiOutlineColumnWidth } from "react-icons/ai";
import { TbColumns } from "react-icons/tb";

import { TextModel } from "../models/text.model";
import { DividerModel } from "../models/divider.model";
import { ImageModel } from "../models/image.model";
import { SpacerModel } from "../models/spacer.model";
import { VideoModel } from "../models/video.model";
import { HeaderModel } from "../models/header.model";
import { FC } from "react";
import { BulletModel } from "../models/bullet.model";
import { PostDetailModel } from "../models/post-detail.model";
import { DisclaimerModel } from "../models/disclaimer.model";

export type AvailableComponentModel = {
  type: string;
  icon: string;
  name: string;
};

const componentToModelMapper: (
  model: AvailableComponentModel,
  index: number
) => BaseComponentModel | undefined = (model, index) => {
  switch (model.type) {
    case "text":
      return new TextModel("text-" + index);
    case "header":
      return new HeaderModel("header-" + index);
    case "bullet":
      return new BulletModel("bullet-" + index);
    case "post-detail":
      return new PostDetailModel("post-detail-" + index);
    case "disclaimer":
      return new DisclaimerModel("disclaimer-" + index);
    case "divider":
      return new DividerModel("divider-" + index);
    case "spacer":
      return new SpacerModel("spacer-" + index);
    case "image":
      return new ImageModel("image-" + index);
    case "video":
      return new VideoModel("video-" + index);
  }
  return undefined;
};

function onAddPageComponent(
  model: AvailableComponentModel,
  pageConfigs: PageConfigModel,
  setPageConfigs: (pageConfigs: PageConfigModel) => void
) {
  let newComponent = componentToModelMapper(
    model,
    pageConfigs.pageComponents.length
  );

  if (newComponent) {
    let newList: BaseComponentModel[] = [
      ...pageConfigs.pageComponents,
      newComponent,
    ];

    setPageConfigs({
      ...pageConfigs,
      pageComponents: newList,
      isEditing: true,
      editIndex: newList.length - 1,
    });
  }
}

export type ItemComponentViewProps = {
  itemKey: number;
  icon: ReactNode;
  text: string;
};

export const ItemComponentView: FC<ItemComponentViewProps> = ({
  itemKey,
  icon,
  text,
}) => {
  const { pageConfigs, setPageConfigs } = UsePageEditorContext();

  return (
    <div
      key={itemKey}
      className="bg-gray-500 font-semibold border rounded-lg p-4 text-center my-1 w-full"
      onClick={() =>
        onAddPageComponent(
          pageConfigs.availableCommponents[itemKey],
          pageConfigs,
          setPageConfigs
        )
      }
    >
      <div className="mx-auto w-8">{icon}</div>
      {text}
    </div>
  );
};

export type ItemComponentProps = {
  model: AvailableComponentModel[];
};

export const ItemComponent: FC<ItemComponentProps> = ({ model }) => {
  return (
    <>
      {model.map((item, index) => {
        let icon = null;

        switch (item.type) {
          case "header":
            icon = <FaHeading size={32} />;
            break;
          case "text":
            icon = <FaFont size={32} />;
            break;
          case "post-detail":
            icon = <FaInfoCircle size={32} />;
            break;
          case "bullet":
            icon = <FaList size={32} />;
            break;
          case "disclaimer":
            icon = <FaExclamation size={32} />;
            break;
          case "divider":
            icon = <CgSpaceBetweenV size={32} />;
            break;
          case "spacer":
            icon = <AiOutlineColumnWidth size={32} />;
            break;
          case "image":
            icon = <FaImage size={32} />;
            break;
          case "video":
            icon = <FaVideo size={32} />;
            break;
          case "column":
            icon = <TbColumns size={32} />;
            break;
          case "html":
            icon = <FaCode size={32} />;
        }

        return (
          icon != null && (
            <ItemComponentView
              key={item.type}
              itemKey={index}
              icon={icon}
              text={item.name}
            />
          )
        );
      })}
    </>
  );
};
