import { FC, useState } from "react";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import {
  MediaEnum,
  SelectMediaInputComponent,
} from "./forms/SelectMediaInput.component";

export type TestimoniInputModel = {
  imageUrl: string;
  name: string;
  title: string;
  text: string;
};

export type TestimoniInputProps = {
  index: number;
  testimoni: TestimoniInputModel;
  onChange: (index: number, testimoni: TestimoniInputModel) => void;
  onDelete: (index: number) => void;
};

export type TestimoniFormProps = {
  testimoniList: TestimoniInputModel[];
  onSaveTestimoni: (testimoniList: TestimoniInputModel[]) => void;
};

export const TestimoniItem: FC<TestimoniInputProps> = ({
  index,
  testimoni,
  onChange,
  onDelete,
}) => {
  return (
    <div className="my-2 rounded-xl bg-slate-300 border shadow p-4">
      <div className="mt-4 flex justify-end">
        <button
          className="button-red-outline-with-hover"
          onClick={() => onDelete(index)}
        >
          <FaRegTrashAlt />
        </button>
      </div>
      <div className="flex mt-4">
        <label className="font-medium my-auto w-32">Name</label>
        <input
          className={"input-field-full-width"}
          type="text"
          value={testimoni.name}
          onChange={(e) => {
            let newTestimoni = { ...testimoni };
            newTestimoni.name = e.target.value;
            onChange(index, newTestimoni);
          }}
        />
      </div>

      <div className="flex mt-4">
        <label className="font-medium my-auto w-32">Title</label>
        <input
          className={"input-field-full-width"}
          type="text"
          value={testimoni.title}
          onChange={(e) => {
            let newTestimoni = { ...testimoni };
            newTestimoni.title = e.target.value;
            onChange(index, newTestimoni);
          }}
        />
      </div>

      <SelectMediaInputComponent
        media={MediaEnum.IMAGE}
        mediaLabel="Image"
        value={testimoni.imageUrl}
        onChange={(url) => {
          let newTestimoni = { ...testimoni };
          newTestimoni.imageUrl = url;
          onChange(index, newTestimoni);
        }}
      />

      <div className="font-medium mt-4">Testimoni</div>
      <textarea
        className={"input-field-full-width"}
        value={testimoni.text}
        onChange={(e) => {
          let newTestimoni = { ...testimoni };
          newTestimoni.text = e.target.value;
          onChange(index, newTestimoni);
        }}
      />
    </div>
  );
};

export const TestimoniFormComponent: FC<TestimoniFormProps> = ({
  testimoniList,
  onSaveTestimoni,
}) => {
  const [testimoni, setTestimoni] =
    useState<TestimoniInputModel[]>(testimoniList);

  const onAddNewTestimoni = () => {
    let newTestimoni = [...testimoni];
    newTestimoni.splice(0, 0, {
      name: "",
      text: "",
      title: "",
      imageUrl: "",
    });

    setTestimoni(newTestimoni);
  };

  const onDeleteTestimoni = (index: number) => {
    let newTestimoni = [...testimoni];
    newTestimoni.splice(index, 1);
    setTestimoni(newTestimoni);
  };

  const onChange = (index: number, mTestimoni: TestimoniInputModel) => {
    let newTestimoni = [...testimoni];
    newTestimoni[index] = mTestimoni;
    setTestimoni(newTestimoni);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div className="font-bold text-3xl">Testimoni</div>

        <div className="flex">
          <button
            className="button-yellow-outline-with-hover mr-2"
            onClick={onAddNewTestimoni}
          >
            <FaPlus />
          </button>
          <button
            className="button-yellow-outline-with-hover"
            onClick={() => onSaveTestimoni(testimoni)}
          >
            Save
          </button>
        </div>
      </div>

      {testimoni.length === 0 && (
        <div className="rounded bg-gray-400 text-center mt-4 p-4">
          Testimoni Empty
        </div>
      )}

      {testimoni.map((item, index) => {
        return (
          <TestimoniItem
            key={`testimoni-${index}`}
            index={index}
            testimoni={item}
            onChange={onChange}
            onDelete={onDeleteTestimoni}
          />
        );
      })}
    </div>
  );
};
