import { PATH_PRODUCTS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ProductModel } from "../data/product.model";

export const GetProductUsecase = async (id: string) => {
  try {
    let response = await client.get(PATH_PRODUCTS + "/" + id);
    let description =
      response.data.data.description !== null &&
        response.data.data.description !== ""
        ? JSON.parse(response.data.data.description)
        : null;

    return {
      ...response.data.data,
      availableAffiliate:
        description !== null && description.availableAffiliate
          ? description.availableAffiliate
          : false,
      benefits:
        description !== null && description.benefits
          ? description.benefits.split(",").map((item: string) => item.trim())
          : null,
      freeTrials:
        description !== null && description.freeTrials
          ? JSON.parse(description.freeTrials)
          : null,
      crossSells:
        description !== null && description.crossSells
          ? JSON.parse(description.crossSells)
          : null,
      appStoreProductId: description !== null && description.appStoreProductId ? description.appStoreProductId : null,
      playStoreProductId: description !== null && description.playStoreProductId ? description.playStoreProductId : null,
    } as ProductModel;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
