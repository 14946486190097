import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { SaveSettingsUsecase } from "@Pages/setting/domain/SaveSettings.usecase";
import { GENERAL_SELECTED_PRODUCTS } from "@Pages/setting/utils/const";
import { FC } from "react";
import {
  SelectedProducts,
  SelectedProductsFormComponent,
} from "../components/SelectedProductsForm.component";
import { SettingSectionWithProductProps } from "../SettingStaticPages.page";

export const FeaturedProductsSection: FC<SettingSectionWithProductProps> = ({
  settingsData,
  setNeedRefetch,
  productList,
}) => {
  const toasterContext = UseToasterContext();

  const getSelectedProducts = () => {
    let selectedProductString = settingsData.find(
      (item) => item.key === GENERAL_SELECTED_PRODUCTS
    );
    if (selectedProductString) {
      let parsed = JSON.parse(selectedProductString.value);
      return parsed;
    }
    return [
      {
        productId: "",
        productName: "",
        productDuration: "",
        productBenefit: "",
        slashPrice: "",
        isFeatured: false,
      },
      {
        productId: "",
        productName: "",
        productDuration: "",
        productBenefit: "",
        slashPrice: "",
        isFeatured: false,
      },
      {
        productId: "",
        productName: "",
        productDuration: "",
        productBenefit: "",
        slashPrice: "",
        isFeatured: false,
      },
    ];
  };

  const onSelectedProductChanged = (selectedProducts: SelectedProducts[]) => {
    let selectedProductStringify = JSON.stringify(selectedProducts);
    let selectedProductString = settingsData.find(
      (item) => item.key === GENERAL_SELECTED_PRODUCTS
    );
    SaveSettingsUsecase(
      selectedProductString !== undefined,
      GENERAL_SELECTED_PRODUCTS,
      selectedProductStringify
    )
      .then((message) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Featured updated successfully!",
            message,
            ToasterType.SUCCESS
          ),
        ]);
        setNeedRefetch(true);
      })
      .catch((errors) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to update settings!",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      });
  };

  return (
    <div className="mt-4">
      <SelectedProductsFormComponent
        selectedProducts={getSelectedProducts()}
        onSelectedProductChanged={onSelectedProductChanged}
        listOfProducts={productList}
      />
    </div>
  );
};
