import { FC, PropsWithChildren, useContext } from "react";
import { createContext, useState } from "react";
import { AvailableComponentModel } from "../presentation/components/ItemComponent.component";
import { BaseComponentModel } from "./editor-sidebar.context";

export type StockAnalysisModel = {
  code: string;
  buyPrice: number;
  mos: number;
  currentPrice: number;
  valuation: number;
  image_url: string;
};

export type PageConfigModel = {
  id: number;
  pageComponents: BaseComponentModel[];
  availableCommponents: AvailableComponentModel[];
  isEditing: boolean;
  editIndex: number;
  oldModel: BaseComponentModel | null;
  title: string;
  slug: string;
  type: string;
  isShowHeader: boolean;
  headerType: string;
  isShowFooter: boolean;
  footerType: string;
  visibility: string;
  analysis?: StockAnalysisModel;
};

export type PageConfigContent = {
  pageConfigs: PageConfigModel;
  setPageConfigs: (pageConfigs: PageConfigModel) => void;
};

export const PageEditorContext = createContext<PageConfigContent>({
  pageConfigs: {
    id: -1,
    pageComponents: [],
    availableCommponents: [],
    isEditing: false,
    editIndex: -1,
    oldModel: null,
    title: "",
    slug: "",
    type: "",
    isShowHeader: false,
    headerType: "",
    isShowFooter: false,
    footerType: "",
    visibility: "all",
  },
  setPageConfigs: () => {},
});

export const PageEditorProvider: FC<PropsWithChildren> = ({ children }) => {
  const [pageConfigs, setPageConfigs] = useState<PageConfigModel>({
    id: -1,
    pageComponents: [],
    availableCommponents: [],
    isEditing: false,
    editIndex: -1,
    oldModel: null,
    title: "",
    slug: "",
    type: "",
    isShowHeader: false,
    headerType: "",
    isShowFooter: false,
    footerType: "",
    visibility: "all",
  });

  const value = {
    pageConfigs,
    setPageConfigs,
  };

  return (
    <PageEditorContext.Provider value={value}>
      {children}
    </PageEditorContext.Provider>
  );
};

export const UsePageEditorContext = () => {
  return useContext(PageEditorContext);
};
