import {
  BsCheckCircle,
  BsExclamationCircle,
  BsExclamationTriangle,
  BsInfoCircle,
} from "react-icons/bs";

export enum ToasterType {
  SUCCESS = "toast-success",
  DANGER = "toast-danger",
  INFO = "toast-info",
  WARNING = "toast-warning",
}

export enum ToasterPosition {
  TOP_RIGHT = "toast-top-right",
  TOP_LEFT = "toast-top-left",
  BOTTOM_LEFT = "toast-bottom-left",
  BOTTOM_RIGHT = "toast-bottom-right",
}

export class ToasterModel {
  title: string;
  description: string;
  type: ToasterType;

  constructor(title: string, description: string, type: ToasterType) {
    this.title = title;
    this.description = description;
    this.type = type;
  }

  getIcon() {
    return this.type === ToasterType.SUCCESS ? (
      <BsCheckCircle size={32} />
    ) : this.type === ToasterType.DANGER ? (
      <BsExclamationCircle size={32} />
    ) : this.type === ToasterType.INFO ? (
      <BsInfoCircle size={32} />
    ) : this.type === ToasterType.WARNING ? (
      <BsExclamationTriangle size={32} />
    ) : (
      <div></div>
    );
  }
}
