import { PATH_ORDERS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const CompleteOrderUsecase: (id: string) => Promise<boolean> = async (
  id
) => {
  try {
    let formData = new FormData();
    formData.append("status", "paid");

    await client.put(PATH_ORDERS + "/" + id, formData);
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
