import { ModalProps } from "@Components/modal/Modal.component";
import { useContext } from "react";
import { FC, PropsWithChildren, useState } from "react";
import { createContext } from "react";

export type ModalContent = {
  modal: ModalProps | null;
  setModal: (modal: ModalProps | null) => void;
  closeModal: VoidFunction;
};

export const ModalContext = createContext<ModalContent>({
  modal: null,
  setModal: () => { },
  closeModal: () => { }
});

export const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [modal, setModal] = useState<ModalProps | null>(null);

  const closeModal = () => {
    setModal(null);
  }

  const value: ModalContent = { modal, setModal, closeModal };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export const UseModalContext = () => {
  return useContext(ModalContext);
};
