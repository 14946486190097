import { PATH_GR_CONTACTS, PATH_GR_CONTACT_ACTIVITIES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ActivityModel, ContactModel } from "../data/contact.model";

export const ContactListUsecase: (
  page: number
) => Promise<ContactModel[]> = async (page) => {
  try {
    let response = await client.get(PATH_GR_CONTACTS + `?page=${page}`);
    let data = response.data.data;

    return data.map((item: any) => {
      return {
        id: item.contactId,
        createdOn: new Date(item.createdOn).toString(),
        changedOn: new Date(item.changedOn).toString(),
        email: item.email,
        name: item.name,
        engagementScore: item.engagementScore,
        origin: item.origin,
        campaign: {
          id: item.campaign.campaignId,
          name: item.campaign.name,
        },
      };
    });
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const ContactAcitivitiesUsecase: (
  contactId: string,
  page: number
) => Promise<ActivityModel[]> = async (contactId, page) => {
  try {
    let response = await client.get(
      PATH_GR_CONTACT_ACTIVITIES.replace("{id}", contactId) + `?page=${page}`
    );
    let data = response.data.data;

    return data.map((item: any) => {
      return {
        activity: item.activity,
        createdOn: new Date(item.createdOn).toString(),
        subject: item.subject,
        previewUrl: item.previewUrl,
      };
    });
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
