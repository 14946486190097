import { FC, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import {
  MediaEnum,
  SelectMediaInputComponent,
} from "./forms/SelectMediaInput.component";

export type PortfolioInputModel = {
  imageUrl: string;
};

export type PortfolioInputProps = {
  portfolio: PortfolioInputModel;
  onChange: (portfolio: PortfolioInputModel) => void;
};

export type PortfolioFormProps = {
  portfolioList: PortfolioInputModel;
  onSavePortfolio: (portfolioList: PortfolioInputModel) => void;
};

export const PortfolioItem: FC<PortfolioInputProps> = ({
  portfolio,
  onChange,
}) => {
  return (
    <div className="my-2 rounded-xl bg-slate-300 border shadow p-4">
      <SelectMediaInputComponent
        media={MediaEnum.IMAGE}
        mediaLabel="Image"
        value={portfolio.imageUrl}
        onChange={(url) => {
          let newPortfolio = { ...portfolio };
          newPortfolio.imageUrl = url;
          onChange(newPortfolio);
        }}
      />
    </div>
  );
};

export const PortfolioFormComponent: FC<PortfolioFormProps> = ({
  portfolioList,
  onSavePortfolio,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [portfolios, setPortfolios] =
    useState<PortfolioInputModel>(portfolioList);

  const onChange = (mPortfolio: PortfolioInputModel) => {
    setPortfolios(mPortfolio);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div
          className="font-bold text-3xl w-full"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          Portfolio
        </div>

        <div className="flex">
          {!isCollapsed && (
            <button
              className="button-yellow-outline-with-hover"
              onClick={() => onSavePortfolio(portfolios)}
            >
              Save
            </button>
          )}
          <button
            className="ml-4 mx-auto"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </div>
      </div>

      {!isCollapsed && (
        <PortfolioItem portfolio={portfolios} onChange={onChange} />
      )}
    </div>
  );
};
