import Moment from "moment";

export function convertDateFromApiToView(apiDate: string) {
  return Moment.utc(apiDate).local().format("D MMMM YYYY, HH:mm:ss");
}

export function formatToCurrency(price: number) {
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  });
  return formatter.format(price);
}