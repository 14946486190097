import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";

import "tw-elements";
import "./style.css";
import MidasApp from "./MidasApp";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MidasApp />
    </BrowserRouter>
  </React.StrictMode>
);

// reportWebVitals();
