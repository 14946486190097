import { PATH_USERS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ListUserModel } from "../data/user.model";

export const GetAllUsersUsecase: (
  params: string
) => Promise<ListUserModel> = async (params) => {
  try {
    let response = await client.get(PATH_USERS + params);
    return {
      list: response.data.data.list,
      meta: response.data.data.meta,
    };
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
