import { PATH_LOADS_ROLES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const LoadRolesUsecase = async () => {
  try {
    let response = await client.get(PATH_LOADS_ROLES);
    return response.data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
