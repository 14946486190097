import { ModalProps } from "@Components/modal/Modal.component";
import { ReactNode } from "react";

export const createModal: (
  icon: ReactNode,
  title: string | undefined,
  description: ReactNode,
  primaryCta: ReactNode,
  secondaryCta: ReactNode
) => ModalProps = (icon, title, description, primaryCta, secondaryCta) => {
  return {
    icon: icon,
    title: title,
    description: description,
    primaryCta: primaryCta,
    secondaryCta: secondaryCta,
  };
};
