import { PATH_ANALYSIS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { AnalysisModel } from "../data/analysis.model";

export const GetAnalysisUsecase: (
  id: string
) => Promise<AnalysisModel> = async (id) => {
  try {
    let response = await client.get(PATH_ANALYSIS + "/" + id);
    let data: AnalysisModel = response.data.data;

    data.buy_price = parseFloat(response.data.data.buy_price);
    data.current_price = parseFloat(response.data.data.current_price);
    data.margin_of_safety = parseFloat(response.data.data.margin_of_safety);
    data.valuation = parseFloat(response.data.data.valuation);
    data.page.is_show_header = response.data.data.page.is_show_header !== "0";
    data.page.is_show_footer = response.data.data.page.is_show_footer !== "0";

    return data;
  } catch (e) {
    throw new Error(e.response.message);
  }
};
