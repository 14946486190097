import { PATH_RESOURCES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ListResourceModel } from "../data/resource.model";

export const GetAllResourcesUsecase: (
  params: string
) => Promise<ListResourceModel> = async (params: string) => {
  try {
    let response = await client.get(PATH_RESOURCES + params);
    return {
      list: response.data.data.list,
      meta: response.data.data.meta,
    };
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
