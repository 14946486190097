import { MetaModel } from "@Pages/common/data/meta.model";
import { FC } from "react";

export type BottomListProps = {
  shouldShow: boolean;
  onLimitChange: (newValue: any) => void;
  canGoPrev: boolean;
  onGoToPrev: () => void;
  canGoNext: boolean;
  onGoToNext: () => void;
  meta: MetaModel;
};

export const BottomListComponent: FC<BottomListProps> = ({
  shouldShow,
  onLimitChange,
  canGoPrev,
  onGoToPrev,
  canGoNext,
  onGoToNext,
  meta,
}) => {
  return (
    <>
      {shouldShow && (
        <div className="flex justify-between mt-4">
          <div className="mr-1 border rounded px-2 py-2">
            <select onChange={(event) => onLimitChange(event.target.value)}>
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="60">60</option>
              <option value="80">80</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="flex">
            <div
              className={
                canGoPrev
                  ? "button-yellow-outline-with-hover"
                  : "button-yellow-outline-without-hover"
              }
              onClick={onGoToPrev}
            >
              &lt;
            </div>

            {/* item position details */}
            <div className="m-auto ml-4 mr-4">
              {meta.total > 0 ? meta.start : 0} -{" "}
              {meta.total < meta.end ? meta.total : meta.end} of {meta.total}
            </div>

            <div
              className={
                canGoNext
                  ? "button-yellow-outline-with-hover"
                  : "button-yellow-outline-without-hover"
              }
              onClick={onGoToNext}
            >
              &gt;
            </div>
          </div>
        </div>
      )}
    </>
  );
};
