import { FC, useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const PaymentChoiceData = [
  {
    key: "bri",
    title: "BRI",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-bank-bri-logo.webp",
    ],
  },
  {
    key: "mandiri",
    title: "Mandiri",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-bank-mandiri-logo.webp",
    ],
  },
  {
    key: "bni",
    title: "BNI",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-bank-bni-logo.webp",
    ],
  },
  {
    key: "pertama",
    title: "Permata",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-bank-permata-logo.webp",
    ],
  },
  {
    key: "bca",
    title: "BCA",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-bank-bca-logo.webp",
    ],
  },
  {
    key: "bss",
    title: "BSS",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-bank-sahabat-sampoerna-logo.webp",
    ],
  },
  {
    key: "bjb",
    title: "BJB",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-bank-bjb-logo.webp",
    ],
  },
  {
    key: "bsi",
    title: "BSI",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-bsi-logo.webp",
    ],
  },
  {
    key: "alfamart",
    title: "Alfamart",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-alfamart-logo.webp",
    ],
  },
  {
    key: "indomaret",
    title: "Indomaret",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-indomaret-logo.webp",
    ],
  },
  {
    key: "ovo",
    title: "OVO",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-ovo-logo.webp",
    ],
  },
  {
    key: "dana",
    title: "Dana",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-dana-logo.webp",
    ],
  },
  {
    key: "linkaja",
    title: "LinkAja",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-linkaja-logo.webp",
    ],
  },
  {
    key: "shopeepay",
    title: "ShopeePay",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-shopeepay-logo.webp",
    ],
  },
  {
    key: "astrapay",
    title: "AstraPay",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-astrapay.webp",
    ],
  },
  {
    key: "credit-card",
    title: "Cards",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-visa-logo.webp",
      "https://api.midascuan.id/storage/logos/midas-payment-mastercard-logo.webp",
      "https://api.midascuan.id/storage/logos/midas-payment-jcb-logo.webp",
    ],
  },
  {
    key: "qris",
    title: "QRIS",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-qris-logo.webp",
    ],
  },
  {
    key: "kredivo",
    title: "Kredivo",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-kredivo-logo.webp",
    ],
  },
  {
    key: "akulaku",
    title: "Akulaku",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-akulaku-logo.webp",
    ],
  },
  {
    key: "uangme",
    title: "UangMe",
    image: [
      "https://api.midascuan.id/storage/logos/midas-payment-uangme-logo.webp",
    ],
  },
];

export type AvailablePaymentItem = {
  key: string;
  title: string;
  image: string[];
};

export type AvailablePaymentItemProps = {
  data: AvailablePaymentItem;
  isSelected: boolean;
  onSelectChange: (key: string, isSelected: boolean) => void;
};

export type AvailablePaymentProps = {
  dataList: AvailablePaymentItem[];
  onSaveChange: (data: AvailablePaymentItem[]) => void;
};

export const AvailablePaymentItemComponent: FC<AvailablePaymentItemProps> = ({
  data,
  isSelected,
  onSelectChange,
}) => {
  return (
    <div className="flex gap-4">
      <input
        type={"checkbox"}
        onChange={(e) => {
          onSelectChange(data.key, e.target.checked);
        }}
        className="my-auto"
        checked={isSelected}
      />
      <div className="my-auto">{data.title}</div>
      {data.image.map((url) => (
        <img
          key={`logo-${data.title}-${url}`}
          src={url}
          alt={data.title}
          className="h-5 object-scale-down my-auto"
        />
      ))}
    </div>
  );
};

export const AvailablePaymentComponent: FC<AvailablePaymentProps> = ({
  dataList,
  onSaveChange,
}) => {
  const [data, setData] = useState<AvailablePaymentItem[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    setData(dataList);
  }, [dataList]);

  const isSelected = (key: string) => {
    return data.find((item) => item.key === key) !== undefined;
  };

  const onPaymentSelect = (key: string, isSelected: boolean) => {
    if (isSelected) {
      let value = PaymentChoiceData.find((item) => item.key === key);

      if (value) {
        let newData = [...data];
        newData.push(value);
        setData(newData);
      }
    } else {
      let newData = data.filter((item) => item.key !== key);
      setData(newData);
    }
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div
          className="w-full text-lg font-bold my-auto"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          Available Payments
        </div>
        <div className="flex">
          {!isCollapsed && (
            <button
              type={"button"}
              className="button-yellow-outline-with-hover"
              onClick={() => onSaveChange(data)}
            >
              Save
            </button>
          )}
          <button
            className="ml-4 mx-auto"
            type={"button"}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
          </button>{" "}
        </div>
      </div>
      {!isCollapsed && (
        <div className="mt-2">
          <div className="">
            Pilih metode pembayaran yang ingin di tampilkan di halaman checkout.
          </div>
          <div className="font-bold text-sm">
            *Tidak terintegrasi dengan Xendit, pastikan metode yang dipilih
            sudah di aktifkan di Xendit
          </div>
          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {PaymentChoiceData.map((item, index) => {
              return (
                <AvailablePaymentItemComponent
                  key={`available-payment-choice-${index}`}
                  data={item}
                  onSelectChange={onPaymentSelect}
                  isSelected={isSelected(item.key)}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
