/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { client } from "@Utils/ApiService";
import { API_PATH_LOGIN } from "@Utils/ApiConst";
import { UserSession } from "@Utils/UserSession";
import { FaEnvelope, FaEye, FaEyeSlash, FaLock } from "react-icons/fa";

import { default as MidasLogo } from "../../assets/midas.png";

export type LoginFormInput = {
  email: string;
  password: string;
};

export const LoginPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [errorLogin, setErrorLogin] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInput>();

  useEffect(() => {
    if (UserSession.checkIsLoggedIn()) {
      redirectUser();
    }
  }, []);

  const onSubmit: (data: LoginFormInput) => void = (data) => {
    let formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);

    client
      .post(API_PATH_LOGIN, formData)
      .then((response) => {
        let data = response.data.data;
        UserSession.login(
          data.user.id,
          data.user.fullname,
          data.user.email,
          data.token,
          data.user.role,
          data.user.picture_url,
          data.user.member_active_roles
        );

        redirectUser();
      })
      .catch((errors) => {
        setErrorLogin(errors.response.data.message);
      });
  };

  const redirectUser = () => {
    let redirectLocation = searchParams.get("continue");
    if (redirectLocation === null) {
      redirectLocation = "/";
    } else if (
      UserSession.getUserSession().role !== "admin" &&
      UserSession.getUserSession().role !== "superadmin"
    ) {
      window.location.assign("https://midascuan.id");
      return;
    } else {
      window.location.assign(redirectLocation);
    }

    navigate(redirectLocation);
  };

  return (
    <div className="bg-sidebar w-full h-screen p-24">
      <div className="border rounded bg-black w-fit h-fit m-auto p-4 flex flex-col">
        <div className="m-auto">
          <img src={MidasLogo} alt="" />
        </div>

        {/* error message */}
        {errorLogin !== "" && (
          <div className="w-full mt-6 rounded p-2 text-white text-xs bg-red-500 text-justify">
            {errorLogin}
          </div>
        )}

        <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
          {/* Email Field */}
          <div
            className={
              errors.email
                ? "flex p-0 input-field-full-width border-red-500 bg-white h-8"
                : "flex p-0 input-field-full-width bg-white h-8"
            }
          >
            <div className="w-6 ml-1 m-auto">
              <FaEnvelope />
            </div>
            <input
              type="text"
              className="ml-2 w-full"
              placeholder={"Email"}
              {...register("email", { required: "Email is required." })}
            />
          </div>
          {errors.email && (
            <p className="text-red-500 text-xs">{errors.email?.message}</p>
          )}

          {/* Password Field */}
          <div
            className={
              errors.password
                ? "flex p-0 input-field-full-width border-red-500 bg-white mt-4 h-8"
                : "flex p-0 input-field-full-width bg-white mt-4 h-8"
            }
          >
            <div className="w-6 ml-1 m-auto">
              <FaLock />
            </div>
            <input
              type={isPasswordHidden ? "password" : "text"}
              className="ml-2 w-full"
              placeholder={"Password"}
              {...register("password", { required: "Password is required." })}
            />
            <div
              className="w-6 mr-1 m-auto"
              onClick={() => setIsPasswordHidden(!isPasswordHidden)}
            >
              {isPasswordHidden ? <FaEye /> : <FaEyeSlash />}
            </div>
          </div>
          {errors.password && (
            <p className="text-red-500 text-xs">{errors.password?.message}</p>
          )}

          <button
            className="button-yellow-outline-with-hover mt-4 w-full bg-white"
            type="submit"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};
