import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { AutoResponderModel } from "@Pages/get-response/data/autoresponder.model";
import { FC } from "react";
import { FaCheckCircle, FaEye, FaTimesCircle } from "react-icons/fa";
import { PageControllerComponent } from "../components/PageController.component";
import { SectionProps } from "../GetResponse.page";

export type AutoResponderItemProps = {
  data: AutoResponderModel;
};

export type AutoResponderSectionProps = SectionProps & {
  data: AutoResponderModel[];
};

export const AutoResponderItem: FC<AutoResponderItemProps> = ({ data }) => {
  return (
    <div>
      <div className="p-4 grid grid-cols-1 md:grid-cols-3 border border-black rounded text-center">
        <div>
          <div className="">Delivered</div>
          <div className="font-bold">{data.statistics.delivered}</div>
        </div>
        <div>
          <div className="">Open Rate</div>
          <div className="font-bold">
            {(data.statistics.openRate * 100).toFixed(2)} %
          </div>
        </div>
        <div>
          <div className="">Click Rate</div>
          <div className="font-bold">
            {(data.statistics.clickRate * 100).toFixed(2)} %
          </div>
        </div>
      </div>
      <div className="p-4 grid grid-cols-2 gap-4">
        <div>Name</div>
        <div>{data.name}</div>

        <div>Subject</div>
        <div>{data.subject}</div>

        <div>Campaign ID</div>
        <div>{data.campaign.id}</div>

        <div>Campaign Name</div>
        <div>{data.campaign.name}</div>

        <div>Created On</div>
        <div>{data.createdOn}</div>

        <div>Status</div>
        <div>{data.status}</div>
      </div>
    </div>
  );
};

export const AutoResponderSection: FC<AutoResponderSectionProps> = ({
  data,
  page,
  setPage,
}) => {
  const modalContext = UseModalContext();

  const showDetail = (index: number) => {
    let title = data[index].id;
    let description = <AutoResponderItem data={data[index]} />;
    let primaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Close
      </button>
    );

    modalContext.setModal(
      createModal(null, title, description, primaryCta, null)
    );
  };

  return (
    <div className="mt-4">
      <table className={`border-collapse border w-full table-auto`}>
        <thead className="p-2 shadow-sm bg-gray-100 sticky">
          <tr>
            <th className="border border-spacing-1 text-center font-bold px-2">
              ID
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 text-ellipsis">
              Name
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 hidden md:table-cell">
              Campaign Name
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={`autoresponder-row-${item.id}-${index}`}>
              <td className="border px-2 text-center">{item.id}</td>
              <td className="border px-2 text-left">
                {item.name}
                <div>
                  <button onClick={() => showDetail(index)}>
                    <FaEye size={20} className="text-blue-400" />
                  </button>
                </div>
              </td>
              <td className="border px-2 text-left hidden md:table-cell">
                {item.campaign.name}
              </td>
              <td className="border px-2">
                {item.status === "enabled" ? (
                  <FaCheckCircle size={24} color="green" className="m-auto" />
                ) : (
                  <FaTimesCircle size={24} color="red" className="m-auto" />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PageControllerComponent
        currentPage={page}
        showPrev={page > 1}
        showNext={data.length >= 100}
        onPrev={setPage}
        onNext={setPage}
      />
    </div>
  );
};
