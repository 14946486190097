/* eslint-disable react-hooks/exhaustive-deps */

import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { ContentHeader } from "@Components/ContentHeader.component";
import { SearchInputComponent } from "@Components/inputs/SearchInputs.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE } from "@Helpers/regex";
import { buildQueryParamsFromUrl, buildUrlSearchParams } from "@Helpers/utils";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { DeletePageUsecase } from "@Pages/page/domain/DeletePage.usecase";
import { GetAllPagesUsecase } from "@Pages/page/domain/GetAllPages.usecase";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { FaExclamationTriangle, FaRegTrashAlt } from "react-icons/fa";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { LandingPageModel } from "../data/landing-page.model";

export const LandingPageListPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const modalContext = UseModalContext();
  const toasterContext = UseToasterContext();

  const searchRef = useRef() as MutableRefObject<HTMLInputElement>;

  const [needRefetch, setNeedRefetch] = useState(true);
  const [apiData, setApiData] = useState<LandingPageModel[]>();

  useEffect(() => {
    if (needRefetch) {
      async function fetchData() {
        let queryParams = buildQueryParamsFromUrl(searchParams.entries());
        queryParams = "?type=Landing Page&limit=1000&" + queryParams;

        try {
          let response = await GetAllPagesUsecase(queryParams);
          let iFrames = response.list.filter((item) =>
            item.content_json.toLowerCase().includes("iframe")
          );
          let formatted: LandingPageModel[] = iFrames.map((item) => {
            return {
              id: item.id.toString(),
              slug: item.slug,
              title: item.title,
              status: item.status,
            };
          });

          setApiData(formatted);
        } catch (errors) {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Failed to fetch landing pages",
              errors.message,
              ToasterType.DANGER
            ),
          ]);
        }

        setNeedRefetch(false);
      }
      fetchData();
    }
  }, [needRefetch]);

  const onDelete = async (index: number) => {
    if (apiData) {
      try {
        await DeletePageUsecase(parseInt(apiData[index].id ?? "0"));
        setNeedRefetch(true);
        modalContext.setModal(null);
      } catch (errors) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to delete landing page",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      }
    }
  };

  const onShowDeleteModal = (index: number) => {
    let icon = (
      <div className="text-red-500">
        <FaExclamationTriangle size={48} />
      </div>
    );
    let title = "Hapus Media";
    let description = apiData ? (
      <>
        Apakah anda yakin ingin menghapus{" "}
        <span className="font-bold">{apiData[index].title}</span> ?
      </>
    ) : (
      <></>
    );
    let primaryCta = (
      <button
        className="button-red-outline-with-hover"
        onClick={() => onDelete(index)}
      >
        Hapus
      </button>
    );
    let secondaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(icon, title, description, primaryCta, secondaryCta)
    );
  };

  const handleSearch = (e: any) => {
    if (e.key === "Enter") {
      let clearedString = e.target.value
        .replace(REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE, "")
        .replaceAll(" ", "+");

      setNeedRefetch(true);
      navigate({
        search:
          "?" +
          buildUrlSearchParams(searchParams.entries(), "search", clearedString),
      });
    }
  };

  return (
    <div className="m-4">
      {!apiData && <MidasShimmering />}
      {apiData && (
        <>
          <ContentHeader
            title={"Landing Pages"}
            rightButton={
              <YellowHoverButton
                label={"Add New"}
                destination="/landing-page/add"
              />
            }
          />
          <div className="h-6"></div>

          {/* top config */}
          <div className="border p-3 align-middle flex flex-col justify-between shadow-sm md:flex-row">
            <div className="flex"></div>
            <div className="border rounded mt-2 md:mt-0 flex">
              <SearchInputComponent
                searchRef={searchRef}
                onKeyDown={handleSearch}
              />
            </div>
          </div>

          {/* list items */}
          <div className="mt-4">
            {apiData.length === 0 && (
              <div className="text-center bg-gray-300 p-4">
                No Landing Page Available!
              </div>
            )}
            {apiData.length > 0 && (
              <table className="table-auto border w-full">
                <thead className="p-2 shadow-sm bg-gray-100 sticky">
                  <tr>
                    <th></th>
                    <th>Title</th>
                    <th className="hidden md:table-cell">Slug</th>
                    <th className="hidden md:table-cell">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {apiData.map((item, index) => (
                    <tr key={item.id}>
                      <td className="text-center">{index + 1}</td>
                      <td className="p-2">
                        <div className="font-bold text-ellipsis">
                          {item.title}
                        </div>
                        <div className="flex mt-3">
                          <Link to={"/landing-page/detail/" + item.id}>
                            <button
                              className="text-blue-400 mr-2 w-6 h-6"
                              title="Edit"
                            >
                              <BsPencilSquare />
                            </button>
                          </Link>
                          <button
                            className="text-red-400 mr-2 w-6 h-6"
                            title="Delete Permanently"
                            onClick={() => onShowDeleteModal(index)}
                          >
                            <FaRegTrashAlt />
                          </button>
                        </div>
                      </td>
                      <td className="text-center hidden md:table-cell">
                        {item.slug}
                      </td>
                      <td className="text-center hidden md:table-cell">
                        {item.status === "draft" ? (
                          <div className="text-yellow-500">Draft</div>
                        ) : (
                          <div className="text-green-500">Published</div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </>
      )}
    </div>
  );
};
