import { ContentHeader } from "@Components/ContentHeader.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { MediaChooser } from "@Pages/common/presentation/MediaChooser.page";
import { ResourceModel } from "@Pages/resources/data/resource.model";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FaArrowCircleDown, FaArrowCircleUp, FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  AddCourseItemUsecase,
  AddCourseSectionUsecase,
  AddCourseUsecase,
} from "../domain/AddCourse.usecase";

export type AddCourseFormInputs = {
  title: string;
  description?: string;
  cover_image_path: string;
  slug: string;
  status: string;
  level: string;
};

export type CourseInputs = {
  title: string;
  description: string;
  cover_image_path: string;
  video_url: string;
  duration_in_seconds: string;
};

export type SectionInputs = {
  title: string;
  courses: CourseInputs[];
};

export const AddCoursePage = () => {
  const navigate = useNavigate();

  const [sectionList, setSectionList] = useState<SectionInputs[]>([]);

  const toasterContext = UseToasterContext();
  const modalContext = UseModalContext();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AddCourseFormInputs>();

  const addCourseItems = async (sectionIndex: number, sectionId: number) => {
    try {
      await Promise.all(
        sectionList[sectionIndex].courses.map(async (course, index) =>
          AddCourseItemUsecase(
            sectionId,
            index + 1,
            course.title,
            course.description,
            course.cover_image_path,
            course.video_url,
            course.duration_in_seconds
          )
        )
      );

      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("Success to add course!", "", ToasterType.SUCCESS),
      ]);
      navigate("/courses");
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to add course item!",
          e.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const addCourseSections = (courseId: number) => {
    sectionList.forEach(async (section, sectionIndex) => {
      try {
        let result = await AddCourseSectionUsecase(
          courseId,
          sectionIndex + 1,
          section.title
        );
        addCourseItems(sectionIndex, result.id);
      } catch (e) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to add course section!",
            e.message,
            ToasterType.DANGER
          ),
        ]);
      }
    });
  };

  const onSubmit = async (data: AddCourseFormInputs) => {
    if (sectionList.length > 0 && sectionList[0].courses.length > 0) {
      try {
        let response = await AddCourseUsecase(
          data.title,
          data.description ?? "",
          data.cover_image_path,
          data.slug,
          data.level,
          "published"
        );
        addCourseSections(response.id);
      } catch (e) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to add course!",
            e.message,
            ToasterType.DANGER
          ),
        ]);
      }
    } else {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Course must have at least one section with video!",
          "",
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const onMediaSelected = (data: ResourceModel) => {
    setValue("cover_image_path", data.path);
    modalContext.setModal(null);
  };

  const onSelectMediaClicked = () => {
    let title = "Select Media";
    let description = (
      <MediaChooser type={"image"} onMediaSelected={onMediaSelected} />
    );
    let cancelButton = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(null, title, description, cancelButton, null)
    );
  };

  const onSelectMediaForCourseClicked = (
    sectionIndex: number,
    courseIndex: number
  ) => {
    let title = "Select Media";
    let description = (
      <MediaChooser
        type={"image"}
        onMediaSelected={(data) => {
          let sections = [...sectionList];
          sections[sectionIndex].courses[courseIndex].cover_image_path =
            data.path;
          setSectionList(sections);
          modalContext.setModal(null);
        }}
      />
    );
    let cancelButton = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(null, title, description, cancelButton, null)
    );
  };

  const onAddNewSection = () => {
    setSectionList([
      ...sectionList,
      {
        title: "",
        courses: [],
      },
    ]);
  };

  const onMoveSectionUp = (index: number) => {
    if (index > 0) {
      let mSectionList = [...sectionList];
      let temp = { ...mSectionList[index] };
      mSectionList[index] = { ...mSectionList[index - 1] };
      mSectionList[index - 1] = temp;

      setSectionList(mSectionList);
    }
  };

  const onMoveSectionDown = (index: number) => {
    if (index < sectionList.length - 1) {
      let mSectionList = [...sectionList];
      let temp = { ...mSectionList[index] };
      mSectionList[index] = { ...mSectionList[index + 1] };
      mSectionList[index + 1] = temp;

      setSectionList(mSectionList);
    }
  };

  const onAddNewCourse = (sectionIndex: number) => {
    let sections = [...sectionList];
    sections[sectionIndex].courses.push({
      title: "",
      cover_image_path: "",
      description: "",
      duration_in_seconds: "0",
      video_url: "",
    });
    setSectionList(sections);
  };

  const onMoveCourseUp = (sectionIndex: number, courseIndex: number) => {
    let sections = [...sectionList];

    if (courseIndex > 0) {
      let coursesList = [...sections[sectionIndex].courses];
      let temp = { ...coursesList[courseIndex] };
      coursesList[courseIndex] = { ...coursesList[courseIndex - 1] };
      coursesList[courseIndex - 1] = temp;

      sections[sectionIndex].courses = coursesList;

      setSectionList(sections);
    }
  };

  const onMoveCourseDown = (sectionIndex: number, courseIndex: number) => {
    let sections = [...sectionList];

    if (courseIndex < sections[sectionIndex].courses.length - 1) {
      let coursesList = [...sections[sectionIndex].courses];
      let temp = { ...coursesList[courseIndex] };
      coursesList[courseIndex] = { ...coursesList[courseIndex + 1] };
      coursesList[courseIndex + 1] = temp;

      sections[sectionIndex].courses = coursesList;

      setSectionList(sections);
    }
  };

  return (
    <div className="bg-white m-4">
      <ContentHeader
        title="Add New Course"
        rightButton={
          <button
            className="button-red-outline-with-hover"
            type="button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
        }
      />

      <form className="w-full mt-6" onSubmit={handleSubmit(onSubmit)}>
        {/* Cover Image */}
        <div className="w-full mt-4 flex">
          <div className="w-28 my-auto">Cover Image</div>
          <div className="input-field-full-width flex p-0">
            <input
              type="text"
              className="w-full outline-none p-1"
              {...register("cover_image_path")}
              disabled
              readOnly
            />
            <button
              className="rounded-r bg-gray-500 shadow text-white w-32"
              onClick={onSelectMediaClicked}
              type="button"
            >
              Select Image
            </button>
          </div>
        </div>

        {/* Title */}
        <div className="flex mt-4">
          <label className="font-medium my-auto w-28">
            Title<span className="text-red-500">*</span>
          </label>
          <div className="w-full">
            <input
              className={`input-field-full-width ${
                errors.title && "border-red-500"
              }`}
              type="text"
              {...register("title", {
                required: "Title is required",
              })}
            />
            {errors.title && (
              <p className="text-red-500 text-xs">{errors.title?.message}</p>
            )}
          </div>
        </div>

        {/* Description field */}
        <div className="flex mt-4">
          <label className="font-medium w-28 mt-1">Description</label>
          <div className="w-full">
            <input
              className="input-field-full-width"
              type="text"
              {...register("description")}
            />
            {errors.description && (
              <p className="text-red-500 text-xs">
                {errors.description?.message}
              </p>
            )}
          </div>
        </div>

        {/* Slug field */}
        <div className="flex mt-4">
          <label className="font-medium w-28 mt-1 my-auto">
            Slug<span className="text-red-500">*</span>
          </label>
          <div className="w-full">
            <input
              className="input-field-full-width"
              type={"text"}
              {...register("slug", {
                required: "Slug is required",
              })}
            />
            {errors.slug && (
              <p className="text-red-500 text-xs">{errors.slug?.message}</p>
            )}
          </div>
        </div>

        {/* Level field */}
        <div className="flex mt-4">
          <label className="font-medium w-28 mt-1 my-auto">
            Level<span className="text-red-500">*</span>
          </label>
          <div className="w-full">
            <select
              className="input-field-full-width"
              {...register("level", {
                required: "Level is required",
              })}
            >
              <option value="">Pilih Level</option>
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advance">Advance</option>
            </select>
            {errors.level && (
              <p className="text-red-500 text-xs">{errors.level?.message}</p>
            )}
          </div>
        </div>

        {/* Section and Course Field */}
        <div className="mt-6">
          <div className="flex justify-between">
            <div className="font-semibold my-auto">Sections</div>
            <button
              className="button-yellow-outline-with-hover"
              onClick={onAddNewSection}
              type={"button"}
            >
              Add Section
            </button>
          </div>
          {sectionList.map((item, sectionIndex) => {
            return (
              <div
                key={`section-${sectionIndex}`}
                className="bg-gray-400 p-4 rounded my-2"
              >
                {/* Section Title field */}
                <div className="flex mt-4">
                  <label className="font-medium w-28 mt-1">
                    Title<span className="text-red-500">*</span>
                  </label>
                  <div className="w-full">
                    <input
                      className="input-field-full-width"
                      value={item.title}
                      onChange={(e) => {
                        let newSectionList = [...sectionList];
                        newSectionList[sectionIndex].title = e.target.value;
                        setSectionList(newSectionList);
                      }}
                    />
                  </div>

                  {sectionIndex > 0 && (
                    <button
                      className="ml-4"
                      onClick={() => onMoveSectionUp(sectionIndex)}
                      type={"button"}
                    >
                      <FaArrowCircleUp size={24} />
                    </button>
                  )}
                  {sectionIndex < sectionList.length - 1 && (
                    <button
                      className="ml-4"
                      onClick={() => onMoveSectionDown(sectionIndex)}
                      type={"button"}
                    >
                      <FaArrowCircleDown size={24} />
                    </button>
                  )}
                  <button
                    type={"button"}
                    className="text-red-500 ml-4"
                    onClick={() => {
                      let newSectionList = [...sectionList];
                      newSectionList.splice(sectionIndex, 1);
                      setSectionList(newSectionList);
                    }}
                  >
                    <FaTrashAlt size={24} />
                  </button>
                </div>

                <div className="flex justify-between mt-4">
                  <div className="font-semibold">Courses</div>
                  <button
                    type={"button"}
                    className="button-yellow-outline-with-hover"
                    onClick={() => onAddNewCourse(sectionIndex)}
                  >
                    Add Course
                  </button>
                </div>

                {/* Course Field */}
                {item.courses.map((course, courseIndex) => {
                  return (
                    <div
                      key={`course-${sectionIndex}-${courseIndex}`}
                      className="bg-gray-200 p-4 rounded my-2"
                    >
                      {/* Course Title field */}
                      <div className="flex mt-4">
                        <label className="font-medium w-28 mt-1">
                          Title<span className="text-red-500">*</span>
                        </label>
                        <div className="w-full">
                          <input
                            className="input-field-full-width"
                            value={course.title}
                            onChange={(e) => {
                              let newSectionList = [...sectionList];
                              newSectionList[sectionIndex].courses[
                                courseIndex
                              ].title = e.target.value;
                              setSectionList(newSectionList);
                            }}
                          />
                        </div>

                        {courseIndex > 0 && (
                          <button
                            className="ml-4"
                            onClick={() =>
                              onMoveCourseUp(sectionIndex, courseIndex)
                            }
                            type={"button"}
                          >
                            <FaArrowCircleUp size={24} />
                          </button>
                        )}
                        {courseIndex < item.courses.length - 1 && (
                          <button
                            className="ml-4"
                            onClick={() =>
                              onMoveCourseDown(sectionIndex, courseIndex)
                            }
                            type={"button"}
                          >
                            <FaArrowCircleDown size={24} />
                          </button>
                        )}
                        <button
                          type={"button"}
                          className="text-red-500 ml-4"
                          onClick={() => {
                            let newSectionList = [...sectionList];
                            newSectionList[sectionIndex].courses.splice(
                              courseIndex,
                              1
                            );
                            setSectionList(newSectionList);
                          }}
                        >
                          <FaTrashAlt size={24} />
                        </button>
                      </div>

                      {/* Cover Image */}
                      <div className="w-full mt-4 flex">
                        <div className="w-28 my-auto">Cover Image</div>
                        <div className="input-field-full-width flex p-0">
                          <input
                            type="text"
                            className="w-full outline-none p-1"
                            value={course.cover_image_path}
                            disabled
                            readOnly
                          />
                          <button
                            className="rounded-r bg-gray-500 shadow text-white w-32"
                            onClick={() =>
                              onSelectMediaForCourseClicked(
                                sectionIndex,
                                courseIndex
                              )
                            }
                            type="button"
                          >
                            Select Image
                          </button>
                        </div>
                      </div>

                      {/* Video URL field */}
                      <div className="flex mt-4">
                        <label className="font-medium w-28 mt-1 my-auto">
                          Video URL
                        </label>
                        <div className="w-full">
                          <input
                            className="input-field-full-width"
                            type="url"
                            value={course.video_url}
                            onChange={(e) => {
                              let newSectionList = [...sectionList];
                              newSectionList[sectionIndex].courses[
                                courseIndex
                              ].video_url = e.target.value;
                              setSectionList(newSectionList);
                            }}
                          />
                        </div>
                      </div>

                      {/* Duration in Seconds field */}
                      <div className="flex mt-4">
                        <label className="font-medium w-28 mt-1 my-auto">
                          Duration (seconds)
                        </label>
                        <div className="w-full">
                          <input
                            className="input-field-full-width"
                            type={"number"}
                            value={course.duration_in_seconds}
                            onChange={(e) => {
                              let newSectionList = [...sectionList];
                              newSectionList[sectionIndex].courses[
                                courseIndex
                              ].duration_in_seconds = e.target.value;
                              setSectionList(newSectionList);
                            }}
                          />
                        </div>
                      </div>

                      {/* Description field */}
                      <div className="flex mt-4">
                        <label className="font-medium w-28 mt-1">
                          Description
                        </label>
                        <div className="w-full">
                          <input
                            className="input-field-full-width"
                            type="text"
                            value={course.description}
                            onChange={(e) => {
                              let newSectionList = [...sectionList];
                              newSectionList[sectionIndex].courses[
                                courseIndex
                              ].description = e.target.value;
                              setSectionList(newSectionList);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        <button
          className="button-yellow-outline-with-hover w-full mt-4"
          type="submit"
        >
          Publish
        </button>
      </form>
    </div>
  );
};
