import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { MediaChooser } from "@Pages/common/presentation/MediaChooser.page";
import { FC } from "react";

export enum MediaEnum {
  IMAGE,
  VIDEO,
  PDF,
}

export type SelectMediaInputProps = {
  media: MediaEnum;
  mediaLabel: string;
  value: string;
  onChange: (newValue: string) => void;
};

export const SelectMediaInputComponent: FC<SelectMediaInputProps> = ({
  media,
  mediaLabel,
  value,
  onChange,
}) => {
  const modalContext = UseModalContext();

  const onSelectMediaClicked = () => {
    let title = "Select Media";
    let description = (
      <MediaChooser
        type={
          media === MediaEnum.VIDEO
            ? "video"
            : media === MediaEnum.PDF
            ? "application/pdf"
            : "image"
        }
        onMediaSelected={(data) => {
          onChange(data.link_url);
          modalContext.setModal(null);
        }}
      />
    );
    let cancelButton = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(null, title, description, cancelButton, null)
    );
  };

  return (
    <div className="flex mt-4">
      <label className="font-medium my-auto w-32">{mediaLabel} Url</label>
      <input
        className={"input-field-full-width"}
        type="text"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      <button
        className="rounded-r bg-gray-500 shadow text-white w-32"
        onClick={onSelectMediaClicked}
        type="button"
      >
        Select {mediaLabel}
      </button>
    </div>
  );
};
