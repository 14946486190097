/* eslint-disable react-hooks/exhaustive-deps */
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import {
  ActivityModel,
  ContactModel,
} from "@Pages/get-response/data/contact.model";
import { ContactAcitivitiesUsecase } from "@Pages/get-response/domain/ContactList.usecase";
import { FC, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { PageControllerComponent } from "../components/PageController.component";
import { SectionProps } from "../GetResponse.page";

export type ContactItemProps = {
  data: ContactModel;
};

export type ContactSectionProps = SectionProps & {
  data: ContactModel[];
};

export const ContactItem: FC<ContactItemProps> = ({ data }) => {
  const [page, setPage] = useState(1);
  const [activities, setActivities] = useState<ActivityModel[]>([]);

  useEffect(() => {
    async function fetchData() {
      let response = await ContactAcitivitiesUsecase(data.id, page);
      setActivities(response);
    }
    fetchData();
  }, [page]);

  return (
    <div>
      <div className="p-4 grid grid-cols-2 gap-4">
        <div>Name</div>
        <div>{data.name}</div>

        <div>Email</div>
        <div>{data.email}</div>

        <div>Campaign</div>
        <div>{data.campaign.name}</div>

        <div>Engagement Score</div>
        <div>{data.engagementScore}</div>

        <div>Origin</div>
        <div>{data.origin}</div>

        <div>Created On</div>
        <div>{data.createdOn}</div>

        <div>Changed On</div>
        <div>{data.changedOn}</div>
      </div>

      <table className={`border-collapse border w-full table-auto mt-4`}>
        <thead className="p-2 shadow-sm bg-gray-100 sticky">
          <tr>
            <th className="border border-spacing-1 text-center font-bold px-2">
              Activity
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 text-ellipsis">
              Subject
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2">
              Created On
            </th>
          </tr>
        </thead>
        <tbody>
          {activities.map((item, index) => (
            <tr key={`contact-activity-row-${index}`}>
              <td className="border px-2 text-center">{item.activity}</td>
              <td className="border px-2 text-left">
                <a href={item.previewUrl} target="_blank" rel="noreferrer">
                  {item.subject}
                </a>
              </td>
              <td className="border px-2">{item.createdOn}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-end m-4">
        {page > 1 && (
          <button
            className="button-yellow-outline-with-hover p-2"
            onClick={() => setPage(page - 1)}
          >
            &lt;
          </button>
        )}
        <div className="my-auto mx-4">Halaman {page}</div>
        {activities.length >= 100 && (
          <button
            className="button-yellow-outline-with-hover p-2"
            onClick={() => setPage(page + 1)}
          >
            &gt;
          </button>
        )}
      </div>
    </div>
  );
};

export const ContactSection: FC<ContactSectionProps> = ({
  data,
  page,
  setPage,
}) => {
  const modalContext = UseModalContext();

  const showDetail = (index: number) => {
    let title = data[index].id;
    let description = <ContactItem data={data[index]} />;
    let primaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Close
      </button>
    );

    modalContext.setModal(
      createModal(null, title, description, primaryCta, null)
    );
  };

  return (
    <div className="mt-4">
      <table className={`border-collapse border w-full table-auto`}>
        <thead className="p-2 shadow-sm bg-gray-100 sticky">
          <tr>
            <th className="border border-spacing-1 text-center font-bold px-2">
              ID
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 text-ellipsis">
              Name
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 hidden md:table-cell">
              Email
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={`campaign-row-${item.id}-${index}`}>
              <td className="border px-2 text-center">{item.id}</td>
              <td className="border px-2 text-left">
                {item.name}
                <div>
                  <button onClick={() => showDetail(index)}>
                    <FaEye size={20} className="text-blue-400" />
                  </button>
                </div>
              </td>
              <td className="border px-2 hidden md:table-cell">{item.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <PageControllerComponent
        currentPage={page}
        showPrev={page > 1}
        showNext={data.length >= 100}
        onPrev={setPage}
        onNext={setPage}
      />
    </div>
  );
};
