import { ProductModel } from "@Pages/product/data/product.model";
import { FC, useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaPlus,
  FaRegTrashAlt,
} from "react-icons/fa";
import {
  MediaEnum,
  SelectMediaInputComponent,
} from "./forms/SelectMediaInput.component";

export type CarouselInputModel = {
  productId?: string;
  imageUrl: string;
  altText: string;
};

export type CarouselInputProps = {
  index: number;
  carousel: CarouselInputModel;
  isNeedProduct: boolean;
  products: ProductModel[];
  onChange: (index: number, carousel: CarouselInputModel) => void;
  onDelete: (index: number) => void;
};

export type CarouselFormProps = {
  title: string;
  max?: number;
  isNeedProduct: boolean;
  products: ProductModel[];
  carouselList: CarouselInputModel[];
  onSaveCarousel: (carouselList: CarouselInputModel[]) => void;
};

export const CarouselItem: FC<CarouselInputProps> = ({
  index,
  carousel,
  isNeedProduct,
  products,
  onChange,
  onDelete,
}) => {
  return (
    <div className="my-2 rounded-xl bg-slate-300 border shadow p-4">
      <div className="mt-4 flex justify-end">
        <button
          className="button-red-outline-with-hover"
          onClick={() => onDelete(index)}
        >
          <FaRegTrashAlt />
        </button>
      </div>
      {isNeedProduct && (
        <div className="flex mt-4">
          <label className="font-medium my-auto w-32">Select Product</label>
          <select
            className={"input-field-full-width"}
            value={carousel.productId}
            onChange={(e) => {
              let newCarousel = { ...carousel };
              newCarousel.productId = e.target.value;
              onChange(index, newCarousel);
            }}
          >
            <option value="">Pilih Produk</option>
            {products.map((item) => (
              <option
                key={item.id}
                value={item.id}
              >{`${item.title} - ${item.duration_length} ${item.duration_type}`}</option>
            ))}
          </select>
        </div>
      )}
      <SelectMediaInputComponent
        media={MediaEnum.IMAGE}
        mediaLabel="Image"
        value={carousel.imageUrl}
        onChange={(url) => {
          let newCarousel = { ...carousel };
          newCarousel.imageUrl = url;
          onChange(index, newCarousel);
        }}
      />
      <div className="flex mt-4">
        <label className="font-medium my-auto w-32">Alternative Text</label>
        <input
          className={"input-field-full-width"}
          type="text"
          value={carousel.altText}
          onChange={(e) => {
            let newCarousel = { ...carousel };
            newCarousel.altText = e.target.value;
            onChange(index, newCarousel);
          }}
        />
      </div>
    </div>
  );
};

export const CarouselFormComponent: FC<CarouselFormProps> = ({
  title,
  max,
  isNeedProduct,
  products,
  carouselList,
  onSaveCarousel,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [mCarouselList, setMCarouselList] =
    useState<CarouselInputModel[]>(carouselList);

  const onAddNew = () => {
    let newCarouselList = [...mCarouselList];
    newCarouselList.splice(0, 0, {
      imageUrl: "",
      altText: "",
    });

    setMCarouselList(newCarouselList);
  };

  const onDelete = (index: number) => {
    let newCarouselList = [...mCarouselList];
    newCarouselList.splice(index, 1);
    setMCarouselList(newCarouselList);
  };

  const onChange = (index: number, carousel: CarouselInputModel) => {
    let newCarouselList = [...mCarouselList];
    newCarouselList[index] = carousel;
    setMCarouselList(newCarouselList);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div
          className="font-bold text-3xl w-full"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {title}
        </div>

        <div className="flex">
          {!isCollapsed &&
            (max === undefined || (max && mCarouselList.length < max)) && (
              <button
                className="button-yellow-outline-with-hover mr-2"
                onClick={onAddNew}
              >
                <FaPlus />
              </button>
            )}
          {!isCollapsed && (
            <button
              className="button-yellow-outline-with-hover"
              onClick={() => onSaveCarousel(mCarouselList)}
            >
              Save
            </button>
          )}
          <button
            className="ml-4 mx-auto"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </div>
      </div>

      {!isCollapsed && mCarouselList.length === 0 && (
        <div className="rounded bg-gray-400 text-center mt-4 p-4">
          {title} Empty
        </div>
      )}

      {!isCollapsed &&
        mCarouselList.map((item, index) => {
          return (
            <CarouselItem
              key={`carousel-${title}-${index}`}
              index={index}
              carousel={item}
              onChange={onChange}
              onDelete={onDelete}
              isNeedProduct={isNeedProduct}
              products={products}
            />
          );
        })}
    </div>
  );
};
