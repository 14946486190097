/* eslint-disable react-hooks/exhaustive-deps */
import { ProductModel } from "@Pages/product/data/product.model";
import { FC, useEffect, useState } from "react";

export type SelectedProducts = {
  productId: string;
  productName: string;
  productDuration: string;
  productBenefit: string;
  productPrice: string;
  slashPrice: string;
  isFeatured: boolean;
};

export type SelectedProductsFormProps = {
  selectedProducts: SelectedProducts[];
  listOfProducts: ProductModel[];
  onSelectedProductChanged: (selectedProducts: SelectedProducts[]) => void;
};

export type ProductFormInputProps = {
  index: number;
  detail: SelectedProducts;
  productList: ProductModel[];
  onSelectedProductChanged: (selectedProduct: SelectedProducts) => void;
};

export const ProductFormItemComponent: FC<ProductFormInputProps> = ({
  index,
  detail,
  productList,
  onSelectedProductChanged,
}) => {
  const getProductDetail = () => {
    return productList.find((item) => detail.productId === item.id.toString());
  };

  const [selectedProductDetail, setSelectedProductDetail] = useState<
    ProductModel | undefined
  >(getProductDetail());

  useEffect(() => {
    let newDetail = getProductDetail();
    setSelectedProductDetail(newDetail);
  }, [detail]);

  return (
    <div
      key={"SelectProduct-" + index}
      className="my-2 rounded-xl bg-slate-300 border shadow p-4"
    >
      <div className="flex mt-2">
        <label className="font-medium my-auto w-32">Select Product</label>
        <select
          className={"input-field-full-width"}
          value={detail.productId}
          onChange={(e) => {
            if (detail.productId !== e.target.value) {
              let productDetail = productList.find(
                (item) => e.target.value === item.id.toString()
              );

              let newDetail = { ...detail };
              newDetail.productId = e.target.value;
              newDetail.productName = `${productDetail?.title}`;
              newDetail.productDuration = `${productDetail?.duration_length} ${productDetail?.duration_type}`;
              newDetail.productPrice = `${productDetail?.price_str}`;

              onSelectedProductChanged(newDetail);
            }
          }}
        >
          <option value="">Pilih Produk</option>
          {productList.map((item) => (
            <option
              key={item.id}
              value={item.id}
            >{`${item.title} - ${item.duration_length} ${item.duration_type}`}</option>
          ))}
        </select>
      </div>

      {detail.productId !== "" && (
        <div className="mt-4">
          <input
            className={"input-field-full-width"}
            type="text"
            value={selectedProductDetail?.title}
            onChange={() => {}}
            readOnly
            disabled
          />

          <input
            className={"input-field-full-width mt-4 mb-4"}
            type="text"
            value={`per ${selectedProductDetail?.duration_length} ${selectedProductDetail?.duration_type}`}
            onChange={() => {}}
            readOnly
            disabled
          />

          <input
            className={"input-field-full-width mt-4 mb-4"}
            type="text"
            value={`${selectedProductDetail?.price_str}`}
            onChange={() => {}}
            readOnly
            disabled
          />

          <label className="font-medium">Benefits (separate by comma)</label>
          <input
            className={"input-field-full-width"}
            type="text"
            value={detail.productBenefit}
            onChange={(e) => {
              let newDetail = { ...detail };
              newDetail.productBenefit = e.target.value;
              onSelectedProductChanged(newDetail);
            }}
          />

          <div className="flex mt-4">
            <label className="font-medium my-auto w-32">Slash Price</label>
            <input
              className={"input-field-full-width"}
              type="text"
              value={detail.slashPrice}
              onChange={(e) => {
                let newDetail = { ...detail };
                newDetail.slashPrice = e.target.value;
                onSelectedProductChanged(newDetail);
              }}
            />
          </div>

          <div className="flex justify-between mt-4">
            <input
              className={"input-field-full-width w-fit mr-4"}
              type="checkbox"
              checked={detail.isFeatured}
              onChange={(e) => {
                let newDetail = { ...detail };
                newDetail.isFeatured = e.target.checked;
                onSelectedProductChanged(newDetail);
              }}
            />
            <label className="font-medium my-auto w-full">Is Featured</label>
          </div>
        </div>
      )}
    </div>
  );
};

export const SelectedProductsFormComponent: FC<SelectedProductsFormProps> = ({
  selectedProducts,
  listOfProducts,
  onSelectedProductChanged,
}) => {
  const [mSelectedProducts, setMSelectedProducts] =
    useState<SelectedProducts[]>(selectedProducts);

  useEffect(() => {
    let newSelected = [...mSelectedProducts];
    let changeExists = false;

    selectedProducts.forEach((product, index) => {
      if (product.productId !== mSelectedProducts[index].productId) {
        newSelected[index] = product;
        changeExists = true;
      }
    });

    if (changeExists) {
      setMSelectedProducts(newSelected);
    }
  }, [selectedProducts]);

  const buildFormView = (index: number) => {
    let availableProducts = listOfProducts.filter((item) => {
      let isSelected = mSelectedProducts.some(
        (selected, mIndex) =>
          mIndex !== index && selected.productId === item.id.toString()
      );

      return isSelected !== true;
    });

    return (
      <ProductFormItemComponent
        key={`FormProduct-${index}`}
        detail={mSelectedProducts[index]}
        index={index}
        productList={availableProducts}
        onSelectedProductChanged={(selectedProduct) => {
          let newSelected = [...mSelectedProducts];
          newSelected[index] = selectedProduct;
          setMSelectedProducts(newSelected);
        }}
      />
    );
  };

  return (
    <div className="mt-2">
      <div className="flex justify-between">
        <div className="font-bold text-3xl">Featured Products</div>

        <button
          className="button-yellow-outline-with-hover"
          onClick={() => onSelectedProductChanged(mSelectedProducts)}
        >
          Save
        </button>
      </div>
      {buildFormView(0)}
      {buildFormView(1)}
      {buildFormView(2)}
    </div>
  );
};
