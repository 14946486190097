import { ContentHeader } from "@Components/ContentHeader.component";
import { Tabs } from "@Components/tabs/Tabs.component";
import { useEffect, useState } from "react";
import { GetAffiliateUsersUsecase } from "../domain/GetAffiliateUsers.usecase";
import { AffiliateUserModel } from "../data/AffiliateUser.model";
import { AffiliateUsersComponent } from "./components/AffiliateUsers.component";
import { GetAffiliateWithdrawRequestsUseCase } from "../domain/GetAffiliateWithdrawRequests.usecase";
import { AffiliateWithdrawModel } from "../data/AffiliateWithdraw.model";
import { AffiliateWithdrawComponent } from "./components/AffiliateWithdraws.component";

export const AffiliatePage = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [needRefresh, setNeedRefresh] = useState(true);
  const [affiliateUsers, setAffiliateUsers] = useState<AffiliateUserModel[]>();
  const [withdrawRequests, setWithdrawRequests] =
    useState<AffiliateWithdrawModel[]>();

  const [withdrawStatusFilter, setWithdrawStatusFilter] = useState<string>();

  useEffect(() => {
    if (needRefresh) {
      fetchAffiliateUsers();
      fetchWithdrawRequests();
      setNeedRefresh(false);
    }
  }, [needRefresh]);

  async function fetchAffiliateUsers() {
    let responseData = await GetAffiliateUsersUsecase();
    setAffiliateUsers(responseData);
  }

  async function fetchWithdrawRequests(status?: string) {
    let responseData = await GetAffiliateWithdrawRequestsUseCase(status);
    setWithdrawRequests(responseData);
  }

  const onChangeTab = (index: number) => {
    setSelectedIndex(index);
  };

  const onSearchWithdrawRequest = (status: string) => {
    fetchWithdrawRequests(status);
    setWithdrawStatusFilter(status);
  };

  const onSearchAffiliateUser = (event: any) => {};

  const onWithdrawRequestStatusChange = (index: number, status: string) => {
    if (withdrawRequests) {
      let currentData = [...withdrawRequests];
      currentData[index] = {
        ...withdrawRequests[index],
        status: status,
      };

      setWithdrawRequests(currentData);
    }
  };

  const buildViewTab = () => {
    switch (selectedIndex) {
      case 0:
        return (
          <AffiliateWithdrawComponent
            withdrawRequests={withdrawRequests}
            statusFilter={withdrawStatusFilter ?? ""}
            onFilterStatus={onSearchWithdrawRequest}
            onStatusChanged={onWithdrawRequestStatusChange}
          />
        );
      case 1:
        return (
          <AffiliateUsersComponent
            affiliateUsers={affiliateUsers}
            onSearching={onSearchAffiliateUser}
          />
        );
    }
  };

  return (
    <div className="h-full overflow-auto m-4">
      <>
        <ContentHeader title={"Affiliate"} />

        <div className="mt-4">
          <Tabs
            tabItems={["Withdraw Requests", "Affiliate Users"]}
            selectedIndex={selectedIndex}
            onClickTab={onChangeTab}
          />
          <div className="border border-black rounded-b-xl shadow-2xl p-4 bg-white">
            {buildViewTab()}
          </div>
        </div>
      </>
    </div>
  );
};
