import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { DividerModel } from "../presentation/models/divider.model";
import { TextModel } from "../presentation/models/text.model";
import { VideoModel } from "../presentation/models/video.model";
import { ImageModel } from "../presentation/models/image.model";
import { SpacerModel } from "../presentation/models/spacer.model";
import { HeaderModel } from "../presentation/models/header.model";
import { BulletModel } from "../presentation/models/bullet.model";
import { PostDetailModel } from "../presentation/models/post-detail.model";
import { DisclaimerModel } from "../presentation/models/disclaimer.model";

export type BaseComponentModel =
  | HeaderModel
  | TextModel
  | PostDetailModel
  | DisclaimerModel
  | BulletModel
  | VideoModel
  | DividerModel
  | ImageModel
  | SpacerModel;

export type EditorSidebarContent = {
  components: BaseComponentModel[];
  setComponents: (components: BaseComponentModel[]) => void;
};

export const EditorSidebarContext = createContext<EditorSidebarContent>({
  components: [],
  setComponents: () => [],
});

export const EditorSidebarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [components, setComponents] = useState<BaseComponentModel[]>([]);
  const value = { components, setComponents };

  return (
    <EditorSidebarContext.Provider value={value}>
      {children}
    </EditorSidebarContext.Provider>
  );
};

export const UseEditorSidebarContext = () => {
  return useContext(EditorSidebarContext);
};
