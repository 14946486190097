import { PATH_RECORDS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const DeleteRecordUsecase: (id?: number) => Promise<boolean> = async (
  id
) => {
  try {
    if (!id) throw new Error("Id cannot be empty");
    await client.delete(PATH_RECORDS + "/" + id);
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
