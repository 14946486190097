import { FC, useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaPlus,
  FaRegTrashAlt,
} from "react-icons/fa";

export type FaqInputModel = {
  question: string;
  answer: string;
};

export type FaqInputProps = {
  index: number;
  faq: FaqInputModel;
  onChange: (index: number, faq: FaqInputModel) => void;
  onDelete: (index: number) => void;
};

export type FaqFormProps = {
  faqList: FaqInputModel[];
  onSaveFaq: (faqList: FaqInputModel[]) => void;
};

export const FaqItem: FC<FaqInputProps> = ({
  index,
  faq,
  onChange,
  onDelete,
}) => {
  return (
    <div className="my-2 rounded-xl bg-slate-300 border shadow p-4">
      <div className="mt-4 flex justify-end">
        <button
          className="button-red-outline-with-hover"
          onClick={() => onDelete(index)}
        >
          <FaRegTrashAlt />
        </button>
      </div>
      <div className="flex mt-4">
        <label className="font-medium my-auto w-32">Question</label>
        <input
          className={"input-field-full-width"}
          type="text"
          value={faq.question}
          onChange={(e) => {
            let newFaq = { ...faq };
            newFaq.question = e.target.value;
            onChange(index, newFaq);
          }}
        />
      </div>

      <div className="mt-4">
        <label className="font-medium my-auto w-32">Answer</label>
        <textarea
          className={"input-field-full-width"}
          value={faq.answer}
          onChange={(e) => {
            let newFaq = { ...faq };
            newFaq.answer = e.target.value;
            onChange(index, newFaq);
          }}
        />
      </div>
    </div>
  );
};

export const FaqFormComponent: FC<FaqFormProps> = ({ faqList, onSaveFaq }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [mFaqList, setMFaqList] = useState<FaqInputModel[]>(faqList);

  const onAddNew = () => {
    let newFaq = [...mFaqList];
    newFaq.push({
      question: "",
      answer: "",
    });
    setMFaqList(newFaq);
  };

  const onDelete = (index: number) => {
    let newFaq = [...mFaqList];
    newFaq.splice(index, 1);
    setMFaqList(newFaq);
  };

  const onChange = (index: number, data: FaqInputModel) => {
    let newData = [...mFaqList];
    newData[index] = data;
    setMFaqList(newData);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div
          className="font-bold text-3xl w-full"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          Frequently Asked Questions
        </div>

        <div className="flex">
          {!isCollapsed && (
            <button
              className="button-yellow-outline-with-hover mr-2"
              onClick={onAddNew}
            >
              <FaPlus />
            </button>
          )}
          {!isCollapsed && (
            <button
              className="button-yellow-outline-with-hover"
              onClick={() => onSaveFaq(mFaqList)}
            >
              Save
            </button>
          )}
          <button
            className="ml-4 mx-auto"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </div>
      </div>

      {!isCollapsed && mFaqList.length === 0 && (
        <div className="rounded bg-gray-400 text-center mt-4 p-4">
          Frequently Asked Question Empty
        </div>
      )}

      {!isCollapsed &&
        mFaqList.map((item, index) => {
          return (
            <FaqItem
              key={`faq-${index}`}
              index={index}
              faq={item}
              onChange={onChange}
              onDelete={onDelete}
            />
          );
        })}
    </div>
  );
};
