/* eslint-disable react-hooks/exhaustive-deps */
import { BottomListComponent } from "@Components/BottomList.component";
import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { ContentHeader } from "@Components/ContentHeader.component";
import { SearchInputComponent } from "@Components/inputs/SearchInputs.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { convertDateFromApiToView } from "@Helpers/formatter";
import { REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE } from "@Helpers/regex";
import { buildQueryParamsFromUrl, buildUrlSearchParams } from "@Helpers/utils";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { GoGraph } from "react-icons/go";
import { FaExclamationTriangle, FaRegTrashAlt } from "react-icons/fa";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CourseModel, ListCoursesModel } from "../data/course.model";
import { DeleteCourseUsecase } from "../domain/DeleteCourse.usecase";
import { GetAllCoursesUsecase } from "../domain/GetAllCourses.usecase";
import { CourseStatisticsModal } from "./components/CourseStatistics.modal";

export const CoursesPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const modalContext = UseModalContext();
  const toasterContext = UseToasterContext();

  const searchRef = useRef() as MutableRefObject<HTMLInputElement>;

  const [needRefetch, setNeedRefetch] = useState(true);
  const [apiData, setApiData] = useState<ListCoursesModel>();

  useEffect(() => {
    if (needRefetch) {
      async function fetchData() {
        let queryParams = buildQueryParamsFromUrl(searchParams.entries());

        if (queryParams !== "") {
          queryParams = "?" + queryParams;
        }

        try {
          let response = await GetAllCoursesUsecase(queryParams);
          setApiData(response);
          setNeedRefetch(false);
        } catch (errors) {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Failed to fetch courses list",
              errors.message,
              ToasterType.DANGER
            ),
          ]);
        }
      }
      fetchData();
    }
  }, [needRefetch]);

  const onDelete = async (index: number) => {
    try {
      await DeleteCourseUsecase(apiData?.list[index].id ?? 0);

      setNeedRefetch(true);
      modalContext.setModal(null);
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to delete record",
          e.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const onShowDeleteModal = (index: number) => {
    let icon = (
      <div className="text-red-500">
        <FaExclamationTriangle size={48} />
      </div>
    );
    let title = "Hapus Course";
    let description = (
      <>
        Apakah anda yakin ingin menghapus{" "}
        <span className="font-bold">{apiData?.list[index].title}</span> ?
      </>
    );
    let primaryCta = (
      <button
        className="button-red-outline-with-hover"
        onClick={() => onDelete(index)}
      >
        Hapus
      </button>
    );
    let secondaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(icon, title, description, primaryCta, secondaryCta)
    );
  };

  const canGoPrev = () => {
    return (apiData && apiData.meta && apiData.meta.page > 1) ?? false;
  };

  const onPrevPage = () => {
    if (apiData && apiData.meta && canGoPrev()) {
      let prevPage = apiData.meta.page - 1;
      if (prevPage >= 1) {
        setNeedRefetch(true);
        navigate({
          search:
            "?" +
            buildUrlSearchParams(
              searchParams.entries(),
              "page",
              prevPage.toString()
            ),
        });
      }
    }
  };

  const canGoNext = () => {
    return (
      (apiData && apiData.meta && apiData.meta.end < apiData.meta.total) ??
      false
    );
  };

  const onNextPage = () => {
    if (apiData && apiData.meta && canGoNext()) {
      let nextPage = apiData.meta.page + 1;
      setNeedRefetch(true);
      navigate({
        search:
          "?" +
          buildUrlSearchParams(
            searchParams.entries(),
            "page",
            nextPage.toString()
          ),
      });
    }
  };

  const onChangeLimit = (newValue: string) => {
    setNeedRefetch(true);
    navigate({
      search:
        "?" + buildUrlSearchParams(searchParams.entries(), "limit", newValue),
    });
  };

  const handleSearch = (e: any) => {
    if (e.key === "Enter") {
      let clearedString = e.target.value
        .replace(REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE, "")
        .replaceAll(" ", "+");

      setNeedRefetch(true);
      navigate({
        search:
          "?" +
          buildUrlSearchParams(searchParams.entries(), "search", clearedString),
      });
    }
  };

  const showStatistics = (course: CourseModel) => {
    let description = (
      <CourseStatisticsModal course={course} />
    );
    let cancelButton = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Tutup
      </button>
    );

    modalContext.setModal(
      createModal(null, undefined, description, cancelButton, null)
    );
  };

  return (
    <div className="m-4">
      <ContentHeader
        title={"Course List"}
        rightButton={
          <YellowHoverButton label={"Add New"} destination="/courses/add" />
        }
      />
      <div className="h-6"></div>

      {/* top config */}
      <div className="border p-3 align-middle flex flex-col justify-between shadow-sm md:flex-row">
        <div></div>

        <div className="border rounded mt-2 md:mt-0 flex">
          <SearchInputComponent
            searchRef={searchRef}
            onKeyDown={handleSearch}
          />
        </div>
      </div>

      {/* list items */}
      <div className="mt-4">
        {!apiData && <MidasShimmering />}

        {apiData && apiData.list.length === 0 && (
          <div className="text-center bg-gray-300 p-4">
            No Course Available!
          </div>
        )}

        {apiData && apiData.list.length > 0 && (
          <table className="table-auto border w-full">
            <thead className="p-2 shadow-sm bg-gray-100 sticky">
              <tr>
                <th></th>
                <th>Title</th>
                <th className="hidden md:table-cell">Author</th>
                <th className="hidden md:table-cell">Date</th>
                <th className="hidden md:table-cell">Status</th>
              </tr>
            </thead>
            <tbody>
              {apiData.list.map((item, index) => (
                <tr key={item.id}>
                  <td className="text-center">{index + 1}</td>
                  <td className="p-2 flex">
                    <img
                      src={item.cover_image_url}
                      className="object-cover aspect-square rounded-xl"
                      alt=""
                      width={100}
                      height={100}
                    />
                    <div className="pl-3">
                      <div className="font-bold text-ellipsis">
                        {item.title}
                      </div>
                      <div className="flex mt-3">
                        <Link to={"/courses/detail/" + item.slug}>
                          <button
                            className="text-blue-400 mr-2 w-6 h-6"
                            title="Edit"
                          >
                            <BsPencilSquare />
                          </button>
                        </Link>
                        <button
                          className="text-blue-400 mr-2 w-6 h-6"
                          title="Statistics"
                          onClick={() => showStatistics(item)}
                        >
                          <GoGraph />
                        </button>
                        <button
                          className="text-red-400 mr-2 w-6 h-6"
                          title="Delete Permanently"
                          onClick={() => onShowDeleteModal(index)}
                        >
                          <FaRegTrashAlt />
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="text-center hidden md:table-cell">
                    {item.created_by.fullname}
                  </td>
                  <td className="text-center hidden md:table-cell">
                    {convertDateFromApiToView(item.created_at)}
                  </td>
                  <td className="text-center hidden md:table-cell">
                    {item.deleted_at != null ? (
                      <div className="text-red-500">In Trash</div>
                    ) : item.status === "published" ? (
                      <div className="text-green-500">Published</div>
                    ) : item.status === "draft" ? (
                      <div className="text-orange-500">Draft</div>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {apiData && apiData.meta && (
          <BottomListComponent
            shouldShow={apiData.meta !== undefined}
            onLimitChange={onChangeLimit}
            canGoPrev={canGoPrev()}
            onGoToPrev={onPrevPage}
            canGoNext={canGoNext()}
            onGoToNext={onNextPage}
            meta={apiData.meta}
          />
        )}
      </div>
    </div>
  );
};
