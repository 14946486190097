import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { EmitenDetailModel, EmitenSectorModel, StockModel } from "@Pages/setting/data/emiten_by_sector.model";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaChartBar, FaChevronDown, FaChevronUp, FaPencilAlt, FaTable } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";

export type EmitenSectorProps = EmitenSectorModel & {
    updateEmitenDetail: (index: number, emitenDetail: EmitenDetailModel) => void;
    onChangeSectorData: (index: number, data: EmitenSectorModel) => void;
    onDeleteSector: (index: number) => void;
    onDeleteEmitenFromSector: (index: number, emitenCode: string) => void;
    stockList: StockModel[];
    index: number;
}

export const EmitenSectorComponent: FC<EmitenSectorProps> = ({
    title, isTable, emitenList, updateEmitenDetail, stockList, index,
    onDeleteSector, onDeleteEmitenFromSector, onChangeSectorData
}) => {
    const modalContext = UseModalContext();

    const [isCollapsed, setIsCollapsed] = useState(true);

    const showAddEditModal = (emitenDetail: EmitenDetailModel | null) => {
        let modalTitle = `${emitenDetail !== null ? "Edit Emiten" : `Tambah Emiten di Sektor ${title}`}`;
        let modalContent = <AddEmitenModalComponent
            emitenDetail={emitenDetail}
            codeList={stockList}
            onClose={() => modalContext.setModal(null)}
            onSave={(emiten: EmitenDetailModel) => {
                let codeName = emiten.code.split("::");
                let emitenToAdd: EmitenDetailModel = {
                    ...emiten,
                    code: codeName[0],
                    name: codeName[1],
                }

                updateEmitenDetail(index, emitenToAdd);
                modalContext.closeModal();
            }} />;

        modalContext.setModal(
            createModal(null, modalTitle, modalContent, null, null)
        );
    }

    const showDeleteModal = () => {
        let modalTitle = `Hapus Sektor`;
        let modalContent = <div className="text-center">Apakah Anda yakin ingin menghapus sektor <span className="font-bold">{title}</span>?</div>
        let primaryButton = <button className="button-red-outline-with-hover" onClick={() => {
            onDeleteSector(index)
            modalContext.closeModal()
        }}>Hapus</button>
        let secondaryButton = <button className="button-outline-with-hover" onClick={() => modalContext.closeModal()}>Batal</button>

        modalContext.setModal(
            createModal(
                null,
                modalTitle,
                modalContent,
                primaryButton,
                secondaryButton
            )
        )
    }

    const showDeleteEmitenModal = (emitenDetail: EmitenDetailModel) => {
        let modalTitle = `Hapus Sektor`;
        let modalContent = <div className="text-center">Apakah Anda yakin ingin menghapus emiten <span className="font-bold">{emitenDetail.name}</span> dari sektor <span className="font-bold">{title}</span>?</div>
        let primaryButton = <button className="button-red-outline-with-hover" onClick={() => {
            onDeleteEmitenFromSector(index, emitenDetail.code)
            modalContext.closeModal()
        }}>Hapus</button>
        let secondaryButton = <button className="button-outline-with-hover" onClick={() => modalContext.closeModal()}>Batal</button>

        modalContext.setModal(
            createModal(
                null,
                modalTitle,
                modalContent,
                primaryButton,
                secondaryButton
            )
        )
    }

    return (
        <div className="mt-4 border rounded p-4">
            <div className="flex justify-between">
                <div className="w-full text-lg font-bold my-auto"
                    onClick={() => setIsCollapsed(!isCollapsed)}>
                    {title}
                </div>
                <div className="flex">
                    {!isCollapsed && (
                        <div className="button-yellow-outline-with-hover mr-2 m-auto p-3" onClick={() => {
                            onChangeSectorData(index, {
                                title: title,
                                emitenList: emitenList,
                                isTable: !isTable
                            });
                        }}>
                            {isTable && <FaTable />}
                            {!isTable && <FaChartBar />}
                        </div>
                    )}
                    {!isCollapsed && (<button className="button-yellow-outline-with-hover mr-2"
                        type="button"
                        onClick={() => showAddEditModal(null)}>+</button>
                    )}
                    {!isCollapsed && (<button className="button-red-outline-with-hover mr-2"
                        type="button"
                        onClick={showDeleteModal}><MdDeleteForever /></button>
                    )}
                    <button
                        className="m-auto"
                        type={"button"}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
                    </button>
                </div>
            </div>

            {!isCollapsed && <div className="mt-2">
                <table className="table-auto border w-full">
                    <thead className="p-2 shadow-sm bg-gray-100 sticky">
                        <tr>
                            <th className="border-l border-t border-b border-black">No</th>
                            <th className="border-l border-t border-b border-black">Stock</th>
                            <th className="border-l border-t border-b border-black hidden md:table-cell">ID Sheet</th>
                            <th className="border-l border-t border-b border-black hidden md:table-cell">Bar Column</th>
                            <th className="border-l border-t border-b border-black hidden md:table-cell">Line Column</th>
                            <th className="border-l border-t border-b border-black hidden md:table-cell">Row Start</th>
                            <th className="border-l border-t border-b border-r border-black"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(emitenList).map((key, index) => {
                            let emitenData = emitenList[key];

                            return (<tr key={`item-${key}-${index}`}>
                                <td className="border-b text-center p-2">{index + 1}</td>
                                <td className="border-b text-center">{emitenData.code} - {emitenData.name}</td>
                                <td className="border-b text-center hidden md:table-cell"><a href={`https://docs.google.com/spreadsheets/d/${emitenData.idSheet}`} target="_blank" rel="noreferrer" className="text-blue-500">{emitenData.idSheet}</a></td>
                                <td className="border-b text-center hidden md:table-cell">{emitenData.barChartIndexColumn}</td>
                                <td className="border-b text-center hidden md:table-cell">{emitenData.lineChartIndexColumn}</td>
                                <td className="border-b text-center hidden md:table-cell">{emitenData.rowStartIndex}</td>
                                <td className="border-b">
                                    <div className="flex justify-center">
                                        <button onClick={() => showAddEditModal(emitenData)}><FaPencilAlt className="text-blue-500" size={18} /></button>
                                        <button onClick={() => showDeleteEmitenModal(emitenData)}><MdDeleteForever className="text-red-500" size={24} /></button>
                                    </div>
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>}
        </div>
    )
}

export type AddEmitenProps = {
    emitenDetail: EmitenDetailModel | null;
    codeList: StockModel[];
    onClose: VoidFunction;
    onSave: (data: EmitenDetailModel) => void;
}

export const AddEmitenModalComponent: FC<AddEmitenProps> = ({ emitenDetail, codeList, onClose, onSave }) => {
    const { register, handleSubmit, setValue, formState: { errors }, } = useForm<EmitenDetailModel>();

    useEffect(() => {
        if (emitenDetail !== null) {
            setValue("code", `${emitenDetail.code}::${emitenDetail.name}`)
            setValue("name", emitenDetail.name)
            setValue("idSheet", emitenDetail.idSheet)
            setValue("barChartIndexColumn", emitenDetail.barChartIndexColumn)
            setValue("lineChartIndexColumn", emitenDetail.lineChartIndexColumn)
            setValue("rowStartIndex", emitenDetail.rowStartIndex)
        }
    }, [emitenDetail]);

    return <div>
        <form className="grid grid-cols-1 md:grid-cols-2 gap-4" onSubmit={handleSubmit(onSave)}>
            {/* Kode emiten */}
            <div className="w-full">
                <select id="code" className="input-field-full-width py-1.5 px-2" {...register("code", {
                    required: "Kode emiten wajib diisi.",
                    disabled: emitenDetail != null
                })}>
                    <option value="">Pilih Kode Emiten</option>
                    {codeList.map((stock) => <option key={stock.code} value={`${stock.code}::${stock.name}`}>{stock.code.toUpperCase()} - {stock.name}</option>)}
                </select>
                {errors.code &&
                    <p className="text-red-500 text-xs">{errors.code?.message}</p>
                }
            </div>

            {/* Id GSheet */}
            <div className="w-full">
                <div className="w-full">
                    <input type="text"
                        placeholder="ID Google Sheet"
                        className="input-field-full-width"
                        {...register("idSheet", { required: "ID Google Sheet wajib diisi.", })}
                    />
                    {errors.idSheet && (
                        <p className="text-red-500 text-xs">{errors.idSheet?.message}</p>
                    )}
                </div>
            </div>

            {/* Bar Chart Index Column */}
            <div className="w-full">
                <div className="w-full">
                    <input type="text"
                        placeholder="Index Kolom Bar Chart"
                        className="input-field-full-width"
                        {...register("barChartIndexColumn")}
                    />
                    {errors.barChartIndexColumn && (
                        <p className="text-red-500 text-xs">{errors.barChartIndexColumn?.message}</p>
                    )}
                    <p className="text-xs">Pisahkan dengan ; (titik koma). Index dimulai dari 0</p>
                </div>
            </div>

            {/* Line Chart Index Column */}
            <div className="w-full">
                <div className="w-full">
                    <input type="text"
                        placeholder="Index Kolom Line Chart"
                        className="input-field-full-width"
                        {...register("lineChartIndexColumn")}
                    />
                    {errors.lineChartIndexColumn && (
                        <p className="text-red-500 text-xs">{errors.lineChartIndexColumn?.message}</p>
                    )}
                    <p className="text-xs">Pisahkan dengan ; (titik koma). Index dimulai dari 0</p>
                </div>
            </div>

            {/* Row Start Index */}
            <div className="w-full col-span-2">
                <div className="w-full">
                    <input type="text"
                        placeholder="Index mulai data"
                        className="input-field-full-width"
                        {...register("rowStartIndex")}
                    />
                    {errors.rowStartIndex && (
                        <p className="text-red-500 text-xs">{errors.rowStartIndex?.message}</p>
                    )}
                </div>
            </div>

            <button className="w-full button-yellow-outline-with-hover" type="submit">Simpan</button>
            <button className="w-full button-red-outline-with-hover" onClick={onClose}>Batal</button>
        </form>
    </div>
}