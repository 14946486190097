import { FC, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import {
  MediaEnum,
  SelectMediaInputComponent,
} from "./forms/SelectMediaInput.component";

export type MembershipTopHeaderInputModel = {
  videoUrl: string;
};

export type MembershipTopHeaderFormProps = {
  data: MembershipTopHeaderInputModel;
  onSave: (data: MembershipTopHeaderInputModel) => void;
};

export const MembershipTopHeaderFormComponent: FC<
  MembershipTopHeaderFormProps
> = ({ data, onSave }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [mData, setMData] = useState<MembershipTopHeaderInputModel>(data);

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div
          className="font-bold text-3xl w-full"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          Top Header Video
        </div>

        <div className="flex">
          {!isCollapsed && (
            <button
              className="button-yellow-outline-with-hover"
              onClick={() => onSave(mData)}
            >
              Save
            </button>
          )}
          <button
            className="ml-4 mx-auto"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </div>
      </div>

      {!isCollapsed && (
        <div className="my-2 rounded-xl bg-slate-300 border shadow p-4">
          <SelectMediaInputComponent
            media={MediaEnum.VIDEO}
            mediaLabel="Video"
            value={mData.videoUrl}
            onChange={(url) => {
              let newData = { ...mData };
              newData.videoUrl = url;
              setMData(newData);
            }}
          />
        </div>
      )}
    </div>
  );
};
