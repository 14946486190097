import { PATH_AFFILIATES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { AffiliateWithdrawModel } from "../data/AffiliateWithdraw.model";

export const GetAffiliateWithdrawRequestsUseCase: (
  status?: string
) => Promise<AffiliateWithdrawModel[]> = async (status) => {
  try {
    let url = PATH_AFFILIATES + "/getAffiliateWithdrawRequest";
    if (status) {
      url += "?status=" + status;
    }

    let response = await client.get(url);
    return response.data.data.map((item: any) => ({
      affiliateName: item.affiliate_name,
      amount: item.amount,
      bank: {
        bankAccountNumber: item.bank.bank_account_number,
        bankHolderName: item.bank.bank_holder_name,
        bankName: item.bank.bank_name,
      },
      createdAt: item.created_at,
      id: item.id,
      status: item.status,
      updatedAt: item.updated_at,
      userId: item.user_id,
      notes: item.notes,
    }));
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
