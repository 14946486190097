import { FC } from "react";
import { useState } from "react";

export type NumberFieldProps = {
  fieldId: string;
  label: string;
  value: string | number;
  min?: number;
  max?: number;
  onChange: (newValue: number) => void;
  disableField?: boolean;
};

export const NumberField: FC<NumberFieldProps> = ({
  fieldId,
  label,
  value,
  min,
  max,
  onChange,
  disableField,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const mOnChange = (event: any) => {
    try {
      let intValue = parseInt(event.target.value);
      if (min && intValue < min) {
        setErrorMessage(`Size must be more than or equal to ${min}`);
      } else if (max && intValue > max) {
        setErrorMessage(`Size must be less than or equal to ${max}`);
      } else {
        onChange(intValue);
        setErrorMessage("");
      }
    } catch (e) {
      setErrorMessage("Only number allowed!");
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="my-auto w-full">{label}</div>
        <div className="ml-2 w-full my-auto">
          <input
            type="number"
            className="px-2 input-field-full-width"
            id={fieldId}
            value={value}
            onChange={mOnChange}
            min={min}
            max={max}
            disabled={disableField ?? false}
          />
        </div>
      </div>
      <div className="text-xs text-red-500">{errorMessage}</div>
    </>
  );
};

NumberField.defaultProps = {
  disableField: false,
};
