/* eslint-disable react-hooks/exhaustive-deps */
import { Link, Outlet } from "react-router-dom";

import {
  FaChalkboardTeacher,
  FaNetworkWired,
  FaShoppingBag,
  FaShoppingCart,
  FaUserAlt,
  FaUsersCog,
  FaVideo,
} from "react-icons/fa";
import {
  MdAlternateEmail,
  MdPermMedia,
  MdSettings,
  MdStickyNote2,
} from "react-icons/md";
import { IoMdAnalytics } from "react-icons/io";
import { RiCoupon3Fill, RiPagesFill } from "react-icons/ri";
import { UseModalContext } from "../contexts/modal.context";
import { ModalComponent } from "@Components/modal/Modal.component";
import { UseToasterContext } from "../contexts/toaster.context";
import { ToasterPosition } from "@Components/toast/toast.model";
import { Header } from "@Components/Header.component";
import { Sidebar } from "@Components/Sidebar.component";
import { Toaster } from "@Components/toast/Toast.component";
import { ReactNode, useEffect, useState } from "react";
import { GetAffiliateRequestCount } from "@Pages/affiliate/domain/GetAffiliateRequestCount.usecase";

export default function BasePage() {
  const modalContext = UseModalContext();
  const toasterContext = UseToasterContext();

  const [notification, setNotification] = useState<ReactNode>();

  useEffect(() => {
    async function getWithdrawRequestCount() {
      try {
        let newRequestCount = await GetAffiliateRequestCount();

        if (newRequestCount > 0) {
          let component = (
            <div>
              Terdapat <span className="font-bold">{newRequestCount}</span>{" "}
              request withdraw affiliate yang belum di konfirmasi.{" "}
              <Link to={"/affiliate"} className="font-bold">
                Cek Sekarang!
              </Link>
            </div>
          );

          setNotification(component);
        }
      } catch (e) {}
    }

    getWithdrawRequestCount();
  }, []);

  return (
    <div className="bg-sidebar">
      <Header />
      <div className="flex">
        <div className="h-screen overflow-y-auto">
          <Sidebar
            menuItems={[
              {
                id: "menu-orders",
                icon: <FaShoppingCart />,
                label: "Pesanan",
                destination: "/orders",
              },
              {
                id: "menu-affiliate",
                icon: <FaNetworkWired />,
                label: "Affiliate",
                destination: "/affiliate",
              },
              {
                id: "menu-media",
                icon: <MdPermMedia />,
                label: "Media",
                destination: "/resources",
              },
              {
                id: "menu-page",
                icon: <MdStickyNote2 />,
                label: "Halaman",
                destination: "/pages",
              },
              {
                id: "menu-landing-page",
                icon: <RiPagesFill />,
                label: "Landing Page",
                destination: "/landing-page",
              },
              {
                id: "menu-course",
                icon: <FaChalkboardTeacher />,
                label: "Course",
                destination: "/courses",
              },
              {
                id: "menu-record",
                icon: <FaVideo />,
                label: "Rekaman",
                destination: "/records",
              },
              {
                id: "menu-analysis",
                icon: <IoMdAnalytics />,
                label: "Analisa",
                destination: "/analysis",
              },
              {
                id: "menu-products",
                icon: <FaShoppingBag />,
                label: "Produk",
                destination: "/products",
              },
              {
                id: "menu-vouchers",
                icon: <RiCoupon3Fill />,
                label: "Voucher",
                destination: "/vouchers",
              },
              {
                id: "menu-user",
                icon: <FaUserAlt />,
                label: "Pengguna",
                destination: "/users",
              },
              {
                id: "menu-role",
                icon: <FaUsersCog />,
                label: "Role",
                destination: "/roles",
              },
              {
                id: "menu-setting",
                icon: <MdSettings />,
                label: "Pengaturan",
                destination: "/settings",
              },
              {
                id: "menu-setting-page",
                icon: <MdSettings />,
                label: "Pengaturan Halaman",
                destination: "/settings/static-pages",
              },
              {
                id: "menu-get-response",
                icon: <MdAlternateEmail />,
                label: "Get Response",
                destination: "/get-response",
              },
            ]}
          />
        </div>
        <div className="w-full h-screen overflow-y-auto bg-white">
          {notification && (
            <div className="m-4 rounded border border-yellow-500 shadow-lg p-4 bg-[#ffdd28]">
              {notification}
            </div>
          )}

          <Outlet />

          {/* render Modal if any */}
          {modalContext.modal !== null && (
            <ModalComponent {...modalContext.modal} />
          )}

          {/* render Toaster if any */}
          {toasterContext.toastList.length > 0 && (
            <Toaster
              toastItems={toasterContext.toastList}
              isAutoDelete={true}
              position={ToasterPosition.BOTTOM_RIGHT}
              autoDeleteTimeInMilis={2000}
            />
          )}
        </div>
      </div>
    </div>
  );
}
