import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { EmitenDetailModel, EmitenSectorModel, StockModel } from "@Pages/setting/data/emiten_by_sector.model";
import { LoadSettingUsecase } from "@Pages/setting/domain/LoadSetting.usecase";
import { SaveSettingsUsecase } from "@Pages/setting/domain/SaveSettings.usecase";
import { LIST_EMITEN_BY_SECTORS } from "@Pages/setting/utils/const";
import { FC, MutableRefObject, useEffect, useRef, useState } from "react";
import { EmitenSectorComponent } from "../components/emitenBySector/EmitenSector.component";
import { GetStockCodesUsecase } from "@Pages/setting/domain/GetStockCodes.usecase";

export const EmitenBySectorsSection: FC = () => {
    const sectorTitleRef = useRef() as MutableRefObject<HTMLInputElement>;
    const sectorIsTableRef = useRef() as MutableRefObject<HTMLInputElement>;

    const modalContext = UseModalContext();

    const [needRefetch, setNeedRefetch] = useState<boolean>(true);
    const [sectorData, setSectorData] = useState<EmitenSectorModel[]>([]);
    const [stockCodeList, setStockCodeList] = useState<StockModel[]>([]);

    useEffect(() => {
        loadStockCodeList();
    }, []);

    useEffect(() => {
        if (needRefetch) {
            loadEmitenBySectors();
        }
    }, [needRefetch]);

    const loadEmitenBySectors = async () => {
        try {
            let response = await LoadSettingUsecase(LIST_EMITEN_BY_SECTORS);
            if (response[LIST_EMITEN_BY_SECTORS]) {
                setSectorData(JSON.parse(response[LIST_EMITEN_BY_SECTORS]));
            }
        } catch (e) {

        } finally {
            setNeedRefetch(false);
        }
    }

    const loadStockCodeList = async () => {
        try {
            let response = await GetStockCodesUsecase();
            setStockCodeList(response);
        } catch (e) {

        }
    }

    const updateEmitenBySectors = async () => {
        try {
            let stringifySectorData = JSON.stringify(sectorData);
            await SaveSettingsUsecase(true, LIST_EMITEN_BY_SECTORS, stringifySectorData);
        } catch (e) {

        } finally {
            setNeedRefetch(true)
        }
    }

    const updateEmitenList = (index: number, emitenDetail: EmitenDetailModel) => {
        let currentSectorData = sectorData;
        if (index >= 0 && index < currentSectorData.length) {
            currentSectorData[index].emitenList[emitenDetail.code] = emitenDetail;
            setSectorData(currentSectorData);
            updateEmitenBySectors();
        }
    }

    const deleteSectorByIndex = (index: number) => {
        let currentSectorData = sectorData;
        currentSectorData.splice(index, 1);
        setSectorData(currentSectorData);
        updateEmitenBySectors();
    }

    const updateSectorByIndex = (index: number, newSectorData: EmitenSectorModel) => {
        let currentSectorData = sectorData;
        if (index >= 0 && index < currentSectorData.length) {
            currentSectorData[index] = newSectorData;
            setSectorData(currentSectorData);
            updateEmitenBySectors();
        }
    }

    const deleteEmitenFromSector = (index: number, emitenCode: string) => {
        let currentSectorData = sectorData;
        delete currentSectorData[index].emitenList[emitenCode];
        setSectorData(currentSectorData);
        updateEmitenBySectors();
    }

    return <div className="mt-4">
        <div className="flex justify-between">
            <div className="font-bold text-xl">Emiten by Sectors</div>
            <YellowHoverButton
                label={"Tambah Sector"}
                onClick={() => {
                    if (!modalContext.modal) {
                        modalContext.setModal(
                            createModal(
                                null,
                                "Tambah Sektor",
                                <div>
                                    <div>
                                        <input ref={sectorTitleRef}
                                            placeholder="Nama sektor"
                                            className={"border rounded h-8 pl-1 w-full"}
                                        />
                                    </div>
                                    <div className="mt-2">
                                        <input ref={sectorIsTableRef}
                                            type="checkbox"
                                            className={"border rounded"}
                                        /> Tampilkan dalam bentuk Tabel
                                    </div>
                                    <div className="mt-2 flex gap-2 justify-between">
                                        <button className="button-yellow-outline-with-hover" onClick={() => {
                                            let sectorTitle = sectorTitleRef.current.value;

                                            if (sectorTitle) {
                                                let currentSectors = sectorData;
                                                currentSectors.push({
                                                    title: sectorTitle,
                                                    isTable: sectorIsTableRef.current.checked,
                                                    emitenList: {}
                                                });
                                                setSectorData(currentSectors);
                                                updateEmitenBySectors();
                                                modalContext.setModal(null);
                                            }

                                            sectorTitleRef.current.textContent = ""
                                        }}>Tambah</button>
                                        <button className="button-red-outline-with-hover" onClick={() => modalContext.setModal(null)}>Batal</button>
                                    </div>
                                </div>,
                                null,
                                null
                            )
                        );
                    }
                }}
            />
        </div>

        <div className="mt-4 border-t h-2"></div>

        {!sectorData && needRefetch && <MidasShimmering />}

        {(!sectorData || sectorData.length === 0) && !needRefetch && <div className="text-center">No Data Available</div>}

        {(sectorData.length > 0) && sectorData.map((item, index) => <EmitenSectorComponent
            key={`sektor-${item.title}-${index}`}
            stockList={stockCodeList}
            updateEmitenDetail={updateEmitenList}
            onDeleteSector={deleteSectorByIndex}
            onDeleteEmitenFromSector={deleteEmitenFromSector}
            onChangeSectorData={updateSectorByIndex}
            index={index}
            {...item}
        />)}
    </div>
}