import { PATH_ANALYSIS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ListAnalysisModel } from "../data/analysis.model";

export const GetAllAnalysisUsecase: (
  params: string
) => Promise<ListAnalysisModel> = async (params) => {
  try {
    let response = await client.get(PATH_ANALYSIS + params);
    return {
      list: response.data.data.data,
      meta: response.data.data.meta,
    };
  } catch (e) {
    throw new Error(e.response.message);
  }
};
