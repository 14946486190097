import { PATH_PAGES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ListPageModel } from "../data/page.model";

export const GetAllPagesUsecase: (
  params: string
) => Promise<ListPageModel> = async (params) => {
  try {
    let response = await client.get(PATH_PAGES + params);
    return {
      list: response.data.data.data,
      meta: response.data.data.meta,
    };
  } catch (e) {
    throw new Error(e.response.message);
  }
};
