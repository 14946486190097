import { FC, useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaPlus,
  FaRegTrashAlt,
} from "react-icons/fa";
import {
  MediaEnum,
  SelectMediaInputComponent,
} from "./forms/SelectMediaInput.component";

export type CriteriaInputModel = {
  text: string;
  imageUrl: string;
};

export type CriteriaInputProps = {
  index: number;
  criteria: CriteriaInputModel;
  onChange: (index: number, criteria: CriteriaInputModel) => void;
  onDelete: (index: number) => void;
};

export type CriteriaFormProps = {
  criteriaList: CriteriaInputModel[];
  onSaveCriteria: (criteriaList: CriteriaInputModel[]) => void;
};

export const CriteriaItem: FC<CriteriaInputProps> = ({
  index,
  criteria,
  onChange,
  onDelete,
}) => {
  return (
    <div className="my-2 rounded-xl bg-slate-300 border shadow p-4">
      <div className="mt-4 flex justify-end">
        <button
          className="button-red-outline-with-hover"
          onClick={() => onDelete(index)}
        >
          <FaRegTrashAlt />
        </button>
      </div>
      <div className="flex mt-4">
        <label className="font-medium my-auto w-32">Description</label>
        <input
          className={"input-field-full-width"}
          type="text"
          value={criteria.text}
          onChange={(e) => {
            let newCriteria = { ...criteria };
            newCriteria.text = e.target.value;
            onChange(index, newCriteria);
          }}
        />
      </div>

      <SelectMediaInputComponent
        media={MediaEnum.IMAGE}
        mediaLabel="Image"
        value={criteria.imageUrl}
        onChange={(url) => {
          let newCriteria = { ...criteria };
          newCriteria.imageUrl = url;
          onChange(index, newCriteria);
        }}
      />
    </div>
  );
};

export const CriteriaFormComponent: FC<CriteriaFormProps> = ({
  criteriaList,
  onSaveCriteria,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [criterias, setCriterias] =
    useState<CriteriaInputModel[]>(criteriaList);

  const onAddNewPortfolio = () => {
    let newCriteria = [...criterias];
    newCriteria.splice(0, 0, {
      text: "",
      imageUrl: "",
    });

    setCriterias(newCriteria);
  };

  const onDeletePortfolio = (index: number) => {
    let newCriteria = [...criterias];
    newCriteria.splice(index, 1);
    setCriterias(newCriteria);
  };

  const onChange = (index: number, mCriteria: CriteriaInputModel) => {
    let newCriteria = [...criteriaList];
    newCriteria[index] = mCriteria;
    setCriterias(newCriteria);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div
          className="font-bold text-3xl w-full"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          Criteria List
        </div>

        <div className="flex">
          {!isCollapsed && (
            <button
              className="button-yellow-outline-with-hover mr-2"
              onClick={onAddNewPortfolio}
            >
              <FaPlus />
            </button>
          )}
          {!isCollapsed && (
            <button
              className="button-yellow-outline-with-hover"
              onClick={() => onSaveCriteria(criterias)}
            >
              Save
            </button>
          )}
          <button
            className="ml-4 mx-auto"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </div>
      </div>

      {!isCollapsed && criterias.length === 0 && (
        <div className="rounded bg-gray-400 text-center mt-4 p-4">
          Criteria Empty
        </div>
      )}

      {!isCollapsed &&
        criterias.map((item, index) => {
          return (
            <CriteriaItem
              key={`criteria-${index}`}
              index={index}
              criteria={item}
              onChange={onChange}
              onDelete={onDeletePortfolio}
            />
          );
        })}
    </div>
  );
};
