import { ReactNode } from "react";
import { FC } from "react";
import { Link } from "react-router-dom";

export type SidebarItemModel = {
  id: string;
  icon: ReactNode;
  label: string;
  destination: string;
};

export type SidebarMenuItemProps = {
  label: string;
  icon: ReactNode;
  destination: string;
  isSelected?: boolean;
};

export const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  label,
  icon,
  destination,
  isSelected,
}) => {
  return (
    <Link to={destination}>
      <div className="flex p-4 pl-7 text-white">
        <div className="w-6 my-auto">{icon}</div>
        <span className="ml-3 text-sm my-auto">{label}</span>
      </div>
    </Link>
  );
};

SidebarMenuItem.defaultProps = {
  isSelected: false,
};

export type SidebarProps = {
  menuItems: SidebarItemModel[];
};

export const Sidebar: FC<SidebarProps> = ({ menuItems }) => {
  return (
    <div className="w-1/4 bg-sidebar text-white sticky">
      <div className="text-xl font-bold p-8 pt-5">Dashboard Admin</div>
      <div className="border-b-2e2e2e mb-3" />
      <div>
        {menuItems.map((item) => (
          <SidebarMenuItem
            key={item.id}
            icon={item.icon}
            label={item.label}
            destination={item.destination}
          />
        ))}
      </div>
    </div>
  );
};
