import { PATH_USERS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const UpdateUserRoleUsecase: (
  userId: string,
  roleId: string,
  expiredDate: string
) => Promise<boolean> = async (id, roleId, expiredDate) => {
  try {
    let formData = new FormData();
    formData.append("role", roleId);
    formData.append("expired_date", expiredDate);

    let response = await client.put(PATH_USERS + "/" + id + "/role", formData);
    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
