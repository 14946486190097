import { PATH_RECORDS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const AddRecordUsecase: (formData: FormData) => Promise<string> = async (
  formData
) => {
  try {
    let response = await client.post(PATH_RECORDS, formData);
    return response.data.message;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
