import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { StockModel, WatchlistDetailModel, WatchlistMonthYearModel } from "@Pages/setting/data/emiten_by_sector.model";
import { LoadSettingUsecase } from "@Pages/setting/domain/LoadSetting.usecase";
import { SaveSettingsUsecase } from "@Pages/setting/domain/SaveSettings.usecase";
import { MEMBER_WATCHLIST_STOCKS } from "@Pages/setting/utils/const";
import { FC, MutableRefObject, useEffect, useRef, useState } from "react";
import { GetStockCodesUsecase } from "@Pages/setting/domain/GetStockCodes.usecase";
import { WatchlistSectorComponent } from "../components/watchlistByMonthYear/WatchlistMonthYear.component";

export const WatchlistByMonthYearSection: FC = () => {
    const sectorTitleRef = useRef() as MutableRefObject<HTMLInputElement>;
  
    const modalContext = UseModalContext();

    const [needRefetch, setNeedRefetch] = useState<boolean>(true);
    const [stockCodeList, setStockCodeList] = useState<StockModel[]>([]);
    const [watchlistData, setWatchlistData] = useState<WatchlistMonthYearModel[]>([]);

    useEffect(() => {
      loadStockCodeList();
    }, []);

    useEffect(() => {
        if (needRefetch) {
          loadWatchlistByMonthYear();
        }
    }, [needRefetch]);

    const loadWatchlistByMonthYear = async () => {
        try {
            let response = await LoadSettingUsecase(MEMBER_WATCHLIST_STOCKS);
            if (response[MEMBER_WATCHLIST_STOCKS]) {
                setWatchlistData(JSON.parse(response[MEMBER_WATCHLIST_STOCKS]));
            }
        } catch (e) {

        } finally {
            setNeedRefetch(false);
        }
    }

    const loadStockCodeList = async () => {
      try {
          let response = await GetStockCodesUsecase();
          setStockCodeList(response);
      } catch (e) {

      }
    }

    const updateWatchlistByMonthYear = async () => {
        try {
            let stringifyWatchlistData = JSON.stringify(watchlistData);
            await SaveSettingsUsecase(true, MEMBER_WATCHLIST_STOCKS, stringifyWatchlistData);
        } catch (e) {

        } finally {
            setNeedRefetch(true)
        }
    }

    const updateEmitenList = (index: number, emitenDetail: WatchlistDetailModel) => {
        let currentWatchlistData = watchlistData;
        if (index >= 0 && index < currentWatchlistData.length) {
            currentWatchlistData[index].emitenList[emitenDetail.code] = emitenDetail;
            setWatchlistData(currentWatchlistData);
            updateWatchlistByMonthYear();
        }
    }

    const deleteSectorByIndex = (index: number) => {
        let currentWatchlistData = watchlistData;
        currentWatchlistData.splice(index, 1);
        setWatchlistData(currentWatchlistData);
        updateWatchlistByMonthYear();
    }

    const updateSectorByIndex = (index: number, newWatchlistData: WatchlistMonthYearModel) => {
        let currentWatchlistData = watchlistData;
        if (index >= 0 && index < currentWatchlistData.length) {
            currentWatchlistData[index] = newWatchlistData;
            setWatchlistData(currentWatchlistData);
            updateWatchlistByMonthYear();
        }
    }

    const deleteEmitenFromSector = (index: number, emitenCode: string) => {
        let currentWatchlistData = watchlistData;
        delete currentWatchlistData[index].emitenList[emitenCode];
        setWatchlistData(currentWatchlistData);
        updateWatchlistByMonthYear();
    }

    return <div className="mt-4">
        <div className="flex justify-between">
            <div className="font-bold text-xl">Watchlist By Month Year</div>
            <YellowHoverButton
                label={"Tambah Sector"}
                onClick={() => {
                    if (!modalContext.modal) {
                        modalContext.setModal(
                            createModal(
                                null,
                                "Tambah Sektor",
                                <div>
                                    <div>
                                        <input ref={sectorTitleRef}
                                            placeholder="Nama Bulan dan Tahun"
                                            className={"border rounded h-8 pl-1 w-full"}
                                        />
                                    </div>
                                    <div className="mt-2 flex gap-2 justify-between">
                                        <button className="button-yellow-outline-with-hover" onClick={() => {
                                            let sectorTitle = sectorTitleRef.current.value;

                                            if (sectorTitle) {
                                                let currentSectors = watchlistData;
                                                currentSectors.push({
                                                    title: sectorTitle,
                                                    emitenList: {}
                                                });
                                                setWatchlistData(currentSectors);
                                                updateWatchlistByMonthYear();
                                                modalContext.setModal(null);
                                            }

                                            sectorTitleRef.current.textContent = ""
                                        }}>Tambah</button>
                                        <button className="button-red-outline-with-hover" onClick={() => modalContext.setModal(null)}>Batal</button>
                                    </div>
                                </div>,
                                null,
                                null
                            )
                        );
                    }
                }}
            />
        </div>

        <div className="mt-4 border-t h-2"></div>

        {!watchlistData && needRefetch && <MidasShimmering />}

        {(!watchlistData || watchlistData.length === 0) && !needRefetch && <div className="text-center">No Data Available</div>}

        {(watchlistData.length > 0) && watchlistData.map((item, index) => <WatchlistSectorComponent
            key={`sektor-${item.title}-${index}`}
            stockList={stockCodeList}
            updateEmitenDetail={updateEmitenList}
            onDeleteSector={deleteSectorByIndex}
            onDeleteEmitenFromSector={deleteEmitenFromSector}
            onChangeSectorData={updateSectorByIndex}
            index={index}
            {...item}
        />)}
    </div>
}