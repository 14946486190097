import { PATH_GR_AUTO_RESPONDER } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { AutoResponderModel } from "../data/autoresponder.model";

export const AutoResponderListUsecase: (
  page: number
) => Promise<AutoResponderModel[]> = async (page) => {
  try {
    let response = await client.get(PATH_GR_AUTO_RESPONDER + `?page=${page}`);
    let data = response.data.data;

    return data.map((item: any) => {
      return {
        id: item.autoresponderId,
        campaignId: item.campaignId,
        name: item.name,
        subject: item.subject,
        status: item.status,
        createdOn: new Date(item.createdOn).toString(),
        statistics: {
          delivered: item.statistics.delivered,
          openRate: item.statistics.openRate,
          clickRate: item.statistics.clickRate,
        },
        campaign: {
          id: item.campaign.id,
          name: item.campaign.name,
        },
        day: item.triggerSettings.dayOfCycle,
      };
    });
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
