/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import {
  MediaEnum,
  SelectMediaInputComponent,
} from "./forms/SelectMediaInput.component";

export type StockFindingInputModel = {
  imageUrl: string;
  title: string;
  description: string;
};

export type StockFindingInputProps = {
  index: number;
  finding: StockFindingInputModel;
  onChange: (index: number, finding: StockFindingInputModel) => void;
};

export type StockFindingFormProps = {
  stockFindingList: StockFindingInputModel[];
  onSaveStockFinding: (stockFindingList: StockFindingInputModel[]) => void;
};

export const StockFindingItem: FC<StockFindingInputProps> = ({
  index,
  finding,
  onChange,
}) => {
  return (
    <div className="my-2 rounded-xl bg-slate-300 border shadow p-4">
      <div className="flex mt-4">
        <label className="font-medium my-auto w-32">Title</label>
        <input
          className={"input-field-full-width"}
          type="text"
          value={finding.title}
          onChange={(e) => {
            let newFinding = { ...finding };
            newFinding.title = e.target.value;
            onChange(index, newFinding);
          }}
        />
      </div>

      <SelectMediaInputComponent
        media={MediaEnum.IMAGE}
        mediaLabel="Image"
        value={finding.imageUrl}
        onChange={(url) => {
          let newFinding = { ...finding };
          newFinding.imageUrl = url;
          onChange(index, newFinding);
        }}
      />

      <div className="mt-4">
        <label className="font-medium my-auto w-32">Description</label>
        <textarea
          className={"input-field-full-width"}
          value={finding.description}
          onChange={(e) => {
            let newFinding = { ...finding };
            newFinding.description = e.target.value;
            onChange(index, newFinding);
          }}
        />
      </div>
    </div>
  );
};

export const StockFindingFormComponent: FC<StockFindingFormProps> = ({
  stockFindingList,
  onSaveStockFinding,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [findingList, setFindingList] =
    useState<StockFindingInputModel[]>(stockFindingList);

  useEffect(() => {
    if (stockFindingList.length < 6) {
      let newStockFindingList = [...stockFindingList];
      for (let i = newStockFindingList.length; i < 6; i++) {
        newStockFindingList.push({
          imageUrl: "",
          title: "",
          description: "",
        });
      }
      setFindingList(newStockFindingList);
    }
  }, []);

  const onChange = (index: number, mFinding: StockFindingInputModel) => {
    let newFinding = [...findingList];
    newFinding[index] = mFinding;
    setFindingList(newFinding);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div
          className="font-bold text-3xl w-full"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          Stock Findings
        </div>

        <div className="flex">
          {!isCollapsed && (
            <button
              className="button-yellow-outline-with-hover"
              onClick={() => onSaveStockFinding(findingList)}
            >
              Save
            </button>
          )}
          <button
            className="ml-4 mx-auto"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </div>
      </div>

      {!isCollapsed &&
        findingList.map((item, index) => {
          return (
            <StockFindingItem
              index={index}
              finding={item}
              onChange={onChange}
            />
          );
        })}
    </div>
  );
};
