import { default as MidasLogo } from "../../assets/midas.png";

export const MidasShimmering = () => {
  return (
    <div className="flex justify-center">
      <div className="animate-pulse">
        <img src={MidasLogo} alt=""/>
      </div>
    </div>
  );
};
