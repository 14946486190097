import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { MediaChooser } from "@Pages/common/presentation/MediaChooser.page";
import {
  PageConfigModel,
  UsePageEditorContext,
} from "@Pages/page/context/page-editor.context";
import { ResourceModel } from "@Pages/resources/data/resource.model";
import { VideoModel } from "../../models/video.model";
import { Slider } from "../forms/Slider.component";

export const VideoViewConfig = () => {
  const { pageConfigs, setPageConfigs } = UsePageEditorContext();
  const { setModal } = UseModalContext();

  const editingModel: VideoModel = pageConfigs.pageComponents[
    pageConfigs.editIndex
  ] as VideoModel;

  const updateOldModel = (newConfig: PageConfigModel) => {
    if (
      pageConfigs.oldModel == null ||
      typeof pageConfigs.oldModel == "undefined"
    ) {
      newConfig["oldModel"] = new VideoModel(
        editingModel.componentKey,
        editingModel.videoUrl,
        editingModel.widthPercentage,
        editingModel.showVideoControls,
        editingModel.isAutoPlay,
        editingModel.canDownload,
        editingModel.videoSource
      );
    }
  };

  const onChangeUrl = (event: any) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as VideoModel).videoUrl =
      event.target.value;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeSource = (event: any) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as VideoModel).videoSource =
      event.target.value;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeWidthPercentage = (newValue: number) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as VideoModel).widthPercentage =
      newValue;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeVideoControls = (event: any) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as VideoModel).showVideoControls =
      event.target.checked;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeCanDownload = (event: any) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as VideoModel).canDownload =
      event.target.checked;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onSelectMediaClicked = () => {
    let title = "Select Media";
    let description = (
      <MediaChooser type={"video"} onMediaSelected={onMediaSelected} />
    );
    let cancelButton = (
      <button
        className="button-outline-with-hover"
        onClick={() => setModal(null)}
      >
        Batal
      </button>
    );

    setModal(createModal(null, title, description, cancelButton, null));
  };

  const onMediaSelected = (data: ResourceModel) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldModel(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as VideoModel).videoUrl =
      data.link_url;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
    setModal(null);
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex">
          <div className="font-semibold w-full">Video Type</div>
          <select
            className={"input-field-full-width"}
            value={editingModel.videoSource}
            onChange={onChangeSource}
          >
            <option value="youtube">Youtube</option>
            <option value="url">Url</option>
          </select>
        </div>
        <input
          type="url"
          className={"input-field-full-width"}
          value={editingModel.videoUrl}
          onChange={onChangeUrl}
          placeholder={"Video URL"}
        />
        <button
          className="button-outline-with-hover"
          onClick={onSelectMediaClicked}
        >
          Select Media
        </button>
        <div className="flex flex-col">
          <div>Width Percentage</div>
          <Slider
            min={1}
            max={100}
            sliderValue={editingModel.widthPercentage}
            postfix={"%"}
            onChange={onChangeWidthPercentage}
          />
        </div>
        <div className="flex">
          <div className="w-full">Show Video Controls</div>
          <div className="text-right">
            <input
              type="checkbox"
              id="video-controls"
              checked={editingModel.showVideoControls}
              onChange={onChangeVideoControls}
            />
          </div>
        </div>
        <div className="flex">
          <div className="w-full">Can Download</div>
          <div className="text-right">
            <input
              type="checkbox"
              id="video-can-download"
              checked={editingModel.canDownload}
              onChange={onChangeCanDownload}
            />
          </div>
        </div>
      </div>
    </>
  );
};
