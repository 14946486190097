/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from "react";
import { 
  FaChevronDown, 
  FaChevronUp, 
  FaPlus, 
  FaRegTrashAlt 
} from "react-icons/fa";

import {
  MediaEnum,
  SelectMediaInputComponent,
} from "./forms/SelectMediaInput.component";

export type ShowcaseInputModel = {
  imageUrl: string;
  altText: string;
};

export type ShowcaseInputProps = {
  index: number;
  showcase: ShowcaseInputModel;
  onChange: (index: number, showcase: ShowcaseInputModel) => void;
  onDelete: (index: number) => void;
};

export type ShowcaseFormProps = {
  showcaseList: ShowcaseInputModel[];
  onSaveShowcase: (showcaseList: ShowcaseInputModel[]) => void;
  title:string;
};

export const ShowcaseItem: FC<ShowcaseInputProps> = ({
  index,
  showcase,
  onChange,
  onDelete,
}) => {
  return (
    <div className="my-2 rounded-xl bg-slate-300 border shadow p-4">
      <div className="mt-4 flex justify-end">
        <button
          className="button-red-outline-with-hover"
          onClick={() => onDelete(index)}
        >
          <FaRegTrashAlt />
        </button>
      </div>
      <SelectMediaInputComponent
        media={MediaEnum.VIDEO}
        mediaLabel="Showcase"
        value={showcase.imageUrl}
        onChange={(url) => {
          let newShowcase = { ...showcase };
          newShowcase.imageUrl = url;
          onChange(index, newShowcase);
        }}
      />
      <div className="flex mt-4">
        <label className="font-medium my-auto w-32">Alternative Text</label>
        <input
          className={"input-field-full-width"}
          type="text"
          value={showcase.altText}
          onChange={(e) => {
            let newShowcase = { ...showcase };
            newShowcase.altText = e.target.value;
            onChange(index, newShowcase);
          }}
        />
      </div>
    </div>
  );
};

export const ShowcaseFormComponent: FC<ShowcaseFormProps> = ({
  showcaseList,
  onSaveShowcase,
  title,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showcases, setShowcases] = useState<ShowcaseInputModel[]>(showcaseList);
  // const [showcases, setShowcases] =
  //   useState<ShowcaseInputModel[]>(showcaseList);

  // useEffect(() => {
  //   if (showcaseList.length < 6) {
  //     let newShowcase = [...showcaseList];
  //     for (let i = newShowcase.length; i < 6; i++) {
  //       newShowcase.push({
  //         imageUrl: "",
  //         altText: "",
  //       });
  //     }
  //     setShowcases(newShowcase);
  //   }
  // }, []);

  const onAddNew = () => {
    let newShowcase = [...showcaseList];
    newShowcase.push({
      imageUrl: "",
      altText: "",
    });
    setShowcases(newShowcase);
  };

  const onDelete = (index: number) => {
    let newShowcase = [...showcaseList];
    newShowcase.splice(index, 1);
    setShowcases(newShowcase);
  };

  const onChange = (index: number, mShowcase: ShowcaseInputModel) => {
    let newShowcases = [...showcaseList];
    newShowcases[index] = mShowcase;
    setShowcases(newShowcases);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div
          className="font-bold text-3xl w-full"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {title}
        </div>

        <div className="flex">
          {!isCollapsed && (
            <button
              className="button-yellow-outline-with-hover mr-2"
              onClick={onAddNew}
            >
              <FaPlus />
            </button>
          )}
          {!isCollapsed && (
            <button
              className="button-yellow-outline-with-hover"
              onClick={() => onSaveShowcase(showcases)}
            >
              Save
            </button>
          )}
          <button
            className="ml-4 mx-auto"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </div>
      </div>

      {!isCollapsed && showcases.length === 0 && (
        <div className="rounded bg-gray-400 text-center mt-4 p-4">
          Showcase Empty
        </div>
      )}

      {!isCollapsed &&
        showcases.map((item, index) => {
          return (
            <ShowcaseItem
              key={`portfolio-${index}`}
              index={index}
              showcase={item}
              onChange={onChange}
              onDelete={onDelete}
            />
          );
        })}
    </div>
  );
};
