import { client } from "@Utils/ApiService";
import { StockModel } from "../data/emiten_by_sector.model"
import { PATH_JARVIS } from "@Utils/ApiConst";

export const GetStockCodesUsecase: () => Promise<StockModel[]> = async () => {
    try {
        let response = await client.get(PATH_JARVIS);
        let data = [...response.data];
        return data as StockModel[];
    } catch (e) {
        throw new Error(e.response.data.message);
    }
}