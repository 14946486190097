import { PATH_GR_SHOPS, PATH_GR_SHOP_DETAIL } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ShopModel } from "../data/shop.model";

export const ShopListUsecase: (page: number) => Promise<ShopModel[]> = async (
  page
) => {
  try {
    let response = await client.get(PATH_GR_SHOPS + `?page=${page}`);
    let data = response.data.data;

    return data.map((item: any) => {
      return {
        id: item.shopId,
        name: item.name,
        locale: item.locale,
        currency: item.currency,
      };
    });
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const AddShopUsecase: (name: string) => Promise<string> = async (
  name
) => {
  try {
    let formData = new FormData();
    formData.append("name", name);

    let response = await client.post(PATH_GR_SHOPS, formData);
    return response.data.message;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const DeleteShopUsecase: (id: string) => Promise<boolean> = async (
  id
) => {
  try {
    await client.delete(PATH_GR_SHOP_DETAIL.replace("{id}", id));
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
