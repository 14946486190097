import { API_PATH_REGISTER } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const RegisterUserUsecase: (
  fullname: string,
  email: string
) => Promise<string> = async (fullname, email) => {
  try {
    let formData = new FormData();
    formData.append("fullname", fullname);
    formData.append("email", email);
    formData.append("password", email);

    let response = await client.post(API_PATH_REGISTER, formData);
    return response.data.data.id;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
