import { PATH_GR_PRODUCTS, PATH_GR_PRODUCTS_DETAIL } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ProductGetResponseModel } from "../data/product.model";

export const ProductListUsecase: (
  page: number
) => Promise<ProductGetResponseModel[]> = async (page) => {
  try {
    let response = await client.get(PATH_GR_PRODUCTS + `?page=${page}`);
    let data = response.data.data.data;

    return data.map((item: any) => {
      return {
        id: item.id,
        contactId: item.contact_list_id,
        contactName: item.contact_list_name,
        autoresponderId: item.autoresponder_cycle_id,
        autoresponderName: item.autoresponder_name,
        product: item.product,
      };
    });
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const AddProductRelation = async (
  productId: string,
  contactId: string,
  contactName: string,
  autoresponderId: string,
  autoresponderName: string
) => {
  try {
    let formData = new FormData();

    formData.append("contact_id", contactId);
    formData.append("contact_name", contactName);
    formData.append("autoresponder_id", autoresponderId);
    formData.append("autoresponder_name", autoresponderName);
    formData.append("product_id", productId);

    let response = await client.post(PATH_GR_PRODUCTS, formData);
    return response.data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const DeleteProductRelation = async (id: string) => {
  try {
    await client.delete(PATH_GR_PRODUCTS_DETAIL.replace("{id}", id));
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
