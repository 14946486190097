import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { RegisterUserUsecase } from "@Pages/user/domain/RegisterUser.usecase";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export type AddUserFormInput = {
  fullname: string;
  email: string;
};

export const AddUserComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddUserFormInput>();

  const toasterContext = UseToasterContext();
  const modalContext = UseModalContext();

  const navigate = useNavigate();

  const onSubmit = async (data: AddUserFormInput) => {
    try {
      let userId = await RegisterUserUsecase(data.fullname, data.email);
      removeModal();
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("Success to add user", "", ToasterType.SUCCESS),
      ]);
      navigate(`/users/detail/${userId}`);
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("Failed to add user", e.message, ToasterType.DANGER),
      ]);
    }
  };

  const removeModal = () => {
    modalContext.setModal(null);
  };

  return (
    <form
      className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full pt-4 pb-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="font-medium">Fullname</div>
      <div className="w-full">
        <input
          className={`input-field-full-width ${
            errors.fullname && "border-red-500"
          }`}
          type="text"
          {...register("fullname", {
            required: "Fullname is required",
          })}
        />
        {errors.fullname && (
          <p className="text-red-500 text-xs">{errors.fullname?.message}</p>
        )}
      </div>

      <div className="font-medium">Email</div>
      <div className="w-full">
        <input
          className={`input-field-full-width ${
            errors.email && "border-red-500"
          }`}
          type="email"
          {...register("email", {
            required: "Email is required",
          })}
        />
        <p className="text-xs">*Email akan menjadi Password</p>
        {errors.email && (
          <p className="text-red-500 text-xs">{errors.email?.message}</p>
        )}
      </div>

      <button
        className="button-red-outline-with-hover w-full"
        type="button"
        onClick={removeModal}
      >
        Cancel
      </button>
      <button className="button-yellow-outline-with-hover w-full" type="submit">
        Submit
      </button>
    </form>
  );
};
