import { PATH_ANALYSIS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const UpdateAnalysisUsecase: (
  id: string,
  formData: FormData
) => Promise<boolean> = async (id, formData) => {
  try {
    await client.put(PATH_ANALYSIS + "/" + id, formData);
    return true;
  } catch (e) {
    throw new Error(e.response.message);
  }
};
