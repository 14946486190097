/* eslint-disable react-hooks/exhaustive-deps */
import { UsePageEditorContext } from "@Pages/page/context/page-editor.context";
import { ListOfAvailableComponents } from "@Utils/ComponentList";
import { useEffect } from "react";
import { ItemComponent } from "./ItemComponent.component";

export const EditorListComponent = () => {
  const { pageConfigs, setPageConfigs } = UsePageEditorContext();

  useEffect(() => {
    setPageConfigs({
      ...pageConfigs,
      availableCommponents: ListOfAvailableComponents,
    });
  }, []);

  return (
    <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-4 gap-x-2">
      <ItemComponent model={pageConfigs.availableCommponents} />
    </div>
  );
};
