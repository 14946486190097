import { PATH_PAGES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const AddPageUsecase: (formData: FormData) => Promise<boolean> = async (
  formData
) => {
  try {
    await client.post(PATH_PAGES, formData);
    return true;
  } catch (e) {
    throw new Error(e.response.message);
  }
};
