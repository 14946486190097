import { UserModel } from "@Pages/common/data/user.model";
import { PATH_USERS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const GetUserUsecase: (id: string) => Promise<UserModel> = async (
  id
) => {
  try {
    let response = await client.get(PATH_USERS + "/" + id);
    return response.data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
