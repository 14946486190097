import { PATH_PRODUCTS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const AddProductUsecase: (
  formData: FormData
) => Promise<string> = async (formData) => {
  try {
    let response = await client.post(PATH_PRODUCTS, formData);
    return response.data.message;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
