/* eslint-disable react-hooks/exhaustive-deps */
import { RoleModel } from "@Pages/role/data/role.model";
import { FC, useEffect, useState } from "react";
import { UsePageEditorContext } from "../../context/page-editor.context";
import { AddPageType } from "../AddPage.page";

export type PageConfigProps = {
  roles: RoleModel[];
  type: AddPageType;
};

export const PageConfigComponent: FC<PageConfigProps> = ({ roles, type }) => {
  const { pageConfigs, setPageConfigs } = UsePageEditorContext();
  const [showRoles, setShowRoles] = useState(
    pageConfigs.visibility.includes(";")
  );

  useEffect(() => {
    if (
      type === AddPageType.ADD_ANALYSIS ||
      type === AddPageType.DETAIL_ANALYSIS
    ) {
      setPageConfigs({
        ...pageConfigs,
        type: "analysis",
      });
    } else {
      setPageConfigs({
        ...pageConfigs,
        type: "page",
      });
    }
  }, []);

  const isRoleChecked = (role: string) => {
    let visibility = pageConfigs.visibility
      .split(";")
      .filter((item) => item !== "");

    return visibility.includes(role);
  };

  return (
    <form className="p-4 flex flex-col">
      {/* title field */}
      <div className="font-medium">Title</div>
      <input
        type="text"
        className={"input-field-full-width"}
        value={pageConfigs.title}
        placeholder={"Page Title"}
        onChange={(e) => {
          let title = e.target.value;
          let slug = title.replaceAll(" ", "-").toLowerCase();
          setPageConfigs({ ...pageConfigs, title: title, slug: slug });
        }}
      />

      {/* slug field */}
      <div className="font-medium my-auto mt-2">Slug</div>
      <input
        type="text"
        className={"input-field-full-width"}
        value={pageConfigs.slug}
        placeholder={"Page Slug"}
        onChange={(e) =>
          setPageConfigs({ ...pageConfigs, slug: e.target.value })
        }
      />

      {/* category field */}
      <div className="font-medium my-auto mt-2">Type</div>
      <select
        className={"input-field-full-width"}
        value={pageConfigs.type}
        onChange={(e) =>
          setPageConfigs({
            ...pageConfigs,
            type: e.target.value,
          })
        }
      >
        {(type === AddPageType.ADD_PAGE ||
          type === AddPageType.DETAIL_PAGE) && (
          <>
            <option value="page">Halaman</option>
            <option value="portfolio">Portfolio</option>
            <option value="watchlist">Watchlist</option>
          </>
        )}
        {(type === AddPageType.ADD_ANALYSIS ||
          type === AddPageType.DETAIL_ANALYSIS) && (
          <option value="analysis">Analisa Saham</option>
        )}
      </select>

      {/* is showing header */}
      <div className="flex mt-2">
        <div className="w-full">Use Header</div>
        <input
          type="checkbox"
          checked={pageConfigs.isShowHeader}
          onChange={(e) =>
            setPageConfigs({
              ...pageConfigs,
              isShowHeader: e.target.checked,
            })
          }
        />
      </div>

      {/* header type */}
      <div className="flex mt-2">
        <div className="w-full">Header Type</div>
        <select
          className={"input-field-full-width"}
          value={pageConfigs.headerType}
          disabled={!pageConfigs.isShowHeader}
          onChange={(e) =>
            setPageConfigs({
              ...pageConfigs,
              headerType: e.target.value,
            })
          }
        >
          <option value="">Pilih Tipe</option>
          <option value="with_menu">With Menu</option>
          <option value="logo_only">Logo Only</option>
        </select>
      </div>

      {/* is showing footer */}
      <div className="flex mt-2">
        <div className="w-full">Use Footer</div>
        <input
          type="checkbox"
          checked={pageConfigs.isShowFooter}
          onChange={(e) =>
            setPageConfigs({
              ...pageConfigs,
              isShowFooter: e.target.checked,
            })
          }
        />
      </div>

      {/* footer type */}
      <div className="flex mt-2">
        <div className="w-full">Footer Type</div>
        <select
          className={"input-field-full-width"}
          value={pageConfigs.footerType}
          disabled={!pageConfigs.isShowFooter}
          onChange={(e) =>
            setPageConfigs({
              ...pageConfigs,
              footerType: e.target.value,
            })
          }
        >
          <option value="">Pilih Tipe</option>
          <option value="with_menu">With Menu</option>
        </select>
      </div>

      {/* visibility */}
      <div className="flex mt-2">
        <div className="w-full">Visibility</div>
        <select
          className={"input-field-full-width"}
          value={
            pageConfigs.visibility === "all"
              ? "all"
              : pageConfigs.visibility.includes(";") ||
                pageConfigs.visibility === "roles"
              ? "roles"
              : ""
          }
          onChange={(e) => {
            setPageConfigs({
              ...pageConfigs,
              visibility: e.target.value === "all" ? e.target.value : ";",
            });

            if (e.target.value === "roles") {
              setShowRoles(true);
            } else {
              setShowRoles(false);
            }
          }}
        >
          <option value="all">Everyone</option>
          <option value="roles">Specific Role</option>
        </select>
      </div>
      {showRoles && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          {roles.map((role, index) => (
            <div key={`role-${role.id}-${index}`}>
              <input
                type={"checkbox"}
                value={role.role}
                checked={isRoleChecked(role.role)}
                onChange={(e) => {
                  let visibility = pageConfigs.visibility
                    .split(";")
                    .filter((item) => item !== "");

                  if (e.target.checked) {
                    visibility.push(e.target.value);
                  } else {
                    visibility = visibility.filter(
                      (item) => item !== e.target.value
                    );
                  }

                  setPageConfigs({
                    ...pageConfigs,
                    visibility: visibility.join(";") + ";",
                  });
                }}
              />{" "}
              {role.title}
            </div>
          ))}
        </div>
      )}
    </form>
  );
};
