import { PATH_SETTINGS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ListSettingsModel } from "../data/settings.model";

export const GetAllSettingsUsecase: () => Promise<ListSettingsModel> =
  async () => {
    try {
      let response = await client.get(PATH_SETTINGS);
      return {
        data: response.data.data,
      };
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  };