/* eslint-disable react-hooks/exhaustive-deps */
import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE } from "@Helpers/regex";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import {
  LandingPageDetailModel,
  LandingPageModel,
} from "@Pages/get-response/data/landing-page.model";
import {
  DetailLandingPageUsecase,
  PublishLandingPageUsecase,
  UnpublishLandingPageUsecase,
} from "@Pages/get-response/domain/LandingPages.usecase";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaCheckCircle, FaEye, FaTimesCircle } from "react-icons/fa";
import { PageControllerComponent } from "../components/PageController.component";
import { GetResponseSection, SectionProps } from "../GetResponse.page";

export type LandingPageItemProps = {
  data: LandingPageModel;
};

export type LandingPageSectionProps = SectionProps & {
  data: LandingPageModel[];
};

export type PublishLandingPageInputs = {
  slug: string;
};

export const LandingPageItem: FC<LandingPageItemProps> = ({ data }) => {
  const toasterContext = UseToasterContext();

  const [detail, setDetail] = useState<LandingPageDetailModel | null>(null);
  const [needRefetch, setNeedRefetch] = useState(true);

  const {
    register,
    trigger,
    watch,
    formState: { errors },
  } = useForm<PublishLandingPageInputs>();

  useEffect(() => {
    if (needRefetch) {
      async function fetchData() {
        let response = await DetailLandingPageUsecase(data.id);
        setDetail(response);
        setNeedRefetch(false);
      }
      fetchData();
    }
  }, [needRefetch]);

  const onPublishPage = async () => {
    const valid = await trigger("slug");

    if (!valid) {
      return;
    }

    let formData = new FormData();
    formData.append("title", detail?.title ?? "");
    formData.append("slug", watch("slug"));
    formData.append(
      "content_json",
      JSON.stringify([
        {
          type: "iframe",
          url: detail?.url,
          pageId: detail?.id,
        },
      ])
    );

    try {
      await PublishLandingPageUsecase(detail?.id ?? "", formData);

      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("Successfully Published", "", ToasterType.SUCCESS),
      ]);
      setNeedRefetch(true);
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("Failed to Publish", "", ToasterType.DANGER),
      ]);
    }
  };

  const onUnpublish = async () => {
    try {
      await UnpublishLandingPageUsecase(detail?.id ?? "");

      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("Successfully Unpublish", "", ToasterType.SUCCESS),
      ]);
      setNeedRefetch(true);
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("Failed to Unpublish", "", ToasterType.DANGER),
      ]);
    }
  };

  return (
    <div>
      {detail?.variants.map((item) => (
        <div className="mt-4" key={`statistic-variant-landing-page-${item.id}`}>
          <div className="flex justify-between">
            <div>Variant : {item.variant}</div>
            <div>{item.winner ? "Winner" : ""}</div>
          </div>
          <div className="grid grid-cols-3 border border-black rounded text-center p-4">
            <div>
              <div className="">Unique Visitors</div>
              <div className="font-bold">{item.uniqueVisitors}</div>
            </div>
            <div>
              <div className="">Visitors</div>
              <div className="font-bold">{item.visitors}</div>
            </div>
            <div>
              <div className="">Subscribed</div>
              <div className="font-bold">{item.subscribed}</div>
            </div>
          </div>
        </div>
      ))}

      <div className="mt-4 p-4">
        <img src={detail?.favico} alt="" className="w-20 h-20" />
      </div>

      <div className="p-4 grid grid-cols-2 gap-4 mt-4">
        <div>Title</div>
        <div>{detail?.title}</div>

        <div>Description</div>
        <div>{detail?.description}</div>

        <div>URL</div>
        <a href={detail?.url} target="_blank" rel="noreferrer">
          {detail?.url}
        </a>

        <div>Status</div>
        <div>{detail?.status}</div>

        <div>Publish</div>
        <div className="flex">
          <div className="my-auto mr-4">
            {detail?.published.toString().toUpperCase()}
          </div>
          {detail?.published === true && (
            <YellowHoverButton label="Unpublish" onClick={onUnpublish} />
          )}
        </div>

        {detail?.slug !== "" && <div>Slug</div>}
        {detail?.slug !== "" && <div>{detail?.slug}</div>}
      </div>

      {detail?.published === false && (
        <div className="flex gap-4 p-4">
          <label className="font-medium my-auto w-28">Slug</label>
          <div className="w-full my-auto">
            <input
              className={`input-field-full-width border border-black ${
                errors.slug && "border-red-500"
              }`}
              type="text"
              {...register("slug", {
                required: "Slug is required",
                pattern: {
                  value: REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE,
                  message:
                    "Invalid slug format, only alphanumeric and dash allowed",
                },
              })}
            />
            {errors.slug && (
              <p className="text-red-500 text-xs">{errors.slug?.message}</p>
            )}
          </div>
          <YellowHoverButton label="Publish" onClick={onPublishPage} />
        </div>
      )}
    </div>
  );
};

export const LandingPageSection: FC<LandingPageSectionProps> = ({
  data,
  page,
  setPage,
  needRefetch,
}) => {
  const modalContext = UseModalContext();

  const showDetail = (index: number) => {
    let title = data[index].id;
    let description = <LandingPageItem data={data[index]} />;
    let primaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => {
          modalContext.setModal(null);
          needRefetch([GetResponseSection.LANDING_PAGES]);
        }}
      >
        Close
      </button>
    );

    modalContext.setModal(
      createModal(null, title, description, primaryCta, null)
    );
  };

  return (
    <div className="mt-4">
      <table className={`border-collapse border w-full table-auto`}>
        <thead className="p-2 shadow-sm bg-gray-100 sticky">
          <tr>
            <th className="border border-spacing-1 text-center font-bold px-2">
              ID
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 text-ellipsis">
              Title
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 text-ellipsis">
              Enable
            </th>
            <th className="border border-spacing-1 text-center font-bold px-2 hidden md:table-cell">
              Publish
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={`campaign-row-${item.id}-${index}`}>
              <td className="border px-2 text-center">{item.id}</td>
              <td className="border px-2 text-left">
                {item.metaTitle}
                <div>
                  <button onClick={() => showDetail(index)}>
                    <FaEye size={20} className="text-blue-400" />
                  </button>
                </div>
              </td>
              <td className="border px-2 hidden md:table-cell">
                {item.status === "enabled" ? (
                  <FaCheckCircle size={24} color="green" className="m-auto" />
                ) : (
                  <FaTimesCircle size={24} color="red" className="m-auto" />
                )}
              </td>
              <td className="border px-2 hidden md:table-cell">
                {item.published ? (
                  <FaCheckCircle size={24} color="green" className="m-auto" />
                ) : (
                  <FaTimesCircle size={24} color="red" className="m-auto" />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PageControllerComponent
        currentPage={page}
        showPrev={page > 1}
        showNext={data.length >= 100}
        onPrev={setPage}
        onNext={setPage}
      />
    </div>
  );
};
