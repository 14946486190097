/* eslint-disable react-hooks/exhaustive-deps */
import { Header } from "@Components/Header.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { ModalComponent } from "@Components/modal/Modal.component";
import { Toaster } from "@Components/toast/Toast.component";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Components/toast/toast.model";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { UserSession } from "@Utils/UserSession";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseComponentModel } from "../context/editor-sidebar.context";
import { PageModel } from "../data/page.model";
import { LoadPageUsecase } from "../domain/LoadPage.usecase";
import { DividerModel } from "./models/divider.model";
import { ImageModel } from "./models/image.model";
import { SpacerModel } from "./models/spacer.model";
import { TextModel } from "./models/text.model";
import { VideoModel } from "./models/video.model";
import { HeaderModel } from "./models/header.model";
import { BulletModel } from "./models/bullet.model";
import { PostDetailModel } from "./models/post-detail.model";
import { DisclaimerModel } from "./models/disclaimer.model";


export const PreviewPagePage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const toasterContext = UseToasterContext();
  const modalContext = UseModalContext();

  const [detail, setDetail] = useState<PageModel>();

  useEffect(() => {
    if (UserSession.checkIsLoggedIn() === false) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await LoadPageUsecase(params.slug ?? "");
        setDetail(response);
      } catch (errors) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to load page",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      }
    }
    fetchData();
  }, [params.slug]);

  const mapApiComponentToModel: (
    model: any,
    index: number
  ) => BaseComponentModel | null = (model, index) => {
    let resultModel = null;
    switch (model.type) {
      case "text":
        resultModel = new TextModel("text-" + index);
        break;
      case "header":
        resultModel = new HeaderModel("header-" + index);
        break;
      case "bullet":
        resultModel = new BulletModel("bullet-" + index);
        break;
      case "post-detail":
        resultModel = new PostDetailModel("post-detail-" + index);
        break;
      case "disclaimer":
        resultModel = new DisclaimerModel("disclaimer-" + index);
        break;
      case "divider":
        resultModel = new DividerModel("divider-" + index);
        break;
      case "spacer":
        resultModel = new SpacerModel("spacer-" + index);
        break;
      case "image":
        resultModel = new ImageModel("image-" + index);
        break;
      case "video":
        resultModel = new VideoModel("video-" + index);
        break;
    }
    resultModel?.jsonToModel(model);
    return resultModel;
  };

  return (
    <div className="bg-white">
      {!detail && <MidasShimmering />}

      {detail && (
        <>
          <div>
            {detail.is_show_header === true && <Header />}
            {JSON.parse(detail.content_json).map(
              (model: any, index: number) => {
                return mapApiComponentToModel(model, index)?.render();
              }
            )}

            {/* render Modal if any */}
            {modalContext.modal !== null && (
              <ModalComponent {...modalContext.modal} />
            )}

            {/* render Toaster if any */}
            {toasterContext.toastList.length > 0 && (
              <Toaster
                toastItems={toasterContext.toastList}
                isAutoDelete={true}
                position={ToasterPosition.BOTTOM_RIGHT}
                autoDeleteTimeInMilis={2000}
              />
            )}
          </div>
          <div className="absolute top-4 -left-6 -rotate-45 bg-red-500">
            <div className="text-white text-center px-6 font-bold">Preview</div>
          </div>
        </>
      )}
    </div>
  );
};
