import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { default as MidasLogo } from "../../../assets/midas.png";

export const NotFoundPage = () => {
  return (
    <div className="w-screen h-screen p-10 bg-black text-white">
      <div className="m-auto text-center">
        <div className="flex justify-center">
          <img src={MidasLogo} alt="" className="w-44" />
        </div>
        <h1 className="text-3xl mt-6 font-bold">
          Opps! Halaman yang anda masukkan tidak ditemukan.
        </h1>
        <p className="text-xl mt-4">
          Pastikan URL yang anda masukkan benar dan anda memiliki akses ke
          halaman itu!
        </p>
        <YellowHoverButton
          label="Back to Home"
          destination="/"
          customClass="text-white mt-10"
        />
      </div>
    </div>
  );
};
