import { PATH_ROLES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const UpdateRoleUsecase: (
  id: string,
  title?: string,
  role?: string,
  access?: string[]
) => Promise<boolean> = async (id, title, role, access) => {
  try {
    let formData = new FormData();
    if (title) formData.append("title", title);
    if (role) formData.append("role", role);
    if (access) formData.append("access", `${access.join(";")};`);

    await client.put(PATH_ROLES + "/" + id, formData);
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
