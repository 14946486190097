import { CourseModel } from "@Pages/courses/data/course.model";
import { ArcElement, Chart, Legend, LinearScale, Tooltip } from "chart.js";
import { FC } from "react";
import { Pie } from "react-chartjs-2";

export type CourseStatisticsModalProps = {
  course: CourseModel;
};

export const CourseStatisticsModal: FC<CourseStatisticsModalProps> = ({
  course,
}) => {
  Chart.register(ArcElement, Tooltip, Legend, LinearScale);

  const defaultOptions = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        color: "black",
        font: { weight: 600, size: 16 },
      },
      legend: {
        labels: {
          color: "black",
          padding: 16,
          font: {
            size: 12,
          },
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
            modifierKey: "ctrl" as const,
          },
        },
        pan: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
      },
    },
  };

  const getTotalCourseUserWatch = () => {
    let data = {
      labels: ["% Member Watch", "% Member Not Watch"],
      datasets: [
        {
          label: "% User",
          data: [course.total_watch_percent, course.total_not_watch_percent],
          borderColor: ["rgb(225, 221, 40)", "rgb(255, 0, 0)"],
          backgroundColor: ["rgba(225, 221, 40, 0.5)", "rgba(255, 0, 0, 0.5)"],
          borderWidth: 1,
        },
      ],
    };

    return data;
  };

  const getTotalCourseUserWatchTime = () => {
    let data = {
      labels: [
        "% Member Watch 100%",
        "% Member Watch >= 50%",
        "% Member Watch < 50%",
      ],
      datasets: [
        {
          label: "% Member",
          data: [
            course.total_watch_complete_percent,
            course.total_watch_more_than_half_percent,
            course.total_watch_less_than_half_percent,
          ],
          borderColor: ["rgb(225, 221, 40)", "rgb(255, 0, 0)", "#34866E"],
          backgroundColor: [
            "rgba(225, 221, 40, 0.5)",
            "rgba(255, 0, 0, 0.5)",
            "#34866E",
          ],
          borderWidth: 1,
        },
      ],
    };

    return data;
  };

  const getTotalSectionUserWatch = (sectionIndex: number) => {
    let section = course.sections[sectionIndex];

    let data = {
      labels: ["% Member Watch", "% Member Not Watch"],
      datasets: [
        {
          label: "% User",
          data: [section.total_watch_percent, section.total_not_watch_percent],
          borderColor: ["rgb(225, 221, 40)", "rgb(255, 0, 0)"],
          backgroundColor: ["rgba(225, 221, 40, 0.5)", "rgba(255, 0, 0, 0.5)"],
          borderWidth: 1,
        },
      ],
    };

    return data;
  };

  const getTotalSectionUserWatchTime = (sectionIndex: number) => {
    let section = course.sections[sectionIndex];

    let data = {
      labels: [
        "% Member Watch 100%",
        "% Member Watch >= 50%",
        "% Member Watch < 50%",
      ],
      datasets: [
        {
          label: "% Member",
          data: [
            section.total_watch_complete_percent,
            section.total_watch_more_than_half_percent,
            section.total_watch_less_than_half_percent,
          ],
          borderColor: ["rgb(225, 221, 40)", "rgb(255, 0, 0)", "#34866E"],
          backgroundColor: [
            "rgba(225, 221, 40, 0.5)",
            "rgba(255, 0, 0, 0.5)",
            "#34866E",
          ],
          borderWidth: 1,
        },
      ],
    };

    return data;
  };

  const getTotalItemUserWatch = (sectionIndex: number, itemIndex: number) => {
    let item = course.sections[sectionIndex].items[itemIndex];

    let data = {
      labels: ["% Member Watch", "% Member Not Watch"],
      datasets: [
        {
          label: "% User",
          data: [item.total_watch_percent, item.total_not_watch_percent],
          borderColor: ["rgb(225, 221, 40)", "rgb(255, 0, 0)"],
          backgroundColor: ["rgba(225, 221, 40, 0.5)", "rgba(255, 0, 0, 0.5)"],
          borderWidth: 1,
        },
      ],
    };

    return data;
  };

  const getTotalItemUserWatchTime = (
    sectionIndex: number,
    itemIndex: number
  ) => {
    let item = course.sections[sectionIndex].items[itemIndex];

    let data = {
      labels: [
        "% Member Watch 100%",
        "% Member Watch >= 50%",
        "% Member Watch < 50%",
      ],
      datasets: [
        {
          label: "% Member",
          data: [
            item.total_watch_complete_percent,
            item.total_watch_more_than_half_percent,
            item.total_watch_less_than_half_percent,
          ],
          borderColor: ["rgb(225, 221, 40)", "rgb(255, 0, 0)", "#34866E"],
          backgroundColor: [
            "rgba(225, 221, 40, 0.5)",
            "rgba(255, 0, 0, 0.5)",
            "#34866E",
          ],
          borderWidth: 1,
        },
      ],
    };

    return data;
  };

  const getTotalItemUserWatchCount = (
    sectionIndex: number,
    itemIndex: number
  ) => {
    let item = course.sections[sectionIndex].items[itemIndex];

    let data = {
      labels: ["# Member Watch", "# Member Not Watch"],
      datasets: [
        {
          label: "# Member",
          data: [item.total_watch, item.total_not_watch],
          borderColor: ["rgb(225, 221, 40)", "rgb(255, 0, 0)"],
          backgroundColor: ["rgba(225, 221, 40, 0.5)", "rgba(255, 0, 0, 0.5)"],
          borderWidth: 1,
        },
      ],
    };

    return data;
  };

  const getTotalItemUserWatchTimeCount = (
    sectionIndex: number,
    itemIndex: number
  ) => {
    let item = course.sections[sectionIndex].items[itemIndex];

    let data = {
      labels: [
        "# Member Watch 100%",
        "# Member Watch >= 50%",
        "# Member Watch < 50%",
      ],
      datasets: [
        {
          label: "# Member",
          data: [
            item.total_watch_complete,
            item.total_watch_more_than_half,
            item.total_watch_less_than_half,
          ],
          borderColor: ["rgb(225, 221, 40)", "rgb(255, 0, 0)", "#34866E"],
          backgroundColor: [
            "rgba(225, 221, 40, 0.5)",
            "rgba(255, 0, 0, 0.5)",
            "#34866E",
          ],
          borderWidth: 1,
        },
      ],
    };

    return data;
  };

  return (
    <div className="mt-4 h-3/4">
      <div className="w-full font-bold text-center text-xl mb-4">
        {course.title}
      </div>

      <div className="grid grid-cols-2 text-center">
        <div className="text-center">
          <Pie data={getTotalCourseUserWatch()} options={defaultOptions} />
        </div>
        <div className="text-center">
          <Pie data={getTotalCourseUserWatchTime()} options={defaultOptions} />
        </div>
      </div>

      <div className="mt-4 p-4 text-center font-bold text-lg">
        Sections Statistik
      </div>

      {course.sections.map((section, index) => (
        <div className="mt-4 py-4 border border-black">
          <div className="mt-4 p-4 text-center font-semibold text-base">
            {section.title}
          </div>

          <div className="grid grid-cols-2 text-center">
            <div className="text-center">
              <Pie
                data={getTotalSectionUserWatch(index)}
                options={defaultOptions}
              />
            </div>
            <div className="text-center">
              <Pie
                data={getTotalSectionUserWatchTime(index)}
                options={defaultOptions}
              />
            </div>
          </div>

          <div className="mt-4 mx-4 p-4 border-b border-black text-center font-bold text-base">
            Course Statistik
          </div>

          {section.items.map((item, itemIndex) => (
            <div className="mt-4">
              <div className="mt-4 p-4 text-center font-semibold text-sm">
                {item.title}
              </div>

              <div className="grid grid-cols-2 text-center">
                <div className="text-center">
                  <Pie
                    data={getTotalItemUserWatch(index, itemIndex)}
                    options={defaultOptions}
                  />
                </div>
                <div className="text-center">
                  <Pie
                    data={getTotalItemUserWatchTime(index, itemIndex)}
                    options={defaultOptions}
                  />
                </div>
                <div className="text-center">
                  <Pie
                    data={getTotalItemUserWatchCount(index, itemIndex)}
                    options={defaultOptions}
                  />
                </div>
                <div className="text-center">
                  <Pie
                    data={getTotalItemUserWatchTimeCount(index, itemIndex)}
                    options={defaultOptions}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
