export function buildQueryParamsFromUrl(entries: any) {
  let queryParams = "";

  for (let entry of entries) {
    let [key, value] = entry;
    queryParams += key + "=" + value + "&";
  }

  if (queryParams.charAt(queryParams.length - 1) === "&") {
    queryParams = queryParams.slice(0, queryParams.length - 1);
  }

  return queryParams;
}

export function buildUrlSearchParams(entries: any, key: string, value: string) {
  let queryParams = "";

  for (let entry of entries) {
    let [entryKey, entryValue] = entry;
    if (entryKey !== key) {
      queryParams += entryKey + "=" + entryValue + "&";
    }
  }

  if (value !== "") {
    queryParams += key + "=" + value;
  }

  if (queryParams.charAt(queryParams.length - 1) === "&") {
    queryParams = queryParams.slice(0, queryParams.length - 1);
  }

  return queryParams;
}
