import { AffiliateWithdrawModel } from "@Pages/affiliate/data/AffiliateWithdraw.model";
import { AffiliateWithdrawDetailItemComponent } from "./AffiliateWithdrawDetail.component";
import { FC } from "react";
import { convertDateFromApiToView, formatToCurrency } from "@Helpers/formatter";

export type AffiliateWithdrawDetailViewProps = {
  withdrawRequest: AffiliateWithdrawModel;
  onCancel: () => void;
  onReject: () => void;
  onApprove: () => void;
};

export const AffiliateWithdrawDetailView: FC<
  AffiliateWithdrawDetailViewProps
> = ({ withdrawRequest, onCancel, onReject, onApprove }) => {
  return (
    <div>
      <div className="flex justify-between w-full">
        <div>Status</div>

        <div className="font-bold">
          {withdrawRequest.status === "requested" ? (
            <div className="text-yellow-500">Waiting for Confirmation</div>
          ) : withdrawRequest.status === "approved" ? (
            <div className="text-green-300">Approved</div>
          ) : withdrawRequest.status === "completed" ? (
            <div className="text-green-500">Complete</div>
          ) : withdrawRequest.status === "rejected" ? (
            <div className="text-red-500">Rejected</div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <AffiliateWithdrawDetailItemComponent
        label="Affiliate Name"
        value={withdrawRequest.affiliateName}
        className="mt-4"
      />

      <AffiliateWithdrawDetailItemComponent
        label="Created Date"
        value={convertDateFromApiToView(withdrawRequest.createdAt)}
        className=""
      />

      <AffiliateWithdrawDetailItemComponent
        label="Updated Date"
        value={convertDateFromApiToView(withdrawRequest.updatedAt)}
        className=""
      />

      <AffiliateWithdrawDetailItemComponent
        label="Jumlah"
        value={formatToCurrency(withdrawRequest.amount)}
        className=""
      />

      <div className="mt-4 border rounded p-4">
        <div className="text-lg font-bold">Bank Details</div>

        <AffiliateWithdrawDetailItemComponent
          label="Bank Name"
          value={withdrawRequest.bank.bankName}
          className="mt-4"
        />

        <AffiliateWithdrawDetailItemComponent
          label="Bank Holder Name"
          value={withdrawRequest.bank.bankHolderName}
          className=""
        />

        <AffiliateWithdrawDetailItemComponent
          label="Bank Account Name"
          value={withdrawRequest.bank.bankAccountNumber}
          className=""
        />
      </div>

      {withdrawRequest.notes && withdrawRequest.notes.length > 0 && (
        <div className="mt-4">
          <div>Catatan</div>
          <div className="text-justify mt-2 font-bold">
            {withdrawRequest.notes}
          </div>
        </div>
      )}

      <div className="my-4 w-full flex justify-center">
        <button
          type="button"
          className="button-outline-with-hover mx-2"
          onClick={onCancel}
        >
          Close
        </button>

        {withdrawRequest.status === "requested" && (
          <button
            type="button"
            className="button-red-outline-with-hover mx-2"
            onClick={onReject}
          >
            Reject
          </button>
        )}

        {withdrawRequest.status !== "rejected" &&
          withdrawRequest.status !== "completed" && (
            <button
              type="button"
              className="button-yellow-outline-with-hover mx-2"
              onClick={onApprove}
            >
              {withdrawRequest.status === "approved" ? "Complete" : "Approve"}
            </button>
          )}
      </div>
    </div>
  );
};
