/* eslint-disable react-hooks/exhaustive-deps */
import { BottomListComponent } from "@Components/BottomList.component";
import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { ContentHeader } from "@Components/ContentHeader.component";
import { SearchInputComponent } from "@Components/inputs/SearchInputs.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { convertDateFromApiToView } from "@Helpers/formatter";
import { REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE } from "@Helpers/regex";
import { buildQueryParamsFromUrl, buildUrlSearchParams } from "@Helpers/utils";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { FaExclamationTriangle, FaRegTrashAlt } from "react-icons/fa";
import { BsPencilSquare } from "react-icons/bs";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ListVoucherModel } from "../data/voucher.model";
import { DeleteVoucherUsecase } from "../domain/DeleteVoucher.usecase";
import { GetAllVouchersUsecase } from "../domain/GetAllVouchers.usecase";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";

export const VoucherListPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const modalContext = UseModalContext();
  const toasterContext = UseToasterContext();

  const searchRef = useRef() as MutableRefObject<HTMLInputElement>;

  const [needRefetch, setNeedRefetch] = useState(true);
  const [apiData, setApiData] = useState<ListVoucherModel>();

  useEffect(() => {
    if (needRefetch) {
      let queryParams = buildQueryParamsFromUrl(searchParams.entries());

      if (queryParams !== "") {
        queryParams = "?" + queryParams;
      }

      GetAllVouchersUsecase(queryParams)
        .then((response) => {
          setApiData(response);
        })
        .catch((errors) =>
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Failed to fetch orders",
              errors.message,
              ToasterType.DANGER
            ),
          ])
        );

      setNeedRefetch(false);
    }
  }, [needRefetch]);

  const handleSearch = (e: any) => {
    if (e.key === "Enter") {
      let clearedString = e.target.value
        .replace(REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE, "")
        .replaceAll(" ", "+");

      setNeedRefetch(true);
      navigate({
        search:
          "?" +
          buildUrlSearchParams(searchParams.entries(), "search", clearedString),
      });
    }
  };

  const canGoPrev = () =>
    (apiData && apiData.meta && apiData.meta.page > 1) ?? false;

  const onPrevPage = () => {
    if (apiData && apiData.meta && canGoPrev()) {
      let prevPage = apiData.meta.page - 1;
      if (prevPage >= 1) {
        setNeedRefetch(true);
        navigate({
          search:
            "?" +
            buildUrlSearchParams(
              searchParams.entries(),
              "page",
              prevPage.toString()
            ),
        });
      }
    }
  };

  const canGoNext = () =>
    (apiData && apiData.meta && apiData.meta.end < apiData.meta.total) ?? false;

  const onNextPage = () => {
    if (apiData && apiData.meta && canGoNext()) {
      let nextPage = apiData.meta.page + 1;
      setNeedRefetch(true);
      navigate({
        search:
          "?" +
          buildUrlSearchParams(
            searchParams.entries(),
            "page",
            nextPage.toString()
          ),
      });
    }
  };

  const getQueryStatus = () => {
    let status = searchParams.get("status");
    if (status == null) {
      status = "all";
    }
    return status;
  };

  const onFilterProductStatus = (selectedStatus: string) => {
    setNeedRefetch(true);
    navigate({
      search:
        "?" +
        buildUrlSearchParams(searchParams.entries(), "status", selectedStatus),
    });
  };

  const onChangeLimit = (newValue: string) => {
    setNeedRefetch(true);
    navigate({
      search:
        "?" + buildUrlSearchParams(searchParams.entries(), "limit", newValue),
    });
  };

  const onDelete = async (index: number) => {
    try {
      await DeleteVoucherUsecase(apiData?.list[index].id.toString() ?? "");
      setNeedRefetch(true);
      modalContext.setModal(null);
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to fetch orders",
          errors.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const onShowDeleteModal = (index: number) => {
    let icon = (
      <div className="text-red-500">
        <FaExclamationTriangle />
      </div>
    );
    let title = "Hapus Produk";
    let description = (
      <>
        Apakah anda yakin ingin menghapus{" "}
        <span className="font-bold">{apiData?.list[index].title}</span> ?
      </>
    );
    let primaryCta = (
      <button
        className="button-red-outline-with-hover"
        onClick={() => onDelete(index)}
      >
        Hapus
      </button>
    );
    let secondaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(icon, title, description, primaryCta, secondaryCta)
    );
  };

  return (
    <div className="m-4">
      {!apiData && <MidasShimmering />}
      {apiData && (
        <>
          <ContentHeader
            title={"Voucher List"}
            rightButton={
              <YellowHoverButton
                label={"Add New"}
                destination="/vouchers/add"
              />
            }
          />
          <div className="h-6"></div>

          {/* top config */}
          <div className="border p-3 align-right flex flex-col justify-between shadow-sm md:flex-row">
            <div>
              {/* status filter */}
              <select
                className="mr-1 border rounded pl-1 pr-1"
                value={getQueryStatus()}
                onChange={(event) => onFilterProductStatus(event.target.value)}
              >
                <option value="all">All</option>
                <option value="draft">Draft</option>
                <option value="publish">Published</option>
                <option value="expired">Expired</option>
                <option value="trash">Trash</option>
              </select>
            </div>
            <div className="border rounded mt-2 md:mt-0 flex">
              <SearchInputComponent
                searchRef={searchRef}
                onKeyDown={handleSearch}
              />
            </div>
          </div>

          {/* list items */}
          <div className="mt-4">
            {apiData.list.length === 0 && (
              <div className="text-center bg-gray-300 p-4">
                No Voucher Available!
              </div>
            )}
            {apiData.list.length > 0 && (
              <table className="table-auto border w-full">
                <thead className="p-2 shadow-sm bg-gray-100 sticky">
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Value</th>
                    <th className="hidden md:table-cell">Quantity</th>
                    <th className="hidden md:table-cell">Expired Date</th>
                    <th className="hidden md:table-cell">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {apiData.list.map((item, index) => (
                    <tr key={item.id}>
                      <td className="text-center">{index + 1}</td>
                      <td className="p-2">
                        <div className="font-bold text-ellipsis">
                          {item.title}
                        </div>
                        <div className="flex mt-3">
                          <Link to={"/vouchers/detail/" + item.id}>
                            <button
                              className="text-blue-400 mr-2 w-6 h-6"
                              title="View Detail"
                            >
                              <BsPencilSquare />
                            </button>
                          </Link>
                          <button
                            className="text-red-400 mr-2 w-6 h-6"
                            title="Delete Permanently"
                            onClick={() => onShowDeleteModal(index)}
                          >
                            <FaRegTrashAlt />
                          </button>
                        </div>
                      </td>
                      <td className="text-center uppercase">{item.code}</td>
                      <td className="text-center">
                        {item.value} ({item.type})
                      </td>
                      <td className="text-center hidden md:table-cell">
                        {item.used} used out of {item.max_usage}
                      </td>
                      <td className="text-center hidden md:table-cell">
                        {convertDateFromApiToView(item.expires_at)}
                      </td>
                      <td className="text-center hidden md:table-cell">
                        {item.deleted_at != null ? (
                          <div className="text-red-500">In Trash</div>
                        ) : item.status === "draft" ? (
                          <div className="text-yellow-500">Draft</div>
                        ) : item.status === "expired" ? (
                          <div className="text-orange-500">Expired</div>
                        ) : item.status === "exceed_limit" ? (
                          <div className="text-orange-500">All Used</div>
                        ) : (
                          <div className="text-green-500">Published</div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {apiData.meta && (
              <BottomListComponent
                shouldShow={apiData.meta !== undefined}
                onLimitChange={onChangeLimit}
                canGoPrev={canGoPrev()}
                onGoToPrev={onPrevPage}
                canGoNext={canGoNext()}
                onGoToNext={onNextPage}
                meta={apiData.meta}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
