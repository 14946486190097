import { AccountInformationModel } from "@Pages/get-response/data/account.model";
import { FC } from "react";
import { SectionProps } from "../GetResponse.page";

export type AccountProps = SectionProps & {
  account?: AccountInformationModel;
};

export const AccountSection: FC<AccountProps> = ({ account, needRefetch }) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div>Account Id</div>
      <div className="font-bold">{account?.id ?? "N/A"}</div>

      <div>First Name</div>
      <div className="font-bold">{account?.firstName ?? "N/A"}</div>

      <div>Last Name</div>
      <div className="font-bold">{account?.lastName ?? "N/A"}</div>

      <div>Email</div>
      <div className="font-bold">{account?.email ?? "N/A"}</div>

      <div>Phone</div>
      <div className="font-bold">{account?.phone ?? "N/A"}</div>

      <div>Company Name</div>
      <div className="font-bold">{account?.companyName ?? "N/A"}</div>

      <div>State</div>
      <div className="font-bold">{account?.state ?? "N/A"}</div>

      <div>City</div>
      <div className="font-bold">{account?.city ?? "N/A"}</div>

      <div>Street</div>
      <div className="font-bold">{account?.street ?? "N/A"}</div>

      <div>Zip Code</div>
      <div className="font-bold">{account?.zipCode ?? "N/A"}</div>

      <div>Country Code</div>
      <div className="font-bold">
        {account?.countryCode ?? "N/A"} ({account?.countryCodeId})
      </div>

      <div>Number Of Employess</div>
      <div className="font-bold">{account?.numberOfEmployees ?? "N/A"}</div>

      <div>Badge Status</div>
      <div className="font-bold">{account?.badgeStatus ?? "N/A"}</div>
    </div>
  );
};
