import { PATH_SETTINGS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const BulkUpdateSettingsUsecase: (
  data: string
) => Promise<string> = async (data) => {
  try {
    let formData = new FormData();
    formData.append("data", data);

    let response = await client.put(PATH_SETTINGS, formData);
    return response.data.message;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
