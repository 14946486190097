import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { ProductModel } from "@Pages/product/data/product.model";
import { SaveSettingsUsecase } from "@Pages/setting/domain/SaveSettings.usecase";
import {
  FREE_TRIALS_SELECTED_STOCK,
  MEMBER_OFFER_MEMBER_AND_PORTOFOLIO,
  MEMBER_OFFER_MEMBER_ONLY,
  MEMBER_OFFER_PORTOFOLIO_ONLY,
  MEMBER_PORTFOLIO_STOCKS,
  MEMBER_SELECTED_STOCKS,
  MEMBER_UPGRADE_MEMBER,
  MEMBER_UPGRADE_PORTOFOLIO,
  MEMBER_WATCHLIST_STOCKS,
  USER_UPGRADE_SERVICES,
} from "@Pages/setting/utils/const";
import { FC, useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp, FaPlus } from "react-icons/fa";
import { SettingSectionWithProductProps } from "../SettingStaticPages.page";
import { useForm } from "react-hook-form";
import { WatchlistFormComponent, WatchlistInputModel } from "../components/WatchlistForm.component";

export type MemberAreaOfferInputType = {
  productId: string;
  productName: string;
  showSale: boolean;
};

export type MemberAreaProductItemProps = {
  productList: ProductModel[];
  data: MemberAreaOfferInputType;
  index: number;
  onChange: (index: number, data: MemberAreaOfferInputType) => void;
};

export type MemberAreaProps = {
  title: string;
  offerList: MemberAreaOfferInputType[];
  onSaveOffer: (offerList: MemberAreaOfferInputType[]) => void;
  productList: ProductModel[];
};

export type SelectedAnalysisStockProps = {
  title: string;
  stockList: string;
  onSaveStocks: (stocks: string) => void;
};

export const MemberAreaProductItem: FC<MemberAreaProductItemProps> = ({
  productList,
  data,
  index,
  onChange,
}) => {
  return (
    <div className="my-2 rounded-xl bg-slate-300 border shadow p-4">
      <div className="flex mt-2">
        <label className="font-medium my-auto w-32">Select Product</label>
        <select
          className={"input-field-full-width"}
          value={data.productId}
          onChange={(e) => {
            if (data.productId !== e.target.value) {
              let productDetail = productList.find(
                (item) => e.target.value === item.id.toString()
              );

              let newDetail = { ...data };
              newDetail.productId = e.target.value;
              newDetail.productName = `${productDetail?.title}`;

              onChange(index, newDetail);
            }
          }}
        >
          <option value="">Pilih Produk</option>
          {productList.map((item) => (
            <option
              key={item.id}
              value={item.id}
            >{`${item.title} - ${item.duration_length} ${item.duration_type}`}</option>
          ))}
        </select>
      </div>

      <div className="w-full mt-2">
        <input
          type={"checkbox"}
          checked={data.showSale}
          onChange={(e) => {
            let newDetail = { ...data };
            newDetail.showSale = e.target.checked;
            onChange(index, newDetail);
          }}
        />{" "}
        Show Sale Label
      </div>
    </div>
  );
};

export const MemberAreaSubSection: FC<MemberAreaProps> = ({
  title,
  productList,
  offerList,
  onSaveOffer,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [dataList, setDataList] =
    useState<MemberAreaOfferInputType[]>(offerList);

  const onChange = (index: number, data: MemberAreaOfferInputType) => {
    let newDataList = [...dataList];
    newDataList[index] = data;
    setDataList(newDataList);
  };

  const onAddProduct = () => {
    let newDataList = [...dataList];
    newDataList.splice(0, 0, {
      productId: "",
      productName: "",
      showSale: false,
    });
    setDataList(newDataList);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div
          className="font-bold text-3xl w-full"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {title}
        </div>

        <div className="flex">
          {!isCollapsed && (
            <button
              className="button-yellow-outline-with-hover mr-2"
              onClick={onAddProduct}
            >
              <FaPlus />
            </button>
          )}
          {!isCollapsed && (
            <button
              className="button-yellow-outline-with-hover"
              onClick={() => onSaveOffer(dataList)}
            >
              Save
            </button>
          )}
          <button
            className="ml-4 mx-auto"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </div>
      </div>

      {!isCollapsed &&
        dataList.map((item, index) => (
          <MemberAreaProductItem
            key={`MemberAreaOffer-${title}-${index}`}
            productList={productList}
            data={item}
            index={index}
            onChange={onChange}
          />
        ))}
    </div>
  );
};

export type StockListFormInputs = {
  stocks: string;
};

export const SeletedStockAnalysis: FC<SelectedAnalysisStockProps> = ({
  title,
  stockList,
  onSaveStocks,
}) => {
  const { register, setValue, watch } = useForm<StockListFormInputs>();

  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    setValue("stocks", stockList);
  }, [stockList]);

  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <div
          className="font-bold text-3xl w-full"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {title}
        </div>

        <div className="flex">
          {!isCollapsed && (
            <button
              className="button-yellow-outline-with-hover"
              onClick={() => onSaveStocks(watch("stocks") ?? "")}
            >
              Save
            </button>
          )}
          <button
            className="ml-4 mx-auto"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </div>
      </div>

      {!isCollapsed && (
        <div className="mt-4">
          <input className="input-field-full-width " {...register("stocks")} />
          <div className="text-xs font-bold">Pisahkan dengan ;</div>
        </div>
      )}
    </div>
  );
};

export const MemberAreaSection: FC<SettingSectionWithProductProps> = ({
  settingsData,
  productList,
  setNeedRefetch,
}) => {
  const toasterContext = UseToasterContext();

  const getOfferList = (key: string) => {
    let selectedProductString = settingsData.find((item) => item.key === key);
    if (selectedProductString) {
      let parsed = JSON.parse(selectedProductString.value);
      return parsed;
    }
    return [];
  };

  const getStockList = () => {
    let stockList = settingsData.find(
      (item) => item.key === MEMBER_SELECTED_STOCKS
    );
    return stockList?.value ?? "";
  };

  const getFreeTrialStockList = () => {
    let stockList = settingsData.find(
      (item) => item.key === FREE_TRIALS_SELECTED_STOCK
    );
    return stockList?.value ?? "";
  };

  const getPortfolioStockList = () => {
    let stockList = settingsData.find(
      (item) => item.key === MEMBER_PORTFOLIO_STOCKS
    );
    return stockList?.value ?? "";
  };

  const getWatchlistStockList = () => {
    let data = settingsData.find((item) => item.key === MEMBER_WATCHLIST_STOCKS);
    if (data?.value) {
      let parsed = JSON.parse(data?.value);
      return parsed;
    }
    return [];
  };

  const onSaveOffers = async (
    key: string,
    offerList: MemberAreaOfferInputType[]
  ) => {
    let offerStringify = JSON.stringify(offerList);
    let offerString = settingsData.find((item) => item.key === key);

    try {
      let response = await SaveSettingsUsecase(
        offerString !== undefined,
        key,
        offerStringify
      );
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Offer updated successfully!",
          response,
          ToasterType.SUCCESS
        ),
      ]);
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to update offer!",
          e.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const onSaveSelectedStocks = async (stocks: string) => {
    try {
      let formattedStockList = stocks
        .split(";")
        .map((item) => item.trim())
        .join(";");
      let response = await SaveSettingsUsecase(
        true,
        MEMBER_SELECTED_STOCKS,
        formattedStockList
      );
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Selected Stocks updated successfully!",
          response,
          ToasterType.SUCCESS
        ),
      ]);
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to update selected stocks!",
          e.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const onSaveFreeTrialSelectedStocks = async (stocks: string) => {
    try {
      let formattedStockList = stocks
        .split(";")
        .map((item) => item.trim())
        .join(";");
      let response = await SaveSettingsUsecase(
        true,
        FREE_TRIALS_SELECTED_STOCK,
        formattedStockList
      );
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Selected Stocks updated successfully!",
          response,
          ToasterType.SUCCESS
        ),
      ]);
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to update selected stocks!",
          e.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const onSavePortfolioStocks = async (stocks: string) => {
    try {
      let formattedStockList = stocks
        .split(";")
        .map((item) => item.trim())
        .join(";");
      let response = await SaveSettingsUsecase(
        true,
        MEMBER_PORTFOLIO_STOCKS,
        formattedStockList
      );
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Portfolio Stocks updated successfully!",
          response,
          ToasterType.SUCCESS
        ),
      ]);
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to update portfolio stocks!",
          e.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const saveSetting = (
    type: string,
    isUpdating: boolean,
    key: string,
    value: string
  ) => {
    SaveSettingsUsecase(isUpdating, key, value)
      .then((message) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            `${type} updated successfully!`,
            message,
            ToasterType.SUCCESS
          ),
        ]);
        setNeedRefetch(true);
      })
      .catch((errors) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to update settings!",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      });
  };

  const onSaveWatchlistList = (data: WatchlistInputModel[]) => {
    if (data.length === 0) {
      return;
    }

    let listStringify = JSON.stringify(data);
    listStringify.replaceAll("null,", "");
    let currentList = settingsData.find(
      (item) => item.key === MEMBER_WATCHLIST_STOCKS
    );

    saveSetting(
      "Watchlist",
      currentList !== undefined,
      MEMBER_WATCHLIST_STOCKS,
      listStringify
    );
  };

  return (
    <div className="mt-4">
      <SeletedStockAnalysis
        stockList={getStockList()}
        onSaveStocks={onSaveSelectedStocks}
        title={"Selected Stocks Analysis"}
      />
      <div className="w-full border-t mt-4"></div>

      <SeletedStockAnalysis
        stockList={getFreeTrialStockList()}
        onSaveStocks={onSaveFreeTrialSelectedStocks}
        title={"Selected FREE TRIALS Stocks Analysis"}
      />
      <div className="w-full border-t mt-4"></div>

      <SeletedStockAnalysis
        stockList={getPortfolioStockList()}
        onSaveStocks={onSavePortfolioStocks}
        title={"Portfolio Stocks"}
      />
      <div className="w-full border-t mt-4"></div>
      <MemberAreaSubSection
        offerList={getOfferList(MEMBER_OFFER_MEMBER_ONLY)}
        onSaveOffer={(offerList) =>
          onSaveOffers(MEMBER_OFFER_MEMBER_ONLY, offerList)
        }
        productList={productList}
        title={"Offer for MEMBERSHIP ONLY"}
      />
      <div className="w-full border-t mt-4"></div>

      <MemberAreaSubSection
        offerList={getOfferList(MEMBER_OFFER_PORTOFOLIO_ONLY)}
        onSaveOffer={(offerList) =>
          onSaveOffers(MEMBER_OFFER_PORTOFOLIO_ONLY, offerList)
        }
        productList={productList}
        title={"Offer for PORTOFOLIO ONLY"}
      />
      <div className="w-full border-t mt-4"></div>

      <MemberAreaSubSection
        offerList={getOfferList(MEMBER_OFFER_MEMBER_AND_PORTOFOLIO)}
        onSaveOffer={(offerList) =>
          onSaveOffers(MEMBER_OFFER_MEMBER_AND_PORTOFOLIO, offerList)
        }
        productList={productList}
        title={"Offer for MEMBERSHIP & PORTOFOLIO"}
      />
      <div className="w-full border-t mt-4"></div>

      <MemberAreaSubSection
        offerList={getOfferList(MEMBER_UPGRADE_MEMBER)}
        onSaveOffer={(offerList) =>
          onSaveOffers(MEMBER_UPGRADE_MEMBER, offerList)
        }
        productList={productList}
        title={"Upgrade for MEMBERSHIP"}
      />
      <div className="w-full border-t mt-4"></div>

      <MemberAreaSubSection
        offerList={getOfferList(MEMBER_UPGRADE_PORTOFOLIO)}
        onSaveOffer={(offerList) =>
          onSaveOffers(MEMBER_UPGRADE_PORTOFOLIO, offerList)
        }
        productList={productList}
        title={"Upgrade for PORTOFOLIO"}
      />

      <div className="w-full border-t mt-4"></div>

      <MemberAreaSubSection
        offerList={getOfferList(USER_UPGRADE_SERVICES)}
        onSaveOffer={(offerList) =>
          onSaveOffers(USER_UPGRADE_SERVICES, offerList)
        }
        productList={productList}
        title={"Upgrade for NEW / EXPIRED User"}
      />
    </div>
  );
};
