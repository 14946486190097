/* eslint-disable react-hooks/exhaustive-deps */
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { MediaChooser } from "@Pages/common/presentation/MediaChooser.page";
import {
  PageConfigModel,
  UsePageEditorContext,
} from "@Pages/page/context/page-editor.context";
import { ResourceModel } from "@Pages/resources/data/resource.model";
import { useEffect, useState } from "react";
import { ImageModel } from "../../models/image.model";
import { NumberField } from "../forms/NumberField.component";
import { Slider } from "../forms/Slider.component";

export const ImageViewConfig = () => {
  const { pageConfigs, setPageConfigs } = UsePageEditorContext();
  const modalContext = UseModalContext();

  const [usePercentage, setUsePercentage] = useState(false);
  const [autoHeight, setAutoHeight] = useState(false);

  const editingModel: ImageModel = pageConfigs.pageComponents[
    pageConfigs.editIndex
  ] as ImageModel;

  useEffect(() => {
    setUsePercentage(editingModel.widthPercentage != null);
    setAutoHeight(editingModel.height == null);
  }, []);

  const updateOldConfig = (newConfig: PageConfigModel) => {
    if (
      pageConfigs.oldModel == null ||
      typeof pageConfigs.oldModel == "undefined"
    ) {
      newConfig.oldModel = new ImageModel(
        editingModel.componentKey,
        editingModel.imageUrl,
        editingModel.width,
        editingModel.widthPercentage,
        editingModel.height,
        editingModel.objectFit,
        editingModel.altText
      );
    }
  };

  const onChangeUrl = (event: any) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldConfig(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as ImageModel).imageUrl =
      event.target.value;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeAltText = (event: any) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldConfig(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as ImageModel).altText =
      event.target.value;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeWidth = (newValue: number) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldConfig(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as ImageModel).width = newValue;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeWidthPercentage = (newValue: number) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldConfig(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as ImageModel).width = null;
    (pageComponents[pageConfigs.editIndex] as ImageModel).widthPercentage =
      newValue;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeScale = (event: any) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldConfig(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as ImageModel).objectFit =
      event.target.value;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onChangeHeight = (newValue: number | null) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldConfig(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as ImageModel).height = newValue;

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
  };

  const onSelectMediaClicked = () => {
    let title = "Select Media";
    let description = (
      <MediaChooser type={"image"} onMediaSelected={onMediaSelected} />
    );
    let cancelButton = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(null, title, description, cancelButton, null)
    );
  };

  const onMediaSelected = (data: ResourceModel) => {
    let newConfig = {
      ...pageConfigs,
    };

    updateOldConfig(newConfig);

    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as ImageModel).imageUrl =
      data.link_url;
    (pageComponents[pageConfigs.editIndex] as ImageModel).altText =
      data?.alt_text ?? "";

    newConfig.pageComponents = pageComponents;

    setPageConfigs(newConfig);
    modalContext.setModal(null);
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <input
          type="url"
          className={"input-field-full-width"}
          value={editingModel.imageUrl}
          onChange={onChangeUrl}
          placeholder={"Image URL"}
        />
        <button
          className="button-outline-with-hover"
          onClick={onSelectMediaClicked}
        >
          Select Media
        </button>

        {usePercentage ? (
          <>
            Width Percentage
            <Slider
              min={1}
              max={100}
              sliderValue={editingModel.widthPercentage}
              postfix={"%"}
              onChange={onChangeWidthPercentage}
            />
          </>
        ) : (
          <NumberField
            fieldId="imageWidth"
            label="Width"
            min={1}
            value={editingModel.width || 1}
            onChange={onChangeWidth}
          />
        )}

        <div className="flex">
          <div className="w-full">Use Percentage</div>
          <input
            type="checkbox"
            checked={usePercentage}
            onChange={(e) => setUsePercentage(e.target.checked)}
          />
        </div>

        <NumberField
          fieldId="imageHeight"
          label="Height"
          min={1}
          value={editingModel.height || 1}
          onChange={onChangeHeight}
          disableField={autoHeight}
        />

        <div className="flex">
          <div className="w-full">Auto Height</div>
          <div className="text-right">
            <input
              type="checkbox"
              id="image-auto-height"
              checked={autoHeight}
              className="input-field-full-width"
              onChange={(e) => {
                if (e.target.checked) {
                  onChangeHeight(null);
                }
                setAutoHeight(e.target.checked);
              }}
            />
          </div>
        </div>

        <div className="flex">
          <div className="w-full">Scale Type</div>
          <div className="w-full">
            <select
              value={editingModel.objectFit}
              onChange={onChangeScale}
              className="input-field-full-width"
            >
              <option value="fill">Fill</option>
              <option value="contain">Container</option>
              <option value="cover">Cover</option>
              <option value="scale-down">Scale Down</option>
              <option value="none">None</option>
            </select>
          </div>
        </div>

        <div>
          Alternative Text
          <input
            type="url"
            className={"input-field-full-width"}
            value={editingModel.altText}
            onChange={onChangeAltText}
            placeholder={"Alternative Text"}
          />
        </div>
      </div>
    </>
  );
};
