import { FC } from "react";
import { MouseEventHandler } from "react";
import { Link } from "react-router-dom";

export type YellowHoverButtonProps = {
  label: string;
  destination?: string;
  onClick?: MouseEventHandler;
  customClass?: string;
};

export const YellowHoverButton: FC<YellowHoverButtonProps> = ({
  label,
  destination,
  onClick,
  customClass,
}) => {
  let button =
    onClick != null ? (
      <button
        className={`button-yellow-outline-with-hover ${
          customClass ? customClass : ""
        }`}
        type="button"
        onClick={onClick}
      >
        {label}
      </button>
    ) : (
      <button
        className={`button-yellow-outline-with-hover ${
          customClass ? customClass : ""
        }`}
        type="button"
      >
        {label}
      </button>
    );

  return destination != null ? <Link to={destination}>{button}</Link> : button;
};
