import { PATH_USERS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const UploadCsvUsecase: (files: FileList) => Promise<boolean> = async (
  files
) => {
  try {
    let formData = new FormData();
    formData.append("file", files[0]);

    await client.post(PATH_USERS + "/upload-csv", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
