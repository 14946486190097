import { ContentHeader } from "@Components/ContentHeader.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AddRecordUsecase } from "../domain/AddRecord.usecase";

export type AddRecordFormInputs = {
  title: string;
  description?: string;
  zoom_link: string;
};

export const AddRecordPage = () => {
  const navigate = useNavigate();

  const toasterContext = UseToasterContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddRecordFormInputs>();

  const onSubmit = async (data: AddRecordFormInputs) => {
    try {
      let formData = new FormData();
      formData.append("title", data.title);
      formData.append("description", data.description ?? "");
      formData.append("zoom_link", data.zoom_link);
      formData.append("status", "published");

      await AddRecordUsecase(formData);
      navigate(-1);
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to add record!",
          errors.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  return (
    <div className="bg-white m-4">
      <ContentHeader
        title="Add New Record"
        rightButton={
          <button
            className="button-red-outline-with-hover"
            type="button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
        }
      />

      <form className="w-full mt-6" onSubmit={handleSubmit(onSubmit)}>
        {/* Recording Name */}
        <div className="flex mt-4">
          <label className="font-medium my-auto w-28" htmlFor="title">
            Title<span className="text-red-500">*</span>
          </label>
          <div className="w-full">
            <input
              className={`input-field-full-width ${
                errors.title && "border-red-500"
              }`}
              type="text"
              {...register("title", {
                required: "Title is required",
              })}
            />
            {errors.title && (
              <p className="text-red-500 text-xs">{errors.title?.message}</p>
            )}
          </div>
        </div>

        {/* Description field */}
        <div className="flex mt-4">
          <label className="font-medium w-28 mt-1" htmlFor="description">
            Description
          </label>
          <div className="w-full">
            <input
              className="input-field-full-width"
              type="text"
              {...register("description")}
            />
            {errors.description && (
              <p className="text-red-500 text-xs">
                {errors.description?.message}
              </p>
            )}
          </div>
        </div>

        {/* Zoom URL field */}
        <div className="flex mt-4">
          <label className="font-medium w-28 mt-1" htmlFor="zoom_link">
            Zoom URL<span className="text-red-500">*</span>
          </label>
          <div className="w-full">
            <input
              className="input-field-full-width"
              type={"url"}
              {...register("zoom_link", {
                required: "Zoom URL is required",
              })}
            />
            {errors.zoom_link && (
              <p className="text-red-500 text-xs">
                {errors.zoom_link?.message}
              </p>
            )}
          </div>
        </div>

        <button
          className="button-yellow-outline-with-hover w-full mt-4"
          type="submit"
        >
          Publish
        </button>
      </form>
    </div>
  );
};
