/* eslint-disable react-hooks/exhaustive-deps */
import { ContentHeader } from "@Components/ContentHeader.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE } from "@Helpers/regex";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AvailableRoles } from "../data/role_const";
import { AddRoleUsecase } from "../domain/AddRole.usecase";

export type AddRecordFormInputs = {
  title: string;
  role: string;
  access: string[];
};

export const AddRolePage = () => {
  const navigate = useNavigate();
  const [rolesCount, setRolesCount] = useState({});
  const [availableRolesMapped, setAvailableRolesMapped] =
    useState<Map<string, string[]>>();

  const toasterContext = UseToasterContext();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<AddRecordFormInputs>();

  useEffect(() => {
    let availableRolesCount = { ...rolesCount };
    let roleMapped = new Map();

    Object.entries(AvailableRoles).forEach((role) => {
      let roleCount = 0;
      let pageRoles = {};

      let parentRoles: string[] = [];

      Object.entries(role[1]).forEach((pageRole) => {
        let pageRoleCount = 0;

        let childRoles: string[] = [];

        pageRole[1].forEach((item) => {
          ++pageRoleCount;
          ++roleCount;

          let accesses = item.access.split(";");

          roleMapped.set(
            `${role[0].toLowerCase()}::${pageRole[0].toLowerCase()}::${item.label.toLowerCase()}`,
            accesses
          );

          accesses.forEach((mAccess) => {
            if (childRoles.indexOf(mAccess) === -1) childRoles.push(mAccess);
          });
        });

        roleMapped.set(
          `${role[0].toLowerCase()}::${pageRole[0].toLowerCase()}`,
          childRoles
        );
        parentRoles = [...parentRoles, ...childRoles];

        pageRoles = {
          ...pageRoles,
          [pageRole[0]]: pageRoleCount,
        };
      });

      roleMapped.set(role[0].toLowerCase(), parentRoles);

      availableRolesCount = {
        ...availableRolesCount,
        [role[0]]: {
          pageRoles: pageRoles,
          count: roleCount,
        },
      };
    });

    setAvailableRolesMapped(roleMapped);
    setRolesCount(availableRolesCount);
  }, []);

  const onSubmit = async (data: AddRecordFormInputs) => {
    try {
      let newAccess: string[] = [];

      data.access.forEach((item) => {
        if (item !== "") {
          let splitted = item.split(";");
          if (splitted.length > 0) {
            newAccess.push(...splitted);
          }
        }
      });

      let setAccess = new Set(newAccess);

      await AddRoleUsecase(data.title, data.role, Array.from(setAccess));
      navigate(-1);
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to add role!",
          errors.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const onSelectAll = (role: string, pageRole: string, isSelected: boolean) => {
    let allSelectedRoles: string[] = [];

    function addRole(roles: string[], newRole: string) {
      roles.push(newRole);
    }

    function processAccess(actionList: any[]) {
      actionList.forEach((action) => {
        let accessName = action.access;

        if (isSelected) {
          addRole(allSelectedRoles, accessName);
        } else {
          let currentAccess = watch("access");
          setValue(
            "access",
            currentAccess.filter((item) => item !== accessName)
          );
        }
      });
    }

    Object.entries(AvailableRoles).forEach((roles) => {
      if (roles[0] === role) {
        Object.entries(roles[1]).forEach((pageRoleItem) => {
          if (
            (pageRole !== "" && pageRoleItem[0] === pageRole) ||
            pageRole === ""
          ) {
            processAccess(pageRoleItem[1]);
          }
        });
      }
    });

    if (isSelected) {
      if (watch("access")) {
        setValue("access", [
          ...new Set([...watch("access"), ...allSelectedRoles]),
        ]);
      } else {
        setValue("access", allSelectedRoles);
      }
    }
  };

  const isChecked = (role: string, pageRole: string) => {
    try {
      let key = "";
      if (role !== "" && pageRole !== "") {
        key = `${role.toLowerCase()}::${pageRole.toLowerCase()}`;
      } else if (role !== "") {
        key = `${role.toLowerCase()}`;
      }

      if (key !== "" && availableRolesMapped) {
        let access = availableRolesMapped.get(key);
        if (!access || access?.length === 0) return false;

        let selectedAccess: string[] = [];
        if (watch("access"))
          watch("access").forEach((item) => {
            let splitted = item.split(";");
            splitted.forEach((splitItem) => {
              if (selectedAccess.indexOf(splitItem) === -1)
                selectedAccess.push(splitItem);
            });
          });

        let selected = true;
        access.forEach((item) => {
          if (!selectedAccess.includes(item)) selected = false;
        });

        return selected;
      }

      return false;
    } catch (e) {}

    return false;
  };

  return (
    <div className="bg-white m-4">
      <ContentHeader
        title="Add New Role"
        rightButton={
          <button
            className="button-red-outline-with-hover"
            type="button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
        }
      />

      <form className="w-full mt-6" onSubmit={handleSubmit(onSubmit)}>
        {/* Role title */}
        <div className="flex mt-4">
          <label className="font-medium my-auto w-28" htmlFor="title">
            Title<span className="text-red-500">*</span>
          </label>
          <div className="w-full">
            <input
              id="title"
              className={`input-field-full-width ${
                errors.title && "border-red-500"
              }`}
              type="text"
              {...register("title", {
                onChange: (e) => {
                  setValue(
                    "role",
                    e.target.value.toLowerCase().replaceAll(" ", "_")
                  );
                },
                required: "Title is required",
              })}
            />
            {errors.title && (
              <p className="text-red-500 text-xs">{errors.title?.message}</p>
            )}
          </div>
        </div>

        {/* Role field */}
        <div className="flex mt-4">
          <label className="font-medium w-28 mt-1" htmlFor="role">
            Role<span className="text-red-500">*</span>
          </label>
          <div className="w-full">
            <input
              id="role"
              className="input-field-full-width"
              type={"text"}
              {...register("role", {
                required: "Role is required",
                pattern: {
                  value: REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE,
                  message:
                    "Invalid role format, only alphanumeric and dash allowed",
                },
              })}
            />
            {errors.role && (
              <p className="text-red-500 text-xs">{errors.role?.message}</p>
            )}
          </div>
        </div>

        {/* Access field */}
        <div className="mt-4 font-bold text-2xl">
          Access<span className="text-red-500">*</span>
        </div>
        <div className="w-full mt-4">
          {Object.entries(AvailableRoles).map((role, index) => {
            return (
              <div key={`access-${role[0]}`} className="my-4">
                <div className="flex justify-between">
                  <div className="text-xl font-bold my-auto">{role[0]}</div>
                  <div className="flex mr-0 md:mr-4 my-auto">
                    <input
                      className="mr-2 my-auto"
                      type="checkbox"
                      checked={isChecked(role[0], "")}
                      onChange={(e) => {
                        onSelectAll(role[0], "", e.target.checked);
                      }}
                    />
                    <div className="my-auto">Select all</div>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4">
                  {Object.entries(role[1]).map((pageRole, pageRoleIndex) => {
                    return (
                      <div
                        key={`page-role-${role[0]}-${pageRole[0]}-${pageRoleIndex}`}
                        className={`p-2 border`}
                      >
                        <div className="flex justify-between">
                          <div className="font-bold my-auto">{pageRole[0]}</div>
                          <div className="flex mr-0 md:mr-4 my-auto">
                            <input
                              className="mr-2 my-auto"
                              type="checkbox"
                              checked={isChecked(role[0], pageRole[0])}
                              onChange={(e) => {
                                onSelectAll(
                                  role[0],
                                  pageRole[0],
                                  e.target.checked
                                );
                              }}
                            />
                            <div className="my-auto">Select all</div>
                          </div>
                        </div>
                        {pageRole[1].map((action) => {
                          return (
                            <div
                              key={`role-action-${role[0]}-${pageRole[0]}-${action.label}`}
                              className="flex mt-1"
                            >
                              <input
                                className="mr-2"
                                type="checkbox"
                                value={action.access}
                                {...register("access")}
                              />
                              {action.label}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        {errors.access && (
          <p className="text-red-500 text-xs">{errors.access?.message}</p>
        )}

        <button
          className="button-yellow-outline-with-hover w-full mt-4"
          type="submit"
        >
          Publish
        </button>
      </form>
    </div>
  );
};
