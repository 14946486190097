import { PATH_RESOURCES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const UpdateResourceUsecase: (
  id?: number,
  alt?: string,
  description?: string
) => Promise<boolean> = async (id, alt, description) => {
  try {
    if (!id) throw new Error("Id cannot be empty");

    let formData = new FormData();
    if (alt) formData.append("alt_text", alt);
    if (description) formData.append("description", description);

    await client.put(PATH_RESOURCES + "/" + id, formData);
    return true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
