import {
  PATH_GR_LANDING_PAGES,
  PATH_GR_LANDING_PAGES_DETAIL,
} from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import {
  LandingPageDetailModel,
  LandingPageModel,
} from "../data/landing-page.model";

export const LandingPageListUsecase: (
  page: number
) => Promise<LandingPageModel[]> = async (page) => {
  try {
    let response = await client.get(PATH_GR_LANDING_PAGES + `?page=${page}`);
    let data = response.data.data;

    return data.map((item: any) => {
      return {
        id: item.landingPageId,
        metaTitle: item.metaTitle,
        status: item.status,
        published: item.published,
      };
    });
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const DetailLandingPageUsecase: (
  id: string
) => Promise<LandingPageDetailModel> = async (id) => {
  try {
    let response = await client.get(
      PATH_GR_LANDING_PAGES_DETAIL.replace("{id}", id)
    );
    let data = response.data.data;

    return {
      id: data.landingPageId,
      title: data.metaTitle,
      description: data.metaDescription ?? "",
      status: data.status,
      favico: data.favicoUrl,
      url: data.url,
      published: data.published,
      slug: data.slug,
      variants: data.variants.map((item: any) => {
        return {
          id: item.variantId,
          variant: item.variant,
          winner: item.winner,
          visitors: item.visitors,
          uniqueVisitors: item.uniqueVisitors,
          subscribed: item.subscribed,
        };
      }),
    };
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const PublishLandingPageUsecase: (
  id: string,
  formData: FormData
) => Promise<any> = async (id, formData) => {
  try {
    await client.post(
      PATH_GR_LANDING_PAGES_DETAIL.replace("{id}", id),
      formData
    );
    return true;
  } catch (e) {
    throw new Error(e.response.message);
  }
};

export const UnpublishLandingPageUsecase: (id: string) => Promise<any> = async (
  id
) => {
  try {
    await client.delete(PATH_GR_LANDING_PAGES_DETAIL.replace("{id}", id));
    return true;
  } catch (e) {
    throw new Error(e.response.message);
  }
};
