/* eslint-disable react-hooks/exhaustive-deps */
import { AccordionComponent } from "@Components/accordion/Accordion.component";
import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { ContentHeader } from "@Components/ContentHeader.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { convertDateFromApiToView } from "@Helpers/formatter";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { UserModel } from "@Pages/common/data/user.model";
import { RoleModel } from "@Pages/role/data/role.model";
import { GetAllRolesUsecase } from "@Pages/role/domain/GetAllRoles.usecase";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaRegCalendarAlt, FaUserCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { GetUserUsecase } from "../domain/GetUser.usecase";
import { UpdateUserRoleUsecase } from "../domain/UpdateUserRole.usecase";

export type UpdateRoleInputs = {
  roleId: string;
  expiredDate: string;
};

export const DetailUserPage = () => {
  const params = useParams();

  const toasterContext = UseToasterContext();

  const [detail, setDetail] = useState<UserModel>();
  const [isAdditionalInfoOpen, setIsAdditionalInfoOpen] = useState(true);
  const [isRoleOpen, setIsRoleOpen] = useState(true);
  const [isSaveOpen, setIsSaveOpen] = useState(true);
  const [inUpdateRoleMode, setInUpdateRoleMode] = useState(false);
  const [needRefetch, setNeedRefetch] = useState(true);

  const [roleList, setRoleList] = useState<RoleModel[]>([]);

  const {
    register,
    trigger,
    watch,
    formState: { errors },
  } = useForm<UpdateRoleInputs>();

  useEffect(() => {
    if (needRefetch) {
      async function fetchDetail() {
        let data = await GetUserUsecase(params.id ?? "");
        setDetail(data);
        setNeedRefetch(false);
      }
      fetchDetail();
    }
  }, [needRefetch]);

  useEffect(() => {
    async function fetchRoles() {
      let data = await GetAllRolesUsecase("?limit=1000");
      setRoleList(data.list);
    }
    fetchRoles();
  }, []);

  const onUpdateRole = async () => {
    let valid = await trigger();
    if (!valid) return;

    let roleId = watch("roleId");
    let userId = params.id ?? "";
    let expiredDate = watch("expiredDate") + ":00+07:00";

    try {
      await UpdateUserRoleUsecase(userId, roleId, expiredDate);
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("Role berhasil di update", "", ToasterType.SUCCESS),
      ]);
      setNeedRefetch(true);
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel("Gagal mengupdate role", "", ToasterType.DANGER),
      ]);
    }
  };

  const buildUpdateRoleForm = () => {
    return (
      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 border border-black p-4 rounded shadow-xl">
        <div className="flex gap-4 ">
          <div className="my-auto">Role</div>
          <div className="w-full my-auto">
            <select
              className={`input-field-full-width border border-black ${
                errors.roleId && "border-red-500"
              }`}
              {...register("roleId", {
                required: "Role is required",
              })}
            >
              <option value="">Pilih Produk</option>
              {roleList.map((item, index) => {
                return (
                  <option key={`role-item-${item.id}-${index}`} value={item.id}>
                    {item.title}
                  </option>
                );
              })}
            </select>
            {errors.roleId && (
              <p className="text-red-500 text-xs">{errors.roleId?.message}</p>
            )}
          </div>
        </div>

        <div className="flex gap-4 ">
          <div className="my-auto">Expired</div>
          <div className="w-full my-auto">
            <input
              type="datetime-local"
              className={`input-field-full-width border border-black 
            ${errors.expiredDate && "border-red-500"}`}
              {...register("expiredDate", {
                required: "Expired date is required",
              })}
            />

            {errors.expiredDate && (
              <p className="text-red-500 text-xs">
                {errors.expiredDate?.message}
              </p>
            )}
          </div>
        </div>

        <YellowHoverButton
          label="Update"
          onClick={onUpdateRole}
          customClass="col-span-2"
        />
      </div>
    );
  };

  return (
    <div className="p-4 h-full bg-gray-300">
      {!detail && <MidasShimmering />}
      {detail && (
        <>
          <ContentHeader title="User Detail" />
          <div className="h-6"></div>

          <div className="border border-black rounded shadow-lg p-4 bg-white">
            <div className="flex justify-center">
              {detail.picture_url && (
                <img
                  src={detail.picture_url}
                  alt={`${detail.email}`}
                  className="w-20 h-20 rounded-full"
                />
              )}
              {!detail.picture_url && <FaUserCircle size={80} />}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <div className="flex">
                <div className="w-32">Fullname</div>
                <div className="font-bold">{detail.fullname}</div>
              </div>

              <div className="flex">
                <div className="w-32">Email</div>
                <div className="font-bold">{detail.email}</div>
              </div>

              <div className="flex">
                <div className="w-32">Phone</div>
                <div className="font-bold">
                  {detail.phone ? detail.phone : "N/A"}
                </div>
              </div>

              <div className="flex">
                <div className="w-32">Birthdate</div>
                <div className="font-bold">
                  {detail.birthdate ? detail.birthdate : "N/A"}
                </div>
              </div>

              <div className="flex">
                <div className="w-32">Telegram</div>
                <div className="font-bold">
                  {detail.telegram ? (
                    <a
                      href={`http://t.me/${detail.telegram}`}
                      target="_blank"
                      rel="noreferrer"
                    >{`@${detail.telegram}`}</a>
                  ) : (
                    "N/A"
                  )}
                </div>
              </div>
            </div>
          </div>

          {detail.additional_info && (
            <div className="mt-4 border border-black rounded shadow-lg bg-white">
              <AccordionComponent
                title="Additional Info"
                isOpen={isAdditionalInfoOpen}
                onToggle={(openStatus) => setIsAdditionalInfoOpen(openStatus)}
              >
                <div className="font-thin font-mono p-4 ">
                  {JSON.stringify(
                    JSON.parse(detail.additional_info ?? "{}"),
                    null,
                    2
                  )}
                </div>
              </AccordionComponent>
            </div>
          )}

          <div className="w-full mt-4 border border-black rounded bg-white shadow-lg">
            <AccordionComponent
              title="Roles"
              isOpen={isRoleOpen}
              onToggle={(openStatus) => setIsRoleOpen(openStatus)}
            >
              <div className="p-4">
                <div className="flex justify-end">
                  {inUpdateRoleMode && (
                    <button
                      className="button-red-outline-with-hover"
                      onClick={() => {
                        setInUpdateRoleMode(!inUpdateRoleMode);
                      }}
                    >
                      Cancel
                    </button>
                  )}
                  {!inUpdateRoleMode && (
                    <YellowHoverButton
                      label="Update"
                      onClick={() => {
                        setInUpdateRoleMode(!inUpdateRoleMode);
                      }}
                    />
                  )}
                </div>

                {inUpdateRoleMode && buildUpdateRoleForm()}

                <table className="table-auto border border-black border-collapse w-full mt-4">
                  <thead className="p-2 shadow-sm bg-gray-100 sticky">
                    <tr>
                      <th className=""></th>
                      <th className="">Role</th>
                      <th className="">Expired Date</th>
                      <th className="">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detail.member_roles.map((item, index) => (
                      <tr key={item.id}>
                        <td className="text-center border-b border-t border-black p-2">
                          {index + 1}
                        </td>
                        <td className="p-2 text-center border-b border-t border-black">
                          {item.role.title}
                        </td>
                        <td className="p-4 text-center border-b border-t border-black">
                          {convertDateFromApiToView(item.expired_date)}
                        </td>
                        <td className="font-bold text-center p-2 border-b border-t border-black">
                          {detail.member_active_roles.some(
                            (activeRole) => activeRole.id === item.id
                          ) ? (
                            <span className="text-green-500">Active</span>
                          ) : new Date(item.expired_date) < new Date() ? (
                            <span className="text-red-500">Expired</span>
                          ) : (
                            <span className="text-gray-500">Not Active</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </AccordionComponent>
          </div>

          <div className="w-full mt-4 border border-black bg-white rounded shadow-lg">
            <AccordionComponent
              title="Save"
              isOpen={isSaveOpen}
              onToggle={(openStatus) => setIsSaveOpen(openStatus)}
            >
              <div className="p-4">
                <div className="flex">
                  <div className="w-4">
                    <FaRegCalendarAlt />
                  </div>
                  <div className="ml-2 text-xs m-auto text-ellipsis">
                    Updated on:{" "}
                    <span className="font-medium">
                      {convertDateFromApiToView(detail.updated_at)}
                    </span>
                  </div>
                </div>
              </div>
            </AccordionComponent>
          </div>
        </>
      )}
    </div>
  );
};
