import { PATH_SETTINGS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const AddSettingUsecase: (
  optionKey: string,
  optionValue: string
) => Promise<string> = async (optionKey, optionValue) => {
  try {
    let formData = new FormData();
    formData.append("option_key", optionKey);
    formData.append("option_value", optionValue);
    formData.append("autoload", "true");

    let response = await client.post(PATH_SETTINGS, formData);
    return response.data.message;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
