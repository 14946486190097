import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { SaveSettingsUsecase } from "@Pages/setting/domain/SaveSettings.usecase";
import {
  CHECKOUT_ADDITIONAL_INFO,
  CHECKOUT_AVAILABLE_PAYMENTS,
} from "@Pages/setting/utils/const";
import { FC } from "react";
import {
  AdditionalInfoFormComponent,
  AdditionalInfoItem,
} from "../components/AdditionalInfoForm.component";
import {
  AvailablePaymentComponent,
  AvailablePaymentItem,
} from "../components/AvailablePaymentForm.component";
import { SettingsSectionsProps } from "../SettingStaticPages.page";

export const CheckoutSection: FC<SettingsSectionsProps> = ({
  settingsData,
  setNeedRefetch,
}) => {
  const toasterContext = UseToasterContext();

  const getAdditionalInfoList = () => {
    let additionalData = settingsData.find(
      (item) => item.key === CHECKOUT_ADDITIONAL_INFO
    );
    if (additionalData) {
      let parsed = JSON.parse(additionalData.value);
      return parsed;
    }
    return [];
  };

  const getAvailablePayments = () => {
    let additionalData = settingsData.find(
      (item) => item.key === CHECKOUT_AVAILABLE_PAYMENTS
    );
    if (additionalData) {
      let parsed = JSON.parse(additionalData.value);
      return parsed;
    }
    return [];
  };

  const saveSetting = (
    type: string,
    isUpdating: boolean,
    key: string,
    value: string
  ) => {
    SaveSettingsUsecase(isUpdating, key, value)
      .then((message) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            `${type} updated successfully!`,
            message,
            ToasterType.SUCCESS
          ),
        ]);
        setNeedRefetch(true);
      })
      .catch((errors) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to update settings!",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      });
  };

  const onSaveAdditionalInfos = (data: AdditionalInfoItem[]) => {
    let listStringify = JSON.stringify(data);
    let currentList = settingsData.find(
      (item) => item.key === CHECKOUT_ADDITIONAL_INFO
    );

    saveSetting(
      "Additional Info",
      currentList !== undefined,
      CHECKOUT_ADDITIONAL_INFO,
      listStringify
    );
  };

  const onSaveAvailablePayments = (data: AvailablePaymentItem[]) => {
    let listStringify = JSON.stringify(data);
    let currentList = settingsData.find(
      (item) => item.key === CHECKOUT_AVAILABLE_PAYMENTS
    );

    saveSetting(
      "Available Payments",
      currentList !== undefined,
      CHECKOUT_AVAILABLE_PAYMENTS,
      listStringify
    );
  };

  return (
    <div className="mt-4">
      <AdditionalInfoFormComponent
        additionalData={getAdditionalInfoList()}
        onSaveAdditionalInfo={onSaveAdditionalInfos}
      />
      <div className="w-full border-t mt-4"></div>
      <AvailablePaymentComponent
        dataList={getAvailablePayments()}
        onSaveChange={onSaveAvailablePayments}
      />
    </div>
  );
};
