/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { FC } from "react";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { AddProductUsecase } from "../domain/AddProduct.usecase";
import { UpdateProductUsecase } from "../domain/UpdateProduct.usecase";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { GetProductUsecase } from "../domain/GetProduct.usecase";
import { ContentHeader } from "@Components/ContentHeader.component";
import { LoadRolesUsecase } from "../domain/LoadRoles.usecase";
import {
  CrossSellProductModel,
  FreeTrialProductModel,
  ProductModel,
} from "../data/product.model";
import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { GetAllProductUsecase } from "../domain/GetAllProducts.usecase";

export type AddProductPageProps = {
  isNewProduct: boolean;
};

export type AddProductFormInput = {
  id: string;
  title: string;
  duration_length: string;
  duration_type: string;
  download_link: string;
  role: string;
  price: string;
  benefits: string;
  availableAffiliate: boolean;
  freeTrials: FreeTrialProductModel[];
  crossSells: CrossSellProductModel[];
  appStoreProductId: string;
  playStoreProductId: string;
};

export const AddProductPage: FC<AddProductPageProps> = ({ isNewProduct }) => {
  const navigate = useNavigate();
  const params = useParams();
  const toasterContext = UseToasterContext();

  const [availableRoles, setAvailableRoles] = useState<any[]>();
  const [availableProducts, setAvailableProducts] = useState<ProductModel[]>();
  const [product, setProduct] = useState<ProductModel>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    trigger,
    control,
    formState: { errors },
  } = useForm<AddProductFormInput>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "freeTrials",
  });
  const crossSellsField = useFieldArray({
    control,
    name: "crossSells",
  });

  useEffect(() => {
    async function fetchDataRoles() {
      try {
        let roleResponse = await LoadRolesUsecase();
        setAvailableRoles(roleResponse);
        if (isNewProduct !== true) {
          let data = await GetProductUsecase(params.id ?? "");

          setValue("id", data.id.toString());
          setValue("title", data.title);
          if (data.duration_length !== null) {
            setValue("duration_length", data.duration_length.toString());
          }
          setValue("duration_type", data.duration_type);
          setValue("price", data.price.toString());
          setValue("availableAffiliate", data.availableAffiliate ?? false);
          setValue("benefits", data.benefits?.join(", ") ?? "");
          setValue("role", data.role_id.toString());
          if (data.link !== null && data.link !== "null") {
            setValue("download_link", data.link ?? "");
          }
          data.appStoreProductId && setValue("appStoreProductId", data.appStoreProductId);
          data.playStoreProductId && setValue("playStoreProductId", data.playStoreProductId);

          setProduct(data);
        }
      } catch (e) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to fetch roles",
            e.message,
            ToasterType.DANGER
          ),
        ]);
        popWithDelay();
      }
    }

    async function fetchDataProducts() {
      try {
        let response = await GetAllProductUsecase("?limit=1000");
        let products = response.list.filter(
          (product) => product.id.toString() !== params.id ?? ""
        );
        setAvailableProducts(products);
      } catch (e) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to fetch roles",
            e.message,
            ToasterType.DANGER
          ),
        ]);
      }
    }

    fetchDataRoles();
    fetchDataProducts();
  }, []);

  useEffect(() => {
    product &&
      product.freeTrials &&
      product.freeTrials.forEach((item) => {
        if (!fields.some((field) => field.role_id === item.role_id)) {
          append({
            role_id: item.role_id,
            title: item.title,
            duration_length: item.duration_length,
            duration_type: item.duration_type,
          });
        }
      });

    product &&
      product.crossSells &&
      product.crossSells.forEach((item) => {
        if (
          !crossSellsField.fields.some(
            (field) => field.product_id === item.product_id
          )
        ) {
          crossSellsField.append({
            product_id: item.product_id,
            title: item.title,
          });
        }
      });
  }, [product]);

  const onSubmit = (data: AddProductFormInput) => {
    let formData = generateBasicFormData();

    if (
      (errors as any).size !== undefined ||
      (errors.download_link !== undefined &&
        errors.duration_length !== undefined)
    ) {
      return;
    }

    formData.append("status", "publish");
    formData.append("move_to_trash", "false");

    sendDataToServer(formData);
  };

  const onSaveToDraft = async () => {
    let valid = await trigger();
    if (!valid) return;

    let formData = generateBasicFormData();

    if (
      (errors as any).size !== undefined ||
      (errors.download_link !== undefined &&
        errors.duration_length !== undefined)
    ) {
      return;
    }

    formData.append("status", "draft");
    formData.append("move_to_trash", "false");

    sendDataToServer(formData);
  };

  const onMoveToTrash = () => {
    let formData = new FormData();

    if (
      (errors as any).size !== undefined ||
      (errors.download_link !== undefined &&
        errors.duration_length !== undefined)
    ) {
      return;
    }

    formData.append("status", "draft");
    formData.append("move_to_trash", "true");

    sendDataToServer(formData);
  };

  const onCancel = () => navigate(-1);

  const generateBasicFormData = () => {
    if (watch("download_link") === "" && watch("duration_length") === "") {
      setError("download_link", {
        type: "manual",
        message: "Either of Duration length or Download link is required.",
      });
      setError("duration_length", {
        type: "manual",
        message: "Either of Duration length or Download link is required.",
      });
    }

    let benefits = "";
    let freeTrials: any[] = [];
    let crossSells: any[] = [];
    let availableForAffiliate: boolean = watch("availableAffiliate");
    let formData = new FormData();

    formData.append("title", watch("title"));
    formData.append("duration_length", watch("duration_length", ""));
    formData.append("duration_type", watch("duration_type"));
    formData.append("download_link", watch("download_link", ""));
    formData.append("price", watch("price"));
    formData.append("role", watch("role"));

    if (watch("benefits")) {
      benefits = watch("benefits")
        .split(",")
        .map((item) => item.trim())
        .join(",");
    }

    if (watch("freeTrials")) {
      let mTrials = watch("freeTrials").map((item) => {
        return {
          role_id: item.role_id,
          title: availableRoles?.find((role) => role.id === item.role_id) ?? "",
          duration_length: item.duration_length,
          duration_type: item.duration_type,
        };
      });
      freeTrials = mTrials;
    }

    if (watch("crossSells")) {
      let mCrossSells = watch("crossSells").map((item) => {
        let data = availableProducts?.filter(
          (product) => product.id.toString() === item.product_id
        );
        return {
          product_id: item.product_id,
          title: data && data.length > 0 ? data[0].title : item.title,
        };
      });
      crossSells = mCrossSells;
    }

    let playStoreProductId = watch("playStoreProductId");
    let appStoreProductId = watch("appStoreProductId");

    formData.append(
      "description",
      JSON.stringify({
        benefits: benefits,
        freeTrials: JSON.stringify(freeTrials),
        crossSells: JSON.stringify(crossSells),
        availableAffiliate: availableForAffiliate,
        playStoreProductId: playStoreProductId,
        appStoreProductId: appStoreProductId,
      })
    );

    return formData;
  };

  const createClient = (formData: FormData) => {
    if (isNewProduct) {
      return AddProductUsecase(formData);
    } else {
      return UpdateProductUsecase(params.id ?? "", formData);
    }
  };

  const sendDataToServer = async (formData: FormData) => {
    try {
      let response = await createClient(formData);
      let title = isNewProduct
        ? "Product successfully created"
        : "Product successfully updated";

      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(title, response, ToasterType.SUCCESS),
      ]);

      popWithDelay();
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to save product",
          errors.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const popWithDelay = () => {
    setTimeout(function () {
      navigate(-1);
    }, 1000);
  };

  return (
    <div className="bg-white m-4">
      <ContentHeader
        title={"Add New Product"}
        rightButton={
          <button
            className="button-red-outline-with-hover"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
        }
      />

      {/* new product form */}
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        {/* title field */}
        <div className="font-medium my-auto w028">
          Title<span className="text-red-500">*</span>
        </div>
        <input
          type="text"
          className={`h-10 ${errors.title
            ? "input-field-full-width border-red-500"
            : "input-field-full-width"
            }`}
          {...register("title", { required: "Title is required." })}
        />
        {errors.title && (
          <p className="text-red-500 text-xs">{errors.title?.message}</p>
        )}

        {/* Duration field */}
        <div className="font-medium w-28 mt-2">Duration</div>
        <div className="flex gap-2">
          <div className="w-full">
            <input
              className={`h-10
                ${errors.duration_length
                  ? "input-field-full-width border-red-500"
                  : "input-field-full-width"
                }`}
              type="number"
              {...register("duration_length", {
                onChange: () => {
                  clearErrors("download_link");
                  clearErrors("duration_length");
                },
              })}
            />
          </div>
          <div className="w-full">
            <select
              className={`h-10
                ${errors.duration_type
                  ? "input-field-full-width border-red-500"
                  : "input-field-full-width"
                }`}
              {...register("duration_type")}
            >
              <option value="day">Day</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div>
        </div>
        {(errors.duration_length || errors.duration_type) && (
          <p className="text-red-500 text-xs">
            {errors.duration_length?.message ?? errors.duration_type?.message}
          </p>
        )}

        {/* Download Link field */}
        <div className="font-medium w-28 mt-2">Download Link</div>
        <input
          type="url"
          className={`h-10
            ${errors.download_link
              ? "input-field-full-width border-red-500"
              : "input-field-full-width"
            }`}
          {...register("download_link", {
            onChange: () => {
              clearErrors("duration_length");
              clearErrors("download_link");
            },
          })}
        />
        {errors.download_link && (
          <p className="text-red-500 text-xs">
            {errors.download_link?.message}
          </p>
        )}

        {/* Role field */}
        <div className="font-medium w-28 mt-2">
          Role<span className="text-red-500">*</span>
        </div>
        <select
          className={`h-10
            ${errors.role
              ? "input-field-full-width border-red-500"
              : "input-field-full-width"
            }`}
          {...register("role", {
            required: "Role is required",
          })}
        >
          <option value="">Pilih Role</option>
          {availableRoles?.map((item) => (
            <option key={`role-${item.id}`} value={item.id}>
              {item.title}
            </option>
          ))}
        </select>
        {errors.role && (
          <p className="text-red-500 text-xs">{errors.role?.message}</p>
        )}

        {/* Price field */}
        <div className="font-medium w-28 mt-2">
          Price<span className="text-red-500">*</span>
        </div>
        <div className="flex border rounded w-full my-auto">
          <div className="bg-gray-400 pt-1 font-semibold object-center align-middle px-2 text-gray-50 h-10">
            Rp
          </div>
          <input
            className="w-full p-1 h-10"
            type="number"
            {...register("price", {
              required: "Price is required",
              min: {
                value: 0,
                message: "Price cannot be less than zero.",
              },
            })}
          />
        </div>
        {errors.price && (
          <p className="text-red-500 text-xs">{errors.price?.message}</p>
        )}

        {/* Benefits field */}
        <div className="font-medium mt-2">Benefits (Pisahkan dengan koma)</div>
        <div className="w-full">
          <input
            className="input-field-full-width h-10"
            type="text"
            {...register("benefits")}
          />
        </div>

        {/* START Is Available for Affiliate */}
        <div className="font-medium mt-6 flex w-full">
          <input
            type={"checkbox"}
            {...register("availableAffiliate")}
            className="h-6 w-6"
          />{" "}
          <div className="my-auto ml-2">
            Available for Affiliate
          </div>
        </div>
        {/* END Is Available for Affiliate */}

        {/* START Free Trials field */}
        <div className="flex mt-4 justify-between">
          <div className="font-medium my-auto">Free Trials</div>
          <YellowHoverButton
            label="+"
            customClass="font-bold"
            onClick={() => {
              append({
                role_id: "",
                title: "",
                duration_length: "",
                duration_type: "day",
              });
            }}
          />
        </div>
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="border border-black bg-white rounded shadow-sm mt-2 p-4"
          >
            <div className="flex justify-between">
              <div className="font-semibold my-auto">
                Free Trial #{index + 1}
              </div>
              <button
                className="button-red-outline-with-hover"
                type="button"
                onClick={() => {
                  remove(index);
                }}
              >
                -
              </button>
            </div>

            <select
              className={`h-10
                ${errors.freeTrials?.[index]?.role_id
                  ? "input-field-full-width border-red-500 mt-2"
                  : "input-field-full-width mt-2"
                }`}
              {...register(`freeTrials.${index}.role_id`, {
                required: "Role is required",
              })}
            >
              <option value="">Pilih Role</option>
              {availableRoles?.map((item) => (
                <option key={`role-${item.id}`} value={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
            {errors.freeTrials?.[index]?.role_id && (
              <p className="text-red-500 text-xs mt-1 mb-2">
                {errors.freeTrials?.[index]?.role_id?.message}
              </p>
            )}

            <div className="flex justify-evenly gap-2">
              <input
                className={
                  errors.freeTrials?.[index]?.duration_length
                    ? "input-field-full-width border-red-500 mt-"
                    : "input-field-full-width mt-2 h-10"
                }
                type="number"
                {...register(`freeTrials.${index}.duration_length`, {
                  required: "Duration Length is required.",
                  onChange: () => {
                    clearErrors(`freeTrials.${index}.duration_length`);
                  },
                })}
              />
              <div className="w-full">
                <select
                  className={
                    errors.freeTrials?.[index]?.duration_type
                      ? "input-field-full-width border-red-500 mt-2 h-10"
                      : "input-field-full-width mt-2 h-10"
                  }
                  {...register(`freeTrials.${index}.duration_type`, {
                    onChange: () => {
                      clearErrors(`freeTrials.${index}.duration_type`);
                    },
                  })}
                >
                  <option value="day">Day</option>
                  <option value="month">Month</option>
                  <option value="year">Year</option>
                </select>
              </div>
            </div>
            {(errors.freeTrials?.[index]?.duration_type ||
              errors.freeTrials?.[index]?.duration_length) && (
                <p className="text-red-500 text-xs mt-1 mb-2">
                  {errors.freeTrials?.[index]?.duration_length?.message ??
                    errors.freeTrials?.[index]?.duration_type?.message}
                </p>
              )}
          </div>
        ))}
        {/* END Free Trials field */}

        {/* START Cross Sell field */}
        <div className="flex mt-4 justify-between">
          <div className="font-medium my-auto">Cross Sells</div>
          <YellowHoverButton
            label="+"
            customClass="font-bold"
            onClick={() => {
              crossSellsField.append({
                product_id: "",
                title: "",
              });
            }}
          />
        </div>
        {crossSellsField.fields.map((field, index) => (
          <div
            key={field.id}
            className="border border-black bg-white rounded shadow-sm mt-2 p-4"
          >
            <div className="flex justify-between">
              <div className="font-semibold my-auto">
                Cross Sell #{index + 1}
              </div>
              <button
                className="button-red-outline-with-hover"
                type="button"
                onClick={() => {
                  crossSellsField.remove(index);
                }}
              >
                -
              </button>
            </div>

            <select
              className={`h-10
                ${errors.crossSells?.[index]?.product_id
                  ? "input-field-full-width border-red-500 mt-2"
                  : "input-field-full-width mt-2"
                }`}
              {...register(`crossSells.${index}.product_id`, {
                required: "Product is required",
              })}
            >
              <option value="">Pilih Product</option>
              {availableProducts?.map((item) => (
                <option key={`role-${item.id}`} value={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
            {errors.crossSells?.[index]?.product_id && (
              <p className="text-red-500 text-xs mt-1 mb-2">
                {errors.crossSells?.[index]?.product_id?.message}
              </p>
            )}
          </div>
        ))}
        {/* END Cross Sell field */}

        {/* In App Product field */}
        <div className="flex gap-2">
          <div className="w-full">
            <div className="font-medium mt-2">App Store Product ID</div>
            <div><input
              className={errors.appStoreProductId
                ? "input-field-full-width border-red-500"
                : "input-field-full-width"
              }
              type="text"
              {...register("appStoreProductId")}
            /></div>
            {errors.appStoreProductId && (
              <div><p className="text-red-500 text-xs">
                {errors.appStoreProductId?.message}
              </p></div>
            )}
          </div>
          <div className="w-full">
            <div className="font-medium mt-2">Play Store Product ID</div>
            <div>
              <input
                className={errors.playStoreProductId
                  ? "input-field-full-width border-red-500"
                  : "input-field-full-width"
                }
                type="text"
                {...register("playStoreProductId")}
              />
            </div>
            {errors.playStoreProductId && (
              <div><p className="text-red-500 text-xs">
                {errors.playStoreProductId?.message}
              </p></div>
            )}
          </div>
        </div>

        <div className="flex mt-4 float-right">
          {isNewProduct !== true && (
            <button
              className="button-red-outline-with-hover mr-2"
              type="button"
              onClick={onMoveToTrash}
            >
              Move to Trash
            </button>
          )}
          <button
            className="button-gray-outline-with-hover mr-2"
            type="button"
            onClick={onSaveToDraft}
          >
            Save to Draft
          </button>
          <button className="button-yellow-outline-with-hover" type="submit">
            Publish
          </button>
        </div>
      </form>
    </div>
  );
};
