import { PATH_JARVIS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { JarvisFileModel } from "../data/jarvis.model";

export const GetJarvisFileListUseCase: (
  folderId: string
) => Promise<JarvisFileModel> = async (folderId: string) => {
  try {
    let response = await client.get(PATH_JARVIS + "/refresh/" + folderId);
    let data = response.data.data as JarvisFileModel;

    let formattedData = [];

    for (const key in data.subFolders) {
      formattedData.push({
        ...data.subFolders[key],
        status: false,
      });
    }

    return {
      time: data.time,
      filesCount: data.filesCount,
      subFolders: formattedData,
    };
  } catch (e) {
    console.log(e);
    throw new Error(e.response.data.message);
  }
};
