/* eslint-disable react-hooks/exhaustive-deps */
import { ContentHeader } from "@Components/ContentHeader.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE } from "@Helpers/regex";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { AxiosProgressEvent } from "axios";
import { ReactNode, useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { UploadResourceUsecase } from "../domain/UploadResource.usecase";

export type AddResourceFormInputs = {
  title: string;
  alt?: string;
  file: FileList | null;
  description?: string;
};

export type AddResourceUploadState = {
  isUploading: boolean;
  uploadProgress: number;
  isDoneUpload: boolean;
  isFailedUpload: boolean;
  failedMessage: string;
};

export const AddResourcePage = () => {
  const navigate = useNavigate();

  const toasterContext = UseToasterContext();

  const [uploadState, setUploadState] = useState<AddResourceUploadState>({
    isUploading: false,
    uploadProgress: 0,
    isDoneUpload: false,
    isFailedUpload: false,
    failedMessage: "",
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AddResourceFormInputs>();

  useEffect(() => {
    if (uploadState.isDoneUpload) {
      setTimeout(() => {
        navigateToResourceList();
      }, 1000);
    }
  }, [uploadState]);

  const uploadProgressCallback = (e: AxiosProgressEvent) => {
    let progress = Math.round((100 * e.loaded) / (e.total ?? 1));

    setUploadState({
      ...uploadState,
      uploadProgress: progress,
    });
  };

  const onSubmit = async (data: AddResourceFormInputs) => {
    if (data.file !== null) {
      try {
        setUploadState({
          ...uploadState,
          isUploading: true,
        });

        await UploadResourceUsecase(
          uploadProgressCallback,
          data.title,
          data.file,
          data.alt,
          data.description
        );
        setUploadState({
          ...uploadState,
          isUploading: false,
          isDoneUpload: true,
          isFailedUpload: false,
          failedMessage: "",
        });
      } catch (errors) {
        setUploadState({
          ...uploadState,
          isUploading: false,
          isDoneUpload: false,
          isFailedUpload: true,
          failedMessage: errors.message,
        });
      }
    } else {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to upload media",
          "Media file must be exists",
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const navigateToResourceList = () => {
    navigate("/resources");
  };

  const buildNewResourceForm: () => ReactNode = () => {
    return (
      <>
        {/* Resource Name */}
        <div className="flex">
          <label className="font-medium my-auto w-28" htmlFor="title">
            Title<span className="text-red-500">*</span>
          </label>
          <div className="w-full">
            <input
              className={`input-field-full-width ${
                errors.title && "border-red-500"
              }`}
              type="text"
              {...register("title", {
                required: "Title is required",
                pattern: {
                  value: REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE,
                  message:
                    "Invalid title format, only alphanumeric and dash allowed",
                },
              })}
            />
            {errors.title && (
              <p className="text-red-500 text-xs">{errors.title?.message}</p>
            )}
          </div>
        </div>

        {/* File field */}
        <div className="flex mt-4">
          <label className="font-medium my-auto w-28" htmlFor="file">
            File<span className="text-red-500">*</span>
          </label>
          <input
            className="input-field-full-width ml-1"
            type="file"
            {...register("file", {
              required: "File is required",
            })}
          />
          <div className="m-auto ml-3 text-red-500">
            <button onClick={() => setValue("file", null)}>
              <AiOutlineCloseCircle />
            </button>
          </div>
        </div>

        {/* Alt field */}
        <div className="flex mt-4">
          <label className="font-medium w-28 mt-1" htmlFor="alt">
            Alt
          </label>
          <div className="w-full">
            <input
              className="input-field-full-width"
              type="text"
              {...register("alt")}
            />
          </div>
        </div>

        {/* Description field */}
        <div className="flex mt-4">
          <label className="font-medium w-28 mt-1" htmlFor="description">
            Description
          </label>
          <div className="w-full">
            <input
              className="input-field-full-width"
              type="text"
              {...register("description")}
            />
          </div>
        </div>

        <button
          className="button-yellow-outline-with-hover w-full mt-4"
          type="submit"
        >
          Save
        </button>
      </>
    );
  };

  return (
    <div className="bg-white m-4">
      <ContentHeader
        title="Add New Media"
        rightButton={
          <button
            className="button-red-outline-with-hover"
            type="button"
            onClick={navigateToResourceList}
          >
            Cancel
          </button>
        }
      />

      <form className="w-full mt-6" onSubmit={handleSubmit(onSubmit)}>
        {buildNewResourceForm()}
      </form>

      {(uploadState.isUploading ||
        uploadState.isDoneUpload ||
        uploadState.isFailedUpload) && (
        <>
          <div className="w-full mt-4 border rounded-lg">
            <div
              className={`text-center text-white rounded-lg 
                ${
                  uploadState.isUploading
                    ? "bg-yellow-400"
                    : uploadState.isDoneUpload
                    ? "bg-green-500"
                    : "bg-red-500"
                }`}
              style={{ width: uploadState.uploadProgress + "%" }}
            >
              {uploadState.uploadProgress + "%"}
            </div>
          </div>
          <div className="text-red-500 text-center text-xs">
            {uploadState.failedMessage}
          </div>
        </>
      )}
    </div>
  );
};
