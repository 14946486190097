/* eslint-disable react-hooks/exhaustive-deps */
import { ContentHeader } from "@Components/ContentHeader.component";
import { Tabs } from "@Components/tabs/Tabs.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { ProductModel } from "@Pages/product/data/product.model";
import { GetAllProductUsecase } from "@Pages/product/domain/GetAllProducts.usecase";
import { FC, useEffect, useState } from "react";
import { GetAllSettingsUsecase } from "../domain/GetAllSettings.usecase";
import { SETTING_STATIC_PAGE_KEYS } from "../utils/const";
import { FeaturedProductsSection } from "./sections/FeaturedProducts.section";
import { HomepageSection } from "./sections/Homepage.section";
import { MemberAreaSection } from "./sections/MemberArea.section";
import { MembershipSection } from "./sections/Membership.section";
import { PhilosophySection } from "./sections/Philosophy.section";
import { StockKeyInsightSection } from "./sections/StockKeyInsight.section";
import { TestimoniSection } from "./sections/Testimoni.section";
import { WatchlistByMonthYearSection } from "./sections/Watchlist.section";

export type SettingsFormInput = {
  id?: number;
  key: string;
  value: string;
  apiValue?: string;
  type?: string;
  label?: string;
};

export type SettingsSectionsProps = {
  settingsData: SettingsFormInput[];
  setNeedRefetch: (isNeedRefetch: boolean) => void;
};

export type SettingSectionWithProductProps = SettingsSectionsProps & {
  productList: ProductModel[];
};

export const SettingsStaticPagesPage: FC = () => {
  const toasterContext = UseToasterContext();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [needRefetch, setNeedRefetch] = useState(true);
  const [formData, setFormData] = useState<SettingsFormInput[]>([]);
  const [listOfProducts, setListOfProducts] = useState<ProductModel[]>([]);

  useEffect(() => {
    if (needRefetch) {
      GetAllSettingsUsecase()
        .then((response) => {
          let processedData: SettingsFormInput[] = [];

          let data = response.data;
          data.forEach((item) => {
            let newData = convertToFormData(
              item.id,
              item.option_key,
              item.option_value
            );
            if (newData !== null) {
              processedData.push(newData);
            }
          });
          setFormData(processedData);
        })
        .catch((errors) => {});
      setNeedRefetch(false);
    }
  }, [needRefetch]);

  useEffect(() => {
    GetAllProductUsecase("?limit=100&status=publish")
      .then((response) => {
        setListOfProducts(response.list);
      })
      .catch((errors) =>
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to fetch products",
            errors.message,
            ToasterType.DANGER
          ),
        ])
      );
  }, []);

  const convertToFormData = (id: number, key: string, value: string) => {
    if (SETTING_STATIC_PAGE_KEYS.includes(key)) {
      let data: SettingsFormInput = {
        id: id,
        key: key,
        value: value,
        apiValue: value,
      };
      return data;
    } else {
      return null;
    }
  };

  const buildViewBasedOnTab = () => {
    switch (selectedTabIndex) {
      case 0:
        return (
          <FeaturedProductsSection
            settingsData={formData}
            setNeedRefetch={setNeedRefetch}
            productList={listOfProducts}
          />
        );
      case 1:
        return (
          <TestimoniSection
            settingsData={formData}
            setNeedRefetch={setNeedRefetch}
          />
        );
      case 2:
        return (
          <HomepageSection
            settingsData={formData}
            setNeedRefetch={setNeedRefetch}
          />
        );
      case 3:
        return (
          <MembershipSection
            settingsData={formData}
            setNeedRefetch={setNeedRefetch}
          />
        );
      case 4:
        return (
          <StockKeyInsightSection
            settingsData={formData}
            setNeedRefetch={setNeedRefetch}
            productList={listOfProducts}
          />
        );
      case 5:
        return (
          <PhilosophySection
            settingsData={formData}
            setNeedRefetch={setNeedRefetch}
          />
        );
      case 6:
        return (
          <MemberAreaSection
            settingsData={formData}
            setNeedRefetch={setNeedRefetch}
            productList={listOfProducts}
          />
        );

      case 7:
        return (
          <WatchlistByMonthYearSection/>
        );
      default:
        return <div className="text-center">Coming Soon</div>;
    }
  };

  return (
    <div className="m-4">
      <ContentHeader title={"Settings"} />
      <div className="h-6"></div>

      <div>
        <Tabs
          tabItems={[
            "Products",
            "Testimoni",
            "Homepage",
            "Membership",
            "Stock Key Insight",
            "Philosophy",
            "Member Area",
            "Watchlist"
          ]}
          selectedIndex={selectedTabIndex}
          onClickTab={(index) => {
            setSelectedTabIndex(index);
          }}
        />
        {buildViewBasedOnTab()}
      </div>
    </div>
  );
};
