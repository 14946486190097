import { PATH_PRODUCTS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ListProductModel, ProductModel } from "../data/product.model";

export const GetAllProductUsecase: (
  params: string
) => Promise<ListProductModel> = async (params) => {
  try {
    let response = await client.get(PATH_PRODUCTS + params);

    let products = response.data.data.data.map((item: any) => {
      let description =
        item.description !== null && item.description !== ""
          ? JSON.parse(item.description)
          : null;

      return {
        ...item,
        benefits:
          description !== null && description.benefits
            ? description.benefits.split(",").map((item: string) => item.trim())
            : null,
        freeTrials:
          description !== null && description.freeTrials
            ? JSON.parse(description.freeTrials)
            : null,
        crossSells:
          description !== null && description.crossSells
            ? JSON.parse(description.crossSells)
            : null,
        appStoreProductId: description !== null && description.appStoreProductId ? description.appStoreProductId : null,
        playStoreProductId: description !== null && description.playStoreProductId ? description.playStoreProductId : null,
      } as ProductModel;
    });

    return {
      list: products,
      meta: response.data.data.meta,
    };
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
