import { ToasterModel } from "@Components/toast/toast.model";
import { useContext } from "react";
import { FC, PropsWithChildren, useState } from "react";
import { createContext } from "react";

export type ToasterContent = {
  toastList: ToasterModel[];
  setToastList: (toastList: ToasterModel[]) => void;
};

export const ToasterContext = createContext<ToasterContent>({
  toastList: [],
  setToastList: () => {},
});

export const ToasterProvider: FC<PropsWithChildren> = ({ children }) => {
  const [toastList, setToastList] = useState<ToasterModel[]>([]);

  const value: ToasterContent = { toastList, setToastList };

  return (
    <ToasterContext.Provider value={value}>{children}</ToasterContext.Provider>
  );
};

export const UseToasterContext = () => {
  return useContext(ToasterContext);
};
