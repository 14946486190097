import { FC } from "react";
import { KeyboardEventHandler } from "react";
import { MutableRefObject } from "react";
import { FaSearch } from "react-icons/fa";

export type SearchInputProps = {
  searchRef: MutableRefObject<HTMLInputElement>;
  onKeyDown: KeyboardEventHandler;
};

export const SearchInputComponent: FC<SearchInputProps> = ({
  searchRef,
  onKeyDown,
}) => {
  return (
    <>
      {/* Search field */}
      <div className="m-1">
        <FaSearch size={20} />
      </div>
      <input
        ref={searchRef}
        onKeyDown={onKeyDown}
        onChange={() => {}}
        className={"outline-none ml-1"}
      />
    </>
  );
};
