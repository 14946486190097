import { UsePageEditorContext } from "@Pages/page/context/page-editor.context";
import { FC } from "react";
import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import { TextModel } from "../../models/text.model";

export type MidasWYSIWYGProps = {
  text: string;
  onTextChange: (newValue: string) => void;
};

export const MidasWYSIWYG: FC<MidasWYSIWYGProps> = ({ text, onTextChange }) => {
  const [currentValue, setCurrentValue] = useState(text);

  return (
    <ReactQuill
      value={currentValue}
      theme={"snow"}
      onChange={(value, delta, source, editor) => {
        onTextChange(value);
        setCurrentValue(value);
      }}
    />
  );
};

export const MidasTextEditor = () => {
  const { pageConfigs, setPageConfigs } = UsePageEditorContext();
  const [useEditor, setUseEditor] = useState(false);

  let editingModel: TextModel = pageConfigs.pageComponents[
    pageConfigs.editIndex
  ] as TextModel;

  const handleOpen = () => {
    setUseEditor(true);
  };

  const handleClose = () => {
    setUseEditor(false);
  };

  const onTextAreaChange = (event: any) => {
    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as TextModel).text =
      event.target.value;

    let newConfig = {
      ...pageConfigs,
      pageComponents: pageComponents,
    };

    if (pageConfigs.oldModel == null || pageConfigs.oldModel === undefined) {
      newConfig["oldModel"] = editingModel;
    }

    setPageConfigs(newConfig);
  };

  const onTextEditorChanged = (newValue: string) => {
    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as TextModel).text = newValue;

    let newConfig = {
      ...pageConfigs,
      pageComponents: pageComponents,
    };

    if (pageConfigs.oldModel == null || pageConfigs.oldModel === undefined) {
      newConfig["oldModel"] = editingModel;
    }

    setPageConfigs(newConfig);
  };

  return (
    <div className="text-right">
      <textarea
        className="w-full p-1"
        onChange={onTextAreaChange}
        value={editingModel.text}
      />
      <button className="button-outline-with-hover" onClick={handleOpen}>
        Use Editor
      </button>

      {useEditor && (
        <div className="modal-container">
          <div className="modal-main">
            <div>
              <div className="flex justify-between">
                <div className="modal-title">Midas Editor</div>
                <div onClick={handleClose}>
                  <AiOutlineCloseCircle size={24} color="#FF0000" />
                </div>
              </div>
              <div>
                <div className="modal-description">
                  <MidasWYSIWYG
                    text={editingModel.text}
                    onTextChange={onTextEditorChanged}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
