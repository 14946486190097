import { AffiliateWithdrawModel } from "@Pages/affiliate/data/AffiliateWithdraw.model";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { FC, useState } from "react";
import { AffiliateWithdrawDetailView } from "./AffiliateWithdrawDetailView.component";
import { AffiliateWithdrawRejectRequestView } from "./AffiliateWithdrawRejectView.component";
import { AffiliateWithdrawApproveRequestView } from "./AffiliateWithdrawApproveView.component";
import { AffiliateWithdrawCompleteRequestView } from "./AffiliateWithdrawCompleteView.component";
import { UpdateAffiliateWithdrawRequestStatusUseCase } from "@Pages/affiliate/domain/UpdateAffiliateWithdrawRequestStatus.usecase";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";

export enum AffiliateWithdrawType {
  DETAIL = "detail",
  REJECT = "reject",
  APPROVE = "approve",
  COMPLETE = "complete",
}

export type AffiliateWithdrawDetailProps = {
  withdrawRequest: AffiliateWithdrawModel;
  onStatusChanged: (newStatus: string) => void;
};

export type AffiliateWithdrawDetailItemProps = {
  label: string;
  value: string;
  className: string;
};

export const AffiliateWithdrawDetailItemComponent: FC<
  AffiliateWithdrawDetailItemProps
> = ({ label, value, className }) => {
  return (
    <div className={`flex justify-between ${className}`}>
      <div>{label}</div>
      <div className="font-bold">{value}</div>
    </div>
  );
};

export const AffiliateWithdrawDetailComponent: FC<
  AffiliateWithdrawDetailProps
> = ({ withdrawRequest, onStatusChanged }) => {
  const modalContext = UseModalContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [viewType, setViewType] = useState<AffiliateWithdrawType>(
    AffiliateWithdrawType.DETAIL
  );

  const closeModal = () => {
    modalContext.setModal(null);
  };

  const sendUpdateRequest = async (status: string, notes?: string) => {
    try {
      setIsLoading(true);
      await UpdateAffiliateWithdrawRequestStatusUseCase(
        withdrawRequest.id,
        status,
        notes
      );
      onStatusChanged(status);
    } catch (e) {
      // TODO
    } finally {
      setIsLoading(false);
    }
  };

  const processReject = async (notes: string) => {
    sendUpdateRequest("rejected", notes);
    modalContext.setModal(null);
  };

  const processComplete = async () => {
    await sendUpdateRequest("completed");
    modalContext.setModal(null);
  };

  const buildView = () => {
    switch (viewType) {
      case AffiliateWithdrawType.DETAIL:
        return (
          <AffiliateWithdrawDetailView
            withdrawRequest={withdrawRequest}
            onCancel={closeModal}
            onReject={() => {
              setViewType(AffiliateWithdrawType.REJECT);
            }}
            onApprove={() => {
              sendUpdateRequest("approved");
              setViewType(AffiliateWithdrawType.APPROVE);
            }}
          />
        );
      case AffiliateWithdrawType.REJECT:
        return (
          <AffiliateWithdrawRejectRequestView
            onCancel={() => {
              setViewType(AffiliateWithdrawType.DETAIL);
            }}
            onReject={processReject}
          />
        );
      case AffiliateWithdrawType.APPROVE:
        return (
          <AffiliateWithdrawApproveRequestView
            withdrawRequest={withdrawRequest}
            onCancel={() => {
              setViewType(AffiliateWithdrawType.DETAIL);
            }}
            onComplete={() => {
              setViewType(AffiliateWithdrawType.COMPLETE);
            }}
          />
        );
      case AffiliateWithdrawType.COMPLETE:
        return (
          <AffiliateWithdrawCompleteRequestView
            onCancel={() => {
              setViewType(AffiliateWithdrawType.APPROVE);
            }}
            onComplete={processComplete}
          />
        );
    }
  };

  return (
    <div className="w-full md:w-96">
      {isLoading ? <MidasShimmering /> : buildView()}
    </div>
  );
};
