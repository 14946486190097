/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import { FC, useContext } from "react";
import { EditorSidebar } from "./components/EditorSidebar.component";
import { EditorPreviewPage } from "./components/EditorPreview.component";
import { PageEditorContext } from "../context/page-editor.context";
import { useEffect } from "react";
import { TextModel } from "./models/text.model";
import { DividerModel } from "./models/divider.model";
import { SpacerModel } from "./models/spacer.model";
import { ImageModel } from "./models/image.model";
import { VideoModel } from "./models/video.model";
import { HeaderModel } from "./models/header.model";
import { BulletModel } from "./models/bullet.model";
import { PostDetailModel } from "./models/post-detail.model";
import { DisclaimerModel } from "./models/disclaimer.model";
import { UserSession } from "../../../utils/UserSession";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { ModalComponent } from "@Components/modal/Modal.component";
import { Toaster } from "@Components/toast/Toast.component";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Components/toast/toast.model";
import { GetPageUsecase } from "../domain/GetPage.usecase";
import { BaseComponentModel } from "../context/editor-sidebar.context";
import { AddPageUsecase } from "../domain/AddPage.usecase";
import { UpdatePageUsecase } from "../domain/UpdatePage.usecase";
import { AddAnalysisUsecase } from "../domain/AddAnalysis.usecase";
import { UpdateAnalysisUsecase } from "../domain/UpdateAnalysis.usecase";
import { GetAnalysisUsecase } from "../domain/GetAnalysis.usecase";

export enum AddPageType {
  ADD_PAGE,
  DETAIL_PAGE,
  ADD_ANALYSIS,
  DETAIL_ANALYSIS,
}

export type AddPageProps = {
  type: AddPageType;
};

export const AddPagePage: FC<AddPageProps> = ({ type }) => {
  const { pageConfigs, setPageConfigs } = useContext(PageEditorContext);
  const navigate = useNavigate();
  const params = useParams();

  const toasterContext = UseToasterContext();
  const modalContext = UseModalContext();

  useEffect(() => {
    if (UserSession.checkIsLoggedIn() === false) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        if (params.id && type === AddPageType.DETAIL_PAGE) {
          let response = await GetPageUsecase(params.id);
          setPageConfigs({
            ...pageConfigs,
            id: parseInt(params.id ?? "0"),
            pageComponents: mapPageComponents(
              JSON.parse(response.content_json)
            ),
            isEditing: false,
            editIndex: -1,
            oldModel: null,
            title: response.title,
            slug: response.slug,
            type: response.type,
            isShowHeader: response.is_show_header,
            headerType: response.header_type ? response.header_type : "",
            isShowFooter: response.is_show_footer,
            footerType: response.footer_type ? response.footer_type : "",
            visibility: response.visibility,
          });
        } else if (params.id && type === AddPageType.DETAIL_ANALYSIS) {
          let response = await GetAnalysisUsecase(params.id);

          setPageConfigs({
            ...pageConfigs,
            id: parseInt(params.id ?? "0"),
            pageComponents: mapPageComponents(
              JSON.parse(response.page.content_json)
            ),
            isEditing: false,
            editIndex: -1,
            oldModel: null,
            title: response.page.title,
            slug: response.page.slug,
            type: response.page.type,
            isShowHeader: response.page.is_show_header,
            headerType: response.page.header_type
              ? response.page.header_type
              : "",
            isShowFooter: response.page.is_show_footer,
            footerType: response.page.footer_type
              ? response.page.footer_type
              : "",
            visibility: response.page.visibility,
            analysis: {
              code: response.code,
              buyPrice: response.buy_price,
              currentPrice: response.current_price,
              mos: response.margin_of_safety,
              valuation: response.valuation,
              image_url: response.image_url,
            },
          });
        }
      } catch (e) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to fetch page",
            e.message,
            ToasterType.DANGER
          ),
        ]);
      }
    }

    fetchData();
  }, []);

  const mapPageComponents = (jsonContent: any) =>
    jsonContent.map((model: any, index: number) => {
      let resultModel: BaseComponentModel | null = null;
      switch (model.type) {
        case "header":
          resultModel = new HeaderModel("header-" + index);
          break;
        case "text":
          resultModel = new TextModel("text-" + index);
          break;
        case "post-detail":
          resultModel = new PostDetailModel("post-detail-" + index);
          break;
        case "bullet":
          resultModel = new BulletModel("bullet-" + index);
          break;
        case "disclaimer":
          resultModel = new DisclaimerModel("disclaimer-" + index);
          break;
        case "divider":
          resultModel = new DividerModel("divider-" + index);
          break;
        case "spacer":
          resultModel = new SpacerModel("spacer-" + index);
          break;
        case "image":
          resultModel = new ImageModel("image-" + index);
          break;
        case "video":
          resultModel = new VideoModel("video-" + index);
          break;
        default:
          resultModel = null;
      }
      resultModel?.jsonToModel(model);
      return resultModel;
    });

  const buildDefaultRequestBody = () => {
    let formData = new FormData();

    formData.append("title", pageConfigs.title);
    formData.append("slug", pageConfigs.slug);
    formData.append("type", pageConfigs.type);
    formData.append("content_html", '""');
    formData.append("is_show_header", pageConfigs.isShowHeader.toString());
    if (pageConfigs.isShowHeader)
      formData.append("header_type", pageConfigs.headerType);
    formData.append("is_show_footer", pageConfigs.isShowFooter.toString());
    if (pageConfigs.isShowFooter)
      formData.append("footer_type", pageConfigs.footerType);
    formData.append(
      "visibility",
      pageConfigs.visibility ? pageConfigs.visibility : "all"
    );

    if (
      (type === AddPageType.ADD_ANALYSIS ||
        type === AddPageType.DETAIL_ANALYSIS) &&
      pageConfigs.analysis
    ) {
      formData.append("code", pageConfigs.analysis.code);
      formData.append("buy_price", pageConfigs.analysis.buyPrice.toString());
      formData.append("valuation", pageConfigs.analysis.valuation.toString());
      formData.append("margin_of_safety", pageConfigs.analysis.mos.toString());
      formData.append("image_url", pageConfigs.analysis.image_url);

      let currentPrice =
        pageConfigs.analysis.currentPrice.toString() !== ""
          ? pageConfigs.analysis.currentPrice.toString()
          : "0";
      formData.append("current_price", currentPrice);
    }

    return formData;
  };

  const onMoveToTrash = () => {
    let formData = new FormData();
    formData.append("move_to_trash", "true");

    saveChangesToServer(formData);
  };

  const onSaveToDraft = () => {
    let formData = buildDefaultRequestBody();
    formData.append("content_json", getComponentList());
    formData.append("status", "draft");
    formData.append("move_to_trash", "false");

    saveChangesToServer(formData);
  };

  const onPublish = () => {
    let formData = buildDefaultRequestBody();
    formData.append("content_json", getComponentList());
    formData.append("status", "published");
    formData.append("move_to_trash", "false");

    saveChangesToServer(formData);
  };

  const onCancel = () => {
    navigate(-1);
  };

  const saveChangesToServer = async (formData: FormData) => {
    let mClient = null;
    if (type === AddPageType.DETAIL_PAGE && params.id) {
      mClient = UpdatePageUsecase(params.id, formData);
    } else if (type === AddPageType.ADD_ANALYSIS) {
      mClient = AddAnalysisUsecase(formData);
    } else if (type === AddPageType.DETAIL_ANALYSIS && params.id) {
      mClient = UpdateAnalysisUsecase(params.id, formData);
    } else {
      mClient = AddPageUsecase(formData);
    }

    try {
      await mClient;
      navigate(-1);
    } catch (errors) {
      let title = "";
      if (
        type === AddPageType.DETAIL_ANALYSIS ||
        type === AddPageType.DETAIL_PAGE
      ) {
        title = "Failed to update page";
      } else {
        title = "Failed to add page";
      }

      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(title, errors.message, ToasterType.DANGER),
      ]);
    }
  };

  const getComponentList: () => string = () => {
    let listOfComponents: any[] = [];

    pageConfigs.pageComponents.forEach((item) => {
      if (item !== null) listOfComponents.push(item.getJsonString());
    });

    return JSON.stringify(listOfComponents);
  };

  return (
    <div className="flex h-full">
      {/* config field */}
      <div className="h-screen bg-gray-300 left-0 w-1/4">
        <EditorSidebar
          onSaveToDraft={onSaveToDraft}
          onMoveToTrash={onMoveToTrash}
          onPublish={onPublish}
          onCancel={onCancel}
          type={type}
        />
      </div>

      {/* preview page */}
      <div className="bg-white h-screen w-3/4 overflow-auto">
        <EditorPreviewPage />

        {/* render Modal if any */}
        {modalContext.modal !== null && (
          <ModalComponent {...modalContext.modal} />
        )}

        {/* render Toaster if any */}
        {toasterContext.toastList.length > 0 && (
          <Toaster
            toastItems={toasterContext.toastList}
            isAutoDelete={true}
            position={ToasterPosition.BOTTOM_RIGHT}
            autoDeleteTimeInMilis={2000}
          />
        )}
      </div>
    </div>
  );
};
