import { PATH_ORDERS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ListOrderModel } from "../data/order.model";

export const GetAllOrdersUsecase: (
  params: string
) => Promise<ListOrderModel> = async (params) => {
  try {
    let response = await client.get(PATH_ORDERS + params);
    return {
      list: response.data.data.data,
      meta: response.data.data.meta,
    };
  } catch (e) {
    throw new Error(e.response.data);
  }
};
