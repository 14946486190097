/* eslint-disable react-hooks/exhaustive-deps */
import { BottomListComponent } from "@Components/BottomList.component";
import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { ContentHeader } from "@Components/ContentHeader.component";
import { SearchInputComponent } from "@Components/inputs/SearchInputs.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE } from "@Helpers/regex";
import { buildQueryParamsFromUrl, buildUrlSearchParams } from "@Helpers/utils";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { FC, MutableRefObject } from "react";
import { useEffect, useRef, useState } from "react";
import { FaEye, FaFileAlt, FaUserAlt, FaUserPlus } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ListUserModel } from "../data/user.model";
import { GetAllUsersUsecase } from "../domain/GetAllUsers.usecase";
import { AddUserComponent } from "./components/AddUser.components";
import { UploadCsvComponent } from "./components/UploadCsv.components";

export const UserListPage: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const modalContext = UseModalContext();
  const toasterContext = UseToasterContext();

  const searchRef = useRef() as MutableRefObject<HTMLInputElement>;

  const [needRefetch, setNeedRefetch] = useState(true);
  const [apiData, setApiData] = useState<ListUserModel>();

  useEffect(() => {
    if (needRefetch) {
      let queryParams = buildQueryParamsFromUrl(searchParams.entries());

      if (queryParams !== "") {
        queryParams = "?" + queryParams;
      }

      GetAllUsersUsecase(queryParams)
        .then((response) => {
          setApiData(response);
        })
        .catch((errors) =>
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Failed to fetch users data",
              errors.message,
              ToasterType.DANGER
            ),
          ])
        );
      setNeedRefetch(false);
    }
  }, [needRefetch]);

  const getQueryRoles = () => {
    let filter = searchParams.get("filter");
    if (filter == null) {
      filter = "all";
    }
    return filter;
  };

  const filterRoles = (selectedRole: string) => {
    setNeedRefetch(true);
    navigate({
      search:
        "?" +
        buildUrlSearchParams(searchParams.entries(), "filter", selectedRole),
    });
  };

  const handleSearch = (e: any) => {
    if (e.key === "Enter") {
      let clearedString = e.target.value
        .replace(REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE, "")
        .replaceAll(" ", "+");

      setNeedRefetch(true);
      navigate({
        search:
          "?" +
          buildUrlSearchParams(searchParams.entries(), "search", clearedString),
      });
    }
  };

  const canGoPrev = () =>
    (apiData && apiData.meta && apiData.meta.page > 1) ?? false;

  const prevPage = () => {
    if (apiData && apiData.meta && canGoPrev()) {
      let prevPage = apiData.meta.page - 1;
      if (prevPage >= 1) {
        setNeedRefetch(true);
        navigate({
          search:
            "?" +
            buildUrlSearchParams(
              searchParams.entries(),
              "page",
              prevPage.toString()
            ),
        });
      }
    }
  };

  const canGoNext = () =>
    (apiData && apiData.meta && apiData.meta.end < apiData.meta.total) ?? false;

  const nextPage = () => {
    if (apiData && apiData.meta && canGoNext()) {
      let nextPage = apiData.meta.page + 1;
      setNeedRefetch(true);
      navigate({
        search:
          "?" +
          buildUrlSearchParams(
            searchParams.entries(),
            "page",
            nextPage.toString()
          ),
      });
    }
  };

  const onChangeLimit = (newValue: string) => {
    setNeedRefetch(true);
    navigate({
      search:
        "?" + buildUrlSearchParams(searchParams.entries(), "limit", newValue),
    });
  };

  const showAddUserModal = () => {
    let title = "Add User";
    let description = <AddUserComponent />;

    modalContext.setModal(
      createModal(<FaUserPlus size={40} />, title, description, null, null)
    );
  };

  const showImportCsvModal = () => {
    let title = "Import CSV";
    let description = <UploadCsvComponent />;

    modalContext.setModal(
      createModal(<FaFileAlt size={40} />, title, description, null, null)
    );
  };

  return (
    <div className="m-4">
      {!apiData && <MidasShimmering />}
      {apiData && (
        <>
          <ContentHeader
            title={"User List"}
            rightButton={
              <div className="flex gap-4">
                <YellowHoverButton
                  label={"Import CSV"}
                  onClick={showImportCsvModal}
                />
                <YellowHoverButton
                  label={"Add New"}
                  onClick={showAddUserModal}
                />
              </div>
            }
          />
          <div className="h-6"></div>

          {/* top config */}
          <div className="border p-3 align-middle flex flex-col justify-between shadow-sm md:flex-row">
            <div className="flex">
              {/* role type filter list */}
              <select
                className="mr-1 border rounded pl-1 pr-1"
                value={getQueryRoles()}
                onChange={(event) => filterRoles(event.target.value)}
              >
                <option value="all">All Roles</option>
                {apiData.meta &&
                  apiData.meta.available_roles.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="border rounded mt-2 md:mt-0 flex">
              <SearchInputComponent
                searchRef={searchRef}
                onKeyDown={handleSearch}
              />
            </div>
          </div>

          {/* list items */}
          <div className="mt-4">
            {apiData.list.length === 0 && (
              <div className="text-center bg-gray-300 p-4">
                No User Available!
              </div>
            )}
            {apiData.list.length > 0 && (
              <table className="table-auto border w-full">
                <thead className="p-2 shadow-sm bg-gray-100 sticky">
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Name</th>
                    <th className="hidden md:table-cell">Email</th>
                    <th className="hidden md:table-cell">Role</th>
                  </tr>
                </thead>
                <tbody>
                  {apiData.list.map((item, index) => (
                    <tr key={item.id}>
                      <td className="text-center">{index + 1}</td>
                      <td className="p-2 text-center items-center">
                        {item.picture_url != null ? (
                          <img
                            src={item.picture_url}
                            width={100}
                            height={100}
                            alt=""
                            className="object-cover aspect-square rounded-xl"
                          />
                        ) : (
                          <div className="w-24 p-3 bg-gray-400 rounded-xl text-white text-center">
                            <FaUserAlt size={72} />
                          </div>
                        )}
                      </td>
                      <td className="p-2">
                        <div className="text-ellipsis">{item.fullname}</div>
                        <Link to={"/users/detail/" + item.id}>
                          <button
                            className="text-blue-400 mr-2 w-6 h-6"
                            title="Edit"
                          >
                            <FaEye />
                          </button>
                        </Link>
                      </td>
                      <td className="text-center hidden md:table-cell">
                        {item.email}
                      </td>
                      <td className="text-center hidden md:table-cell">
                        {item.member_active_roles.length === 0 && item.role}
                        {item.member_active_roles.length > 0 &&
                          item.member_active_roles
                            .map((role) => role.role.title)
                            .join(",\n")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {apiData.meta && (
              <BottomListComponent
                shouldShow={apiData.meta !== null}
                onLimitChange={onChangeLimit}
                canGoPrev={canGoPrev()}
                onGoToPrev={prevPage}
                canGoNext={canGoNext()}
                onGoToNext={nextPage}
                meta={apiData.meta}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserListPage;
