import { AvailableComponentModel } from "@Pages/page/presentation/components/ItemComponent.component";

export const ListOfAvailableComponents: AvailableComponentModel[] = [
  {
    type: "header",
    icon: "header",
    name: "Title",
  },
  {
    type: "text",
    icon: "text",
    name: "Paragraph",
  },
  {
    type: "post-detail",
    icon: "post-detail",
    name: "Post Detail",
  },
  {
    type: "bullet",
    icon: "bullet",
    name: "List",
  },
  {
    type: "disclaimer",
    icon: "disclaimer",
    name: "Disclaimer",
  },
  {
    type: "divider",
    icon: "divider",
    name: "Divider",
  },
  {
    type: "spacer",
    icon: "spacer",
    name: "Space",
  },
  {
    type: "image",
    icon: "image",
    name: "Image",
  },
  {
    type: "video",
    icon: "video",
    name: "Video",
  },
];
