import { PATH_PAGES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const DeletePageUsecase: (id?: number) => Promise<boolean> = async (
  id
) => {
  try {
    if (!id) throw new Error("Id cannot be empty");
    await client.delete(PATH_PAGES + "/" + id);
    return true;
  } catch (e) {
    throw new Error(e.response.message);
  }
};
