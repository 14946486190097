import { UserRoleModel } from "@Pages/common/data/user.model";
import secureLocalStorage from "react-secure-storage";

export const UserSession = (function () {
  const STORAGE_KEY = "MIDAS_USER_SESSION";

  let userId: number = 0;
  let name: string = "";
  let email: string = "";
  let isLoggedIn: boolean = false;
  let token: string = "";
  let role: string = "";
  let pictureUrl: string | null = null;
  let activeRoles: UserRoleModel[] = [];

  const login = (
    mId: number,
    mName: string,
    mEmail: string,
    mToken: string,
    mRole: string,
    mPictureUrl: string | null,
    mActiveRoles: any[]
  ) => {
    userId = mId;
    name = mName;
    email = mEmail;
    isLoggedIn = true;
    token = mToken;
    role = mRole;
    pictureUrl = mPictureUrl;
    activeRoles = [];

    mActiveRoles.forEach((item) => {
      let role: UserRoleModel = {
        title: item.role.title,
        role: item.role.role,
        expiredDate: item.expired_date,
        access: [...new Set((item.role.access as string).split(";"))],
      };
      activeRoles = [...new Set([...activeRoles, role])];
    });

    setLocalStorageData();
  };

  const loginWithToken = (mToken: string) => {
    token = mToken;
    isLoggedIn = true;

    setLocalStorageData();
  };

  const getUserSession = () => {
    return {
      userId: userId,
      name: name,
      email: email,
      isLoggedIn: isLoggedIn,
      token: token,
      role: role,
      pictureUrl: pictureUrl,
    };
  };

  const logout = () => {
    userId = 0;
    name = "";
    email = "";
    isLoggedIn = false;
    token = "";
    role = "";
    pictureUrl = null;
    activeRoles = [];

    setLocalStorageData();
  };

  const setLocalStorageData = () => {
    if (typeof Storage !== "undefined") {
      secureLocalStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({
          userId: userId,
          name: name,
          email: email,
          isLoggedIn: isLoggedIn,
          token: token,
          role: role,
          pictureUrl: pictureUrl,
          activeRoles: activeRoles,
        })
      );
    }
  };

  const getSessionFromLocalStorage = () => {
    if (Storage !== undefined) {
      let savedSession = secureLocalStorage.getItem(STORAGE_KEY) as
        | string
        | null;
      if (savedSession) {
        let session = JSON.parse(savedSession ?? "");

        userId = session.userId;
        name = session.name;
        email = session.email;
        isLoggedIn = session.isLoggedIn;
        token = session.token;
        role = session.role;
        pictureUrl = session.pictureUrl;
        activeRoles = session.activeRoles;
      }
    }
  };

  const checkIsLoggedIn = () => isLoggedIn;

  const getToken = () => token;

  const getName = () => name;

  const getRoles = () => activeRoles;

  const canAccess = (actionName: string) =>
    role.toLowerCase() === "superadmin" ||
    activeRoles.find((item) => item.access.includes("intools::" + actionName));

  getSessionFromLocalStorage();

  return {
    login: login,
    logout: logout,
    getUserSession: getUserSession,
    checkIsLoggedIn: checkIsLoggedIn,
    getToken: getToken,
    getName: getName,
    getRoles: getRoles,
    canAccess: canAccess,
    loginWithToken: loginWithToken,
  };
})();
