import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { MediaChooser } from "@Pages/common/presentation/MediaChooser.page";
import { UsePageEditorContext } from "@Pages/page/context/page-editor.context";
import { ResourceModel } from "@Pages/resources/data/resource.model";

export const StockConfigComponent = () => {
  const { pageConfigs, setPageConfigs } = UsePageEditorContext();
  const modalContext = UseModalContext();

  const getAnalysisModel = () => {
    return (
      pageConfigs.analysis ?? {
        code: "",
        buyPrice: 0,
        currentPrice: 0,
        mos: 0,
        valuation: 0,
        image_url: "",
      }
    );
  };

  const onMediaSelected = (data: ResourceModel) => {
    setPageConfigs({
      ...pageConfigs,
      analysis: { ...getAnalysisModel(), image_url: data.link_url },
    });
    modalContext.setModal(null);
  };

  const onSelectMediaClicked = () => {
    let title = "Select Media";
    let description = (
      <MediaChooser type={"image"} onMediaSelected={onMediaSelected} />
    );
    let cancelButton = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(null, title, description, cancelButton, null)
    );
  };

  return (
    <div className="p-4 flex flex-col">
      {/* image field */}
      <div className="font-medium">Cover Image</div>
      <div className="input-field-full-width flex p-0">
        <input
          type="text"
          className={"input-field-full-width"}
          value={getAnalysisModel().image_url}
          placeholder={"Image Saham"}
          onChange={(e) => {
            setPageConfigs({
              ...pageConfigs,
              analysis: { ...getAnalysisModel(), image_url: e.target.value },
            });
          }}
        />
        <button
          className="rounded-r bg-gray-500 shadow text-white w-32"
          onClick={onSelectMediaClicked}
          type="button"
        >
          Select Image
        </button>
      </div>

      {/* code field */}
      <div className="font-medium mt-4">Kode Saham</div>
      <input
        type="text"
        className={"input-field-full-width"}
        value={getAnalysisModel().code}
        placeholder={"Kode Saham"}
        onChange={(e) => {
          setPageConfigs({
            ...pageConfigs,
            analysis: { ...getAnalysisModel(), code: e.target.value },
          });
        }}
      />

      {/* valuation field */}
      <div className="font-medium mt-4">Valuasi</div>
      <input
        type={"number"}
        className={"input-field-full-width"}
        value={getAnalysisModel().valuation}
        placeholder={"Valuasi"}
        onChange={(e) => {
          setPageConfigs({
            ...pageConfigs,
            analysis: {
              ...getAnalysisModel(),
              valuation: parseFloat(e.target.value),
            },
          });
        }}
      />

      {/* margin of safety field */}
      <div className="font-medium mt-4">Margin of Safety (MoS)</div>
      <input
        type={"number"}
        className={"input-field-full-width"}
        value={getAnalysisModel().mos}
        placeholder={"Margin of Safety"}
        onChange={(e) => {
          setPageConfigs({
            ...pageConfigs,
            analysis: {
              ...getAnalysisModel(),
              mos: parseFloat(e.target.value),
            },
          });
        }}
      />

      {/* buy price field */}
      <div className="font-medium mt-4">Harga Beli</div>
      <input
        type={"number"}
        className={"input-field-full-width"}
        value={getAnalysisModel().buyPrice}
        placeholder={"Harga Beli"}
        onChange={(e) => {
          setPageConfigs({
            ...pageConfigs,
            analysis: {
              ...getAnalysisModel(),
              buyPrice: parseFloat(e.target.value),
            },
          });
        }}
      />

      {/* current price field */}
      <div className="font-medium mt-4">Harga Saat Ini (Optional)</div>
      <input
        type={"number"}
        className={"input-field-full-width"}
        value={getAnalysisModel().currentPrice}
        placeholder={"Harga Saat Ini"}
        onChange={(e) => {
          setPageConfigs({
            ...pageConfigs,
            analysis: {
              ...getAnalysisModel(),
              currentPrice: parseFloat(e.target.value),
            },
          });
        }}
      />
      <div className="text-xs">* Kosongkan untuk menggunakan real data</div>
    </div>
  );
};
