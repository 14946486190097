import { Tabs } from "@Components/tabs/Tabs.component";
import { EditorSidebarProvider } from "@Pages/page/context/editor-sidebar.context";
import { UsePageEditorContext } from "@Pages/page/context/page-editor.context";
import { RoleModel } from "@Pages/role/data/role.model";
import { GetAllRolesUsecase } from "@Pages/role/domain/GetAllRoles.usecase";
import { FC, useEffect } from "react";
import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaArchive, FaCheck, FaRegTrashAlt } from "react-icons/fa";
import { AddPageType } from "../AddPage.page";
import { EditorConfigComponent } from "./EditorConfig.component";
import { EditorListComponent } from "./EditorList.component";
import { PageConfigComponent } from "./PageConfig.component";
import { StockConfigComponent } from "./StockConfig.component";

export type EditorSidebarProps = {
  onMoveToTrash: () => void;
  onSaveToDraft: () => void;
  onPublish: () => void;
  onCancel: () => void;
  type: AddPageType;
};

export const EditorSidebar: FC<EditorSidebarProps> = ({
  onMoveToTrash,
  onSaveToDraft,
  onPublish,
  onCancel,
  type,
}) => {
  const { pageConfigs } = UsePageEditorContext();

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    type === AddPageType.DETAIL_ANALYSIS || type === AddPageType.DETAIL_PAGE
      ? 1
      : 0
  );
  const [roles, setRoles] = useState<RoleModel[]>([]);

  useEffect(() => {
    async function fetchRoles() {
      let response = await GetAllRolesUsecase("?limit=100");
      setRoles(response.list);
    }

    fetchRoles();
  }, []);

  const getTabItems = () => {
    let items = ["Components", "Config"];

    if (
      type === AddPageType.ADD_ANALYSIS ||
      type === AddPageType.DETAIL_ANALYSIS
    ) {
      items.push("Saham");
    }

    return items;
  };

  const buildViewBasedOnTab = () => {
    switch (selectedTabIndex) {
      case 0:
        return buildListOfComponentsView();
      case 1:
        return buildPageConfigView();
      case 2:
        return buildStockConfigView();
    }
  };

  const buildListOfComponentsView = () => {
    return (
      <EditorSidebarProvider>
        <EditorListComponent />
      </EditorSidebarProvider>
    );
  };

  const buildPageConfigView = () => {
    return <PageConfigComponent roles={roles} type={type} />;
  };

  const buildStockConfigView = () => {
    return <StockConfigComponent />;
  };

  return (
    <div className="overflow-y-auto">
      {pageConfigs.isEditing === false && (
        <div className="w-full bg-sidebar h-12 flex justify-between px-2">
          <div className="my-auto">
            <button
              title="Cancel"
              className="p-1 bg-white border border-gray-400 rounded w-8 h-8 text-gray-400 hover:text-white hover:bg-gray-400"
              onClick={onCancel}
            >
              <AiOutlineCloseCircle size={20} />
            </button>
            {(type === AddPageType.DETAIL_PAGE ||
              type === AddPageType.DETAIL_ANALYSIS) && (
              <button
                className="p-1 bg-white border border-red-400 rounded w-8 h-8 text-red-400 hover:text-white hover:bg-red-400 ml-2"
                type="button"
                title="Move to Trash"
                onClick={onMoveToTrash}
              >
                <FaRegTrashAlt size={20} />
              </button>
            )}
          </div>
          <div className="my-auto">
            <button
              title="Save to Draft"
              className="p-1 bg-white border border-gray-400 rounded w-8 h-8 text-gray-400 hover:text-white hover:bg-gray-400"
              onClick={onSaveToDraft}
            >
              <FaArchive size={20} />
            </button>
            <button
              title="Publish"
              className="p-1 bg-white border border-yellow-400 rounded w-8 h-8 text-yellow-400 hover:text-white hover:bg-yellow-400 ml-2"
              onClick={onPublish}
            >
              <FaCheck size={20} />
            </button>
          </div>
        </div>
      )}

      {pageConfigs.isEditing ? (
        <EditorConfigComponent />
      ) : (
        <div className="mt-2">
          <Tabs
            tabItems={getTabItems()}
            selectedIndex={selectedTabIndex}
            onClickTab={(index) => {
              setSelectedTabIndex(index);
            }}
          />
          {buildViewBasedOnTab()}
        </div>
      )}
    </div>
  );
};
