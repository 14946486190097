import { PATH_VOUCHERS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";

export const UpdateVoucherUsecase: (
  id: string,
  formData: FormData
) => Promise<string> = async (id, formData) => {
  try {
    let response = await client.put(PATH_VOUCHERS + "/" + id, formData);
    return response.data.message;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
