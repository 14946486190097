/* eslint-disable react-hooks/exhaustive-deps */
import { ContentHeader } from "@Components/ContentHeader.component";
import { Tabs } from "@Components/tabs/Tabs.component";
import { useEffect, useState } from "react";
import { AccountInformationModel } from "../data/account.model";
import { AutoResponderModel } from "../data/autoresponder.model";
import { CampaignModel } from "../data/campaign.model";
import { ContactModel } from "../data/contact.model";
import { LandingPageModel } from "../data/landing-page.model";
import { ProductGetResponseModel } from "../data/product.model";
import { ShopModel } from "../data/shop.model";
import { AutoResponderListUsecase } from "../domain/AutoResponderList.usecase";
import { CampaignListUsecase } from "../domain/CampaignList.usecase";
import { ContactListUsecase } from "../domain/ContactList.usecase";
import { GetAccountInformationUsecase } from "../domain/GetAccountInformation.usecase";
import { LandingPageListUsecase } from "../domain/LandingPages.usecase";
import { ProductListUsecase } from "../domain/Product.usecase";
import { ShopListUsecase } from "../domain/Shops.usecase";
import { AccountSection } from "./sections/Account.section";
import { AutoResponderSection } from "./sections/AutoResponder.section";
import { CampaignSection } from "./sections/Campaign.section";
import { ContactSection } from "./sections/Contact.section";
import { LandingPageSection } from "./sections/LandingPage.section";
import { ProductSection } from "./sections/Products.section";
import { ShopSection } from "./sections/Shop.section";

export type SectionProps = {
  page: number;
  setPage: (newPage: number) => void;
  needRefetch: (sectionToEdit: GetResponseSection[]) => void;
};

export enum GetResponseSection {
  ACCOUNTS,
  AUTO_RESPONDERS,
  CAMPAIGNS,
  CONTACTS,
  LANDING_PAGES,
  PRODUCTS,
  SHOPS,
}

export const GetResponsePage = () => {
  const [account, setAccount] = useState<AccountInformationModel>();
  const [data, setData] = useState<any>();
  const [page, setPage] = useState({
    autoResponder: 1,
    campaigns: 1,
    contacts: 1,
    landingPages: 1,
    shops: 1,
  });
  const [needRefetch, setNeedRefetch] = useState<GetResponseSection[]>([
    GetResponseSection.ACCOUNTS,
    GetResponseSection.AUTO_RESPONDERS,
    GetResponseSection.CAMPAIGNS,
    GetResponseSection.CONTACTS,
    GetResponseSection.LANDING_PAGES,
    GetResponseSection.PRODUCTS,
    GetResponseSection.SHOPS,
  ]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    if (needRefetch.length > 0) {
      async function fetchData() {
        let responseData = await Promise.all(
          needRefetch.map((item) => {
            if (item === GetResponseSection.ACCOUNTS) {
              return GetAccountInformationUsecase();
            }
            if (item === GetResponseSection.AUTO_RESPONDERS) {
              return AutoResponderListUsecase(page.autoResponder);
            }
            if (item === GetResponseSection.CAMPAIGNS) {
              return CampaignListUsecase(page.campaigns);
            }
            if (item === GetResponseSection.CONTACTS) {
              return ContactListUsecase(page.contacts);
            }
            if (item === GetResponseSection.LANDING_PAGES) {
              return LandingPageListUsecase(page.landingPages);
            }
            if (item === GetResponseSection.PRODUCTS) {
              return ProductListUsecase(1);
            }
            if (item === GetResponseSection.SHOPS) {
              return ShopListUsecase(page.shops);
            }

            return [];
          })
        );

        let mData = {
          ...data,
        };
        let account = undefined;

        responseData.forEach((response, index) => {
          if (needRefetch[index] === GetResponseSection.ACCOUNTS) {
            account = response;
          }
          if (needRefetch[index] === GetResponseSection.AUTO_RESPONDERS) {
            mData = {
              ...mData,
              autoResponder: response,
            };
          }
          if (needRefetch[index] === GetResponseSection.CAMPAIGNS) {
            mData = {
              ...mData,
              campaigns: response,
            };
          }
          if (needRefetch[index] === GetResponseSection.CONTACTS) {
            mData = {
              ...mData,
              contacts: response,
            };
          }
          if (needRefetch[index] === GetResponseSection.LANDING_PAGES) {
            mData = {
              ...mData,
              landingPages: response,
            };
          }
          if (needRefetch[index] === GetResponseSection.PRODUCTS) {
            mData = {
              ...mData,
              products: response,
            };
          }
          if (needRefetch[index] === GetResponseSection.SHOPS) {
            mData = {
              ...mData,
              shops: response,
            };
          }
        });

        setNeedRefetch([]);
        setAccount(account);
        setData(mData);
      }

      fetchData();
    }
  }, [needRefetch]);

  const buildViewBasedOnTab = () => {
    switch (selectedTabIndex) {
      case 0:
        return (
          <AccountSection
            account={account}
            page={0}
            setPage={() => {}}
            needRefetch={(sectionToEdit) =>
              setNeedRefetch([...needRefetch, ...sectionToEdit])
            }
          />
        );
      case 1:
        return (
          <ShopSection
            data={data && data.shops ? (data.shops as ShopModel[]) : []}
            page={page.shops}
            setPage={(newPage) => {
              setPage({
                ...page,
                shops: newPage,
              });
              setNeedRefetch([...needRefetch, GetResponseSection.SHOPS]);
            }}
            needRefetch={(sectionToEdit) =>
              setNeedRefetch([...needRefetch, ...sectionToEdit])
            }
          />
        );
      case 2:
        return (
          <LandingPageSection
            data={
              data && data.landingPages
                ? (data.landingPages as LandingPageModel[])
                : []
            }
            page={page.landingPages}
            setPage={(newPage) => {
              setPage({
                ...page,
                landingPages: newPage,
              });
              setNeedRefetch([
                ...needRefetch,
                GetResponseSection.LANDING_PAGES,
              ]);
            }}
            needRefetch={(sectionToEdit) =>
              setNeedRefetch([...needRefetch, ...sectionToEdit])
            }
          />
        );
      case 3:
        return (
          <ProductSection
            data={
              data && data.products
                ? (data.products as ProductGetResponseModel[])
                : []
            }
            contactList={
              data && data.campaigns ? (data.campaigns as CampaignModel[]) : []
            }
            autoresponderList={
              data && data.autoResponder
                ? (data.autoResponder as AutoResponderModel[])
                : []
            }
            page={1}
            setPage={() => {}}
            needRefetch={(sectionToEdit) =>
              setNeedRefetch([...needRefetch, ...sectionToEdit])
            }
          />
        );
      case 4:
        return (
          <AutoResponderSection
            data={
              data && data.autoResponder
                ? (data.autoResponder as AutoResponderModel[])
                : []
            }
            page={page.autoResponder}
            setPage={(newPage) => {
              setPage({
                ...page,
                autoResponder: newPage,
              });
              setNeedRefetch([
                ...needRefetch,
                GetResponseSection.AUTO_RESPONDERS,
              ]);
            }}
            needRefetch={(sectionToEdit) =>
              setNeedRefetch([...needRefetch, ...sectionToEdit])
            }
          />
        );
      case 5:
        return (
          <CampaignSection
            data={
              data && data.campaigns ? (data.campaigns as CampaignModel[]) : []
            }
            page={page.campaigns}
            setPage={(newPage) => {
              setPage({
                ...page,
                campaigns: newPage,
              });
              setNeedRefetch([...needRefetch, GetResponseSection.CAMPAIGNS]);
            }}
            needRefetch={(sectionToEdit) =>
              setNeedRefetch([...needRefetch, ...sectionToEdit])
            }
          />
        );
      case 6:
        return (
          <ContactSection
            data={
              data && data.contacts ? (data.contacts as ContactModel[]) : []
            }
            page={page.contacts}
            setPage={(newPage) => {
              setPage({
                ...page,
                contacts: newPage,
              });
              setNeedRefetch([...needRefetch, GetResponseSection.CONTACTS]);
            }}
            needRefetch={(sectionToEdit) =>
              setNeedRefetch([...needRefetch, ...sectionToEdit])
            }
          />
        );
    }
  };

  return (
    <div className="m-4">
      <ContentHeader title={"Get Response"} />
      <div className="h-6"></div>

      {account && account !== null && (
        <div className="border border-black rounded-xl shadow-2xl bg-white p-4">
          <div className="font-bold text-lg md:text-xl">
            {account.firstName} {account.lastName}
          </div>
          <div className="font-medium">{account.companyName}</div>
        </div>
      )}

      <div className="mt-4">
        <Tabs
          tabItems={[
            "Account",
            "Shop",
            "Landing Pages",
            "Product X GR",
            "Auto Responder",
            "Campaigns",
            "Contacts",
          ]}
          selectedIndex={selectedTabIndex}
          onClickTab={(index) => {
            setSelectedTabIndex(index);
          }}
        />
        <div className="border border-black rounded-b-xl shadow-2xl p-4 bg-white">
          {buildViewBasedOnTab()}
        </div>
      </div>
    </div>
  );
};
