import { PATH_VOUCHERS } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { VoucherModel } from "../data/voucher.model";

export const GetVoucherUsecase: (id: string) => Promise<VoucherModel> = async (
  id
) => {
  try {
    let response = await client.get(PATH_VOUCHERS + "/" + id);
    return response.data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
