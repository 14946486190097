/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  FaCalculator,
  FaExclamationTriangle,
  FaRegCalendarAlt,
  FaUserAlt,
} from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

import { AccordionComponent } from "@Components/accordion/Accordion.component";

import { ContentHeader } from "@Components/ContentHeader.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import {
  VoucherModel,
  VoucherProductModel,
  VoucherProductRequest,
} from "../data/voucher.model";
import { DeleteVoucherUsecase } from "../domain/DeleteVoucher.usecase";
import { GetVoucherUsecase } from "../domain/GetVoucher.usecase";
import { UpdateVoucherUsecase } from "../domain/UpdateVoucher.usecase";
import { convertDateFromApiToView } from "@Helpers/formatter";
import { AiOutlineMinusCircle } from "react-icons/ai";

export type DetailVoucherFormsInput = {
  code: string;
  voucher_value: number;
  voucher_type: string;
  maximum_use: string;
  expired_date: string;
  description?: string;
};

export const DetailVoucherPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const modalContext = UseModalContext();
  const toasterContext = UseToasterContext();

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<DetailVoucherFormsInput>();

  const [detail, setDetail] = useState<VoucherModel | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = await GetVoucherUsecase(params.id ?? "");
        setValue("code", data.code);
        setValue("description", data.description ?? "");
        setValue("voucher_type", data.type);
        setValue("voucher_value", data.value);
        setValue("maximum_use", data.max_usage);
        setValue("expired_date", data.expires_at);

        setDetail(data);
      } catch (errors) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to fetch media",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      }
    }
    fetchData();
  }, []);

  const onMoveToTrash = async () => {
    try {
      let formData = new FormData();
      formData.append("move_to_trash", "true");

      let message = await UpdateVoucherUsecase(params.id ?? "", formData);
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Voucher moved to trash!",
          message,
          ToasterType.SUCCESS
        ),
      ]);
      popWithDelay();
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to move voucher to trash!",
          errors.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const onUpdateData = async (status: string) => {
    try {
      let formData = new FormData();

      let maxUse = watch("maximum_use") !== "" ? watch("maximum_use") : "0";

      formData.append("type", watch("voucher_type"));
      formData.append("value", watch("voucher_value").toString());
      formData.append("max_usage", maxUse);
      formData.append("status", status);
      formData.append("move_to_trash", "false");
      formData.append("description", watch("description") ?? "");

      let expiredDate = watch("expired_date");

      if (expiredDate !== null) {
        expiredDate = expiredDate.replace(" ", "T");
        if ((expiredDate.match(/:/g) || []).length > 1) {
          expiredDate += "+07:00";
        } else {
          expiredDate += ":00+07:00";
        }
        formData.append("expires_at", expiredDate);
      }

      let products: VoucherProductRequest[] = [];
      detail?.products?.forEach((item: VoucherProductModel) => {
        let product: VoucherProductRequest = {
          id: item.product.id,
        };

        if (item.value && item.value > 0) {
          product.value = item.value;
        }

        products.push(product);
      });

      formData.append("products", JSON.stringify(products));

      let message = await UpdateVoucherUsecase(params.id ?? "", formData);
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Voucher updated successfully!",
          message,
          ToasterType.SUCCESS
        ),
      ]);
      popWithDelay();
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to update voucher!",
          errors.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const onDelete = async () => {
    try {
      await DeleteVoucherUsecase(params.id ?? "");
      navigate(-1);
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to update voucher!",
          errors.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const onShowDeleteModal = () => {
    let icon = (
      <div className="text-red-500">
        <FaExclamationTriangle />
      </div>
    );
    let title = "Hapus Produk";
    let description = (
      <>
        Apakah anda yakin ingin menghapus{" "}
        <span className="font-bold">{detail?.title}</span> ?
      </>
    );
    let primaryCta = (
      <button
        className="button-red-outline-with-hover"
        onClick={() => onDelete()}
      >
        Hapus
      </button>
    );
    let secondaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(icon, title, description, primaryCta, secondaryCta)
    );
  };

  const popWithDelay = () => {
    setTimeout(function () {
      navigate(-1);
    }, 1000);
  };

  return (
    <div className="p-4 h-full bg-gray-300">
      {!detail && <MidasShimmering />}
      {detail && (
        <>
          <ContentHeader title={detail.title} />
          <div className="h-6"></div>

          {/* code field */}
          <div className="w-full mt-4">
            <div>Code</div>
            <input
              type="text"
              className={
                errors.code
                  ? "input-field-full-width border-red-500"
                  : "input-field-full-width"
              }
              {...register("code", { required: "Voucher code is required." })}
              disabled={true}
            />
            {errors.code && (
              <p className="text-red-500 text-xs">{errors.code.message}</p>
            )}
          </div>

          {/* value field */}
          <div className="w-full mt-4">
            <div>Value</div>
            <div className="flex">
              <div className="w-full">
                <input
                  className={
                    errors.voucher_value
                      ? "input-field-full-width border-red-500"
                      : "input-field-full-width"
                  }
                  type="number"
                  {...register("voucher_value", {
                    required: "Voucher value is required.",
                    min: {
                      value: 1,
                      message: "Voucher value must be more than zero.",
                    },
                  })}
                />
              </div>
              <div className="w-full pl-2">
                <select
                  className={
                    errors.voucher_type
                      ? "input-field-full-width border-red-500"
                      : "input-field-full-width"
                  }
                  {...register("voucher_type", {
                    required: "Voucher type is required",
                  })}
                >
                  <option value="percentage">Percentage</option>
                  <option value="fixed">Fixed</option>
                </select>
              </div>
            </div>
            {errors.voucher_value ? (
              <p className="text-red-500 text-xs">
                {errors.voucher_value?.message}
              </p>
            ) : errors.voucher_type ? (
              <p className="text-red-500 text-xs">
                {errors.voucher_type?.message}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* Max Usage field */}
          <div className="font-medium mt-2">Maximum Use</div>
          <div className="flex border rounded w-full">
            <input
              className="w-full p-1"
              type="number"
              {...register("maximum_use", {
                required: "Maximum use is required",
                min: {
                  value: 1,
                  message: "Maximum use must be more than zero.",
                },
              })}
            />
            <div className="bg-gray-400 pt-1 font-semibold object-center align-middle px-2 text-gray-50">
              qty
            </div>
          </div>
          {errors.maximum_use && (
            <p className="text-red-500 text-xs">
              {errors.maximum_use?.message}
            </p>
          )}

          {/* expired date field */}
          <div className="font-medium my-auto w028 mt-2">
            Expired Date<span className="text-red-500">*</span>
          </div>
          <input
            type="datetime-local"
            className={
              errors.expired_date
                ? "input-field-full-width border-red-500"
                : "input-field-full-width"
            }
            {...register("expired_date", {
              required: "Expire date is required.",
            })}
          />
          {errors.expired_date && (
            <p className="text-red-500 text-xs">
              {errors.expired_date?.message}
            </p>
          )}

          <div className="w-full mt-4">
            <div>Description</div>
            <input
              type="text"
              className="input-field-full-width"
              {...register("description")}
            />
          </div>

          <div className="w-full mt-4 border bg-white">
            <AccordionComponent title="Products List" isOpen={true}>
              <div className="flex flex-col p-4">
                <div className="mt-2">
                  <table className="table-auto border w-full">
                    <thead className="p-2 shadow-sm bg-gray-100 sticky">
                      <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Value</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {detail.products.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td className="text-center p-2">{index + 1}</td>
                            <td className="text-center">
                              {item.product.title}
                            </td>
                            <td className="text-center">
                              <input
                                className="text-center"
                                type="number"
                                value={item.value ? item.value : 0}
                                onChange={(e) => {
                                  try {
                                    if (
                                      e.target.value !== undefined &&
                                      e.target.value !== ""
                                    ) {
                                      let newDetailProducts = [
                                        ...detail.products,
                                      ];
                                      if (parseInt(e.target.value) > 0) {
                                        newDetailProducts[index] = {
                                          ...newDetailProducts[index],
                                          value: parseInt(e.target.value),
                                        };
                                      } else if (
                                        parseInt(e.target.value) === 0
                                      ) {
                                        newDetailProducts[index] = {
                                          ...newDetailProducts[index],
                                          value: null,
                                        };
                                      }

                                      setDetail({
                                        ...detail,
                                        products: newDetailProducts,
                                      });
                                    }
                                  } catch (e) {
                                    toasterContext.setToastList([
                                      ...toasterContext.toastList,
                                      new ToasterModel(
                                        "Type mismatch",
                                        "Value must be number",
                                        ToasterType.DANGER
                                      ),
                                    ]);
                                  }
                                }}
                              />
                            </td>
                            <td className="text-red-500">
                              <AiOutlineMinusCircle
                                size={24}
                                onClick={() => {
                                  let newDetailProducts = [...detail.products];
                                  delete newDetailProducts[index];
                                  setDetail({
                                    ...detail,
                                    products: newDetailProducts,
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </AccordionComponent>
          </div>

          <div className="w-full mt-4 border bg-white">
            <AccordionComponent title="Save" isOpen={true}>
              <div className="p-4">
                {/* Status */}
                <div className="flex">
                  <div className="text-sm my-auto text-ellipsis">Status: </div>
                  <div className="font-medium ml-2">
                    {detail.deleted_at != null ? (
                      <div className="text-red-500">In Trash</div>
                    ) : detail.status === "draft" ? (
                      <div className="text-yellow-500">Draft</div>
                    ) : detail.status === "expired" ? (
                      <div className="text-orange-500">Expired</div>
                    ) : detail.status === "exceed_limit" ? (
                      <div className="text-orange-500">All Used</div>
                    ) : (
                      <div className="text-green-500">Published</div>
                    )}
                  </div>
                </div>

                {/* Voucher used */}
                <div className="flex mt-2">
                  <FaCalculator size={16} />
                  <div className="ml-2 text-xs m-auto text-ellipsis">
                    Used: <span className="font-medium">{detail.used}</span>
                  </div>
                </div>

                {/* Voucher in cart */}
                <div className="flex mt-2">
                  <FaCalculator size={16} />
                  <div className="ml-2 text-xs m-auto text-ellipsis">
                    In Cart:{" "}
                    <span className="font-medium">{detail.in_cart}</span>
                  </div>
                </div>

                {detail.updated_by != null && (
                  <>
                    {/* Updated Date */}
                    <div className="flex mt-2">
                      <FaRegCalendarAlt size={16} />
                      <div className="ml-2 text-xs m-auto text-ellipsis">
                        Updated on:{" "}
                        <span className="font-medium">
                          {detail.updated_at &&
                            convertDateFromApiToView(detail.updated_at)}
                        </span>
                      </div>
                    </div>

                    {/* Updated By */}
                    <div className="flex mt-2">
                      <FaUserAlt size={16} />
                      <div className="ml-2 text-xs m-auto text-ellipsis">
                        Updated by:{" "}
                        <span className="font-medium">
                          {detail.updated_by.fullname}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="flex border-t-2 justify-between py-2 mt-2 px-4">
                <div className="my-auto">
                  <button
                    className="button-red-outline-with-hover text-xs"
                    onClick={onShowDeleteModal}
                  >
                    Delete Permanently
                  </button>
                  <button
                    className="button-red-outline-with-hover ml-2 text-xs"
                    type="button"
                    onClick={onMoveToTrash}
                  >
                    Move to Trash
                  </button>
                </div>
                <div className="my-auto">
                  <button
                    className="button-outline-with-hover text-xs"
                    onClick={() => onUpdateData("draft")}
                  >
                    Save to Draft
                  </button>
                  <button
                    className="ml-2 button-yellow-outline-with-hover text-xs"
                    onClick={() => onUpdateData("publish")}
                  >
                    Publish
                  </button>
                </div>
              </div>
            </AccordionComponent>
          </div>
        </>
      )}
    </div>
  );
};
