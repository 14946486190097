import { PATH_ROLES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { RoleModel } from "../data/role.model";

export const GetRoleUsecase: (id: string) => Promise<RoleModel> = async (
  id
) => {
  try {
    let response = await client.get(PATH_ROLES + "/" + id);
    return {
      ...response.data.data,
      access: (response.data.data.access as string).split(";"),
    };
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
